export const CUIT_ONLINE = `
  query verifyCuitOnline($input: String!){
    verifyCuitOnline(input: $input) {
      __typename
      ... on DniAsegurado {
        name
        fiscalIdType
        fiscalID
        birthDate
        personType
        gender
        documentType
        documentNumber
        postalCode
        province
        city
        addressStreet
        addressNumber
        businessDate
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
