export const TRACKING_TYPES = `query tracking_types {
  tracking_types {
    __typename
    ... on TrackingType {
      id
      description
      id_liderar
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
