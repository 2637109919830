import { useState, useCallback, useEffect, useContext } from "react";
import GraphqlService from "../../services/graphql/GraphqlService";
import { Query } from "../../services/graphql/query";
import { IProvince } from "../../services/Province";
import { IAjustmentRates } from "../../services/AjustmentRates";
import { ICoveragePeriod } from "../../services/CoveragePeriod";
import { IFiscalCondition } from "../../services/FiscalCondition";
import { IDestino } from "../../services/IDestino";
import { ContextApp, CustomMessage } from "../../shared";
import { EnumsValues } from "../EnumsValues";
import { IAppSetting } from "../../services/AppSetting";

export const useQuotation = () => {
  const { customRequest } = GraphqlService();
  const { user } = useContext(ContextApp);
  const { messageError } = CustomMessage();
  const [fiscalConditions, setFiscalConditions] = useState<IFiscalCondition[]>(
    []
  );
  const [provinces, setProvinces] = useState<IProvince[]>([]);
  const [destinos, setDestinos] = useState<IDestino[]>([]);
  const [adjustmentRates, setAdjustmentRates] = useState<IAjustmentRates[]>([]);
  const [coveragePeriods, setCoveragePeriods] = useState<ICoveragePeriod[]>([]);

  const [amountInsuredVariation, setAmountInsuredVariation] =
    useState<IAppSetting>();
  const [totalAccessoriesLimit, setTotalAccessoriesLimit] =
    useState<IAppSetting>();

  const getAmountInsuredVariation = useCallback(async () => {
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.maxAmountAccessories },
        },
      });
      setTotalAccessoriesLimit(() => data);
    } catch (error: any) {
      messageError({
        context: "getAmountInsuredVariation",
        message: error?.message,
      });
    }
  }, []);

  const getTotalAccessoriesLimit = useCallback(async () => {
    try {
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.amountInsuredVariation },
        },
      });
      setAmountInsuredVariation(() => data);
    } catch (error: any) {
      messageError({
        context: "getTotalAccessoriesLimit",
        message: error?.message,
      });
    }
  }, []);

  const getFiscalConditions = useCallback(async () => {
    await customRequest({
      query: Query.fiscal_conditions,
      variables: {
        filter: {
          activo_web: true,
        },
      },
    })
      .then((response: IFiscalCondition[]) => {
        if (response && response.length > 0) {
          setFiscalConditions(response);
        }
      })
      .catch(() => {
        messageError({
          context: "getFiscalConditions",
          message: "Error al obtener las condiciones fiscales",
        });
      });
  }, []);

  const getProvinces = useCallback(async () => {
    await customRequest({
      query: Query.provinces,
    })
      .then((response: IProvince[]) => {
        setProvinces(response);
      })
      .catch(() => {
        messageError({
          context: "getProvinces",
          message: "Error al obtener las provincias",
        });
      });
  }, []);

  const getDestinos = useCallback(async () => {
    await customRequest({
      query: Query.destinys,
    })
      .then((response: IDestino[]) => {
        if (response && response.length > 0) {
          setDestinos(response);
        }
      })
      .catch((error: any) => {
        messageError({
          context: "getDestinos",
          message: "Error al obtener los destinos",
        });
      });
  }, []);

  const getAdjustmentRates = useCallback(async () => {
    await customRequest({
      query: Query.ajustment_rates,
    })
      .then((response: IAjustmentRates[]) => {
        if (response && response.length > 0) {
          setAdjustmentRates(response);
        }
      })
      .catch((error: any) => {
        messageError({
          context: "getAdjustmentRates",
          message: "Error al obtener las tasas de ajuste",
        });
      });
  }, []);

  const getCoveragePeriods = useCallback(async () => {
    await customRequest({
      query: Query.coverage_periods,
      variables: {
        filter: {
          activo_web: true,
          user_id: user?.id,
        },
        orderBy: { field: "description", direction: "asc" },
      },
    })
      .then((response: ICoveragePeriod[]) => {
        if (response && response.length > 0) {
          setCoveragePeriods(response);
        }
      })
      .catch((error: any) => {
        messageError({
          context: "getCoveragePeriods",
          message: "Error al obtener los periodos de cobertura",
        });
      });
  }, []);

  useEffect(() => {
    getFiscalConditions();
    getProvinces();
    getDestinos();
    getAdjustmentRates();
    getCoveragePeriods();
    getAmountInsuredVariation();
    getTotalAccessoriesLimit();
  }, []);

  return {
    fiscalConditions,
    provinces,
    destinos,
    adjustmentRates,
    coveragePeriods,
    amountInsuredVariation,
    totalAccessoriesLimit,
  };
};
