import { CarFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd";
import { IQuotationForEmision } from "../../../services/IQuotationForEmision";
import { coverageIdLiderar } from "../../../shared/EnumsValues";
import { formatStringToMoney } from "../../../shared/utils";

interface ICardCoverageProps {
  quotation: IQuotationForEmision | undefined;
}

const CardVehicle = (props: ICardCoverageProps): JSX.Element => {
  const { quotation } = props;
  const vehicleBrand = quotation?.quotation_request?.brand_model?.brand;
  const vehicleModel = quotation?.quotation_request?.brand_model?.model;
  const insured_amount = quotation?.quotation_request?.insured_amount;
  const vehicleYear = quotation?.quotation_request?.year;
  const adjustment_rate =
    quotation?.quotation_request?.adjustment_rate.description;

  return (
    <Card bordered={false} className="ant-card-1">
      <Col span={2}>
        <CarFilled
          className="icon"
          style={{ fontSize: "30px", color: "#763790" }}
        />
      </Col>
      <Col span={12}>
        <Row style={{ display: "flex", flexDirection: "column" }}>
          <p>
            Marca: <b>{vehicleBrand || "-"}</b>
          </p>
          <p>
            Modelo: <b>{vehicleModel || "-"}</b>
          </p>
        </Row>
      </Col>
      <Col
        span={10}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <p>
          Año: <b>{vehicleYear || "-"}</b>
        </p>
        <p>
          Suma asegurada:{" "}
          <b>
            {insured_amount
              ? formatStringToMoney(
                  quotation?.coverage?.id_liderar === coverageIdLiderar.A
                    ? "0"
                    : insured_amount
                )
              : "-"}
          </b>
        </p>
        <p>
          Cláusula de ajuste: <b>{adjustment_rate ? adjustment_rate : "-"}</b>
        </p>
      </Col>
    </Card>
  );
};

export default CardVehicle;
