export const SEARCH_RENOVATION_CSV = `
mutation searchRenovationCSV {
  searchRenovationCSV {
    __typename
    ... on SuccessfulResponse {
      status
      status_message
    }
  }
}
`;
