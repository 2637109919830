import { FormInstance, List, Modal } from "antd";
import { useEffect, useMemo, useState, useCallback } from "react";
import VirtualList from "rc-virtual-list";

import GraphqlService from "../../../services/graphql/GraphqlService";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";
import FormWithProducerList from "./FormWithProducerList/FormWithProducerList";
import { EnumsValues } from "../../../shared/EnumsValues";
import {
  IProducerByCuit,
  IProducerByGroup,
  IUserProducer,
} from "../../../services/UserProducer";
import { CustomMessage } from "../../../shared";
import { IProducer } from "../../../services/Producer";
import { IUser } from "../../../services/user";

interface IModalUsarProducerProps {
  manageProducerModalVisible: boolean;
  setManageProducerModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  userId: number;
  dataProducerModal: IUser | undefined;
}

const ModalUserProducer = (props: IModalUsarProducerProps): JSX.Element => {
  const [isDirectInsuranceOnly, setIsDirectInsuranceOnly] = useState(false);

  const { manageProducerModalVisible, setManageProducerModalVisible, userId } =
    props;

  const { Mutation, Query, customRequest } = GraphqlService();
  const { messageSuccess, messageModalError } = CustomMessage();

  // CUIT
  const [allProducerListForInputByCuit, setAllProducerListForInputByCuit] =
    useState<string[]>([]);
  const [producersAsignedByCuit, setProducersAsignedByCuit] = useState<
    string[]
  >([]);

  // CODIGO
  const [producerListForInputLiderar, setProducerListForInputLiderar] =
    useState<IProducer[]>([]);
  const [producersAsignedByCode, setProducersAsignedByCode] = useState<
    IUserProducer[]
  >([]);

  // GRUPO
  const [allProducerListForInputByGroup, setAllProducerListForInputByGroup] =
    useState<string[]>([]);
  const [producersAsignedByGroup, setProducersAsignedByGroup] = useState<
    string[]
  >([]);

  const [modalSeeProducersOpen, setModalSeeProducersOpen] =
    useState<boolean>(false);
  const [producersForModal, setProducersForModal] = useState<any>([]);
  const [seeProducersModalTitle, setSeeProducersModalTitle] =
    useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const producersToShowByCode = useMemo(() => {
    // Todos los productores cuyo cuit y grupo no estan asignados
    return producersAsignedByCode.filter(
      (item) =>
        !producersAsignedByCuit.includes(item.producer.cuit) &&
        !producersAsignedByGroup.includes(item.producer.group)
    );
  }, [producersAsignedByCode, producersAsignedByCuit, producersAsignedByGroup]);

  const producersToShowByCodeOnInput = useMemo(() => {
    // Todos los productores que no esten seleccionados ,y ademas ni su cuit ni grupo estan asignados
    return producerListForInputLiderar.filter(
      (item) =>
        !producersToShowByCode
          .map((itemProducer) => itemProducer.producer.id)
          .includes(item.id) &&
        !producersAsignedByGroup.includes(item.group) &&
        !producersAsignedByCuit.includes(item.cuit)
    );
  }, [
    producerListForInputLiderar,
    producersAsignedByCuit,
    producersAsignedByGroup,
    producersToShowByCode,
  ]);

  const producersToShowByGroupOnInput = useMemo(() => {
    return allProducerListForInputByGroup.filter(
      (item) => !producersAsignedByGroup.includes(item)
    );
  }, [allProducerListForInputByGroup, producersAsignedByGroup]);

  const producersToShowByCuitOnInput = useMemo(() => {
    return allProducerListForInputByCuit.filter(
      (item) => !producersAsignedByCuit.includes(item)
    );
  }, [allProducerListForInputByCuit, producersAsignedByCuit]);

  const getProducersLiderar = useCallback(() => {
    return customRequest({
      query: Query.producers,
      variables: {
        filter: {
          cuit: isDirectInsuranceOnly
            ? EnumsValues.ProducerCuit.LIDERAR
            : undefined,
          activo_web: true,
        },
      },
    })
      .then((data: IProducer[]) => {
        setProducerListForInputLiderar(data);
      })
      .catch((error: any) => {
        messageModalError({
          context: "producers",
          message: error.message,
        });
      });
  }, [isDirectInsuranceOnly]);

  useEffect(() => {
    if (manageProducerModalVisible) {
      getProducersLiderar();
    }
  }, [isDirectInsuranceOnly, manageProducerModalVisible]);

  const deleteUserProducerWithCode = (id: number) => {
    return customRequest({
      query: Mutation.deleteProducers,
      variables: {
        input: {
          user_id: userId,
          producer_id: id,
        },
      },
    })
      .then(() => {
        messageSuccess({
          context: "deleteProducers",
          message: "Asignacion de productor borrada correctamente",
          time: 2000,
        });
      })
      .catch((error: any) => {
        messageModalError({
          context: "deleteProducers",
          message: error.message,
        });
      });
  };

  const createUserProducerWithCode = (id: number) => {
    return customRequest({
      query: Mutation.setProducer,
      variables: {
        input: {
          user_id: userId,
          producer_id: id,
          related_to: EnumsValues.SetUserProducerBy.CODE,
        },
      },
    })
      .then(() => {
        messageSuccess({
          context: "producers",
          message: "Productor creado correctamente",
        });
        getProducersLiderar();
      })
      .catch((error: any) => {
        messageModalError({
          context: "producers",
          message: error.message,
        });
      });
  };

  const getAllProducers = () => {
    return customRequest({
      query: Query.producers,
      variables: {
        filter: {
          activo_web: true,
        },
      },
    })
      .then((data: IProducer[]) => {
        if (data) {
          const producersByCuitForInput = Array.from(
            new Set(data.map((producers) => producers.cuit))
          );
          setAllProducerListForInputByCuit(producersByCuitForInput);

          const producersByGroupForInput = Array.from(
            new Set(data.map((producers) => producers.group))
          );
          setAllProducerListForInputByGroup(producersByGroupForInput);
        }
      })
      .catch((error: any) => {
        messageModalError({
          context: "producers",
          message: error.message,
        });
      });
  };

  const getAllProducersAsignedToUserIdByCode = () => {
    return customRequest({
      query: Query.getProducers,
      variables: {
        filter: {
          user_id: userId,
          related_to: EnumsValues.SetUserProducerBy.CODE,
        },
      },
    })
      .then((data: IUserProducer[]) => {
        if (data) {
          setProducersAsignedByCode(data);
        }
      })
      .catch((error: any) => {
        messageModalError({
          context: "getProducers",
          message: error.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getProducersAsignedToUserIdByCuit = () => {
    return customRequest({
      query: Query.getProducerCuit,
      variables: {
        filter: {
          user_id: userId,
        },
      },
    })
      .then((data: IProducerByCuit[]) => {
        if (data) {
          setProducersAsignedByCuit(
            data.map((producer) => producer.producer_cuit)
          );
        }
      })
      .catch((error: any) => {
        messageModalError({
          context: "getProducerCuit",
          message: error.message,
        });
      });
  };

  const getProducersAsignedToUserIdByGroup = () => {
    return customRequest({
      query: Query.getProducerGroup,
      variables: {
        filter: {
          user_id: userId,
        },
      },
    })
      .then((data: IProducerByGroup[]) => {
        if (data) {
          setProducersAsignedByGroup(
            data.map((producer) => producer.producer_group)
          );
        }
      })
      .catch((error: any) => {
        messageModalError({
          context: "getProducerCuit",
          message: error.message,
        });
      });
  };

  const deleteUserProducer = (cuit: string) => {
    return customRequest({
      query: Mutation.deleteUserProducerCuit,
      variables: {
        input: {
          user_id: userId,
          producer_cuit: cuit,
        },
      },
    })
      .then(() => {
        messageSuccess({
          context: "deleteUserProducerCuit",
          message: "Asignacion de productor borrada correctamente",
          time: 2000,
        });
      })
      .catch((error: any) => {
        messageModalError({
          context: "deleteUserProducerCuit",
          message: error.message,
        });
      });
  };

  const createUserProducerCuit = (cuit: string) => {
    return customRequest({
      query: Mutation.createUserProducerCuit,
      variables: {
        input: {
          user_id: userId,
          producer_cuit: cuit,
        },
      },
    })
      .then(() => {
        messageSuccess({
          context: "createUserProducerCuit",
          message: "Asignacion de productor creada correctamente",
          time: 2000,
        });
      })
      .catch((error: any) => {
        messageModalError({
          context: "createUserProducerCuit",
          message: error.message,
        });
      });
  };

  const createUserProducerGroup = (group: string) => {
    return customRequest({
      query: Mutation.createUserProducerGroup,
      variables: {
        input: {
          user_id: userId,
          producer_group: group,
        },
      },
    })
      .then(() => {
        messageSuccess({
          context: "createUserProducerGroup",
          message: "Asignacion de productor creada correctamente",
          time: 2000,
        });
      })
      .catch((error: any) => {
        messageModalError({
          context: "createUserProducerGroup",
          message: error.message,
        });
      });
  };

  const deleteUserProducerGroup = (group: string) => {
    return customRequest({
      query: Mutation.deleteUserProducerGroup,
      variables: {
        input: {
          user_id: userId,
          producer_group: group,
        },
      },
    })
      .then(() => {
        messageSuccess({
          context: "deleteUserProducerGroup",
          message: "Asignacion de productor borrada correctamente",
          time: 2000,
        });
      })
      .catch((error: any) => {
        messageModalError({
          context: "deleteUserProducerGroup",
          message: error.message,
        });
      });
  };

  useEffect(() => {
    if (userId) {
      getProducersLiderar();
      getAllProducers();
      getAllProducersAsignedToUserIdByCode();
      getProducersAsignedToUserIdByCuit();
      getProducersAsignedToUserIdByGroup();
    }
  }, [userId]);

  const getProducersForModal = (cuit?: string, group?: string) => {
    return customRequest({
      query: Query.producers,
      variables: {
        filter: {
          activo_web: true,
          cuit,
          group,
        },
      },
    })
      .then((data: IProducer[]) => {
        setProducersForModal(data);
      })
      .catch((error: any) => {
        messageModalError({
          context: "producers",
          message: error.message,
        });
      });
  };

  const handleDeleteWithCuit = async (cuit: string) => {
    setIsLoading(true);
    await deleteUserProducer(cuit);
    getProducersAsignedToUserIdByCuit();
    getAllProducersAsignedToUserIdByCode();
  };

  const handleCreateWithCuit = async (cuit: string) => {
    await createUserProducerCuit(cuit);
    getProducersAsignedToUserIdByCuit();
    getAllProducersAsignedToUserIdByCode();
  };

  const handleDeleteWithCode = async (producer: IUserProducer) => {
    setIsLoading(true);
    await deleteUserProducerWithCode(producer.producer.id);
    getAllProducersAsignedToUserIdByCode();
  };

  const handleCreateWithCode = async (id: any) => {
    const producer = await producerListForInputLiderar.find(
      (producerParam) => producerParam.id_liderar === id
    );

    await createUserProducerWithCode(Number(producer?.id));
    getAllProducersAsignedToUserIdByCode();
  };

  const handleDeleteWithGroup = async (group: string) => {
    setIsLoading(true);
    await deleteUserProducerGroup(group);
    getProducersAsignedToUserIdByGroup();
    getAllProducersAsignedToUserIdByCode();
  };

  const handleCreateWithGroup = async (group: string) => {
    await createUserProducerGroup(group);
    getProducersAsignedToUserIdByGroup();
    getAllProducersAsignedToUserIdByCode();
  };

  return (
    <>
      <Modal
        title="Productores relacionados"
        className="modal-user-producers"
        visible={manageProducerModalVisible}
        destroyOnClose={true}
        onOk={() => {
          setManageProducerModalVisible(true);
        }}
        footer={null}
        onCancel={() => {
          setManageProducerModalVisible(false);
          setIsDirectInsuranceOnly(false);
        }}
        style={{ minWidth: "80%", top: "2vh" }}
      >
        <FormWithProducerList
          listToRenderOnInput={producersToShowByCuitOnInput.map(
            (cuit: string, index) => {
              return {
                key: index,
                value: cuit,
                label: cuit,
              };
            }
          )}
          labelForInput={"Buscar CUIT"}
          labelForList={"Productores asignados por CUIT"}
          onFinish={(formValues: any, form: FormInstance) => {
            handleCreateWithCuit(formValues.productor_search);
            form.resetFields();
          }}
          producersSelected={producersAsignedByCuit}
          renderListFormat={(value: string) => <List.Item.Meta title={value} />}
          handleDelete={handleDeleteWithCuit}
          seeProducers={async (value) => {
            await getProducersForModal(value);
            setSeeProducersModalTitle(`Productores del CUIT ${value}`);
            setModalSeeProducersOpen(true);
          }}
          isLoading={isLoading}
        />
        <FormWithProducerList
          listToRenderOnInput={producersToShowByGroupOnInput.map(
            (group: string, index) => {
              return {
                key: index,
                value: group,
                label: group,
              };
            }
          )}
          labelForInput={"Buscar grupo"}
          labelForList={"Productores asignados por grupo"}
          onFinish={(formValues: any, form: FormInstance) => {
            handleCreateWithGroup(formValues.productor_search);
            form.resetFields();
          }}
          producersSelected={producersAsignedByGroup}
          renderListFormat={(value: string) => <List.Item.Meta title={value} />}
          handleDelete={handleDeleteWithGroup}
          seeProducers={async (value) => {
            await getProducersForModal(undefined, value);
            setSeeProducersModalTitle(`Productores del grupo ${value}`);
            setModalSeeProducersOpen(true);
          }}
          isLoading={isLoading}
        />

        <FormWithProducerList
          listToRenderOnInput={producersToShowByCodeOnInput.map(
            (producer: IProducer) => {
              return {
                key: producer.id,
                value: producer.id_liderar,
                label: `${producer.id_liderar} - ${producer.nombre}`,
              };
            }
          )}
          labelForInput={"Buscar productor por código"}
          labelForList={"Productores asignados por código"}
          onFinish={(formValues: any, form: FormInstance) => {
            handleCreateWithCode(formValues.productor_search);
            form.resetFields();
          }}
          producersSelected={producersToShowByCode}
          showCheckBox
          onCheckBoxChange={(e: any) =>
            setIsDirectInsuranceOnly(e.target.checked)
          }
          renderListFormat={(value: any) => {
            return (
              <List.Item.Meta
                title={`${value.producer.id_liderar} - ${value.producer.nombre}`}
              />
            );
          }}
          handleDelete={handleDeleteWithCode}
          isLoading={isLoading}
        />
        <span>
          Nota: Escribir en el input de búsqueda al menos 3 números para que
          aparezca el listado
        </span>
        <div className="buttons">
          <ButtonLiderar
            color="white"
            buttonName="Volver"
            onClick={() => setManageProducerModalVisible(false)}
          />
        </div>
      </Modal>
      {producersForModal && (
        <Modal
          title={seeProducersModalTitle || "Productores"}
          visible={modalSeeProducersOpen}
          footer={null}
          destroyOnClose={true}
          style={{ top: "2vh" }}
          onCancel={() => {
            setModalSeeProducersOpen(false);
            setSeeProducersModalTitle(undefined);
          }}
        >
          <List size="small" bordered>
            <VirtualList data={producersForModal} height={600} itemKey="id">
              {(item) => (
                <List.Item
                  key={item}
                >{`${item.id_liderar} - ${item.nombre}`}</List.Item>
              )}
            </VirtualList>
          </List>
        </Modal>
      )}
    </>
  );
};

export default ModalUserProducer;
