export const SET_CHECK_DEBT_SUMMARIES = `
  mutation set_check_debt_summaries($input: SetCheckDebtSummariesInput!){
    set_check_debt_summaries(input: $input){
      __typename
      ... on SyncBoolean {
        status
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
