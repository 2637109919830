export const ASEGURADO = `query asegurado($name: String!){
  asegurado(name: $name)  {
    __typename
    ... on AseguradoList {
      asegurado {
        nombre_aseg
        id_asegurado
        localidad
        provincia
        nro_documento
        cuit
        cod_tipo_documento
      }
    }
      ... on ResultError{
      status_code
      message
    }
  }
}`;
