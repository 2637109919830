import { PlayCircleOutlined } from "@ant-design/icons";
import ProTable, { ActionType } from "@ant-design/pro-table";
import { isPlatform } from "@ionic/react";
import { Modal, Tooltip } from "antd";
import { useRef, useState } from "react";
import { RouteConfigComponentProps } from "react-router-config";
import { IManualCron } from "../../services/Cron";
import GraphqlService from "../../services/graphql/GraphqlService";
import { ABM, CustomMessage } from "../../shared";
import { useCheckAuthority } from "../../shared/CustomHooks";
import { ExportableColumn } from "../../shared/Exporter";

import "./style.less";

const TITLE_PRO_TABLE = "Sincronización manual de tablas / procesos";

export default function Sync(
  routeProps: RouteConfigComponentProps
): JSX.Element {
  const [loading, setLoading] = useState<boolean>();
  const { messageError, messageSuccess } = CustomMessage();
  const { customRequest, Mutation } = GraphqlService();

  const actionRef = useRef<ActionType>();

  const CRONS: IManualCron[] = [
    {
      id: 1,
      name: "Accesorios",
      description: "Sincroniza tabla de accesorios",
      mutation: Mutation.upsertAccessory,
    },
    {
      id: 2,
      name: "Coberturas",
      description: "Sincroniza tabla de coberturas",
      mutation: Mutation.upsertCoverage,
    },
    {
      id: 3,
      name: "Destinos",
      description: "Sincroniza tabla de destinos",
      mutation: Mutation.upsertDestiny,
    },
    {
      id: 4,
      name: "Marca modelo",
      description: "Sincroniza tabla de marcas y modelos",
      mutation: Mutation.upsertBrandModel,
    },
    {
      id: 5,
      name: "Productores",
      description: "Sincroniza tabla de productores",
      mutation: Mutation.upsertProducer,
    },
    {
      id: 6,
      name: "Publicaciones",
      description: "Sincroniza publicaciones",
      mutation: Mutation.upsertPublications,
    },
    {
      id: 7,
      name: "Renovaciones",
      description: "Sincroniza renovaciones (CSV)",
      mutation: Mutation.searchRenovationCSV,
    },
    {
      id: 8,
      name: "Tasa de ajuste",
      description: "Sincroniza tabla de clausulas de ajuste",
      mutation: Mutation.upsertAdjustmentRate,
    },
    {
      id: 9,
      name: "Tipo de endoso",
      description: "Sincroniza tabla de tipos de endosos",
      mutation: Mutation.upsertEndorsementType,
    },
    {
      id: 10,
      name: "Tipo de vehículo",
      description: "Sincroniza tabla de tipos de vehículos",
      mutation: Mutation.upsertVehicleTypeUniverse,
    },
  ];

  interface IData {
    status: string;
    status_message: string;
  }

  const info = (data: IData[]) => {
    Modal.info({
      content: data.length ? (
        <>
          <h3>Renovaciones</h3>
          {data.map((item: any, index) => (
            <div key={index}>
              <p>{item.status}</p>
              <p>
                {item.status ? "Resultado - OK" : "Resultado - Error"}:{" "}
                {item.status_message}
              </p>
            </div>
          ))}
        </>
      ) : (
        <>No hay renovaciones para actualizar</>
      ),
      maskClosable: true,
      closable: true,
    });
  };

  const handleManualSync = (record: IManualCron) => {
    customRequest({
      mutation: record.mutation,
      variables: { key: "*" },
    })
      .then((response: any) => {
        if (record.mutation === Mutation.searchRenovationCSV) {
          info(response);
        } else {
          messageSuccess({
            context: "Sync.handleManualSync.1",
            message: "Sincronización iniciada correctamente",
            time: 2500,
          });
        }
        setLoading(false);
      })
      .catch((error: any) => {
        messageError({
          context: "Sync.handleManualSync.2",
          message: error.message || "Error al sincronizar",
        });
        setLoading(false);
      });
  };

  const columns = (): ExportableColumn<IManualCron>[] => [
    {
      export: false,
      dataIndex: "id",
      title: "id",
      hideInTable: true,
      hideInSearch: true,
      hideInForm: true,
      type: ABM.TYPE_COLUMN.NUMBER,
    },
    {
      export: true,
      dataIndex: "name",
      title: "Nombre",
      type: ABM.TYPE_COLUMN.STRING,
      render: (_: any, record) => record.name || "-",
      align: "left",
      hideInTable: false,
      hideInSearch: true,
      hideInForm: true,
    },
    {
      export: true,
      dataIndex: "description",
      title: "Descripción",
      type: ABM.TYPE_COLUMN.STRING,
      render: (_: any, record) => record.description || "-",
      align: "left",
      hideInTable: false,
      hideInSearch: true,
      hideInForm: true,
    },
    {
      title: "Acción",
      dataIndex: "option",
      valueType: "option",
      fixed: "right",
      width: 100,
      export: false,
      hideInTable: false,
      hideInSearch: true,
      hideInForm: true,
      render: (_, record) => (
        <Tooltip
          key="run_cron_tooltip"
          trigger={isPlatform("desktop") ? "hover" : " focus"}
          title={loading ? "Sincronizando..." : "Sincronizar"}
        >
          <PlayCircleOutlined
            className={loading ? "disabled-icon" : "pointer"}
            onClick={() => {
              if (!loading) {
                setLoading(true);
                handleManualSync(record);
              }
            }}
            disabled={true}
          />
        </Tooltip>
      ),
    },
  ];

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>{TITLE_PRO_TABLE}</h1>
        <ProTable<IManualCron>
          defaultSize="small"
          actionRef={actionRef}
          rowKey="id"
          /**
           * @description este metodo debe poder ejecutar siempre la consulta al backend
           */
          columns={columns()}
          dataSource={CRONS}
          search={false}
        />
      </>
    )
  );
}
