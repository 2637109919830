import {
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
  FormInstance,
  Select,
} from "antd";
import moment from "moment";
import { useState } from "react";
import ms from "ms";

import useLocationAndPostalCodes from "../../../shared/hooks/useLocationAndPostalCodes";
import { ICuitOnline } from "../../../services/CuitOnline";
import useCuitVerification from "../../../shared/hooks/useCuitVerification";
import SelectFetchOnEnter from "../../utils/SelectFetchOnEnter";
import { DEFAULT_FORMAT_DATE } from "../../../shared/MomentJS";
import { Datepicker } from "../../DatePicker/DatePicker";
import { formNames } from "../utils/formNames";
import { IGender } from "../../../services/Gender";
import { IAppSetting } from "../../../services/AppSetting";
import { EnumsValues } from "../../../shared";
import { EmissionById } from "../../../services/EmissionById";
import useFormatData from "../../../shared/hooks/useFormatData";

const { Panel } = Collapse;

interface ILegalRepresentativeProps {
  form: FormInstance;
  genderOptions: () => false | JSX.Element[];
  nationalityOptions: () => false | JSX.Element[];
  gendersTypes: IGender[];
  provinceOptions: () => false | JSX.Element[];
  minBornTime: IAppSetting | undefined;
  isReadOnlyForm: boolean;
  formType: string;
  emission: EmissionById | undefined;
  readOnlyOr: boolean;
}

export const LegalRepresentativeForm = (
  props: ILegalRepresentativeProps
): JSX.Element => {
  const {
    form,
    genderOptions,
    nationalityOptions,
    gendersTypes,
    provinceOptions,
    minBornTime,
    isReadOnlyForm,
    formType,
    emission,
    readOnlyOr,
  } = props;

  const {
    LEGAL_REPRESENTATIVE_BIRTH_DATE,
    LEGAL_REPRESENTATIVE_BIRTH_PLACE,
    LEGAL_REPRESENTATIVE_CUIT,
    LEGAL_REPRESENTATIVE_DEPARTMENT,
    LEGAL_REPRESENTATIVE_EMAIL,
    LEGAL_REPRESENTATIVE_FLOOR,
    LEGAL_REPRESENTATIVE_GENDER,
    LEGAL_REPRESENTATIVE_HOME,
    LEGAL_REPRESENTATIVE_LOCATION,
    LEGAL_REPRESENTATIVE_NAME_SURNAME,
    LEGAL_REPRESENTATIVE_NATIONALITY,
    LEGAL_REPRESENTATIVE_NUMBER,
    LEGAL_REPRESENTATIVE_PHONE,
    LEGAL_REPRESENTATIVE_POSTAL_CODE,
    LEGAL_REPRESENTATIVE_PROVINCE,
    LEGAL_REPRESENTATIVE_YES_OR_NO,
    title,
  } = formNames.legalRepresentative;

  const { formatDataCuit } = useFormatData();
  const { verifyCuit, isLoading: isLoadingCuitVerification } =
    useCuitVerification();
  const [isLegalRepresentative, setIsLegalRepresentative] =
    useState<boolean>(false);
  const [provinceIdSelected, setProvinceIdSelected] = useState<number>(0);
  const [selectedlocalidad, setSelectedlocalidad] = useState<string>("");

  const { locationOptions, postalCodeOptions } = useLocationAndPostalCodes({
    selectedlocalidad,
    provinceIdSelected,
    formItemLocationName: LEGAL_REPRESENTATIVE_LOCATION.name,
    formItemPostalCodeName: LEGAL_REPRESENTATIVE_POSTAL_CODE.name,
    form,
  });

  const handleChangeProvince = (id: number) => {
    setProvinceIdSelected(id);
  };

  const handleChangeLocationSelect = (location: string) => {
    setSelectedlocalidad(location);
  };

  return (
    <Collapse className="collapse">
      <Panel header={title} key="1">
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={LEGAL_REPRESENTATIVE_YES_OR_NO.label}
              name={LEGAL_REPRESENTATIVE_YES_OR_NO.name}
            >
              {formType !== EnumsValues.formType.directEmissionReadOnly &&
                formType !==
                  EnumsValues.formType.directEmissionHistoricalReadOnly &&
                formType !== EnumsValues.formType.renowedQuotesListReadOnly && (
                  <Switch
                    onChange={(value) => setIsLegalRepresentative(value)}
                  />
                )}
              {readOnlyOr && (
                <Switch
                  checked={
                    emission?.pledged_creditor?.business_name_surname !==
                    undefined
                  }
                  disabled={readOnlyOr} //TODO: ver  emission?.pledged_creditor?.business_name_surname cuando este el servicio completo
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese CUIT / CUIL",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_CUIT.label}
              name={LEGAL_REPRESENTATIVE_CUIT.name}
            >
              {formType !== EnumsValues.formType.directEmissionReadOnly &&
                formType !==
                  EnumsValues.formType.directEmissionHistoricalReadOnly && (
                  <SelectFetchOnEnter
                    query={verifyCuit}
                    isLoading={isLoadingCuitVerification}
                    functionToFormatData={formatDataCuit}
                    placeholder={LEGAL_REPRESENTATIVE_CUIT.placeholder}
                    form={form}
                    disabled={!isLegalRepresentative || readOnlyOr}
                    className={readOnlyOr ? "read-only" : undefined}
                    onChange={(_, data: ICuitOnline[]) => {
                      if (!isReadOnlyForm) {
                        if (form) {
                          form.setFieldsValue({
                            [LEGAL_REPRESENTATIVE_NAME_SURNAME.name]:
                              data[0]?.name,
                          });
                          form.setFieldsValue({
                            [LEGAL_REPRESENTATIVE_HOME.name]:
                              data[0]?.addressStreet,
                          });
                          form.setFieldsValue({
                            [LEGAL_REPRESENTATIVE_NUMBER.name]:
                              data[0]?.addressNumber,
                          });
                          form.setFieldsValue({
                            [LEGAL_REPRESENTATIVE_BIRTH_DATE.name]: data[0]
                              ?.birthDate
                              ? moment(data[0]?.birthDate, DEFAULT_FORMAT_DATE)
                              : undefined,
                          });
                          const setGenderOnForm = () => {
                            const selectedGender = gendersTypes.find(
                              (gender) => gender.description === data[0]?.gender
                            );
                            return selectedGender?.id_liderar;
                          };
                          form.setFieldsValue({
                            [LEGAL_REPRESENTATIVE_GENDER.name]:
                              setGenderOnForm(),
                          });
                        }
                      }
                    }}
                    optionRender={(item: ICuitOnline, index: number) => (
                      <Select.Option value={item.fiscalID} key={index}>
                        {item.fiscalID} - {item.name}
                      </Select.Option>
                    )}
                  />
                )}
              {(formType === EnumsValues.formType.directEmissionReadOnly ||
                formType ===
                  EnumsValues.formType.directEmissionHistoricalReadOnly) && (
                <Input
                  value={emission?.legal_representative?.cuit}
                  disabled
                  className="read-only"
                  placeholder={LEGAL_REPRESENTATIVE_CUIT.placeholder}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese nombre y apellido",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_NAME_SURNAME.label}
              name={LEGAL_REPRESENTATIVE_NAME_SURNAME.name}
            >
              <Input
                type="text"
                placeholder={LEGAL_REPRESENTATIVE_NAME_SURNAME.placeholder}
                maxLength={200}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese domicilio",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_HOME.label}
              name={LEGAL_REPRESENTATIVE_HOME.name}
            >
              <Input
                type="text"
                placeholder={LEGAL_REPRESENTATIVE_HOME.placeholder}
                maxLength={50}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese número de domicilio",
                },
                {
                  pattern: /^[\d]{0,10}$/,
                  message: "Máximo 10 dígitos",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_NUMBER.label}
              name={LEGAL_REPRESENTATIVE_NUMBER.name}
            >
              <InputNumber
                type="number"
                controls={false}
                placeholder={LEGAL_REPRESENTATIVE_NUMBER.placeholder}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={LEGAL_REPRESENTATIVE_FLOOR.label}
              name={LEGAL_REPRESENTATIVE_FLOOR.name}
            >
              <Input
                placeholder={LEGAL_REPRESENTATIVE_FLOOR.placeholder}
                type="text"
                maxLength={40}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={LEGAL_REPRESENTATIVE_DEPARTMENT.label}
              name={LEGAL_REPRESENTATIVE_DEPARTMENT.name}
            >
              <Input
                type="text"
                placeholder={LEGAL_REPRESENTATIVE_DEPARTMENT.placeholder}
                maxLength={40}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese teléfono",
                },
                {
                  pattern: /^[\d]{0,30}$/,
                  message: "Máximo 30 dígitos",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_PHONE.label}
              name={LEGAL_REPRESENTATIVE_PHONE.name}
            >
              <InputNumber
                controls={false}
                type="number"
                placeholder={LEGAL_REPRESENTATIVE_PHONE.placeholder}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  type: "email",
                  message: "Debe ingresar un email válido",
                },
                { required: isLegalRepresentative, message: "Ingrese email" },
              ]}
              label={LEGAL_REPRESENTATIVE_EMAIL.label}
              name={LEGAL_REPRESENTATIVE_EMAIL.name}
            >
              <Input
                type="email"
                maxLength={100}
                placeholder={LEGAL_REPRESENTATIVE_EMAIL.placeholder}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={LEGAL_REPRESENTATIVE_GENDER.label}
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione género",
                },
              ]}
              name={LEGAL_REPRESENTATIVE_GENDER.name}
            >
              <Select
                placeholder={LEGAL_REPRESENTATIVE_GENDER.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {genderOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione provincia",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_PROVINCE.label}
              name={LEGAL_REPRESENTATIVE_PROVINCE.name}
            >
              <Select
                placeholder={LEGAL_REPRESENTATIVE_PROVINCE.placeholder}
                onChange={handleChangeProvince}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {provinceOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione localidad",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_LOCATION.label}
              name={LEGAL_REPRESENTATIVE_LOCATION.name}
            >
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                disabled={
                  !isLegalRepresentative ||
                  form.getFieldsValue([LEGAL_REPRESENTATIVE_PROVINCE.name]) ===
                    undefined ||
                  readOnlyOr
                }
                placeholder={LEGAL_REPRESENTATIVE_LOCATION.placeholder}
                onSelect={handleChangeLocationSelect}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {locationOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione código postal",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_POSTAL_CODE.label}
              name={LEGAL_REPRESENTATIVE_POSTAL_CODE.name}
            >
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                placeholder={LEGAL_REPRESENTATIVE_POSTAL_CODE.placeholder}
                disabled={
                  !isLegalRepresentative ||
                  form.getFieldsValue([LEGAL_REPRESENTATIVE_LOCATION.name]) ===
                    undefined ||
                  readOnlyOr
                }
                className={readOnlyOr ? "read-only" : undefined}
              >
                {postalCodeOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={LEGAL_REPRESENTATIVE_BIRTH_DATE.label}
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione fecha de nacimiento",
                },
              ]}
              name={LEGAL_REPRESENTATIVE_BIRTH_DATE.name}
            >
              <Datepicker
                getPopupContainer={(trigger) => trigger}
                placeholder={LEGAL_REPRESENTATIVE_BIRTH_DATE.placeholder}
                format={DEFAULT_FORMAT_DATE}
                allowClear
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
                showToday={false}
                defaultDate={
                  new Date(
                    new Date().getTime() -
                      ms(minBornTime?.setting_value || "18y")
                  )
                }
                maxDate={
                  new Date(
                    new Date().getTime() -
                      ms(minBornTime?.setting_value || "18y")
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese lugar de nacimiento",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_BIRTH_PLACE.label}
              name={LEGAL_REPRESENTATIVE_BIRTH_PLACE.name}
            >
              <Input
                type="text"
                placeholder={LEGAL_REPRESENTATIVE_BIRTH_PLACE.placeholder}
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione nacionalidad",
                },
              ]}
              label={LEGAL_REPRESENTATIVE_NATIONALITY.label}
              name={LEGAL_REPRESENTATIVE_NATIONALITY.name}
            >
              <Select
                placeholder={LEGAL_REPRESENTATIVE_NATIONALITY.placeholder}
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                allowClear
                disabled={!isLegalRepresentative || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
                filterOption={(input: string, option: any) => {
                  return (
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {nationalityOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default LegalRepresentativeForm;
