export const NATIONALITIES = `query nationalities(
  $filter: FilterNationalityInput
  $orderBy: NationalityOrderInput
  $skip: Int
  $take: Int
) {
  nationalities(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on Nationality {
      id
      country
      gentile
      iso
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
