import { Col, Collapse, Form, FormInstance, Input, Row, Select } from "antd";
import moment from "moment";

import { ICuitOnline } from "../../../services/CuitOnline";
import SelectFetchOnEnter from "../../utils/SelectFetchOnEnter";
import { formNames } from "../utils/formNames";
import { DEFAULT_FORMAT_DATE } from "../../../shared/MomentJS";
import { IGender } from "../../../services/Gender";
import { CustomMessage, EnumsValues } from "../../../shared";
import useFormatData from "../../../shared/hooks/useFormatData";
import { useEffect } from "react";

const { Panel } = Collapse;
interface IClientInformationProps {
  fiscalConditionOptions: () => false | JSX.Element[];
  verifyCuit: (value: string) => Promise<ICuitOnline[]>;
  isLoadingCuitVerification: boolean;
  form: FormInstance;
  cuit?: string;
  setPersonType: React.Dispatch<React.SetStateAction<string>>;
  ageVerification: (birthDate: string | undefined | null) => void;
  isRenovation: boolean;
  gendersTypes: IGender[];
  isReadOnlyForm: boolean;
  formType: string;
  setFiscalCondition: React.Dispatch<React.SetStateAction<string | undefined>>;
  readOnlyOr: boolean;
}

export const ClientInformationForm = (
  props: IClientInformationProps
): JSX.Element => {
  const {
    fiscalConditionOptions,
    verifyCuit,
    isLoadingCuitVerification,
    form,
    cuit,
    setPersonType,
    ageVerification,
    isRenovation,
    gendersTypes,
    isReadOnlyForm,
    formType,
    setFiscalCondition,
    readOnlyOr,
  } = props;

  const { formatDataCuit } = useFormatData();
  const { messageError } = CustomMessage();
  const { COMPANY_NAME_OR_SURNAME, CUIT_CUIL, FISCAL_CONDITION, title } =
    formNames.clientInformation;

  const updatePersonType = async (cuitValue: string): Promise<void> => {
    try {
      const response = await verifyCuit(cuitValue);
      if (response[0]?.personType) {
        setPersonType(response[0]?.personType);
      }
    } catch (error: any) {
      messageError({
        context: "ClientInformationForm - updatePersonType",
        message: error?.message,
      });
    }
  };

  useEffect(() => {
    if (cuit) {
      updatePersonType(cuit);
    }
  }, [verifyCuit, cuit]);

  const handleChange = (_: any, data: ICuitOnline[]) => {
    if (data[0]?.personType) {
      setPersonType(data[0]?.personType);
    } else {
      setPersonType("");
    }

    if (!isReadOnlyForm) {
      const birthDate = data[0]?.birthDate;
      ageVerification(birthDate);

      if (form) {
        form.setFieldsValue({
          [formNames.clientInformation.CUIT_CUIL.name]: data[0]?.fiscalID,
          [formNames.clientInformation.COMPANY_NAME_OR_SURNAME.name]:
            data[0]?.name,
          [formNames.userInformation.USER_HOME.name]: data[0]?.addressStreet,
          [formNames.userInformation.USER_HOME_NUMBER.name]:
            data[0]?.addressNumber,
          [formNames.naturalPerson.NATURAL_PERSON_BIRTH_DATE.name]: data[0]
            ?.birthDate
            ? moment(data[0]?.birthDate, DEFAULT_FORMAT_DATE)
            : undefined,
        });
        const setGenderOnForm = () => {
          const selectedGender = gendersTypes.find(
            (gender) => gender.description === data[0]?.gender
          );
          return selectedGender?.id_liderar;
        };
        form.setFieldsValue({
          [formNames.naturalPerson.NATURAL_PERSON_GENDER.name]:
            setGenderOnForm(),
        });
      }
    }
  };

  return (
    <Collapse defaultActiveKey={["1"]} className="collapse">
      <Panel header={title} key="1">
        <Row justify="start" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingrese CUIT, CUIL o DNI",
                },
              ]}
              label={CUIT_CUIL.label}
              name={CUIT_CUIL.name}
            >
              {formType === EnumsValues.formType.directEmission && (
                <SelectFetchOnEnter
                  query={verifyCuit}
                  isLoading={isLoadingCuitVerification}
                  functionToFormatData={formatDataCuit}
                  placeholder={CUIT_CUIL.placeholder}
                  form={form}
                  initialValue={cuit}
                  onChange={handleChange}
                  optionRender={(item: ICuitOnline, index: number) => (
                    <Select.Option value={item.fiscalID} key={index}>
                      {item.fiscalID} - {item.name}
                    </Select.Option>
                  )}
                />
              )}
              {formType !== EnumsValues.formType.directEmission && (
                <Input
                  value={cuit}
                  disabled
                  className={
                    formType === EnumsValues.formType.renovation
                      ? "read-only-disabled"
                      : "read-only"
                  }
                  placeholder={CUIT_CUIL.placeholder}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={COMPANY_NAME_OR_SURNAME.label}
              rules={[{ required: true, message: "No hay datos en AFIP" }]}
              name={COMPANY_NAME_OR_SURNAME.name}
            >
              <Input
                placeholder={COMPANY_NAME_OR_SURNAME.placeholder}
                disabled
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                { required: true, message: "Seleccione condición fiscal" },
              ]}
              label={FISCAL_CONDITION.label}
              name={FISCAL_CONDITION.name}
            >
              <Select
                placeholder={FISCAL_CONDITION.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                onSelect={(value: string) => setFiscalCondition(value)}
                aria-placeholder="Seleccione"
                disabled={isRenovation || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {fiscalConditionOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default ClientInformationForm;
