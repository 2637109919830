export const UPDATE_SETTING = `
  mutation updateAppSetting($input: UpdateAppSettingInput!) {
    updateAppSetting(input: $input) {
      __typename
      ... on AppSetting {
        setting_value
        setting_name
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
