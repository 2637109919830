import { Col, Row, Card } from "antd";
import { useContext, useEffect, useState } from "react";

import HomeCard from "./HomeCard/HomeCard";
import FormPoliza from "./FormPoliza/FormPoliza";
import CardCotizacionRapida from "./CardCotizacionRapida/CardCotizacionRapida";
import { ContextApp, CustomMessage, EnumsValues } from "../../shared";
import GraphqlService from "../../services/graphql/GraphqlService";
import { PageLoading } from "@ant-design/pro-layout";

import "./style.less";

const cardBuscarPolizaDetails = {
  cardTitle: "Buscar por:",
  cardIcon: "search",
};

const CardCotizacionRapidaDetails = {
  cardTitle: "Cotización",
  cardIcon: "search",
  hideHeader: true,
};

const Home = (): JSX.Element => {
  const { user } = useContext(ContextApp);
  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const getHomeMessageUserHasNoRole = async () => {
    try {
      setLoading(true);
      const data = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: EnumsValues.SettingKeys.SetHomeMessageForUserHasNoRole,
          },
        },
      });
      setMessage(data.setting_value);
    } catch (error: any) {
      messageError({
        context: "Home - getHomeMessageUserHasNoRole",
        message: error?.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!user?.roles?.length) {
      getHomeMessageUserHasNoRole();
    }
  }, [user?.roles?.length]);

  if (loading) {
    return <PageLoading />;
  }

  if (!loading && !user?.roles?.length) {
    return (
      <Card
        className="card-home-message"
        style={{ maxWidth: 500, margin: "auto" }}
      >
        {message}
      </Card>
    );
  }

  return (
    <div className="home-container">
      <Row gutter={100}>
        <Col md={24} lg={10}>
          <HomeCard cardProps={cardBuscarPolizaDetails}>
            <FormPoliza />
          </HomeCard>
        </Col>
        <Col md={24} lg={10}>
          <HomeCard cardProps={CardCotizacionRapidaDetails}>
            <CardCotizacionRapida />
          </HomeCard>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
