import { SendOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input } from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import { useRef, useState } from "react";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import ContentCard, { ICard } from "./ContentCard/ContentCard";

import "./style.less";

export interface IModalSummaryDataProps {
  [key: string]: ICard[];
}

export interface IModalSummaryProps {
  data: IModalSummaryDataProps;
  modalVisible: boolean;
  onCancel: () => void;
  onDownloadRequest: (state?: boolean, email?: string) => void;
  onOk: () => void;
  titleModal: string;
}

export default function ModalSummary(props: IModalSummaryProps): JSX.Element {
  const { modalVisible, onCancel, titleModal, data, onOk, onDownloadRequest } =
    {
      ...props,
    };

  const [isDownloadModalVisible, setIsDownloadModalVisible] =
    useState<boolean>();
  const [sendToMail, setSendToMail] = useState<boolean>();
  const inputRef = useRef<Input | null>(null);
  const [downloadRequestForm] = useForm();

  return (
    <>
      <Modal
        className="modal-summary"
        title={titleModal}
        footer={null}
        destroyOnClose
        visible={modalVisible}
        style={{ minWidth: "80%" }}
        onCancel={() => onCancel()}
        onOk={() => onOk()}
      >
        {
          <Form layout="vertical" wrapperCol={{ span: 24 }}>
            <ContentCard
              data={data.quotationSummaryForModal}
              title="Datos de la cobertura"
            />
            <ContentCard
              data={data.InsuredDataForModal}
              title="Datos del asegurado"
            />
            <ContentCard
              data={data.naturalPersonDataForModal}
              title="Datos adicionales (Persona física)"
            />
            <ContentCard
              data={data.legalPersonDataForModal}
              title="Datos adicionales (Persona jurídica)"
            />
            <ContentCard
              data={data.vehicleDataForModal}
              title="Datos del vehículo"
            />
            <ContentCard data={data.accessoriesForModal} title="Accesorios" />
            <ContentCard
              data={data.legalRepresentativeDataForModal}
              title="Datos del representante legal"
            />
            <ContentCard
              data={data.pledgedCreditorDataForModal}
              title="Datos del acreedor prendario"
            />
            <div className="buttons">
              <ButtonLiderar
                color="white"
                buttonName="Volver"
                onClick={() => onCancel()}
              />
              <ButtonLiderar
                color="violet"
                buttonName="Descargar póliza"
                className="form-button search-button"
                htmlType="submit"
                onClick={() => setIsDownloadModalVisible(() => true)}
              />
            </div>
          </Form>
        }
      </Modal>
      <Modal
        title={
          <div className="download-modal-title">
            <span>Solicitud de descarga de póliza</span>
          </div>
        }
        className="Download__modal"
        visible={isDownloadModalVisible}
        style={{ top: 5 }}
        onOk={() =>
          onDownloadRequest(
            sendToMail,
            inputRef.current?.input.value.toString()
          )
        }
        centered
        onCancel={() => {
          setIsDownloadModalVisible(() => false);
        }}
        footer={
          <div className="ModalButtons">
            <Button
              type="primary"
              icon={<SendOutlined />}
              size={"large"}
              className="button"
              onClick={() => {
                onDownloadRequest(
                  sendToMail,
                  inputRef.current?.input.value.toString()
                );
              }}
            >
              Enviar
            </Button>
          </div>
        }
        bodyStyle={{ height: "20vh" }}
      >
        <Form form={downloadRequestForm}>
          <Checkbox
            onChange={(e) => {
              setSendToMail(() => e.target.checked);
              if (!e.target.checked)
                downloadRequestForm.setFieldsValue({
                  email: undefined,
                });
              downloadRequestForm.validateFields(["email"]);
            }}
          >
            Enviar por email
          </Checkbox>
          <Form.Item
            name="email"
            rules={[
              {
                required: sendToMail,
                message: "Ingresar el email es obligatorio",
              },
              {
                type: "email",
                message: "Debe ingresar un email válido",
              },
            ]}
          >
            <Input
              type="email"
              ref={inputRef}
              className="download-input-email"
              style={!sendToMail ? { backgroundColor: "#eee" } : {}}
              disabled={!sendToMail}
              placeholder="Ingrese email..."
            ></Input>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
