export const PUBLICATIONS = `
query publications(
  $orderBy: PublicationOrderInput
  $filter: FilterPublicationInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  publications(
    skip: $skip
    take: $take
    orderBy: $orderBy
    filter: $filter
    searchText: $searchText
  ) {
    __typename
    ... on Publication {
      id
      pub_archivo
      description
      visualiza
      publication_type_id
      publication_type {
        id
        description
        publication_type
      }
      its
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const PUBLICATION_FILE = `
query publicationFile ($id: Int!) {
  publicationFile(id: $id) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
export const DEBTSUMMARY_FILE = `
query debtSummaryFile ($id: Int!,$type: String!) {
  debtSummaryFile(id: $id,type: $type) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
