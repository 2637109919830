import { FundFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd";

import { IQuotationForEmision } from "../../../services/IQuotationForEmision";

interface ICardAccesories {
  quotation: IQuotationForEmision | undefined;
}

const CardExtraInfo = (props: ICardAccesories) => {
  const { quotation } = props;
  const fiscalCondition =
    quotation?.quotation_request?.fiscal_condition?.description;

  return (
    <Card bordered={false} className="ant-card-2">
      <Col span={2}>
        <Row>
          <FundFilled
            className="icon"
            style={{ fontSize: "30px", color: "#763790" }}
          />
        </Row>
      </Col>
      <Col span={12}>
        <Row style={{ display: "flex", flexDirection: "column" }}>
          <p>
            Condición fiscal: <b>{fiscalCondition || "-"}</b>
          </p>
        </Row>
      </Col>
    </Card>
  );
};

export default CardExtraInfo;
