import { PageLoading } from "@ant-design/pro-layout";
import AseguradoItem from "./AseguradoItem/AseguradoItem";
import PolizaItem from "./PolizaItem/PolizaItem";
import NoResults from "./NoResults/NoResults";
import TopBar from "./TopBar/TopBar";
import { IPoliza } from "../../../services/Poliza";
import { IAseguradoSearch } from "../../../services/Asegurado";
import "./style.less";
import { camelCase } from "../../../shared/utils";
import useBusquedaPoliza from "../../../shared/hooks/useBusquedaPoliza";
import { useCallback, useEffect } from "react";
import VirtualList from "rc-virtual-list";
import { List } from "antd";

interface ResultsScreenProps {
  polizas: IPoliza[];
  asegurados: IAseguradoSearch[];
  loading: boolean;
  termSearched: string;
  filter: string;
  searchTypeTitle: string;
  handleClickAsegurado: (id: string, nombre: string) => void;
  setPolizas: (polizas: IPoliza[]) => void;
  cleanSearch: () => void;
  setFilter: (e: any) => void;
}

const ResultsScreen: React.FC<ResultsScreenProps> = (props) => {
  const { setAseguradosOrderedBy, aseguradosOrderedBy } = useBusquedaPoliza();
  const {
    searchTypeTitle,
    handleClickAsegurado,
    polizas,
    asegurados,
    loading,
    setPolizas,
    termSearched,
    cleanSearch,
    setFilter,
    filter,
  } = props;

  useEffect(() => {
    setAseguradosOrderedBy([]);
  }, [asegurados]);

  const sortList = useCallback(
    (selectedFilter: "Nombre" | "DNI") => {
      if (selectedFilter === "Nombre") {
        setAseguradosOrderedBy(
          [...asegurados].sort((a, b) =>
            a.nombre_aseg.localeCompare(b.nombre_aseg)
          )
        );
      }
      if (selectedFilter === "DNI") {
        setAseguradosOrderedBy(
          [...asegurados].sort((a, b) => {
            return Number(a.nro_documento) - Number(b.nro_documento);
          })
        );
      }
    },
    [asegurados]
  );

  const handleClose = (): void => {
    if (polizas.length > 0 && asegurados && asegurados.length > 0) {
      setPolizas([]);
    } else {
      cleanSearch();
    }
  };

  if (loading) {
    return <PageLoading />;
  }

  if (polizas.length) {
    return (
      <div className="ResultsScreen">
        <TopBar
          description={searchTypeTitle}
          results={polizas.length}
          title={termSearched.toUpperCase()}
          handleClose={handleClose}
          setFilter={setFilter}
          filter={filter}
          filterFeature={false}
        />
        {polizas.map((poliza: IPoliza, index: number) => (
          <PolizaItem key={index} poliza={poliza} />
        ))}
      </div>
    );
  }

  if (asegurados.length) {
    return (
      <div className="ResultsScreen">
        <TopBar
          description={searchTypeTitle}
          results={asegurados.length}
          title={camelCase(termSearched)}
          handleClose={handleClose}
          setFilter={setFilter}
          filter={filter}
          filterFeature={true}
          sortList={sortList}
          setAseguradosOrderedBy={setAseguradosOrderedBy}
        />
        <List>
          <VirtualList
            data={aseguradosOrderedBy.length ? aseguradosOrderedBy : asegurados}
            height={600}
            itemKey="VirtualList_AseguradoItem"
            itemHeight={130}
          >
            {(asegurado: IAseguradoSearch) => {
              return (
                <AseguradoItem
                  key={asegurado.id_asegurado}
                  handleClickAsegurado={handleClickAsegurado}
                  asegurado={asegurado}
                />
              );
            }}
          </VirtualList>
        </List>
      </div>
    );
  }

  return (
    <div className="ResultsScreen">
      <NoResults />
    </div>
  );
};

export default ResultsScreen;
