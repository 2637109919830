import { useState, useRef, useCallback, useContext } from "react";
import { useHistory } from "react-router";
import { Button, DatePicker, Input } from "antd";
import { SorterResult } from "antd/es/table/interface";
import { RouteConfigComponentProps } from "react-router-config";

import ProTable, { ActionType } from "@ant-design/pro-table";
import { ParamsType } from "@ant-design/pro-provider";
import {
  FORMAT_DATE_TIME_1,
  FORMAT_DATE_TIME_2,
  FORMAT_DATE_TIME_4,
} from "../../shared/MomentJS";
import { ABM, MomentJS, Tools } from "../../shared";
import CustomFilter from "../CustomFilter/CustomFilter";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import GraphqlService from "../../services/graphql/GraphqlService";
import { ExportableColumn } from "../ABM/LocalExporter/index";
import { IRenovations } from "../../services/Renovations";

import {
  formatStringToMoney,
  getPaginationArgs,
  policyFormat,
} from "../../shared/utils";

import "./RenovationList.less";
import { NavContext } from "@ionic/react";
import { IBaseFile } from "../../services/defaultSchema";
import { FilePdfOutlined } from "@ant-design/icons";
import { useCheckAuthority } from "../../shared/CustomHooks";

enum renovationListItems {
  ID = "id",
  POLICY = "policy_number",
  ITS = "its",
  ITS_RANGE = "its_range",
  DUE_DATE = "due_date",
  DUE_DATE_RANGE = "due_date_range",
  PRODUCER = "producer_name",
  INSURED_BUSINESS_NAME = "insured_business_name",
  LOCALIDAD = "insured_address_city",
  PROVINCE = "insured_address_province",
  PATENT = "vehicle_license_plate",
  COVERAGE = "coverage_description",
  AMOUNT_INSURED = "amount_insured",
  PAYMENT_METHOD = "payment_method",
}

const {
  ID,
  POLICY,
  ITS,
  DUE_DATE,
  DUE_DATE_RANGE,
  PRODUCER,
  INSURED_BUSINESS_NAME,
  LOCALIDAD,
  PROVINCE,
  PATENT,
  COVERAGE,
  AMOUNT_INSURED,
  PAYMENT_METHOD,
  ITS_RANGE,
} = renovationListItems;
/**
 * Configure manualmente los campos de filtrado
 */

const LIST_FILTER = [
  ID,
  POLICY,
  ITS,
  DUE_DATE_RANGE,
  PRODUCER,
  INSURED_BUSINESS_NAME,
  LOCALIDAD,
  PROVINCE,
  PATENT,
  COVERAGE,
  AMOUNT_INSURED,
  PAYMENT_METHOD,
  ITS_RANGE,
];
const LIST_SORTER = [
  ID,
  POLICY,
  ITS,
  DUE_DATE,
  PRODUCER,
  INSURED_BUSINESS_NAME,
  LOCALIDAD,
  PROVINCE,
  PATENT,
  COVERAGE,
  AMOUNT_INSURED,
  PAYMENT_METHOD,
  ITS_RANGE,
];

export const RenovationList = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  // // props

  // // states
  const [sorter, setSorter] = useState<string>("");
  const [dataTable, setDataTable] = useState<IRenovations[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [downloading, setDownloading] = useState<boolean>(false);

  // // services and hooks
  const { Query, customRequest } = GraphqlService();
  const history = useHistory();
  const { navigate } = useContext(NavContext);
  // // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // // methods

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            if (element === ID) {
              variables.current.filter[ID] = Number(search[element]);
            } else {
              variables.current.filter[element] = search[element];
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.countRenovations,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.renovations,
        variables: variables.current,
      });

      const [totalItems, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      setTotal(totalItems);

      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total: totalItems,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const requestFile = async () => {
    setDownloading(true);
    try {
      const params = JSON.parse(JSON.stringify(variables.current));
      delete params.skip;
      delete params.take;

      const result: IBaseFile = await customRequest({
        query: Query.renovationsPdf,
        variables: params,
      });
      if (!result.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(result);
    } catch (error) {
      //intentional
    }
    setDownloading(false);
  };

  const columns = useCallback(
    (): ExportableColumn<IRenovations>[] => [
      {
        export: false,
        dataIndex: ID,
        title: "Id",
        align: "left",
        defaultSortOrder: "descend",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => record?.id || "-",
        renderFormItem: () => <Input placeholder="Ingrese id" />,
      },
      {
        export: false,
        dataIndex: POLICY,
        title: "Póliza",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => policyFormat(record?.policy_number) || "-",
        renderFormItem: () => <Input placeholder="Ingrese póliza" />,
      },
      {
        export: false,
        dataIndex: ITS,
        title: "Fecha Solicitud",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.DATE,
        render: (_, record) => (
          <p className="non-wrap-cell">
            {record.its
              ? MomentJS.specificMoment(
                  {
                    date: record.its,
                    format: FORMAT_DATE_TIME_2,
                    utc: true,
                  },
                  FORMAT_DATE_TIME_1,
                  true
                )
              : "-"}
          </p>
        ),
      },
      {
        export: false,
        dataIndex: ITS_RANGE,
        title: "Fecha solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        renderFormItem: () => (
          <DatePicker.RangePicker
            name="its_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={["Fecha desde", "Fecha hasta"]}
            allowEmpty={[true, true]}
            className="renderFormItem"
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: DUE_DATE,
        title: "Fecha vto.",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.DATE,
        render: (_: any, record) => (
          <p className="non-wrap-cell">
            {MomentJS.specificMoment(
              {
                date: record?.due_date,
                format: FORMAT_DATE_TIME_2,
                utc: false,
              },
              FORMAT_DATE_TIME_4,
              true
            ) || "-"}
          </p>
        ),
      },
      {
        export: false,
        dataIndex: DUE_DATE_RANGE,
        title: "Fecha vto.",
        align: "left",
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.DATE,
        renderFormItem: () => (
          <DatePicker.RangePicker
            name={DUE_DATE_RANGE}
            format={FORMAT_DATE_TIME_4}
            placeholder={["Fecha desde", "Fecha hasta"]}
            allowEmpty={[true, true]}
            className="renderFormItem"
            allowClear
          />
        ),
      },
      {
        export: false,
        dataIndex: PRODUCER,
        title: "Productor",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.producer_name || "-",
        renderFormItem: () => <Input placeholder="Ingrese productor" />,
      },
      {
        export: false,
        dataIndex: INSURED_BUSINESS_NAME,
        title: "Razón social",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => record?.insured_business_name || "-",
        renderFormItem: () => <Input placeholder="Ingrese razón social" />,
      },

      {
        export: false,
        dataIndex: LOCALIDAD,
        title: "Localidad",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.insured_address_city || "-",
        renderFormItem: () => <Input placeholder="Ingrese localidad" />,
      },
      {
        export: false,
        dataIndex: PROVINCE,
        title: "Provincia",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.insured_address_province || "-",
        renderFormItem: () => <Input placeholder="Ingrese provincia" />,
      },
      {
        export: false,
        dataIndex: PATENT,
        title: "Patente",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.vehicle_license_plate || "-",
        renderFormItem: () => <Input placeholder="Ingrese patente" />,
      },

      {
        export: false,
        dataIndex: COVERAGE,
        title: "Cobertura",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.coverage_description || "-",
        renderFormItem: () => <Input placeholder="Ingrese cobertura" />,
      },
      {
        export: false,
        dataIndex: AMOUNT_INSURED,
        title: Tools.leftAlign("Suma asegurada"),
        align: "right",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => (
          <p className="non-wrap-cell">
            {formatStringToMoney(record.amount_insured.toString())}
          </p>
        ),
        renderFormItem: () => <Input placeholder="Ingrese suma asegurada" />,
      },
      {
        export: false,
        dataIndex: PAYMENT_METHOD,
        title: "Forma de pago",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.payment_method || "-",
        renderFormItem: () => <Input placeholder="Ingrese forma de pago" />,
      },
    ],
    []
  );

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>Listado de pólizas a renovar</h1>
        <CustomFilter header="Filtrar por" />
        <ProTable<IRenovations>
          defaultSize="small"
          actionRef={actionRef}
          rowKey="id"
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(
                  `/app/policy/renovation/policies-to-renewed-list/${record.id}`
                ),
            };
          }}
          search={{
            collapsed: false,
            resetText: "Limpiar búsqueda",
            searchText: "Buscar",
            collapseRender: false,
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IRenovations>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          params={{
            sorter,
          }}
          className="renovation-list-table"
          dataSource={dataTable}
          pagination={{
            defaultPageSize: 20,
          }}
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns()}
          scroll={{ x: "scroll" }}
          toolBarRender={() => [
            <>
              <Button
                type="primary"
                icon={<FilePdfOutlined />}
                style={{
                  width: "auto",
                }} /* TODO: revisar width fijo para todos los botones en global.less */
                loading={downloading}
                disabled={!total}
                onClick={() => {
                  requestFile();
                }}
              >
                Descargar PDF
              </Button>
            </>,
          ]}
        />
        <div className="buttons">
          <ButtonLiderar
            color="white"
            buttonName="Volver"
            className="button-left"
            onClick={() => history.goBack()}
          />
        </div>
      </>
    )
  );
};

export default RenovationList;
