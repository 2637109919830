import { Modal } from "antd";

import "./ModalConfirm.less";

interface IModalConfirmProps {
  onOk: ((...args: any[]) => any) | undefined;
  title: string;
  content: string;
}

export const ModalConfirm = (props: IModalConfirmProps) => {
  const { onOk, title, content } = props;

  Modal.confirm({
    className: "modal-confirm-function",
    title,
    content,
    okText: "Confirmar",
    cancelText: "Cancelar",
    centered: true,
    okButtonProps: {
      htmlType: "submit",
    },
    onOk,
  });
};
