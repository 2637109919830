export const RENOVATION = `
query renovation($id:Int!){
  renovation(id:$id) {
    __typename
    ... on Renovation {
      id
      amount_insured
      producer_id_liderar
      policy_number
      due_date
      insured_id
      insured_business_name
      insured_address_street
      insured_address_city
      insured_address_province_id
      insured_address_postal_code
      payment_method
      insured_address_number
      vehicle_year
      vehicle_license_plate
      vehicle_chassis
      vehicle_engine_number
      producer_name
      brand_model_id_liderar
      brand_model {
        id
        brand
        model
        year
        amount_insured
        vehicleModel
      }
      policy {
        endoso {
          paymentMethod
        }
        tipo
        vehiculo {
          id_vehiculo
          marca
          anio
          patente
          codigo_motor
          codigo_chasis
          estado
          prima_total
          tipo_codigo
          tipo
          cobertura_codigo
          cobertura
          uso_codigo
          uso
          anexos
          codigo_carroceria
          carroceria
          suma_asegurada
          ajuste_automotor
          limite_rc
          tipo_combustible_codigo
          tipo_combustible
          codigo_accesorios
          descrip_accesorios
          suma_accesorios
          adicional_granizo
        }
        productor {
          zona_riesgo
          producto
          id_liderar
        }
        asegurado{
          id_asegurado
          nombre_aseg
          calle
          numero
          piso
          departamento
          codigo_postal
          localidad
          codigo_provincia
          provincia
          tipo_iva
          fiscal_condition
          cod_tipo_documento
          tipo_documento
          nro_documento
          cuit
          fecha_nacimiento
          sexo
        }
      }
    }
      ... on ResultError {
        status_code
        message
      }
    }
  }
  `;

export const RENOVATIONS = `
query renovations(
  $filter: FilterRenovationInput
  $orderBy: RenovationOrderInput
  $skip: Int
  $take: Int
) {
  renovations(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on Renovation {
      id
      its
      policy_number
      due_date
      producer_name
      insured_business_name
      insured_address_province
      insured_address_city
      vehicle_license_plate
      coverage_description
      amount_insured
      payment_method
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const COUNT_RENOVATIONS = `
query countRenovations(
  $filter: FilterRenovationInput
  $orderBy: RenovationOrderInput
) {
  countRenovations(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
     count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const RENOVATIONS_LIST_FILE = `
query renovationListFile(
  $filter: FilterRenovationInput
  $orderBy: RenovationOrderInput
  $skip: Int
  $take: Int
) {
  renovationListFile(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
