import { Button } from "antd";
import { Language } from "../../../../../services/Language";
import "./index.less";

export interface ILanguageButtonProps {
  language: Language;
  i18nCurrentLanguage: string;
  key: string | number;
  onClick: () => void;
}

export const LanguageButton = (props: ILanguageButtonProps): JSX.Element => {
  const { language, i18nCurrentLanguage, key, onClick } = props;
  return (
    <Button
      onClick={onClick}
      key={key}
      disabled={i18nCurrentLanguage === language.language_code}
    >
      <div className="languageButtonContent">
        {language.flag_url ? <img src={language.flag_url} alt=""></img> : null}
        {language.language_code.toUpperCase()}
      </div>
    </Button>
  );
};
