export const BOOKS_QUEUE_REQUESTS = `
query booksQueueRequests(
  $filter: FilterBooksQueueRequestInput
  $orderBy: BooksQueueRequestOrderInput
  $skip: Int
  $take: Int
) {
  booksQueueRequests(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
  ) {
    __typename
    ... on BooksQueueRequests {
      id
      File
      request_id
      Pedido
      TIPO
      Fec_des
      Fec_has
      COD_PROD
      Estado
      createdBy {
        username
      }
      status_queue_requests {
        status
      }
      liderar_message
      producer {
        nombre
        id_liderar
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const BOOKS_QUEUE_REQUESTS_COUNT = `
query booksQueueRequestsCount(
  $filter: FilterBooksQueueRequestInput
  $orderBy: BooksQueueRequestOrderInput
) {
  booksQueueRequestsCount(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const BOOKS_FROM_UNIVERSE = `
query booksFromUniverse($input: BooksInput!) {
  booksFromUniverse(input: $input) {
    __typename
    ... on BooksQueueRequests {
      request_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_BOOK_FILE = `
query getBookFile($id: Int!) {
  getBookFile(id: $id) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
