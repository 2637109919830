export const LOGIN = `mutation login($username: String, $password: String, $email: String,$token:String) {
  login(
    username: $username, password: $password, email: $email,token:$token
  ) 
  {
    __typename
    ...on Auth{
      token
    }
    ...on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_USER = `
mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    __typename
    ... on User {
      id
      username
      firstname
      lastname
      active
      lastAccess
      email
      is_system_user
      already_logged_in
      is_banned
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_USER = `
mutation deleteUser($id: Int!) {
  deleteUser(id: $id) {
    __typename
    ... on User {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPDATE_USER = `
mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    __typename
    ... on User {
      id
      username
      firstname
      lastname
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPSERT_USER_ROLE = `
mutation upsertUserRole($input: UpsertUserRoleInput!) {
  upsertUserRole(input: $input) {
    __typename
    ... on UserRole {
      role_id
      user_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const RESET_PASSWORD = `
mutation resetPassword($username: String, $email: String) {
  resetPassword(username: $username, email: $email) {
    __typename
    ... on RecoveryResult {
      result
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const SET_PASSWORD = `
mutation setPassword($token: String!, $password: String!) {
  setPassword(token: $token, password: $password){
    __typename
    ... on RecoveryResult {
      result
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const SET_USER_BAN = `
mutation setUserBan($input: SetUserBanInput!) {
  setUserBan(input: $input){
    __typename
    ... on User {
      id
      username
      firstname
      lastname
      is_banned
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UNLOCK_USER_BY_LOGIN = `
mutation unlockUserByLogin($user_id:Int!){
  unlockUserByLogin(user_id: $user_id) {
    __typename
    ... on  User {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
