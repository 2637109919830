export const UPSERT_ACCESSORY = `
mutation upsertAccessory($key: String!) {
  upsertAccessory(key: $key){
    __typename
    ... on SyncBoolean{
      status
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
