export const UPSERT_VEHICLE_TYPE_UNIVERSE = `
mutation upsertVehicleTypeUniverse($key: String!) {
  upsertVehicleTypeUniverse(key: $key) {
    __typename
    ... on SyncBoolean{
      status
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
