import { useContext } from "react";
import { ContextPoliza } from "../../../shared/ContextPoliza";
import { Card, Col, Row } from "antd";
import { FileTextFilled } from "@ant-design/icons";
import { IQuotation } from "../../../services/IQuotation";
import { formatStringToMoney } from "../../../shared/utils";
import "./style.less";

interface IProps {
  coverage: IQuotation;
}

const CoverageItem = (props: IProps): JSX.Element => {
  const {
    id,
    total_ice,
    total_no_ice,
    coverage: { descrip_web, description },
    coverage_id,
    request_id,
  } = props.coverage;
  const { setCoverageSelected, vehicleInfo } = useContext(ContextPoliza);

  const handleCoverageClick = () => {
    setCoverageSelected({
      quotation_id: id,
      name: descrip_web,
      period: vehicleInfo.period,
      details: description,
      total_ice,
      total_no_ice,
      request_id: request_id,
      coverage_id: coverage_id,
    });
  };

  return (
    <div className="CoverageItem">
      <Card className="card-coverage" onClick={handleCoverageClick}>
        <Col span="4" className="coverage-icon">
          <FileTextFilled />
        </Col>

        <Row className="coverage-row">
          <Col span="12" className="left-col">
            <Col className="second-col">Cobertura:</Col>
            <Col className="aseg-value">{descrip_web}</Col>
          </Col>
          {Number(total_ice) !== 0 && (
            <Col className="right-col">
              <Col className="premio-value">Premio con granizo:</Col>
              <Col className="price-value">
                {formatStringToMoney(total_ice)}
              </Col>
            </Col>
          )}
          {Number(total_no_ice) !== 0 && (
            <Col className="right-col">
              <Col className="premio-value">Premio sin granizo:</Col>
              <Col className="price-value">
                {formatStringToMoney(total_no_ice)}
              </Col>
            </Col>
          )}
        </Row>
        <Row className="coverage-row">
          <Col span="12" className="left-col">
            <Col className="second-col">Detalles:</Col>
            <Col className="aseg-value">{description}</Col>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CoverageItem;
