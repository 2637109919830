import { FileTextFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd";

import { IQuotationForEmision } from "../../../services/IQuotationForEmision";
import { formatStringToMoney } from "../../../shared/utils";
import { coverageIdLiderar } from "../../../shared/EnumsValues";

interface ICardCoverageProps {
  quotation: IQuotationForEmision | undefined;
}

const CardCoverage = (props: ICardCoverageProps): JSX.Element => {
  const { quotation } = props;
  const coverageName = quotation?.coverage?.descrip_web;
  const descriptionCovegare = quotation?.coverage?.description;
  const total_ice = quotation?.total_ice;
  const total_no_ice = quotation?.total_no_ice;
  const coveragePeriod =
    quotation?.quotation_request?.coverage_period?.description;

  return (
    <Card bordered={false} className="ant-card-2">
      <Col span={2}>
        <Row>
          <FileTextFilled
            className="icon"
            style={{ fontSize: "30px", color: "#763790" }}
          />
        </Row>
      </Col>
      <Col span={12}>
        <Row style={{ display: "flex", flexDirection: "column" }}>
          <p>
            Cobertura: <b>{coverageName || "-"}</b>
          </p>
          <p>
            Detalles: <b>{descriptionCovegare || "-"}</b>
          </p>
        </Row>
      </Col>
      <Col span={10}>
        <Row
          style={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            flexDirection: "column",
          }}
        >
          <p>
            Periodo cobertura: <b>{coveragePeriod || "-"}</b>
          </p>
          {total_ice !== 0 && (
            <p>
              {`Premio ${
                quotation?.coverage.id_liderar !== coverageIdLiderar.A
                  ? "con"
                  : "sin"
              } granizo`}

              <b> {formatStringToMoney(String(total_ice))}</b>
            </p>
          )}
          {total_no_ice !== 0 && (
            <p>
              Premio sin granizo:
              <b>{formatStringToMoney(String(total_no_ice))}</b>
            </p>
          )}
        </Row>
      </Col>
    </Card>
  );
};

export default CardCoverage;
