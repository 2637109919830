import { Form, Input, Row, Col, Select } from "antd";
import { useEffect, useState } from "react";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { IPolicySection } from "../../../services/PolicySection";
import { CustomMessage } from "../../../shared";
import { EnumsValues } from "../../../shared/EnumsValues";
import "./style.less";

const PolizaInput = (): JSX.Element => {
  const { Query, customRequest } = GraphqlService();
  const { messageError } = CustomMessage();
  const [policySections, setPolicySections] = useState<IPolicySection[]>([]);

  const getPolicySections = async () => {
    try {
      const data: IPolicySection[] = await customRequest({
        query: Query.policy_sections,
        variables: {
          orderBy: {
            field: "id_liderar",
            direction: "asc",
          },
        },
      });
      setPolicySections(data);
    } catch (error) {
      messageError({
        context: "getPolicySections",
        message: "Error al obtener secciones de póliza",
      });
    }
  };

  useEffect(() => {
    getPolicySections();
  }, []);

  return (
    <div className="poliza-input">
      <Row>
        <Col span={7}>
          <Form.Item
            className="formItem mg"
            label="Sección"
            name="seccion"
            rules={[{ required: true, message: "Ingrese sección" }]}
            initialValue={"04"}
          >
            <Select
              aria-label="valor para buscar póliza"
              className="inputForm"
              placeholder="04"
              maxLength={2}
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              dropdownMatchSelectWidth={false}
              showSearch
              options={policySections.map(({ id_liderar, name }) => ({
                value: id_liderar,
                key: id_liderar,
                label: `${id_liderar} - ${name}`,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            className="formItem mg"
            label="Póliza"
            name="poliza"
            rules={[
              { required: true, message: "Ingrese póliza" },
              {
                min: EnumsValues.MinLengthInputs.PolizaNumber,
                message: `${EnumsValues.MinLengthInputs.PolizaNumber} caracteres mínimo`,
              },
              {
                max: EnumsValues.MaxLengthInputs.PolizaNumber,
                message: `${EnumsValues.MaxLengthInputs.PolizaNumber} caracteres máximo`,
              },
            ]}
          >
            <Input
              aria-label="valor para buscar póliza"
              className="inputForm"
              placeholder="015213433"
              maxLength={9}
            />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item
            className="formItem"
            label="Endoso"
            name="endoso"
            rules={[{ required: false, message: "Ingrese endoso" }]}
          >
            <Input
              aria-label="endoso"
              className="inputForm"
              placeholder="000000"
              maxLength={6}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default PolizaInput;
