export const GET_PRODUCERS = `
query getProducers(
  $filter: FilterUserProducerInput
  $searchText: String
) {
  getProducers(
    filter: $filter
    searchText: $searchText
  ) {
    __typename
    ... on  UserProducer{
      id
      producer {
        id
        cuit
        nombre
        id_liderar
        group
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;

export const GET_PRODUCER_CUIT = `
query getProducerCuit($filter: FilterUserProducerCuitInput) {
  getProducerCuit(filter: $filter) {
    __typename
    ... on UserProducerCuit {
      producer_cuit
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_PRODUCER_GROUP = `
query getProducerGroup($filter: FilterUserProducerGroupInput) {
  getProducerGroup(filter: $filter) {
    __typename
    ... on UserProducerGroup {
      producer_group
    }
    ... on ResultError {
      status_code
      message
    }
  }
}


`;
