export const COMPANY = `
query company(
    $filter: FilterCompanyInput! 
  ){
  company(
      filter: $filter
  )
  {
      __typename
      ... on Company{
          id
          name
          description
      }
      ... on ResultError{
          status_code
          message
      }
  }
  }
`

export const COMPANIES = `
query companies(
    $filter:  FilterCompanyInput,
    $orderBy:  CompanyOrderInput,
    $skip: Int,
    $take: Int
){
companies(
  filter: $filter
  orderBy: $orderBy
  skip: $skip
  take: $take
)
{
    __typename
    ... on Company{
          id
        name
        description
    }
    ... on ResultError{
        status_code
        message
    }
}
}

`