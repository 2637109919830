import { Col, Collapse, Form, Input, Row } from "antd";

import { formNames } from "../utils/formNames";

const { Panel } = Collapse;

interface IVehicleDataProps {
  isRenovation: boolean;
  readOnlyOr: boolean;
}

const { VEHICLE_CHASIS_NUMBER, VEHICLE_ENGINE_NUMBER, VEHICLE_PATENT, title } =
  formNames.vehicleData;

export const VehicleDataForm = (props: IVehicleDataProps): JSX.Element => {
  const { isRenovation, readOnlyOr } = props;

  return (
    <Collapse defaultActiveKey={["1"]} className="collapse">
      <Panel header={title} key="1">
        <Row justify="start" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={VEHICLE_CHASIS_NUMBER.label}
              rules={[{ required: true, message: "Ingrese número de chasis" }]}
              name={VEHICLE_CHASIS_NUMBER.name}
            >
              <Input
                placeholder={VEHICLE_CHASIS_NUMBER.placeholder}
                disabled={isRenovation || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Ingrese número de motor" }]}
              label={VEHICLE_ENGINE_NUMBER.label}
              name={VEHICLE_ENGINE_NUMBER.name}
            >
              <Input
                type="text"
                placeholder={VEHICLE_ENGINE_NUMBER.placeholder}
                disabled={isRenovation || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Ingrese patente" }]}
              label={VEHICLE_PATENT.label}
              name={VEHICLE_PATENT.name}
            >
              <Input
                type="text"
                placeholder={VEHICLE_PATENT.placeholder}
                disabled={isRenovation || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default VehicleDataForm;
