export const convertSpecialStringToNormalStringObject = (obj: any) => {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const element = obj[key];
      if (typeof element === "string") {
        obj[key] = convertSpecialStringToNormalString(element);
      }
    }
  }
  return obj;
};
const convertSpecialStringToNormalString = (str: string) => {
  return str
    .replace("&amp;", "&")
    .replace("&lt;", `<`)
    .replace("&gt;", `>`)
    .replace("&quot;", `"`)
    .replace("&apos;", `'`);
};
