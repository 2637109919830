import { RouteConfigComponentProps } from "react-router-config";
import { useState, useRef, useCallback, useContext } from "react";
import { useHistory } from "react-router";
import { DatePicker, Input } from "antd";
import { SorterResult } from "antd/es/table/interface";

import ProTable, { ActionType } from "@ant-design/pro-table";
import { ParamsType } from "@ant-design/pro-provider";
import {
  FORMAT_DATE_TIME_1,
  FORMAT_DATE_TIME_2,
  FORMAT_DATE_TIME_4,
} from "../../shared/MomentJS";
import { ABM, MomentJS, Tools } from "../../shared";
import CustomFilter from "../CustomFilter/CustomFilter";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import GraphqlService from "../../services/graphql/GraphqlService";
import { ExportableColumn } from "../ABM/LocalExporter/index";
import { Datepicker } from "../DatePicker/DatePicker";
import { IRenovations } from "../../services/Renovations";
import { IEmissionList } from "../../services/EmissionList";
import { NavContext } from "@ionic/react";
import {
  formatStringToMoney,
  getPaginationArgs,
  policyFormat,
} from "../../shared/utils";
import { useCheckAuthority } from "../../shared/CustomHooks";

import "./EmissionListHistorical.less";

enum emisionListItems {
  NUMERATOR_ID = "numerator_id",
  POLICY = "id_policy",
  INSURED_BUSINESS_NAME = "business_name",
  START_DATE = "effective_date",
  PRODUCER = "producer",
  COVERAGE_TYPE = "coverage_description",
  VEHICLE_BRAND = "brand",
  VEHICLE_MODEL = "model",
  VEHICLE_YEAR = "vehicle_year",
  AMOUNT_INSURED = "amount_insured",
  TOTAL_PRICE = "prize",
  STATUS = "error_notice",
  ITS_RANGE = "its_range",
  ITS = "its",
  USERNAME = "username",
}

const {
  NUMERATOR_ID,
  POLICY,
  INSURED_BUSINESS_NAME,
  START_DATE,
  PRODUCER,
  COVERAGE_TYPE,
  VEHICLE_BRAND,
  VEHICLE_MODEL,
  VEHICLE_YEAR,
  AMOUNT_INSURED,
  TOTAL_PRICE,
  STATUS,
  ITS_RANGE,
  ITS,
  USERNAME,
} = emisionListItems;
/**
 * Configure manualmente los campos de filtrado
 */

const LIST_FILTER = [
  NUMERATOR_ID,
  POLICY,
  INSURED_BUSINESS_NAME,
  START_DATE,
  PRODUCER,
  COVERAGE_TYPE,
  VEHICLE_BRAND,
  VEHICLE_MODEL,
  VEHICLE_YEAR,
  AMOUNT_INSURED,
  TOTAL_PRICE,
  STATUS,
  ITS_RANGE,
  ITS,
];
const LIST_SORTER = [
  NUMERATOR_ID,
  POLICY,
  INSURED_BUSINESS_NAME,
  START_DATE,
  PRODUCER,
  COVERAGE_TYPE,
  VEHICLE_BRAND,
  VEHICLE_MODEL,
  VEHICLE_YEAR,
  AMOUNT_INSURED,
  TOTAL_PRICE,
  STATUS,
  ITS_RANGE,
  ITS,
  USERNAME,
];

export const EmissionListHistorical = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  // // props

  // // states
  const [sorter, setSorter] = useState<string>("");
  const [dataTable, setDataTable] = useState<IEmissionList[]>([]);

  // // services and hooks
  const { Query, customRequest } = GraphqlService();
  const history = useHistory();
  const { navigate } = useContext(NavContext);

  // // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // // methods

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            if (element === NUMERATOR_ID) {
              variables.current.filter[NUMERATOR_ID] = String(search[element]);
            } else {
              variables.current.filter[element] = search[element];
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.countEmissionHistorical,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.emissionsHistorical,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const columns = useCallback(
    (): ExportableColumn<IEmissionList>[] => [
      {
        export: false,
        dataIndex: NUMERATOR_ID,
        title: "Id",
        align: "left",
        defaultSortOrder: "descend",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => record?.numerator_id || "-",
        renderFormItem: () => <Input placeholder="Ingrese id" />,
      },
      {
        export: false,
        dataIndex: POLICY,
        title: "Póliza",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => policyFormat(record?.id_policy) || "-",
        renderFormItem: () => <Input placeholder="Ingrese póliza" />,
      },
      {
        export: false,
        dataIndex: INSURED_BUSINESS_NAME,
        title: "Nombre asegurado",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => record?.business_name || "-",
        renderFormItem: () => (
          <Input placeholder="Ingrese nombre del asegurado" />
        ),
      },
      {
        export: false,
        dataIndex: ITS,
        title: "Fecha Solicitud",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.DATE,
        render: (_, record) => (
          <p className="non-wrap-cell">
            {record.its
              ? MomentJS.specificMoment(
                  {
                    date: record.its,
                    format: FORMAT_DATE_TIME_2,
                    utc: true,
                  },
                  FORMAT_DATE_TIME_1,
                  true
                )
              : "-"}
          </p>
        ),
      },
      {
        export: false,
        dataIndex: ITS_RANGE,
        title: "Fecha solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        renderFormItem: () => (
          <DatePicker.RangePicker
            name="its_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={["Fecha desde", "Fecha hasta"]}
            allowEmpty={[true, true]}
            className="renderFormItem"
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "username",
        title: "Solicitante",
        sorter: true,
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record.createdBy.username || "-",
        renderFormItem: () => <Input />,
      },
      {
        export: false,
        dataIndex: START_DATE,
        title: "Fecha inicio",
        align: "left",
        sorter: false,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.DATE,
        render: (_: any, record) => (
          <p className="non-wrap-cell">
            {MomentJS.specificMoment(
              {
                date: record?.effective_date,
                format: FORMAT_DATE_TIME_4,
                utc: false,
              },
              FORMAT_DATE_TIME_4,
              true
            ) || "-"}
          </p>
        ),
        renderFormItem: () => (
          <Datepicker
            placeholder="Seleccione fecha"
            format={FORMAT_DATE_TIME_4}
            allowClear
          />
        ),
      },
      {
        export: false,
        dataIndex: PRODUCER,
        title: "Productor",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.producer.nombre || "-",
        renderFormItem: () => <Input placeholder="Ingrese productor" />,
      },
      {
        export: false,
        dataIndex: COVERAGE_TYPE,
        title: "Cobertura",
        align: "left",
        sorter: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.coverage_description || "-",
        renderFormItem: () => <Input placeholder="Ingrese cobertura" />,
      },
      {
        export: false,
        dataIndex: VEHICLE_BRAND,
        title: "Marca",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.brand_model.brand || "-",
        renderFormItem: () => <Input placeholder="Ingrese marca" />,
      },
      {
        export: false,
        dataIndex: VEHICLE_MODEL,
        title: "Modelo",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.brand_model.model || "-",
        renderFormItem: () => <Input placeholder="Ingrese modelo" />,
      },
      {
        export: false,
        dataIndex: VEHICLE_YEAR,
        title: "Año",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.vehicle_year || "-",
        renderFormItem: () => <Input placeholder="Ingrese año" />,
      },
      {
        export: false,
        dataIndex: AMOUNT_INSURED,
        title: Tools.leftAlign("Suma asegurada"),
        align: "right",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => (
          <p className="non-wrap-cell">
            {formatStringToMoney(record.amount_insured.toString())}
          </p>
        ),
        renderFormItem: () => <Input placeholder="Ingrese suma asegurada" />,
      },
      {
        export: false,
        dataIndex: TOTAL_PRICE,
        title: Tools.leftAlign("Premio total"),
        align: "right",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => (
          <p className="non-wrap-cell">
            {formatStringToMoney(String(record.prize))}
          </p>
        ),
        renderFormItem: () => <Input placeholder="Ingrese premio total" />,
      },
      {
        export: false,
        dataIndex: STATUS,
        title: "Estado",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record?.error_notice || "-",
        renderFormItem: () => <Input placeholder="Ingrese estado" />,
      },
    ],
    []
  );

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>Listado de emisiones histórico</h1>
        <CustomFilter header="Filtrar por" />
        <ProTable<IEmissionList>
          defaultSize="small"
          actionRef={actionRef}
          rowKey="id"
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(`/app/policy/direct-emission/${record.id}?historical`),
            };
          }}
          search={{
            collapsed: false,
            resetText: "Limpiar búsqueda",
            searchText: "Buscar",
            collapseRender: false,
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IRenovations>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          params={{
            sorter,
          }}
          className="emision-list-historical-table"
          dataSource={dataTable}
          pagination={{
            defaultPageSize: 20,
          }}
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns()}
          scroll={{ x: "scroll" }}
        />
        <div className="buttons">
          <ButtonLiderar
            color="white"
            buttonName="Volver"
            className="button-left"
            onClick={() => history.goBack()}
          />
        </div>
      </>
    )
  );
};

export default EmissionListHistorical;
