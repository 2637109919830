export const GET_USER_COVERAGE_PERIOD = `
query getUserCoveragePeriod($searchText: String, $filter: FilterUserCoveragePeriodInput) {
  getUserCoveragePeriod(searchText: $searchText, filter: $filter) {
    __typename
    ... on UserCoveragePeriod {
      id
      user_id
      coverage_period_id
      coverage_period {
        id
        description
        activo_web
        by_default
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
