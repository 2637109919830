import { EnumsValues } from "../shared";

export interface IStatusQueueRequest {
  id: number;
  status: string;
}

export const StatusQueueRequestArray: IStatusQueueRequest[] = [
  {
    id: EnumsValues.StatusQueueRequest.Processing,
    status: EnumsValues.StatusQueueRequestName.Processing,
  },
  {
    id: EnumsValues.StatusQueueRequest.Pending,
    status: EnumsValues.StatusQueueRequestName.Pending,
  },
  {
    id: EnumsValues.StatusQueueRequest.Available,
    status: EnumsValues.StatusQueueRequestName.Available,
  },
  {
    id: EnumsValues.StatusQueueRequest.Error,
    status: EnumsValues.StatusQueueRequestName.Error,
  },
  {
    id: EnumsValues.StatusQueueRequest.Finalized,
    status: EnumsValues.StatusQueueRequestName.Finalized,
  },
];
