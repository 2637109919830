import { DownOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { useLayoutEffect } from "react";
import "./style.less";

const { Panel } = Collapse;

export interface ICustomFilterProps {
  header: string;
}

const CustomFilter = (props: ICustomFilterProps) => {
  const { header } = props;

  useLayoutEffect(() => {
    let htmlFilterAntd = document.querySelector(
      ".ant-pro-table .ant-pro-table-search-query-filter"
    ) as HTMLElement;
    if (htmlFilterAntd) {
      let collapsePanel = document.querySelector(
        ".collapse-panel-collection"
      ) as HTMLElement;
      let FinalHtmlFilterAntd = collapsePanel.querySelector(
        ".ant-collapse-content .ant-collapse-content-box"
      ) as HTMLElement;
      FinalHtmlFilterAntd.appendChild(htmlFilterAntd);
    }
  }, []);

  return (
    <Collapse
      bordered={false}
      className="collapse-panel-collection"
      expandIcon={({ isActive }) => (
        <DownOutlined rotate={isActive ? 180 : 0} />
      )}
    >
      <Panel
        forceRender={true}
        header={header}
        key="1"
        className="collapse-panel-collection"
      />
    </Collapse>
  );
};

export default CustomFilter;
