export const FILES_IDS_BY_LICENSE_PLATE = `
query filesIdsByLicensePlate($licensePlate: String!) {
  filesIdsByLicensePlate(licensePlate: $licensePlate) {
    __typename
    ... on IFileIds{
      ids
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const FILE = `
query file($id: Int!){
  file(id: $id){
    __typename
    ... on IFile{
      id
      file
      mimetype
      filename
      file_type_id
      encoding
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
