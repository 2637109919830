import { Col, Collapse, Form, Input, InputNumber, Row } from "antd";
import { DEFAULT_FORMAT_DATE } from "../../../shared/MomentJS";
import { Datepicker } from "../../DatePicker/DatePicker";
import { formNames } from "../utils/formNames";

const { Panel } = Collapse;

interface ILegalPersonProps {
  readOnlyOr: boolean;
}

export const LegalPersonForm = (props: ILegalPersonProps): JSX.Element => {
  const { readOnlyOr } = props;

  const {
    LEGAL_PERSON_CONTRACT_DATE,
    LEGAL_PERSON_MAIN_ACTIVITY,
    LEGAL_PERSON_REGISTRATION_DATE,
    LEGAL_PERSON_REGISTRATION_NUMBER,
    title,
  } = formNames.legalPerson;

  return (
    <Collapse defaultActiveKey={["1"]} className="collapse">
      <Panel header={title} key="1">
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={LEGAL_PERSON_REGISTRATION_DATE.label}
              rules={[
                { required: true, message: "Ingrese fecha de registración" },
              ]}
              name={LEGAL_PERSON_REGISTRATION_DATE.name}
            >
              <Datepicker
                placeholder={LEGAL_PERSON_REGISTRATION_DATE.placeholder}
                format={DEFAULT_FORMAT_DATE}
                allowClear
                maxDate={new Date()}
                showToday
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                { required: true, message: "Ingrese número de inscripción" },
                {
                  pattern: /^[\d]{0,20}$/,
                  message: "Máximo 20 dígitos",
                },
              ]}
              label={LEGAL_PERSON_REGISTRATION_NUMBER.label}
              name={LEGAL_PERSON_REGISTRATION_NUMBER.name}
            >
              <InputNumber
                type="number"
                placeholder={LEGAL_PERSON_REGISTRATION_NUMBER.placeholder}
                controls={false}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Ingrese fecha de contrato" }]}
              label={LEGAL_PERSON_CONTRACT_DATE.label}
              name={LEGAL_PERSON_CONTRACT_DATE.name}
            >
              <Datepicker
                placeholder={LEGAL_PERSON_CONTRACT_DATE.placeholder}
                format={DEFAULT_FORMAT_DATE}
                allowClear
                showToday
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingrese actividad principal",
                },
              ]}
              label={LEGAL_PERSON_MAIN_ACTIVITY.label}
              name={LEGAL_PERSON_MAIN_ACTIVITY.name}
            >
              <Input
                type="text"
                placeholder={LEGAL_PERSON_MAIN_ACTIVITY.placeholder}
                maxLength={100}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default LegalPersonForm;
