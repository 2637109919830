import { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router";
import GraphqlService from "../../services/graphql/GraphqlService";
import { Authorization, ContextApp, EnumsValues } from "../../shared";

export const MaintenancePage = () => {
  const { Query, customRequest } = GraphqlService();
  const [isMaintenanceModeActive, setIsMaintenanceModeActive] = useState(true);
  const [maintenanceModeMessage, setMaintenanceModeMessage] = useState(
    "Sitio web en mantenimiento"
  );
  const history = useHistory();
  const { functions } = useContext(ContextApp);

  const getMaintenanceModeSetting = async (): Promise<void> => {
    try {
      const response = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.MaintenanceMode },
        },
      });

      setIsMaintenanceModeActive(response.setting_value === "true");
    } catch (error) {
      //
    }
  };

  const getMaintenanceModeMessage = async (): Promise<void> => {
    try {
      const response = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.MaintenanceModeMessage },
        },
      });

      setMaintenanceModeMessage(response.setting_value);
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    getMaintenanceModeSetting();
    getMaintenanceModeMessage();
  }, []);

  useEffect(() => {
    const isUserAdmin = Authorization.security(
      functions,
      EnumsValues.Functions.IgnoreMaintenanceMode
    );

    if (isMaintenanceModeActive === false || isUserAdmin) {
      history.replace("/");
    }
  }, [isMaintenanceModeActive, functions]);

  return (
    <div
      style={{
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <h3>{maintenanceModeMessage}</h3>
    </div>
  );
};
