import { useState, PropsWithChildren, useEffect, Component } from "react";
import { Result, Button, Modal } from "antd";
import { Link } from "react-router-dom";

interface ExceptionManagerState {
  hasError: any;
}

export interface ExceptionManagerProps {
  setResult?: (error: any) => void;
  messageModal?: string;
  messageComponent?: JSX.Element;
}

class ExceptionManagerContent extends Component<ExceptionManagerProps> {
  state: ExceptionManagerState;

  constructor(props: any) {
    super(props);
    this.state = {
      hasError: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
    return { hasError: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    try {
      if (this.props.messageModal) {
        Modal.warning({
          title: "Ups!!",
          content: `${this.props.messageModal}. Un informe automatico se envió al administrador.`,
        });
      }

      // También puedes registrar el error en un servicio de reporte de errores
      if (this.props.setResult)
        this.props.setResult({
          name: error.name,
          message: error.message,
          stack: errorInfo.componentStack,
        });
    } catch {
      // @TODO: No hago nada si falla
    }
  }

  render() {
    const message = this.props.messageComponent ? (
      this.props.messageComponent
    ) : (
      <Result
        status="warning"
        title="Hemos encontrado un problema! Un informe automatico se envió al administrador."
        extra={
          <Button type="primary">
            <Link to="/">Continuar</Link>
          </Button>
        }
      />
    );
    if (this.props.messageModal) {
      return <>{this.state.hasError ? null : this.props.children}</>;
    }
    return <>{this.state.hasError ? message : this.props.children}</>;
  }
}

const ExceptionManager: React.FC<PropsWithChildren<ExceptionManagerProps>> = (
  props
) => {
  // const { Logger } = Service();
  const [result, setResult] = useState<any>(null);

  const catchErrors = () => {
    try {
      if (result !== null) {
        // Logger.error(result).finally(() => {
        //   setResult(null);
        // });
        console.error(result);
        setResult(() => null);
      }
    } catch {
      // @TODO: No hago nada
    }
  };

  useEffect(() => {
    catchErrors();
  }, [result]);

  return (
    <ExceptionManagerContent setResult={setResult}>
      {props.children}
    </ExceptionManagerContent>
  );
};

export default ExceptionManager;
