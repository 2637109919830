import { useState } from "react";
import { Card, Row, Col } from "antd";
import { HeartFilled, CarFilled } from "@ant-design/icons";
import { CustomMessage, EnumsValues } from "../../../../shared";
import { IPoliza } from "../../../../services/Poliza";
import PolizaDetails from "../PolizaDetails/PolizaDetails";
import TagValidity from "./TagValidity";
import { formatPolicyNumber } from "../../../../shared/utils";
import _ from "lodash";
import "./style.less";

interface PolizaItemProps {
  poliza: IPoliza;
}

const PolizaItem: React.FC<PolizaItemProps> = ({ poliza }) => {
  const { vehiculo, asegurado, endoso }: IPoliza = poliza;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { PolizaTypeByNumber, PolizaType } = EnumsValues;

  const { messageError } = CustomMessage();

  let polizaType: string = endoso[0].id.substring(0, 2);

  const policyNumber = formatPolicyNumber(endoso[0].id);

  const getPolizaType = (polizaParam: string) => {
    switch (polizaParam) {
      case PolizaTypeByNumber.AUTO:
        return PolizaType.AUTO;
      case PolizaTypeByNumber.VIDA:
        return PolizaType.VIDA;
      default:
        return PolizaType.AUTO;
    }
  };

  const handlePolizaClick = (canOpen: boolean): void => {
    if (canOpen === false) {
      messageError({
        context: "canOpenPoliza",
        message: "Acceso denegado",
      });
      return;
    }
    setIsModalVisible(true);
  };

  polizaType = getPolizaType(polizaType);

  return (
    <div className="PolizaItem">
      <PolizaDetails
        polizaProp={poliza}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      />
      <Card
        // style={
        //   endoso[0].vigencia
        //     ? { borderLeftColor: "#87d068" }
        //     : { borderLeftColor: "#f50" }
        // }
        /*TODO: se oculta el tag de vigencia hasta que Liderar defina una
        estrategia de validación de vigencia de las polizas*/
        onClick={() => handlePolizaClick(poliza.canOpen)}
      >
        <Col span="2" className="poliza-icon">
          {polizaType === PolizaType.VIDA ? <HeartFilled /> : <CarFilled />}
        </Col>
        <Row>
          <Col span={4} className="second-col">
            Tipo:
          </Col>
          <Col span={13} className="third-col">
            {polizaType === PolizaType.VIDA ? PolizaType.VIDA : PolizaType.AUTO}
          </Col>
          <Col span={4}>
            <TagValidity title="" validity={endoso[0].vigencia} />
          </Col>
        </Row>
        <Row className="asegurado-row">
          <Col span={4} className="second-col">
            Asegurado:
          </Col>
          <Col span={13} className="third-col">
            {_.startCase(_.toLower(asegurado[0].nombre_aseg))}
          </Col>
        </Row>
        <Row className="asegurado-row">
          <Col span="4" className="second-col">
            Nº asegurado:
          </Col>
          <Col span="8" className="third-col">
            {asegurado[0].id_asegurado}
          </Col>
        </Row>
        <Col span={1} className="first-col more-container">
          {/* <Link to="">
            <MoreOutlined className="more-button" /> 
          </Link> */}{" "}
          {/* //TODO: LUEGO IMPLEMENTAR FUNCIONALIDADES EN TRES PUNTITOS */}
        </Col>
        {polizaType === PolizaType.AUTO && (
          <Row className="vehicle-row">
            <Col span={4} className="second-col">
              Vehículo:
            </Col>
            <Col span={13} className="third-col">
              {vehiculo[0].marca} {vehiculo[0].anio}
            </Col>
          </Row>
        )}

        <Row wrap={false}>
          <Col span={4} className="second-col">
            Vigencia:
          </Col>
          <Col span={13} className="third-col">
            {endoso[0].vigente_desde} - {endoso[0].vigente_hasta}
          </Col>
          <Col>
            Póliza: <span className="poliza-number">{policyNumber}</span>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default PolizaItem;
