import { Row, Col } from "antd";
import "./style.less";

interface IProps {
  label: string;
  value: string;
}
const SummaryItem = ({ label, value }: IProps) => {
  return (
    <Row className="SummaryItem">
      <Col span={8} className="label">
        {label}:
      </Col>
      <Col span={12} className="value">
        {value}
      </Col>
    </Row>
  );
};

export default SummaryItem;
