import {
  Col,
  Menu,
  MenuItemProps,
  MenuProps,
  Row,
  SubMenuProps,
  Tag,
} from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { useCallback, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { IRouteConfig } from "../../../routes/routes";
import GraphqlService from "../../../services/graphql/GraphqlService";

import { Authorization, ContextApp, EnumsValues } from "../../../shared";
import { AvatarComponent } from "../HeaderComponent/AvatarComponent";
import "./style.less";

interface IMainMenuProps {
  routes: IRouteConfig[];
}

const ENVTagColor: Record<string, string> = {
  dev: "orange",
  test: "green",
  stage: "#87d068",
};

export const MainMenu = (props: IMainMenuProps): JSX.Element => {
  const { routes } = props;

  const { Query, customRequest } = GraphqlService();
  const { functions, maintenanceMode, setMaintenanceMode } =
    useContext(ContextApp);

  const menuProps: MenuProps = {
    theme: "light",
    mode: "horizontal",
  };
  const getMaintenanceModeSetting = async (): Promise<void> => {
    try {
      const response = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.MaintenanceMode },
        },
      });

      setMaintenanceMode(response.setting_value === "true");
    } catch (error) {
      //
    }
  };

  useEffect(() => {
    getMaintenanceModeSetting();
  }, []);

  const renderMenuRoutes = useCallback(
    (
      paramRoutes: IRouteConfig[],
      level?: number,
      previousIndexes: number[] = []
    ) => {
      return paramRoutes.map((item, index) => {
        if (
          (item.authority &&
            !Authorization.security(functions, item.authority)) ||
          item.hideInMenu
        ) {
          return null;
        }
        if (item.routes) {
          const subMenuProps: SubMenuProps = {};
          if (item.icon) {
            subMenuProps.icon = item.icon;
          }
          subMenuProps.title = item.name;
          const currentIndexes = [...previousIndexes];
          previousIndexes.push(index);
          return (
            <SubMenu
              key={
                level
                  ? `${
                      currentIndexes.length
                        ? `${currentIndexes.join("_")}_`
                        : ""
                    }${level}_${index}`
                  : index
              }
              {...subMenuProps}
            >
              {renderMenuRoutes(item.routes, (level || 0) + 1, previousIndexes)}
            </SubMenu>
          );
        } else {
          const menuItemProps: MenuItemProps = {};
          if (item.icon) {
            menuItemProps.icon = item.icon;
          }
          return (
            <Menu.Item
              key={
                level
                  ? `${
                      previousIndexes.length
                        ? `${previousIndexes.join("_")}_`
                        : ""
                    }${level}_${index}`
                  : index
              }
              {...menuItemProps}
            >
              <Link to={item.path}>{item.name}</Link>
            </Menu.Item>
          );
        }
      });
    },
    [functions]
  );

  return (
    <div className="mainMenuContainer">
      <Row>
        <Col xs={3}>
          <Link to="/app/home">
            <img
              src="/assets/logo.png"
              alt="logo liderar seguros"
              className="logo"
            />
          </Link>
        </Col>
        <Col xs={16}>
          <Menu
            mode="horizontal"
            className="MainMenu"
            {...menuProps}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {renderMenuRoutes(routes)}
          </Menu>
        </Col>
        <Col xs={5}>
          <AvatarComponent />
        </Col>
      </Row>
      {maintenanceMode && (
        <Tag className="maintenance-tag" color="orange">
          En mantenimiento
        </Tag>
      )}
      {process.env?.REACT_APP_ENV && ENVTagColor[process.env.REACT_APP_ENV] && (
        <Tag className="env-tag" color={ENVTagColor[process.env.REACT_APP_ENV]}>
          {process.env.REACT_APP_ENV}
        </Tag>
      )}
    </div>
  );
};
