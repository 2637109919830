export const UPSERT_ADJUSTMENT_RATE = `
mutation upsertAdjustmentRate($key: String!) {
  upsertAdjustmentRate(key: $key){
    __typename
    ... on SyncBoolean{
      status
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
