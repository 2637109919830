import { useContext } from "react";
import { Redirect } from "react-router-dom";
import { ContextApp } from "../../shared/ContextApp";

const DefaultRedirect = (): JSX.Element => {
  const { user } = useContext(ContextApp);
  if (user) {
    return <Redirect to="/app/home" />;
  } else {
    return <Redirect to="/login" />;
  }
};

export default DefaultRedirect;
