import {
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Row,
  FormInstance,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";

import { formNames } from "../utils/formNames";
import { IQuotationRequest } from "../../../services/IQuotation";
import { IEmissionErrorResponse } from "../interfaces/IValuesEmisionRenovationForm";

const { Panel } = Collapse;

interface IQuoteProps {
  quotationResponse?: IQuotationRequest;
  form: FormInstance;
  emissionResponse: IEmissionErrorResponse;
  readOnlyOr: boolean;
}

export const QuoteForm = (props: IQuoteProps): JSX.Element => {
  const { quotationResponse, form, emissionResponse, readOnlyOr } = props;
  const [labelNameForQuotationPrima, setLabelNameForQuotationPrima] =
    useState<string>("Premio");

  const {
    QUOTATION_PRIMA,
    QUOTATION_PREMIUM,
    QUOTATION_RESULT_CODE,
    QUOTATION_RESULT_MESSAGE,
    title,
  } = formNames.quote;

  useEffect(() => {
    if (quotationResponse && form) {
      if (Number(quotationResponse?.quotation[0]?.total_no_ice) !== 0) {
        setLabelNameForQuotationPrima("Premio sin granizo");
        form.setFieldsValue({
          [QUOTATION_PREMIUM.name]:
            quotationResponse?.quotation[0]?.total_no_ice,
        });
      } else if (Number(quotationResponse?.quotation[0]?.total_ice) !== 0) {
        setLabelNameForQuotationPrima("Premio con granizo");
        form.setFieldsValue({
          [QUOTATION_PREMIUM.name]: quotationResponse?.quotation[0].total_ice,
        });
      }
    }
  }, [quotationResponse, form]);

  useEffect(() => {
    if (emissionResponse && form) {
      form.setFieldsValue({
        [QUOTATION_RESULT_CODE.name]: emissionResponse.status_code,
        [QUOTATION_RESULT_MESSAGE.name]: emissionResponse.message,
      });
    }
  }, [emissionResponse, form]);

  return (
    <Collapse defaultActiveKey={["1"]} className="collapse">
      <Panel header={title} key="1">
        <Row justify="start" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={labelNameForQuotationPrima}
              name={QUOTATION_PREMIUM.name}
            >
              <Input
                type="text"
                placeholder={QUOTATION_PREMIUM.placeholder}
                readOnly
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={QUOTATION_PRIMA.label}
              name={QUOTATION_PRIMA.name}
            >
              <Input
                type="text"
                placeholder={QUOTATION_PRIMA.placeholder}
                readOnly
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={QUOTATION_RESULT_CODE.label}
              name={QUOTATION_RESULT_CODE.name}
            >
              <InputNumber
                type="text"
                placeholder={QUOTATION_RESULT_CODE.placeholder}
                readOnly
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={24} xl={24}>
            <Form.Item
              label={QUOTATION_RESULT_MESSAGE.label}
              name={QUOTATION_RESULT_MESSAGE.name}
            >
              <TextArea
                placeholder={QUOTATION_RESULT_MESSAGE.placeholder}
                autoSize={{ minRows: 3 }}
                readOnly
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default QuoteForm;
