import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Alert, Button, Form, Input, Divider } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

import { NavContext } from "@ionic/react";
import { PageLoading } from "@ant-design/pro-layout";
import { CustomMessage } from "../../shared/CustomMessage";
import { ContextApp } from "../../shared/ContextApp";
import GraphqlService from "../../services/graphql/GraphqlService";
import { Mutation } from "../../services/graphql/mutation";

import "./style.less";

const LoginMessage = ({ content }: { content: string }) => (
  <Alert
    style={{
      marginBottom: 24,
    }}
    message={content}
    type="error"
  />
);

export interface LoginProps {
  location: any;
  onFinish?: () => void;
}

interface IDataLogin {
  password: string;
  remember: boolean;
  username: string;
}

const LoginForm: React.FC<LoginProps> = (props) => {
  const { location } = props;
  const [messageErrorState, setMessageErrorState] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { customRequest } = GraphqlService();
  const { messageError } = CustomMessage();
  const { checkAuth, setSessionExpired } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const [form] = Form.useForm();
  const queryStrings = new URLSearchParams(location.search);
  const token = queryStrings.get("token");

  const loginWithToken = async (tokenParam: string) => {
    try {
      const dataResponse = await customRequest({
        query: Mutation.login,
        variables: { tokenParam },
      });
      setSessionExpired(false);
      localStorage.setItem("token", dataResponse.token);
      checkAuth();
      navigate("/app/home");
    } catch (error: any) {
      messageError({
        context: "login.loginWithToken.1",
        message: error.message,
      });
    }
  };

  useEffect(() => {
    if (token) {
      loginWithToken(token);
    }
  }, [token]);

  const onFinish = async (values: any) => {
    setLoading(true);
    const { username, password } = values as IDataLogin;
    try {
      let dataResponse: any;
      if (username.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) {
        dataResponse = await customRequest({
          query: Mutation.login,
          variables: { email: username, password },
        });
      } else {
        dataResponse = await customRequest({
          query: Mutation.login,
          variables: { username, password },
        });
      }
      setSessionExpired(false);
      localStorage.setItem("token", dataResponse.token);
      checkAuth();
      navigate("/app/home");
    } catch (errors: any) {
      setMessageErrorState(errors.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div className="Login">
      <Form
        form={form}
        name="normal_login"
        layout="vertical"
        className="login-form"
        initialValues={{ remember: true }}
        requiredMark={"optional"}
        onFinish={props?.onFinish || onFinish}
      >
        {messageErrorState && <LoginMessage content={messageErrorState} />}

        <h2 className="top-text">Ingresa con tus datos</h2>

        <Form.Item
          className="formItem"
          label="Usuario o email"
          name="username"
          rules={[{ required: true, message: "Por favor ingrese su usuario" }]}
        >
          <Input aria-label="username" className="inputForm" />
        </Form.Item>
        <Form.Item
          className="formItem"
          label="Contraseña"
          name="password"
          rules={[
            {
              required: true,
              message: "Por favor ingrese su contraseña",
            },
          ]}
        >
          <Input.Password
            aria-label="password"
            type="password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Divider />
        <Form.Item shouldUpdate>
          {({ getFieldsValue }) => {
            const { username, password } = getFieldsValue();
            const formIsComplete = !!username && !!password;
            return (
              <Button
                data-testid="ButtonSubmit"
                type="primary"
                htmlType="submit"
                className="form-button"
                disabled={!formIsComplete}
              >
                Ingresar
              </Button>
            );
          }}
        </Form.Item>
        <Link className="login-form-forgot" to="/resetPassword">
          Olvidé mi contraseña
        </Link>
      </Form>
    </div>
  );
};

export default LoginForm;
