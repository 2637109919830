import { DatePicker, Image, Input, Select, Tooltip } from "antd";
import { useCallback, useContext, useRef, useState } from "react";
import { SorterResult } from "antd/lib/table/interface";
import { useHistory } from "react-router";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";

import ProTable, { ActionType } from "@ant-design/pro-table";
import { SaveForm } from "../ABM";
import { ExportableColumn } from "../../shared/Exporter";
import GraphqlService from "../../services/graphql/GraphqlService";
import {
  ABM,
  CustomMessage,
  ContextApp,
  Tools,
  EnumsValues,
} from "../../shared";
import { ParamsType } from "@ant-design/pro-provider";
import { Datepicker } from "../DatePicker/DatePicker";
import {
  FORMAT_DATE_TIME_1,
  FORMAT_DATE_TIME_3,
  FORMAT_DATE_TIME_4,
  MomentJS,
} from "../../shared/MomentJS";
import CustomFilter from "../CustomFilter/CustomFilter";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import { camelCase, getPaginationArgs } from "../../shared/utils";
import SelectWithFetch from "../utils/SelectWithFetch";
import { IProducer } from "../../services/Producer";
import { StatusQueueRequestArray } from "../../services/StatusQueueRequest";
import { IBaseFile } from "../../services/defaultSchema";
import { IBooksQueueRequests } from "../../services/booksQueueRequests";
import csvIcon from "../../assets/csv-icon.png";

const { Option } = Select;

interface IformValues {
  COD_PROD: string;
  Fec_des: string;
  Fec_has: string;
}

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  "request_id",
  "Pedido",
  "Fec_des",
  "Fec_has",
  "COD_PROD",
  "status_id",
  "Pedido",
  "liderar_message",
  "its_range",
];
const LIST_SORTER = [
  "request_id",
  "Pedido",
  "Fec_des",
  "Fec_has",
  "COD_PROD",
  "insured",
  "status",
  "Pedido",
  "username",
];

/**
 * Se configura por cada ABM diferente
 */

interface IProps {
  title: string;
  createTitle: string;
  bookType: "emission" | "collection";
  contextName: string;
}

const Books = (props: IProps): JSX.Element => {
  const { title, createTitle, bookType, contextName } = props;

  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>("");
  const [producer, setProducer] = useState<IProducer>();

  const { Query, customRequest } = GraphqlService();
  const { messageError, messageSuccess } = CustomMessage();
  const history = useHistory();

  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  const { user } = useContext(ContextApp);

  const canDownloadFile = useCallback((record: IBooksQueueRequests) => {
    return (
      record.status_queue_requests.status ===
      EnumsValues.StatusQueueRequestName.Available
    );
  }, []);

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);
      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            if (element === "FECHA_CORTE") {
              variables.current.filter[element] = moment(
                new Date(search[element])
              ).format(FORMAT_DATE_TIME_4);
            } else if (element === "COD_PROD") {
              variables.current.filter["COD_PROD"] = String(
                producer?.id_liderar
              );
            } else if (element === "Pedido") {
              variables.current.filter[element] = moment(
                new Date(search[element])
              ).format(FORMAT_DATE_TIME_3);
            } else {
              variables.current.filter[element] = search[element];
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current
      );

      variables.current.skip = skip;
      variables.current.take = take;

      if (bookType === EnumsValues.BookTypes.emission) {
        variables.current.filter = {
          ...variables.current.filter,
          TIPO: EnumsValues.FileRequestType.LibroEmision,
        };
      }
      if (bookType === EnumsValues.BookTypes.collection) {
        variables.current.filter = {
          ...variables.current.filter,
          TIPO: EnumsValues.FileRequestType.LibroCzas,
        };
      }

      const countPromise = customRequest({
        query: Query.booksQueueRequestsCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const dataPromise = customRequest({
        query: Query.booksQueueRequests,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);

      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const handleProducerSearch = (value: string): Promise<any[]> => {
    return new Promise((resolve) => {
      if (Number.isNaN(value)) {
        return resolve([]);
      }
      if (!value) {
        return resolve([]);
      }
      return customRequest({
        query: Query.producers,
        variables: {
          searchText: value,
          filter: {
            user_id: user?.id,
            activo_web: true,
          },
        },
      })
        .then((data: IProducer[]) => {
          resolve(data);
        })
        .catch(() => {
          messageError({
            context: "handleProducerSearch",
            message: "Error al obtener los productores",
          });
        });
    });
  };

  const columns = useCallback(
    (): ExportableColumn<IBooksQueueRequests>[] => [
      {
        export: true,
        dataIndex: "request_id",
        title: "ID",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        defaultSortOrder: "descend",
        align: "left",
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => record.request_id || "-",
        renderFormItem: () => {
          return <Input />;
        },
      },
      {
        export: true,
        sorter: true,
        dataIndex: "Pedido",
        title: "Fecha de solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "La Fecha es obligatoria",
            },
          ],
        },
        render: (_: any, record) =>
          moment(new Date(record.Pedido)).format(FORMAT_DATE_TIME_1) || "-",
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "its_range",
        title: "Fecha solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        renderFormItem: () => (
          <DatePicker.RangePicker
            name="its_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={["Fecha desde", "Fecha hasta"]}
            allowEmpty={[true, true]}
            className="renderFormItem"
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "username",
        title: "Solicitante",
        sorter: true,
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record.createdBy.username || "-",
        renderFormItem: () => <Input />,
      },
      {
        export: true,
        sorter: true,
        title: "Fecha desde",
        dataIndex: "Fec_des",
        type: ABM.TYPE_COLUMN.DATE,
        render: (_: any, record) => (
          <p className="non-wrap-cell">
            {MomentJS.specificMoment(
              {
                date: record?.Fec_des,
                format: FORMAT_DATE_TIME_4,
                utc: false,
              },
              FORMAT_DATE_TIME_4,
              true
            ) || "-"}
          </p>
        ),
        renderFormItem: () => (
          <Datepicker
            placeholder="Seleccione fecha"
            format={FORMAT_DATE_TIME_4}
            allowClear
          />
        ),

        align: "left",
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        sorter: true,
        title: "Fecha hasta",
        dataIndex: "Fec_has",
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "La Fecha es obligatoria",
            },
          ],
        },
        render: (_: any, record) => (
          <p className="non-wrap-cell">
            {MomentJS.specificMoment(
              {
                date: record?.Fec_has,
                format: FORMAT_DATE_TIME_4,
                utc: false,
              },
              FORMAT_DATE_TIME_4,
              true
            ) || "-"}
          </p>
        ),
        renderFormItem: () => (
          <Datepicker
            placeholder="Seleccione fecha"
            format={FORMAT_DATE_TIME_4}
            allowClear
          />
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: "COD_PROD",
        title: "Productor",
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El productor es obligatorio",
            },
          ],
        },
        render: (_: any, record) =>
          `${record.producer.id_liderar} ${record.producer.nombre}` || "-",
        renderFormItem: () => (
          <SelectWithFetch
            lengthValueToFetch={3}
            onSelect={(value: IProducer) => {
              setProducer(value);
            }}
            query={handleProducerSearch}
            placeholder={"Ingrese nombre/número de productor"}
            optionRender={(item: IProducer) => (
              <Option value={item.id_liderar} key={item.id_liderar}>
                {item.id_liderar} - {camelCase(item.nombre)}
              </Option>
            )}
            onClear={() => {
              setProducer(undefined);
            }}
          />
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: "status_id",
        title: "Estado",
        align: "left",
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
        renderFormItem: () => (
          <Select
            placeholder="Seleccione estado"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            dropdownMatchSelectWidth={false}
            options={StatusQueueRequestArray.map((item) => ({
              value: item.id,
              key: item.id,
              label: item.status,
            }))}
            allowClear
          />
        ),
      },
      {
        export: true,
        dataIndex: "status",
        title: "Estado",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record.status_queue_requests.status || "-",
        sorter: false,
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: "liderar_message",
        title: "Mensaje",
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El mensaje es obligatorio",
            },
          ],
        },
        className: "ellipsisColumn",
        render: (_: any, record) => (
          <Tooltip title={record.liderar_message}>
            <div className="ellipsisColumn">
              <span>{record.liderar_message || "-"}</span>
            </div>
          </Tooltip>
        ),
        renderFormItem: () => (
          <Input placeholder="Ingrese mensaje" minLength={4} />
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "op",
        title: "Op.",
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {canDownloadFile(record) ? (
              <Tooltip title="Descargar archivo">
                <Image
                  preview={false}
                  src={csvIcon}
                  style={{ width: 30 }}
                  className="pointer"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDownload(record);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="El CSV no esta disponible">
                <DownloadOutlined className="disabled-icon" />
              </Tooltip>
            )}
          </>
        ),
      },
    ],
    []
  );

  const handleDownload = useCallback(async (record: IBooksQueueRequests) => {
    try {
      const result: IBaseFile = await customRequest({
        query: Query.getBookFile,
        variables: {
          id: record.id,
        },
      });
      if (!result.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(result);
    } catch (error: any) {
      console.error(error);
      messageError({
        context: `${contextName}.handleDownload`,
        message: error.message || "Ocurrió un error al descargar el archivo",
      });
    }
  }, []);

  return (
    <div className={contextName}>
      <h1>{title}</h1>
      <CustomFilter header="Filtrar por" />
      <ProTable<IBooksQueueRequests>
        defaultSize="small"
        className={`pro-table-request-${contextName}`}
        actionRef={actionRef}
        rowKey="id"
        search={{
          collapsed: false,
          resetText: "Limpiar búsqueda",
          searchText: "Buscar",
          collapseRender: false,
        }}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IBooksQueueRequests>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        params={{
          sorter,
        }}
        request={async (_params, _sorter, _filter) =>
          request({ ..._params, _sorter, _filter })
        }
        columns={columns()}
        pagination={{
          className: "pagination-bar",
        }}
        toolbar={{
          className: `${contextName}-toolbar`,
        }}
      />
      <div className="buttons">
        <ButtonLiderar
          color="white"
          buttonName="Volver"
          onClick={() => history.goBack()}
        />
        <ButtonLiderar
          color="violet"
          buttonName="Nueva solicitud"
          onClick={() => setCreateModalVisible(true)}
        />
      </div>
      <SaveForm
        loading={formLoading}
        title={createTitle}
        submitText="Enviar"
        onCancel={() => setCreateModalVisible(false)}
        modalVisible={createModalVisible}
        className={`modal-create-${contextName}`}
        columns={columns()}
        onOk={(formValues: IformValues) => {
          if (!producer) {
            messageError({
              context: `${contextName}.SaveForm.onOk.1`,
              message: "Seleccione un productor",
            });
            return;
          }

          const input = {
            Fec_des: moment(new Date(formValues.Fec_des)).format(
              FORMAT_DATE_TIME_4
            ),
            Fec_has: moment(new Date(formValues.Fec_has)).format(
              FORMAT_DATE_TIME_4
            ),
            COD_PROD: producer.id_liderar,
            TIPO: "",
          };

          if (bookType === EnumsValues.BookTypes.emission) {
            input.TIPO = EnumsValues.FileRequestType.LibroEmision;
          }

          if (bookType === EnumsValues.BookTypes.collection) {
            input.TIPO = EnumsValues.FileRequestType.LibroCzas;
          }

          setFormLoading(true);
          customRequest({
            query: Query.booksFromUniverse,
            variables: {
              input,
            },
          })
            .then(() => {
              messageSuccess({
                context: `${contextName}.SaveForm.onOk.2`,
                message: "Solicitud creada correctamente",
                time: 2000,
              });
              setCreateModalVisible(() => false);
            })
            .catch((error: any) => {
              messageError({
                context: `${contextName}.SaveForm.onOk.3`,
                message: error.message,
              });
            })
            .finally(() => {
              actionRef.current?.reload();
              setFormLoading(false);
            });
        }}
      />
    </div>
  );
};

export default Books;
