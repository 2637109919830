import { RouteConfigComponentProps } from "react-router-config";
import { EditOutlined } from "@ant-design/icons";
import ProTable, { ActionType } from "@ant-design/pro-table";
import { Input, Select, Tooltip } from "antd";
import { ExporterDropdown, SaveForm } from "../ABM";
import { useCallback, useContext, useRef, useState, useEffect } from "react";
import { ExportableColumn, ExportableTable } from "../../shared/Exporter";
import { SorterResult } from "antd/lib/table/interface";
import GraphqlService from "../../services/graphql/GraphqlService";
import {
  ABM,
  Authorization,
  CustomMessage,
  ContextApp,
  EnumsValues,
  MomentJS,
  Tools,
} from "../../shared";
import { IPermission } from "../../services/permission";
import { isPlatform } from "@ionic/react";
import { ParamsType } from "@ant-design/pro-provider";
import {
  showCollapseRender,
  useCheckAuthority,
} from "../../shared/CustomHooks";

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ["name", "permission_id"];
const LIST_SORTER = ["name"];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = "Permisos";
const TITLE_UPDATE_FORM = "Editar permiso";
const INPUT_SEARCH_PLACEHOLDER = "Buscar...";

export default function Permission(
  routeProps: RouteConfigComponentProps
): JSX.Element {
  // props

  // states
  const [searchText, setSearchText] = useState("");
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>("");
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [permissionsCombo, setPermissionsCombo] = useState<IPermission[]>([]);
  // services and hooks
  const { Query, Mutation, customRequest } = GraphqlService();
  const {
    messageError,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // contexts
  const { functions } = useContext(ContextApp);

  // methods

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  useEffect(() => {
    customRequest({
      query: Query.permissions,
    })
      .then((data: IPermission[]) => {
        setPermissionsCombo(() => data);
        return {
          current: 1,
          data: data,
          pageSize: "1",
          success: true,
          total: data.length,
        };
      })
      .catch(() => {
        return {
          current: 1,
          data: [],
          pageSize: "1",
          success: true,
          total: 0,
        };
      });
  }, []);

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    const search: any = ABM.valuesResult(params);

    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element]) {
          if (!variables.current.filter) {
            variables.current.filter = {};
          }
          variables.current.filter[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search._sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search._sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    return customRequest({
      query: Query.permissions,
      variables: variables.current,
    })
      .then((data: IPermission[]) => {
        setDataTable(data);
        return {
          current: 1,
          data: data,
          pageSize: "1",
          success: true,
          total: data.length,
        };
      })
      .catch(() => {
        return {
          current: 1,
          data: [],
          pageSize: "1",
          success: true,
          total: 0,
        };
      });
  };

  const updatePermission = (value: any) => {
    setFormLoading(() => true);
    messageUpdating({
      context: "TablePermission.updatePermission.1",
      message: "permiso",
    });
    customRequest({
      mutation: Mutation.updatePermission,
      variables: { input: { ...value, id: parseInt(editForm.id) } },
    })
      .then(() => {
        messageUpdateSuccess({
          context: "TablePermission.updatePermission.2",
        });
        handleUpdateModalVisible(false);
        if (actionRef.current) {
          actionRef.current.reload();
        }
      })
      .catch((error: any) => {
        if (error.status_code && error.message) {
          return messageError({
            context: "TablePermission.updatePermission.3",
            message: error.message,
          });
        }
        messageUpdateError({ context: "TablePermission.updatePermission.3" });
      })
      .finally(() => {
        setFormLoading(() => false);
      });
  };

  const columns = useCallback(
    (): ExportableColumn<IPermission>[] => [
      {
        export: false,
        dataIndex: "id",
        title: "id",
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: "name",
        title: "Permiso",
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El permiso es obligatorio",
            },
          ],
        },
        render: (_: any, record: { name: any }) => record.name || "-",
        renderFormItem: () => (
          <Input placeholder="Ingrese permiso" minLength={4} />
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: "permission_id",
        title: "Permiso",
        valueType: "select",
        renderFormItem: () => (
          <Select
            options={permissionsCombo.map((permiso) => ({
              label: permiso.name,
              value: permiso.id,
            }))}
            placeholder="Seleccione permiso..."
            mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            dropdownMatchSelectWidth={false}
            allowClear
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: "description",
        title: "Descripción",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IPermission) => record.description || "-",
        renderFormItem: () => (
          <Input placeholder="Ingrese descripción" minLength={4} />
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        title: "Op.",
        dataIndex: "option",
        valueType: "option",
        fixed: "right",
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.PermissionUpdate
            ) && (
              <Tooltip
                key="edit_permiso_tooltip"
                trigger={isPlatform("desktop") ? "hover" : " focus"}
                title="Modificar permiso"
              >
                <EditOutlined
                  className="pointer"
                  onClick={() => {
                    handleUpdateModalVisible(true);
                    setEditFormValues(record);
                  }}
                />
              </Tooltip>
            )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.PermissionUpdate
            )}
          </>
        ),
      },
    ],
    [dataTable]
  );
  const LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (LIST_FILTER.find((element) => element === value.dataIndex)) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const exportableTable: ExportableTable<IPermission> = {
    columns: columns(),
    items: dataTable,
    filename: `permiso_${MomentJS.momentDefaultFormat()}`,
  };

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>{TITLE_PRO_TABLE}</h1>
        <ProTable<IPermission>
          defaultSize="small"
          actionRef={actionRef}
          rowKey="id"
          search={{
            resetText: "Limpiar",
            searchText: "Buscar",
            collapseRender: showCollapseRender(columns()),
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IPermission>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          onReset={() => {
            setSearchText("");
          }}
          params={{
            sorter,
          }}
          toolBarRender={() => [
            <div className="content-search-table" key={0}>
              <Tooltip
                key="searchtext"
                title={"Buscar por: " + LIST_FILTER_NAMES.join(", ")}
              >
                <Input.Search
                  size="middle"
                  placeholder={INPUT_SEARCH_PLACEHOLDER}
                  enterButton
                  value={searchText}
                  onSearch={handleSearch}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                />
              </Tooltip>
            </div>,
            <>
              <ExporterDropdown exportable={exportableTable} />
            </>,
          ]}
          /**
           * @description este metodo debe poder ejecutar siempre la consulta al backend
           */
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns()}
        />
        {editForm && (
          <SaveForm
            loading={formLoading}
            title={TITLE_UPDATE_FORM}
            modalVisible={updateModalVisible}
            values={editForm}
            columns={columns()}
            onOk={async (value) => updatePermission(value)}
            onCancel={() => {
              handleUpdateModalVisible(false);
              setEditFormValues({});
            }}
          />
        )}
      </>
    )
  );
}
