export const PERMISSIONS = `
  query permissions($filter:  FilterPermissionInput,
    $orderBy:  PermissionOrderInput,
    $skip: Int,
    $take: Int) {
    permissions(filter: $filter
  orderBy: $orderBy
  skip: $skip
  take: $take) {
      __typename
      ...on Permissions {
        id
        name
        code
        description
      }
      ...on ResultError {
        status_code
        message
      }
    }
  }
  `;
