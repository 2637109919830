export const AJUSTMENT_RATES = `
query adjustment_rates {
  adjustment_rates{
    __typename
    ... on AdjustmentRate{
      id
      id_liderar
      description
			tasa_aumento_list
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
