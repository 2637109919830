export const IMPRESSIONS_QUEUE_REQUESTS = `
query impressionsQueueRequests(
  $filter: FilterImpressionsQueueRequestInput,
  $orderBy: ImpressionsQueueRequestOrderInput,
  $skip: Int,
  $take: Int
) {
  impressionsQueueRequests(
    filter: $filter,
    orderBy: $orderBy,
    skip: $skip,
    take: $take,
  ) {
    __typename
    ... on ImpressionsQueueRequest{ 
    	id
      createdBy {
        username
      }
      request_id
      File
      Pedido
      TIPO
      COD_PROD
      Id_PV
      Envia_mail
      Mail
      Graba_pdf
      Ruta_PDF
      Estado
      status_queue_requests_id
      status_queue_requests {
        id
        status
      }
      liderar_message
      producer_id
      producer {
        id
        nombre
        id_liderar
      }
      its
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const IMPRESSIONS_QUEUE_REQUESTS_COUNT = `
query impressionsQueueRequestsCount(
  $filter: FilterImpressionsQueueRequestInput
) {
  impressionsQueueRequestsCount(
    filter: $filter
  ) {
    __typename
    ... on Count{ 
    	count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const IMPRESSIONS_QUEUE_REQUEST_FILE = `
query impressionsQueueRequestFile ($id: Int!) {
  impressionsQueueRequestFile(id: $id) {
    __typename
    ... on ImpressionsQueueRequestFile {
      filename
      mimetype
      encoding
      file
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
