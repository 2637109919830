import { parcialTypeImpressions } from "../../shared/utils";

export function createBinaryArrayForPartialImpressions(
  selectedValues?: string[]
): string[] | undefined {
  if (!selectedValues) return;

  const binaryArray = parcialTypeImpressions.map((item) =>
    selectedValues.includes(item) ? "1" : "0"
  );
  return binaryArray;
}
