import { useCallback, useEffect, useState } from "react";
import { FormInstance, Select } from "antd";
import GraphqlService from "../../services/graphql/GraphqlService";
import { IPostalCode } from "../../services/PostalCode";
import { CustomMessage } from "../CustomMessage";
import { EnumsValues } from "../EnumsValues";
import { camelCase } from "../utils";

export interface ILocationAndPostalCodesProps {
  provinceIdSelected: number;
  selectedlocalidad?: string;
  formItemLocationName: string;
  formItemPostalCodeName?: string;
  form: FormInstance;
}

const useLocationAndPostalCodes = (props: ILocationAndPostalCodesProps) => {
  const {
    selectedlocalidad,
    provinceIdSelected,
    formItemLocationName,
    formItemPostalCodeName,
    form,
  } = props;

  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();

  const [localidades, setLocalidades] = useState<IPostalCode[]>([]);
  const [postalCodes, setPostalCodes] = useState<IPostalCode[]>([]);

  const getLocationByProvince = useCallback(async () => {
    if (provinceIdSelected) {
      await customRequest({
        query: Query.locationByProvinceId,
        variables: {
          provinceId: provinceIdSelected,
        },
      })
        .then((response: IPostalCode[]) => {
          if (response && response.length > 0) {
            if (provinceIdSelected === EnumsValues.Provincia.CAPITAL) {
              setLocalidades([response[0]]);
            } else {
              setLocalidades(response);
            }
          }
        })
        .catch((error:any) => {
          messageError({
            context: "getLocationByProvince",
            message: "Error al obtener Localidades",
          });
        });
    }
  }, [provinceIdSelected]);

  const getPostalCodes = useCallback(
    async (provinceId: number, localidad: string) => {
      await customRequest({
        query: Query.postal_codes,
        variables: {
          filter: {
            proid: provinceId,
            location: localidad,
          },
        },
      })
        .then((response: IPostalCode[]) => {
          if (response && response.length > 0) {
            setPostalCodes(response);
          }
        })
        .catch((error:any) => {
          messageError({
            context: "getLPostalCodes",
            message: "Error al obtener los códigos postales",
          });
        });
    },
    []
  );

  useEffect(() => {
    if (provinceIdSelected) {
      form.resetFields([formItemLocationName]);
      if (formItemPostalCodeName) {
        form.resetFields([formItemPostalCodeName]);
      }
      getLocationByProvince();
    }
  }, [provinceIdSelected]);

  useEffect(() => {
    if (provinceIdSelected && selectedlocalidad) {
      if (formItemPostalCodeName) {
        form.resetFields([formItemPostalCodeName]);
      }
      getPostalCodes(provinceIdSelected, selectedlocalidad);
    }
  }, [provinceIdSelected, selectedlocalidad]);

  const locationOptions = () =>
    localidades.length !== 0 &&
    localidades.map((item: IPostalCode) => (
      <Select.Option value={item.location} key={item.id}>
        {camelCase(item.location)}
      </Select.Option>
    ));

  const postalCodeOptions = () =>
    postalCodes.map((item: IPostalCode, index: number) => (
      <Select.Option value={item.codpostal} key={index}>
        {item.codpostal}
      </Select.Option>
    ));

  return {
    localidades,
    postalCodes,
    getLocationByProvince,
    getPostalCodes,
    locationOptions,
    postalCodeOptions,
  };
};

export default useLocationAndPostalCodes;
