import {
  Suspense,
  useContext,
  useLayoutEffect,
  useState,
  useEffect,
} from "react";
import { renderRoutes, RouteConfigComponentProps } from "react-router-config";
import { Redirect } from "react-router";
import { Layout } from "antd";

import { HeaderComponent } from "./HeaderComponent";
import { ContextApp } from "../../shared";
import ExceptionManager from "../../components/ExceptionManager";
import { UserService } from "../../services/user";
import { PageLoading } from "@ant-design/pro-layout";
import { NavContext } from "@ionic/react";

import "./index.less";

const RegisteredLayout = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  const { navigate } = useContext(NavContext);
  const [background, setBackground] = useState<any>({});
  const { user, sessionExpired } = useContext(ContextApp);
  const { expireSession } = UserService();

  const { Footer, Content } = Layout;

  useLayoutEffect(() => {
    if (sessionExpired) {
      expireSession();
    }
  }, [sessionExpired]);

  useEffect(() => {
    if (user && user.already_logged_in === false) {
      navigate("/password");
    }
    if (location.pathname === "/app/home") {
      setBackground({ backgroundImage: 'url("/assets/bg_home.jpg")' });
    } else {
      setBackground({});
    }
  }, [location.pathname]);

  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {!sessionExpired ? (
        <Layout className="RegisteredLayout" style={background}>
          <HeaderComponent routes={routeProps.route?.routes} />
          <div className="site-layout-content">
            <Content className="contenido">
              <Suspense fallback={<PageLoading />}>
                <ExceptionManager>
                  {renderRoutes(routeProps.route?.routes)}
                </ExceptionManager>
              </Suspense>
            </Content>
          </div>

          <Footer></Footer>
        </Layout>
      ) : (
        <>
          <Redirect to="/login" />;
        </>
      )}
    </>
  );
};

export default RegisteredLayout;
