import { Col } from "antd";
import { IVehiculo } from "../../../../../services/Vehiculo";
import { formatStringToMoney } from "../../../../../shared/utils";
import PolizaDetailsItem from "../PolizaDetailsItem";

interface VehicleProps {
  vehiculo: IVehiculo;
}

const Vehicle = (props: VehicleProps): JSX.Element => {
  const { vehiculo } = props;
  return (
    <>
      <Col span={14}>
        <PolizaDetailsItem property="Vehículo:" value={vehiculo?.marca} />
        <PolizaDetailsItem property="Modelo:" value={vehiculo?.anio} />
        <PolizaDetailsItem property="Patente:" value={vehiculo?.patente} />
        <PolizaDetailsItem property="Tipo:" value={vehiculo?.tipo} />
        <PolizaDetailsItem property="Destino:" value={vehiculo?.uso} />
        <PolizaDetailsItem property="Cobertura:" value={vehiculo?.cobertura} />
        <PolizaDetailsItem
          property="Adicional granizo:"
          value={vehiculo?.adicional_granizo || "-"}
        />
      </Col>
      <Col span={10}>
        <PolizaDetailsItem property="Estado:" value={vehiculo?.estado || "-"} />
        <PolizaDetailsItem
          property="Prima total:"
          value={formatStringToMoney(String(vehiculo?.prima_total))}
        />
        <PolizaDetailsItem
          property="Nº chasis:"
          value={vehiculo?.codigo_chasis}
        />
        <PolizaDetailsItem
          property="Nº motor:"
          value={vehiculo?.codigo_motor}
        />
        <PolizaDetailsItem
          property="Suma asegurada:"
          value={formatStringToMoney(String(vehiculo?.suma_asegurada))}
        />
        <PolizaDetailsItem
          property="Tasa de ajuste:"
          value={
            vehiculo?.ajuste_automotor !== "" ? vehiculo?.ajuste_automotor : "-"
          }
        />
        {!vehiculo?.descrip_accesorios?.length ? (
          <PolizaDetailsItem property="Accesorios:" value={"Sin accesorios"} />
        ) : (
          vehiculo?.descrip_accesorios?.length &&
          vehiculo.descrip_accesorios.map(
            (accesorio: string, index: number) => {
              return (
                <PolizaDetailsItem
                  key={index}
                  property={`${accesorio}:`}
                  value={`$ ${vehiculo?.suma_accesorios[index]}`}
                />
              );
            }
          )
        )}
      </Col>
    </>
  );
};

export default Vehicle;
