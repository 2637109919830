import {
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";
import { useState, useCallback } from "react";

import { IProvince } from "../../../../services/Province";
import useLocationAndPostalCodes from "../../../../shared/hooks/useLocationAndPostalCodes";
import { camelCase } from "../../../../shared/utils";

const { Panel } = Collapse;
const { Option } = Select;

export interface IPledgedCreditorProps {
  form: FormInstance;
  setAcreedorProvinceName: React.Dispatch<React.SetStateAction<string>>;
  provinces: IProvince[];
  setPostalCodePledgedCreditor: React.Dispatch<React.SetStateAction<number>>;
}

const PledgedCreditorFormItems = (props: IPledgedCreditorProps) => {
  const {
    form,
    setAcreedorProvinceName,
    provinces,
    setPostalCodePledgedCreditor,
  } = props;

  const [provinceIdAcreedorSelected, setProvinceIdAcreedorSelected] =
    useState<number>(0);
  const [selectedlocalidadAcreedor, setSelectedlocalidadAcreedor] =
    useState<string>("");
  const [isAcreedorPrendario, setIsAcreedorPrendario] =
    useState<boolean>(false);

  const { localidades, postalCodes } = useLocationAndPostalCodes({
    selectedlocalidad: selectedlocalidadAcreedor,
    provinceIdSelected: provinceIdAcreedorSelected,
    formItemLocationName: "acreedor_localidad",
    formItemPostalCodeName: "acreedor_postal_code",
    form,
  });

  const handleChangeLocationSelectAcreedor = (location: string) => {
    setSelectedlocalidadAcreedor(location);
  };

  const handleChangeProvinceAcreedor = useCallback(
    (id: number) => {
      setProvinceIdAcreedorSelected(id);
      const selectedProvince = provinces.find((province) => province.id === id);
      if (selectedProvince) {
        setAcreedorProvinceName(selectedProvince?.description);
      }
    },
    [provinces]
  );

  const handleChangePostalCodeAcreedor = useCallback(
    (codpostal: string) => {
      const selectedPostalCode = postalCodes.find(
        (postalCode) =>
          postalCode.codpostal === codpostal &&
          postalCode.location === selectedlocalidadAcreedor
      );
      if (selectedPostalCode) {
        setPostalCodePledgedCreditor(selectedPostalCode?.id);
      }
    },
    [selectedlocalidadAcreedor, postalCodes]
  );

  return (
    <Collapse className="collapse">
      <Panel header="Datos del acreedor prendario" key="5">
        <Row gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              label="Acreedor prendario"
              name="is_acreedor_prendario"
            >
              <Switch onChange={(value) => setIsAcreedorPrendario(value)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Ingrese apellido",
                },
              ]}
              label="Apellido"
              name="acreedor_surname"
            >
              <Input
                type="text"
                disabled={!isAcreedorPrendario}
                placeholder="Ingrese apellido"
                maxLength={100}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Ingrese nombre",
                },
              ]}
              label="Nombre"
              name="acreedor_name"
            >
              <Input
                type="text"
                disabled={!isAcreedorPrendario}
                placeholder="Ingrese nombre"
                maxLength={100}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Ingrese domicilio",
                },
              ]}
              label="Domicilio"
              name="acreedor_home_street"
            >
              <Input
                type="text"
                disabled={!isAcreedorPrendario}
                maxLength={30}
                placeholder="Ingrese Domicilio"
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Ingrese número de domicilio",
                },
              ]}
              label="Número"
              name="acreedor_home_number"
            >
              <InputNumber
                controls={false}
                type="number"
                disabled={!isAcreedorPrendario}
                maxLength={10}
                placeholder="Ingrese número de domicilio"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item label="Piso" name="acreedor_home_floor">
              <Input
                type="text"
                disabled={!isAcreedorPrendario}
                placeholder="Ingrese piso"
                maxLength={40}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item label="Departamento" name="acreedor_home_department">
              <Input
                type="text"
                disabled={!isAcreedorPrendario}
                placeholder="Ingrese departamento"
                maxLength={40}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Seleccione provincia",
                },
              ]}
              label="Provincia"
              name="acreedor_province_code"
            >
              <Select
                placeholder="Seleccione"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                disabled={!isAcreedorPrendario}
                onChange={handleChangeProvinceAcreedor}
              >
                {provinces.length !== 0 &&
                  provinces.map((item) => (
                    <Option value={item.id} key={item.id}>
                      {camelCase(item.description)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Seleccione localidad",
                },
              ]}
              label="Localidad"
              name="acreedor_localidad"
            >
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                disabled={
                  !isAcreedorPrendario ||
                  form.getFieldsValue().acreedor_province_code === undefined
                }
                placeholder="Seleccione"
                onSelect={handleChangeLocationSelectAcreedor}
              >
                {localidades.map((item, index: number) => (
                  <Option value={item.location} key={index}>
                    {camelCase(item.location)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Ingrese código postal",
                },
              ]}
              label="Código postal"
              name="acreedor_postal_code"
            >
              <Select
                showSearch
                placeholder="Seleccione"
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                disabled={
                  !isAcreedorPrendario ||
                  form.getFieldsValue().acreedor_localidad === undefined
                }
                onChange={handleChangePostalCodeAcreedor}
              >
                {postalCodes.map((item, index: number) => (
                  <Option value={item.codpostal} key={index}>
                    {item.codpostal}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default PledgedCreditorFormItems;
