export const ROLE = `
query role(
    $filter: FilterRoleInput! 
  ){
  role(
      filter: $filter
  )
  {
      __typename
      ... on Role{
          id
          name
          is_system_role
      }
      ... on ResultError{
          status_code
          message
      }
  }
  }
`;

export const ROLES = `
query roles(
    $filter:  FilterRoleInput,
    $orderBy:  RoleOrderInput,
    $skip: Int,
    $take: Int,
    $searchText: String,
){
roles(
  filter: $filter
  orderBy: $orderBy
  skip: $skip
  take: $take
  searchText: $searchText
)
{
    __typename
    ... on Role{
        id
        name
        is_system_role
     }
    ... on ResultError{
        status_code
        message
    }
}
}

`;
