export const UPSERT_VEHICLE_TYPE = `
mutation upsertVehicleType($input: UpsertVehicleTypeInput!) {
  upsertVehicleType(input: $input) {
    __typename
    ... on SuccessfulResponse {
      status
      status_message
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
