import { Table } from "antd";
import Modal from "antd/lib/modal/Modal";
import { IDebtSummaryDetail } from "../../../../services/DebtSummary";
import ButtonLiderar from "../../../ButtonLiderar/ButtonLiderar";
import { AlignType } from "rc-table/lib/interface";
import "./style.less";

export interface IDebtSummaryDetailProps extends IDebtSummaryDetail {
  name: string;
  parsedAmount: string;
}

export interface IModalSummaryProps {
  data: IDebtSummaryDetailProps[];
  modalVisible: boolean;
  onCancel: () => void;
  onOk: () => void;
  titleModal: string;
}

interface Icolumns {
  title: string;
  dataIndex: string;
  align?: AlignType;
}

export default function ModalRelatedInsurancePolicy(
  props: IModalSummaryProps
): JSX.Element {
  const { modalVisible, onCancel, titleModal, data, onOk } = {
    ...props,
  };

  const columns: Icolumns[] = [
    {
      title: "Asegurado",
      dataIndex: "name",
    },
    {
      title: "Patente",
      dataIndex: "patent",
      align: "center",
    },
    {
      title: "Pólizas",
      dataIndex: "policy_id",
    },
    {
      title: "Vencimiento",
      dataIndex: "due_date",
      align: "center",
    },
    {
      title: "Importe",
      dataIndex: "parsedAmount",
      align: "center",
    },
    {
      title: "Cuota",
      dataIndex: "instalment",
      align: "center",
    },
  ];
  return (
    <>
      <Modal
        className="modal-related-insurance-policy"
        title={titleModal}
        footer={null}
        destroyOnClose
        visible={modalVisible}
        onCancel={() => onCancel()}
        onOk={() => onOk()}
      >
        <div className="border-table">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered={false}
            size="small"
          />
        </div>
        <div className="buttons">
          <ButtonLiderar
            color="violet"
            buttonName="Cerrar"
            className="form-button search-button"
            htmlType="submit"
            onClick={() => onOk()}
          />
        </div>
      </Modal>
    </>
  );
}
