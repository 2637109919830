import { useCallback, useEffect } from "react";
import { Radio, Input, Form } from "antd";
import { useLocation } from "react-router";
import PolizaInput from "./PolizaInput";
import { IHistoryState, IPolizaInput } from "../BusquedaPolizas";
import { EnumsValues } from "../../../shared";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";
import "./style.less";

interface SearchFormProps {
  getPoliza: (values: IPolizaInput) => void;
  setSearchType: (type: string) => void;
  searchType: string;
  clearButton: boolean;
  cleanSearch: () => void;
  onClick?: () => void;
  hideTitle?: boolean;
  formValuesOnLoad?: any;
}

const SearchForm: React.FC<SearchFormProps> = (props) => {
  const { PolizaSearchType } = EnumsValues;
  const location = useLocation();
  const state = location.state as IHistoryState;
  const {
    getPoliza,
    setSearchType,
    searchType,
    clearButton,
    cleanSearch,
    onClick,
    hideTitle = false,
    formValuesOnLoad,
  } = props;
  const { NAME, PATENTE, POLIZA } = PolizaSearchType;
  const [form] = Form.useForm();

  useEffect(() => {
    cleanSearch();
    if (location.pathname === "/app/poliza/busqueda") {
      setTimeout(() => {
        form.setFieldsValue({
          //TODO: revisar luego, solucion temporal
          endoso: "",
          poliza: "",
          name: "",
          seccion: "",
          patente: "",
        });
      }, 500);
    }
  }, [searchType]);

  const onChange = (e: any) => {
    setSearchType(e.target.value);
  };

  const renderInput = useCallback(
    (searchTypeParam: string) => {
      switch (searchTypeParam) {
        case POLIZA:
          return <PolizaInput />;
        case NAME:
          return (
            <Form.Item
              className="formItem"
              label="Apellido o razón social"
              name="name"
              rules={[{ required: true, message: "Ingrese valor de búsqueda" }]}
            >
              <Input
                aria-label="valor para buscar poliza"
                className="inputForm"
                placeholder="Juan Perez"
                maxLength={35}
                allowClear={true}
              />
            </Form.Item>
          );

        case PATENTE:
          return (
            <Form.Item
              className="formItem"
              label="Patente"
              name="patente"
              rules={[{ required: true, message: "Ingrese valor de búsqueda" }]}
            >
              <Input
                aria-label="valor para buscar póliza"
                className="inputForm"
                placeholder="NVY332"
                maxLength={12}
                allowClear={true}
              />
            </Form.Item>
          );

        default:
          return <PolizaInput />;
      }
    },
    [NAME, PATENTE, POLIZA]
  );

  useEffect(() => {
    if (formValuesOnLoad) {
      if (state.typeOfSearch === POLIZA) {
        form.setFieldsValue({
          seccion: formValuesOnLoad.seccion,
          poliza: formValuesOnLoad.poliza,
          endoso: formValuesOnLoad.endoso,
          radio: POLIZA,
        });
        setSearchType(POLIZA);
        setTimeout(() => {
          form.submit();
        }, 0);
      }
      if (state.typeOfSearch === NAME) {
        form.setFieldsValue({ name: formValuesOnLoad, radio: NAME });
        setSearchType(NAME);
        setTimeout(() => {
          form.submit();
        }, 0);
      }
      if (state.typeOfSearch === PATENTE) {
        form.setFieldsValue({ patente: formValuesOnLoad, radio: PATENTE });
        setSearchType(PATENTE);
        setTimeout(() => {
          form.submit();
        }, 0);
      }
    }
  }, [form, formValuesOnLoad, setSearchType]);

  return (
    <div className="SearchForm">
      {!hideTitle && <h3 className="form-title">Buscar por:</h3>}
      <Form
        name="search_poliza"
        layout="vertical"
        className="form-get-poliza"
        initialValues={{
          name: "",
          radio: "poliza",
        }}
        form={form}
        onFinish={getPoliza}
      >
        <Form.Item className="formItem" name="radio">
          <Radio.Group onChange={onChange} className="search-value">
            <Radio.Button className="radio-value" value={POLIZA}>
              Póliza
            </Radio.Button>
            <Radio.Button className="radio-value" value={NAME}>
              Nombre
            </Radio.Button>
            <Radio.Button className="radio-value" value={PATENTE}>
              Patente
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        {renderInput(searchType)}
        <div className="form-buttons">
          {clearButton ? (
            <ButtonLiderar
              color="white"
              onClick={cleanSearch}
              buttonName="Limpiar búsqueda"
              className="clean-form-button form-button"
              minWidth={"fit-content"}
              flex={1}
              margin={0}
            />
          ) : (
            <div style={{ flex: 1, padding: 5, marginRight: 8 }} />
          )}
          <ButtonLiderar
            color="violet"
            buttonName="Buscar"
            className="form-button search-button"
            htmlType="submit"
            minWidth={100}
            onClick={onClick}
            margin={0}
            flex={1}
          />
        </div>
      </Form>
    </div>
  );
};

export default SearchForm;
