export const PROVINCES = `
query provinces {
  provinces {
    __typename
    ... on Province{
      id
      description
      id_liderar
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
