import { StrictMode, useEffect, useState } from "react";
import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./global.less";

import "moment/locale/es";
import { renderRoutes } from "react-router-config";
import routes from "./routes/routes";
import { ContextApp } from "./shared";
import { IConfig, IConfigApp, IEnvironment } from "./services/environment";
import configJson from "./config.json";
import { IUser, UserService } from "./services/user";
import { IRole } from "./services/role";
import { PageLoading } from "@ant-design/pro-layout";
import { ConfigProvider } from "antd";
import esESIntl from "antd/lib/locale/es_ES";
import { Language } from "./services/Language";

// eslint-disable-next-line @typescript-eslint/no-var-requires
require("dotenv").config();

const App: React.FC = () => {
  const [configApp] = useState<IConfigApp>(configJson);
  const [user, setUser] = useState<IUser | undefined>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [functions, setFunctions] = useState<string[]>([]);
  const [environment, setEnvironment] = useState<IEnvironment>();
  const [config, setConfig] = useState<IConfig>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [showLoading, setShowLoading] = useState<boolean>(true);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [sessionExpired, setSessionExpired] = useState<boolean>(false);
  const [mainMenuCollapsed, setMainMenuCollapsed] = useState(
    window.innerWidth < 992
  );
  const [menuBreakpointCollapsed, setMenuBreakpointCollapsed] = useState(
    window.innerWidth < 992
  );

  const [maintenanceMode, setMaintenanceMode] = useState(false);
  const [languages] = useState<Language[]>([]);
  const [, setAuth] = useState<boolean>();
  const { authenticate } = UserService();

  const defaultEnvironmentCode = configJson.default_environment;
  const defaultEnvironment = (
    configJson.environments as {
      [key: string]: IEnvironment;
    }
  )[defaultEnvironmentCode];
  const defaultConfig = configJson.default_config;

  const checkAuth = async (): Promise<any> => {
    setAuth(() => false);
    setShowLoading(() => true);
    return authenticate()
      .then((res: any) => {
        if (res) {
          const authenticatedUser: IUser = {
            email: res.email,
            id: res.id,
            username: res.username,
            password: "",
            firstname: res.firstname,
            lastname: res.lastname,
            already_logged_in: res.already_logged_in,
            is_blocked_by_login: res.is_blocked_by_login,
            roles: res.roles,
            is_banned: res.is_banned,
            coverages: res.coverages,
            producers: res.producers,
            vehicleTypes: res.vehicleTypes,
          };
          setUser(() => authenticatedUser);
          setRoles(() => res.roles);
          setFunctions(() => res.permissions);
        }
        setAuth(() => true);
      })
      .catch(() => {
        setAuth(() => true);
      })
      .finally(() => {
        setShowLoading(() => false);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!user && token) {
      checkAuth();
    } else {
      setShowLoading(() => false);
    }
  }, [user]);

  return (
    <ContextApp.Provider
      value={{
        configApp,
        user,
        setUser,
        setLoading,
        loading,
        loadingMessage,
        setLoadingMessage,
        setShowLoading,
        showLoading,
        environment: environment || defaultEnvironment,
        setEnvironment,
        roles,
        setRoles,
        functions,
        setFunctions,
        config: config || defaultConfig,
        setConfig,
        dataLoaded,
        setDataLoaded,
        checkAuth,
        setAuth,
        mainMenuCollapsed,
        setMainMenuCollapsed,
        menuBreakpointCollapsed,
        setMenuBreakpointCollapsed,
        sessionExpired,
        setSessionExpired,
        languages,
        maintenanceMode,
        setMaintenanceMode,
      }}
    >
      <IonApp>
        <ConfigProvider locale={esESIntl}>
          {!showLoading ? (
            <IonReactRouter>
              <IonRouterOutlet>
                <StrictMode>{renderRoutes(routes)}</StrictMode>
              </IonRouterOutlet>
            </IonReactRouter>
          ) : (
            <PageLoading />
          )}
        </ConfigProvider>
      </IonApp>
    </ContextApp.Provider>
  );
};

export default App;
