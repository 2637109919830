import { useState, useEffect } from "react";
import { Modal } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { ABM } from "../../../shared";
import "./style.less";
import ExceptionManager from "../../ExceptionManager";
import { SaveOutlined, ClearOutlined } from "@ant-design/icons";
import { PageLoading } from "@ant-design/pro-layout";
import GraphqlService from "../../../services/graphql/GraphqlService";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";

export declare type SaveFormColumns<T = Record<string, unknown>> =
  ProColumns<T> & {
    type?: string;
  };

/**
 * @property modalVisible: determina si el modal es visible o no
 * @property onCancel: funcion ejecutada al cerrar el modal
 * @property onOk: funcion ejecutada al realizar submit del formulario
 * @property columns: campos a llenar en el formulario. Especificar el tipo de columna ya que será el tipo de dato que se enviará en la función on ok
 * @property title: titulo del modal
 * @property className: clases extras para estilizar el formulario de alguna forma particular. El selector a utilizar debería ser SaveForm.(clase extra)
 * @property submitText: texto a mostrar en el boton submit
 * @property buttonCancel: booleano que determina si muestra o no el boton cancelar
 * @property onReset: funcion ejecutada al limpiar el formulario
 * @property values: valores por defecto del formulario
 * @property message: mensaje a mostrar como primera línea del formulario
 * @property loading: booleano que determina si el boton submit está cargando o no
 * @property saveFormFooterIcon: desactiva o cambia los iconos actuales de los botones del footer
 */
export interface SaveFormProps {
  modalVisible: boolean;
  onCancel: () => void;
  onOk?: (val: any) => void;
  columns: SaveFormColumns<any>[];
  title: string;
  className?: string;
  submitText?: string;
  cancelText?: string;
  buttonCancel?: boolean;
  onReset?: () => void; // Function;
  values?: unknown;
  message?: string;
  loading?: boolean;
  formItemLayout?: any;
  width?: string | number;
  style?: React.CSSProperties;
  saveFormFooterIcon?: {
    reset?: JSX.Element;
    cancel?: JSX.Element;
    submit?: JSX.Element;
  };
  disabledSubmit?: boolean;
  footer?: JSX.Element[];
}

const defaultFormItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const SaveFormContent: React.FC<SaveFormProps> = (props) => {
  const [stColumns, setStColumns] = useState<any[]>([]);
  const { rmTypenameObj } = GraphqlService();
  const initialValuesF = (set: any) => {
    let values: any = set;
    values = rmTypenameObj(values);
    Object.keys(set).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(values, key)) {
        switch (typeof set[key]) {
          case "boolean":
            values[key] = `${set[key]}`;
            break;
          case "number":
            values[key] = `${set[key]}`;
            break;
          default:
            values[key] = set[key];
            break;
        }
      }
    });
    return values;
  };

  const {
    modalVisible,
    onCancel,
    onOk,
    columns,
    values,
    title,
    message,
    className,
    submitText,
    buttonCancel,
    onReset,
    loading,
    saveFormFooterIcon,
    formItemLayout,
    width,
    style,
    disabledSubmit,
    footer,
  } = props;

  const searchConfig = {
    submitText: submitText || "Guardar",
    resetText: values ? "Deshacer" : "Limpiar",
  };

  const setColumns = () => {
    columns.forEach((element) => {
      const el: any = element;
      delete el.export;
      delete el.sorter;
    });
    setStColumns(columns);
  };

  useEffect(() => {
    setColumns();
  }, [columns]);

  const layout = formItemLayout || defaultFormItemLayout;

  const getResetIcon = () => {
    return saveFormFooterIcon ? saveFormFooterIcon?.reset : <ClearOutlined />;
  };

  const getSubmitIcon = () => {
    return saveFormFooterIcon ? saveFormFooterIcon?.submit : <SaveOutlined />;
  };

  return (
    <Modal
      destroyOnClose
      title={title}
      visible={modalVisible}
      onCancel={() => onCancel()}
      footer={null}
      className={`SaveForm${className ? ` ${className}` : ""}`}
      width={width}
      style={style}
    >
      {message !== "" ? <p>{message}</p> : null}
      {stColumns.length ? (
        <ProTable
          onSubmit={async (value) => {
            if (onOk) {
              onOk(ABM.parseObjByConfigColumn(columns, value));
            }
          }}
          rowKey="id"
          search={searchConfig}
          type="form"
          form={{
            ...layout,
            initialValues: values ? initialValuesF(values) : {},
            layout: "horizontal",
            submitter: {
              render: (renderProps: any) => (
                <div key="SaveFormFooter" className="SaveFormFooter">
                  {footer && footer.length ? (
                    <>{footer.map((element) => element)}</>
                  ) : (
                    <>
                      <ButtonLiderar
                        htmlType="reset"
                        className="Reset"
                        color="white"
                        disabled={loading}
                        buttonName={searchConfig.resetText}
                        onClick={() => {
                          renderProps.form.resetFields();
                          if (onReset) {
                            onReset();
                          }
                        }}
                        icon={getResetIcon()}
                      />

                      {buttonCancel && (
                        <ButtonLiderar
                          color="white"
                          disabled={loading}
                          buttonName="Cancelar"
                          onClick={() => onCancel()}
                          icon={saveFormFooterIcon?.cancel}
                        />
                      )}
                      <ButtonLiderar
                        htmlType="submit"
                        color="violet"
                        buttonName={searchConfig.submitText}
                        loading={loading}
                        disabled={disabledSubmit}
                        icon={getSubmitIcon()}
                      />
                    </>
                  )}
                </div>
              ),
            },
          }}
          columns={stColumns}
        />
      ) : (
        <PageLoading />
      )}
    </Modal>
  );
};

const SaveForm: React.FC<SaveFormProps> = (props) => {
  return (
    <ExceptionManager messageModal="¡El componente SaveForm tiene un problema!">
      <SaveFormContent {...props} />
    </ExceptionManager>
  );
};

export default SaveForm;
