import {
  Component,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IonDatetime, isPlatform } from "@ionic/react";
import { DatePicker } from "antd";
import "./Datepicker.less";
import { DatetimeChangeEventDetail } from "@ionic/core";
import { PickerProps } from "antd/lib/date-picker/generatePicker";
import moment from "moment";

export interface IDatepickerProps {
  minDate?: Date | moment.Moment;
  maxDate?: Date | moment.Moment;
  defaultDate?: Date | moment.Moment;
  showTime?: boolean;
  allowClear?: boolean;
  format?: string;
  placeholder?: string;
  disabled?: boolean;
  showToday?: boolean;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  onSelect?: (value: moment.Moment) => void;
  [property: string]: any;
}

/**
 * Tested to work well in renderFormItem in columns array for ProTable
 */
export const Datepicker = (props: IDatepickerProps): JSX.Element => {
  const {
    minDate,
    maxDate,
    defaultDate,
    showTime,
    allowClear,
    format,
    placeholder,
    disabled,
    onSelect,
    getPopupContainer,
    showToday,
    ...otherProperties
  } = props;

  const disabledDateFunction = useCallback(
    (date: moment.Moment) => {
      let minDateValidation = false;
      const minDateForValidation = minDate && moment(minDate);
      if (minDateForValidation) {
        minDateForValidation.set("hour", 0);
        minDateForValidation.set("minute", 0);
        minDateForValidation.set("second", 0);
        minDateValidation = date.isBefore(minDateForValidation);
      }

      let maxDateValidation = false;
      const maxDateForValidation = maxDate && moment(maxDate);
      if (maxDateForValidation) {
        maxDateForValidation.set("hour", 23);
        maxDateForValidation.set("minute", 59);
        maxDateForValidation.set("second", 59);
        maxDateValidation = date.isAfter(maxDateForValidation);
      }

      return minDateValidation || maxDateValidation;
    },
    [maxDate, minDate]
  );
  const ionDatetimeDateRef = useRef<HTMLIonDatetimeElement>(null);
  const ionDatetimeHourRef = useRef<HTMLIonDatetimeElement>(null);
  const mobilePickerRef =
    useRef<Component<PickerProps<moment.Moment>, any, any> | null>(null);

  const [minTimeLimit, setMinTimeLimit] = useState<string>();
  const [maxTimeLimit, setMaxTimeLimit] = useState<string>();
  const [ionPickersValue, setIonPickersValue] = useState<
    string | null | undefined
  >(new Date().toISOString());

  useEffect(() => {
    if (maxDate) setIonPickersValue(() => moment(maxDate).format("YYYY-MM-DD"));
  }, []);

  const checkTimeLimits = useCallback(
    (value: moment.Moment) => {
      const dateFormatValue = value.format("YYYY-MM-DD");
      if (minDate) {
        const minDateMoment = moment(minDate);
        if (minDateMoment.format("YYYY-MM-DD") === dateFormatValue) {
          setMinTimeLimit(() => minDateMoment.format("YYYY-MM-DDTHH:mm"));
        } else {
          setMinTimeLimit(() => undefined);
        }
      }
      if (maxDate) {
        const maxDateMoment = moment(maxDate);
        if (maxDateMoment.format("YYYY-MM-DD") === dateFormatValue) {
          setMaxTimeLimit(() => maxDateMoment.format("YYYY-MM-DDTHH:mm"));
        } else {
          setMaxTimeLimit(() => undefined);
        }
      }
    },
    [maxDate, minDate]
  );

  const onIonDateChange = useCallback(
    (event: CustomEvent<DatetimeChangeEventDetail>) => {
      setIonPickersValue(event.detail.value);
      if (event.detail.value) {
        const dateValue = new Date(event.detail.value);
        const dayOfMonthValue = dateValue.getDate();
        const monthValue = dateValue.getMonth();
        const yearValue = dateValue.getFullYear();

        const antPickerValue = mobilePickerRef.current?.props.value;

        if (moment.isMoment(antPickerValue)) {
          const clonedAntPickerValue = antPickerValue.clone();
          clonedAntPickerValue.set("dayOfYear", dayOfMonthValue);
          clonedAntPickerValue.set("month", monthValue);
          clonedAntPickerValue.set("year", yearValue);

          if (props.onChange && typeof props.onChange === "function") {
            props.onChange(clonedAntPickerValue);
          }

          checkTimeLimits(clonedAntPickerValue);

          if (onSelect) {
            onSelect(clonedAntPickerValue);
          }
        } else {
          if (props.onChange && typeof props.onChange === "function") {
            props.onChange(moment(dateValue));
          }

          checkTimeLimits(moment(dateValue));

          if (onSelect) {
            onSelect(moment(dateValue));
          }
        }

        if (ionDatetimeHourRef.current && showTime) {
          ionDatetimeHourRef.current.open().then(() => {
            /* Fix to reubicate picker up modals */
            const elements = document.getElementsByClassName(
              "datepicker-component-time"
            );
            const element = elements.item(0);
            const rootElement = document.getElementById("root");
            if (element && rootElement) {
              rootElement.appendChild(element);
            }
          });
        }
      }
    },
    [checkTimeLimits, onSelect, showTime]
  );

  const onIonHourChange = useCallback(
    (event: CustomEvent<DatetimeChangeEventDetail>) => {
      setIonPickersValue(event.detail.value);
      if (event.detail.value) {
        const dateValue = new Date(event.detail.value);
        const hourValue = dateValue.getHours();
        const minuteValue = dateValue.getMinutes();

        const antPickerValue = mobilePickerRef.current?.props.value;

        if (moment.isMoment(antPickerValue)) {
          const clonedAntPickerValue = antPickerValue.clone();
          clonedAntPickerValue.set("hour", hourValue);
          clonedAntPickerValue.set("minute", minuteValue);

          if (props.onChange && typeof props.onChange === "function") {
            props.onChange(clonedAntPickerValue);
          }

          if (onSelect) {
            onSelect(clonedAntPickerValue);
          }
        } else {
          if (props.onChange && typeof props.onChange === "function") {
            props.onChange(moment(dateValue));
          }

          if (onSelect) {
            onSelect(moment(dateValue));
          }
        }
      }
    },
    [onSelect]
  );

  const yearValues = useMemo(() => {
    const thisYear = new Date().getFullYear();

    let minYear = thisYear - 100;
    let maxYear = thisYear + 100;

    if (minDate) {
      let minDateYear: number;

      if (minDate instanceof Date) {
        minDateYear = minDate.getFullYear();
      } else {
        minDateYear = minDate.toDate().getFullYear();
      }
      minYear = Math.max(minYear, minDateYear);
    }

    if (maxDate) {
      let maxDateYear: number;

      if (maxDate instanceof Date) {
        maxDateYear = maxDate.getFullYear();
      } else {
        maxDateYear = maxDate.toDate().getFullYear();
      }
      maxYear = Math.min(maxYear, maxDateYear);
    }

    const years: number[] = [];

    for (let year = minYear; year <= maxYear; year++) {
      years.push(year);
    }

    return years;
  }, [minDate, maxDate]);

  return isPlatform("mobile") ? (
    <>
      <DatePicker
        ref={mobilePickerRef}
        {...otherProperties}
        allowClear={allowClear}
        format={format}
        placeholder={placeholder}
        disabled={disabled}
        dropdownClassName="no-display-dropdown"
        onOpenChange={(open) => {
          if (open && ionDatetimeDateRef.current) {
            ionDatetimeDateRef.current?.open().then(() => {
              /* Fix to reubicate picker up modals */
              const elements = document.getElementsByClassName(
                "datepicker-component-date"
              );
              const element = elements.item(0);
              const rootElement = document.getElementById("root");
              if (element && rootElement) {
                rootElement.appendChild(element);
              }
            });
          }
        }}
        inputReadOnly
      />
      <>
        <IonDatetime
          doneText="Elegir fecha"
          cancelText="Cancelar"
          displayFormat="DD MMM YYYY"
          ref={ionDatetimeDateRef}
          onIonChange={onIonDateChange}
          style={{ display: "none" }}
          min={minDate && moment(minDate).format("YYYY-MM-DD")}
          max={maxDate && moment(maxDate).format("YYYY-MM-DD")}
          yearValues={yearValues}
          pickerOptions={{
            cssClass: "datepicker-component-date",
            animated: false,
          }}
          value={ionPickersValue}
        />
        <IonDatetime
          doneText="Elegir hora"
          cancelText="Cancelar"
          displayFormat="HH mm"
          ref={ionDatetimeHourRef}
          onIonChange={onIonHourChange}
          style={{ display: "none" }}
          min={minTimeLimit}
          max={maxTimeLimit}
          pickerOptions={{
            cssClass: "datepicker-component-time",
            animated: false,
          }}
          value={ionPickersValue}
        />
      </>
    </>
  ) : (
    <DatePicker
      {...otherProperties}
      disabledDate={disabledDateFunction}
      allowClear={allowClear}
      format={format}
      placeholder={placeholder}
      disabled={disabled}
      onSelect={onSelect}
      defaultPickerValue={defaultDate && moment(defaultDate)}
      showTime={showTime}
      getPopupContainer={getPopupContainer}
      showToday={showToday}
    />
  );
};
