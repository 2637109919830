import SearchForm from "../../BusquedaPolizas/SearchForm/SearchForm";
import useBusquedaPoliza from "../../../shared/hooks/useBusquedaPoliza";
import "./style.less";

const FormPoliza = () => {
  const { getPoliza, cleanSearch, setSearchType, searchType, clearButton } =
    useBusquedaPoliza();

  return (
    <div className="Form-Container">
      <SearchForm
        getPoliza={getPoliza}
        setSearchType={setSearchType}
        searchType={searchType}
        clearButton={clearButton}
        cleanSearch={cleanSearch}
        hideTitle={true}
      />
    </div>
  );
};

export default FormPoliza;
