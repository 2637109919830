import { renderRoutes, RouteConfigComponentProps } from "react-router-config";
import { useCheckAuthority } from "../../shared/CustomHooks";

export const EmptyLayout = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  return (
    useCheckAuthority(routeProps) || (
      <>{renderRoutes(routeProps?.route?.routes)}</>
    )
  );
};
