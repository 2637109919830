import { useState, useCallback, useLayoutEffect, useEffect, memo } from "react";
import { Select, Spin, FormInstance } from "antd";
import { debounce } from "lodash";
import { SearchOutlined } from "@ant-design/icons";

import { EnumsValues } from "../../shared";

export interface ISelectWithFetchProps {
  query: (value: any) => Promise<any[] | undefined>;
  optionRender: (item: any, index: number) => JSX.Element;
  onChange?: (value: string | number | undefined, data?: any) => void;
  onSelect?: (value: any) => void;
  onClear?: () => void;
  placeholder?: string;
  initialValue?: any;
  initialData?: any[];
  disabled?: boolean;
  lengthValueToFetch?: number;
  form?: FormInstance;
  className?: string;
}

const SelectWithFetch = memo((props: ISelectWithFetchProps) => {
  const {
    query,
    optionRender,
    onChange,
    onSelect,
    onClear,
    placeholder,
    initialValue,
    initialData = [],
    disabled,
    lengthValueToFetch = 0,
    className,
  } = props;
  const [value, setValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any[]>(initialData);
  const [fetchCount, setFetchCount] = useState(0);

  const fetchData = useCallback(
    debounce((searchValue: any): void => {
      if (searchValue.length >= lengthValueToFetch) {
        setIsLoading(() => true);
        setFetchCount((oldFetchCount) => oldFetchCount + 1);
        const thisFetchCount = fetchCount;
        query(searchValue).then((fetchedData) => {
          if (thisFetchCount !== fetchCount) {
            return;
          }
          if (fetchedData) {
            setData(fetchedData);
          }
          setIsLoading(() => false);
        });
      }
    }, 500),
    [value, query]
  );

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    setData([]);
  };

  const handleSelect = (z: any) => {
    let element;
    if (data && data[0]?.__typename === EnumsValues.RequestType.USERS_TINY) {
      element = data.find((item) => item.id === z.value);
    }
    if (data && data[0]?.__typename === EnumsValues.RequestType.PRODUCER) {
      element = data.find((item) => item.id_liderar === z.value);
    }
    if (onSelect) {
      onSelect(element);
    }
  };

  const handleChange = (searchValue: any) => {
    if (onChange) {
      onChange(searchValue, data);
    }
    setValue(searchValue);
  };

  useEffect(() => {
    if (!initialValue) {
      setValue(() => undefined);
    }
  }, [initialValue]);

  const initialFetch = async () => {
    await fetchData(initialValue);
  };

  // necesario para cargar el valor en el formulario
  useLayoutEffect(() => {
    if (data?.length === 1) {
      setData(data);
      const changeData =
        data[0].__typename === EnumsValues.RequestType.DNI_ASEGURADO
          ? {
              value: data[0].fiscalID,
              label: [data[0].fiscalID, " - ", data[0].name],
              key: data[0].fiscalID,
            }
          : {
              value: data[0].id_liderar,
              label: [data[0].nombre, " - ", data[0].id_liderar],
              key: data[0].id_liderar,
            };
      handleChange(changeData);
      if (onSelect) {
        onSelect(data[0]);
      }
    }
  }, [data]);

  useLayoutEffect(() => {
    if (initialValue) {
      initialFetch();
    }
  }, [initialValue]);

  return (
    <Select
      showSearch
      allowClear
      className={className}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      dropdownMatchSelectWidth={false}
      value={value}
      placeholder={placeholder}
      labelInValue
      optionFilterProp="children"
      notFoundContent={isLoading && <Spin size="small" />}
      filterOption={false}
      onSearch={fetchData}
      defaultOpen={data.length > 1}
      onClear={handleClear}
      onChange={handleChange}
      onSelect={handleSelect}
      onBlur={() => {
        setData([]);
        setIsLoading(() => false);
      }}
      suffixIcon={<SearchOutlined />}
      disabled={disabled}
    >
      {data && data.map((d, index) => optionRender(d, index))}
    </Select>
  );
});
export default SelectWithFetch;
