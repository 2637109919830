import { useCallback } from "react";
import { ICuitOnline } from "../../services/CuitOnline";

const useFormatData = () => {
  const formatDataCuit = useCallback(
    (data: ICuitOnline) => ({
      value: data.fiscalID,
      label: [data.fiscalID, " - ", data.name],
      key: data.fiscalID,
    }),
    []
  );

  return { formatDataCuit };
};

export default useFormatData;
