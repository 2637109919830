import { Result } from "antd";
import { useContext } from "react";
import { RouteConfigComponentProps } from "react-router-config";
import { Link } from "react-router-dom";
import { ContextApp } from "./ContextApp";
import { ExportableColumn } from "./Exporter";

export const useCheckAuthority = (
  props: RouteConfigComponentProps
): JSX.Element | null => {
  const { functions } = useContext(ContextApp);

  if (
    props?.route?.authority &&
    !functions?.find((item) => item === props?.route?.authority)
  ) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Lo sentimos, no estás autorizado para ver esta página."
        extra={<Link to="/">Volver a la home</Link>}
      />
    );
  }
  return null;
};

export const showCollapseRender = (
  columns: ExportableColumn<any>[]
): false | undefined => {
  if (
    columns.filter((column) => !column.hideInSearch && column.renderFormItem)
      .length === 1
  ) {
    return false;
  } else {
    return undefined;
  }
};
