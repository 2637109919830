import { useCallback } from "react";
import { useParams } from "react-router";
import { IBaseFile } from "../../../services/defaultSchema";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { CustomMessage, EnumsValues, Tools } from "../../../shared";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";
import { IValuesEmisionRenovationForm } from "../interfaces/IValuesEmisionRenovationForm";

interface IFormButtons {
  isLoading: boolean;
  getQuotation: () => void;
  amountOutOfRange: boolean;
  accesoriesError: boolean;
  emissionReadOnly: boolean;
  formType: string;
  quotationId: number | undefined;
  confirmModal: () => void;
  onFinish: (values: IValuesEmisionRenovationForm) => Promise<void>;
  fiscalCondition: string | undefined;
  renovationBlocked: boolean;
}

export const FormButtons = (props: IFormButtons): JSX.Element => {
  const {
    isLoading,
    getQuotation,
    amountOutOfRange,
    accesoriesError,
    emissionReadOnly,
    formType,
    quotationId,
    confirmModal,
    onFinish,
    fiscalCondition,
    renovationBlocked,
  } = props;

  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();

  const params: { id?: string } = useParams();

  const handleGetEmissionFile = async () => {
    try {
      const response: IBaseFile = await customRequest({
        query: Query.getEmissionFile,
        variables: {
          id: Number(params?.id),
        },
      });

      if (!response.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(response);
    } catch (error) {
      messageError({
        context: "emission - get file",
        message: "Ocurrió un error al descargar el archivo",
      });
    }
  };

  const handleGetInsuredFile = async () => {
    try {
      const response: IBaseFile = await customRequest({
        query: Query.getInsuredFile,
        variables: {
          id: Number(params?.id),
        },
      });

      if (!response.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(response);
    } catch (error) {
      messageError({
        context: "emission - getInsuredFile",
        message: "Ocurrió un error al descargar el archivo",
      });
    }
  };

  const handleGetEmissionHistoricalFile = async () => {
    try {
      const response: IBaseFile = await customRequest({
        query: Query.getEmissionHistoricalFile,
        variables: {
          id: Number(params?.id),
        },
      });

      if (!response.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(response);
    } catch (error) {
      messageError({
        context: "formButtons - handleGetEmissionHistoricalFile",
        message: "Ocurrió un error al descargar el archivo",
      });
    }
  };

  const handleGetInsuredHistoricalFile = async () => {
    try {
      const response: IBaseFile = await customRequest({
        query: Query.getInsuredHistoricalFile,
        variables: {
          id: Number(params?.id),
        },
      });

      if (!response.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(response);
    } catch (error) {
      messageError({
        context: "formButtons - getInsuredHistoricalFile",
        message: "Ocurrió un error al descargar el archivo",
      });
    }
  };

  const handleGetRenovationQuotatedPDF = useCallback(async () => {
    try {
      const response: IBaseFile = await customRequest({
        query: Query.renovationQuotatedPDF,
        variables: {
          id: quotationId,
        },
      });

      if (!response.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(response);
    } catch (error) {
      messageError({
        context: "formButtons - renovationQuotatedPDF",
        message: "Ocurrió un error al descargar el archivo",
      });
    }
  }, [quotationId]);

  const showModal = useCallback(() => {
    return (
      fiscalCondition === EnumsValues.FiscalCondition.ConsFinal ||
      fiscalCondition === EnumsValues.FiscalCondition.Monotributo
    );
  }, [fiscalCondition]);

  return (
    <div className="buttons">
      <ButtonLiderar
        color="white"
        buttonName="Volver"
        useHistoryGoBack
        loading={isLoading}
      />
      {emissionReadOnly && (
        <ButtonLiderar
          color="violet"
          buttonName="Versión imprimible"
          htmlType="button"
          onClick={
            formType === EnumsValues.formType.directEmissionReadOnly
              ? () => handleGetEmissionFile()
              : () => handleGetEmissionHistoricalFile()
          }
        />
      )}
      {emissionReadOnly && (
        <ButtonLiderar
          color="violet"
          buttonName="Ficha Asegurado"
          htmlType="button"
          onClick={
            formType === EnumsValues.formType.directEmissionReadOnly
              ? () => handleGetInsuredFile()
              : () => handleGetInsuredHistoricalFile()
          }
        />
      )}
      {formType === EnumsValues.formType.renowedQuotesListReadOnly && (
        <ButtonLiderar
          color="violet"
          buttonName="Versión imprimible"
          htmlType="button"
          onClick={() => handleGetRenovationQuotatedPDF()}
        />
      )}
      {!emissionReadOnly &&
        formType !== EnumsValues.formType.renowedQuotesListReadOnly && (
          <ButtonLiderar
            color="violet"
            buttonName={"Solicitar cotización"}
            className="form-button search-button"
            htmlType="button"
            onClick={() => getQuotation()}
            loading={isLoading}
            disabled={renovationBlocked}
          />
        )}
      {!emissionReadOnly &&
        formType !== EnumsValues.formType.renowedQuotesListReadOnly && (
          <ButtonLiderar
            color="violet"
            buttonName={
              params.id ? "Solicitar renovación" : "Solicitar emisión"
            }
            className="form-button search-button"
            htmlType={showModal() ? "button" : "submit"}
            onClick={() => (showModal() ? confirmModal() : onFinish)}
            loading={isLoading}
            disabled={amountOutOfRange || accesoriesError || renovationBlocked}
          />
        )}
    </div>
  );
};

export default FormButtons;
