import { UPSERT_VEHICLE_TYPE_UNIVERSE } from "./VehicleType";
import { SAVE_FILE } from "./File";
import {
  LOGIN,
  UNLOCK_USER_BY_LOGIN,
  UPDATE_USER,
  UPSERT_USER_ROLE,
} from "../mutation/User";
import {
  CREATE_USER,
  DELETE_USER,
  RESET_PASSWORD,
  SET_PASSWORD,
  SET_USER_BAN,
} from "./User";
import { UPDATE_SETTING } from "./AppSetting";
import { QUOTATION, QUOTATION_RECALCULATE } from "./Quotation";
import { CREATE_QUOTATION_REQUEST } from "./QuotationRequest";
import { CREATE_EMISSION } from "./Emission";
import { REFRESH_TOKEN } from "./RefreshToken";
import { CREATE_ROLE, DELETE_ROLE, UPDATE_ROLE } from "./Role";
import { UPSERT_ROLE_PERMISSION } from "./RolePermission";
import { UPDATE_PERMISSION } from "./Permission";
import { CREATE_PLEDGED_CREDITOR } from "./PledgedCreditor";
import { UPSERT_VEHICLE_TYPE } from "./UserVehicleType";
import { UPSERT_USER_COVERAGE } from "./UserCoverageType";
import {
  CREATE_USER_PRODUCER_CUIT,
  CREATE_USER_PRODUCER_GROUP,
  DELETE_PRODUCERS,
  DELETE_USER_PRODUCER_CUIT,
  DELETE_USER_PRODUCER_GROUP,
  SET_PRODUCER,
} from "./UserProducer";
import { UPSERT_BRAND_MODEL } from "./BrandModel";
import { UPSERT_PRODUCER } from "./Producer";
import { UPSERT_ENDORSEMENT_TYPE } from "./EndorsementType";
import { UPSERT_TRACKING_TYPE } from "./TrackingType";
import { UPSERT_ADJUSTMENT_RATE } from "./AdjustmentRate";
import { SET_CHECK_DEBT_SUMMARIES } from "./Payment";
import { UPDATE_PUBLICATION, UPSERT_PUBLICATIONS } from "./Publications";
import { UPSERT_DESTINY } from "./Destiny";
import { UPSERT_ACCESSORY } from "./Accessory";
import { UPSERT_USER_COVERAGE_PERIOD } from "./UserCoveragePeriod";
import { UPSERT_COVERAGE } from "./Coverage";
import { SEARCH_RENOVATION_CSV } from "./Renovation";

const Mutation = {
  login: { name: "login", gql: LOGIN },
  createUser: { name: "createUser", gql: CREATE_USER },
  updateUser: { name: "updateUser", gql: UPDATE_USER },
  deleteUser: { name: "deleteUser", gql: DELETE_USER },
  upsertUserRole: { name: "upsertUserRole", gql: UPSERT_USER_ROLE },
  updateAppSetting: { name: "updateAppSetting", gql: UPDATE_SETTING },
  resetPassword: { name: "resetPassword", gql: RESET_PASSWORD },
  setPassword: { name: "setPassword", gql: SET_PASSWORD },
  quotation: { name: "quotation", gql: QUOTATION },
  createQuotationRequest: {
    name: "createQuotationRequest",
    gql: CREATE_QUOTATION_REQUEST,
  },
  createEmission: { name: "createEmission", gql: CREATE_EMISSION },
  createPledgedCreditor: {
    name: "createPledgedCreditor",
    gql: CREATE_PLEDGED_CREDITOR,
  },
  refreshToken: { name: "refreshToken", gql: REFRESH_TOKEN },
  updatePermission: { name: "updatePermission", gql: UPDATE_PERMISSION },
  updateRole: { name: "updateRole", gql: UPDATE_ROLE },
  upsertRolePermission: {
    name: "upsertRolePermission",
    gql: UPSERT_ROLE_PERMISSION,
  },
  createRole: { name: "createRole", gql: CREATE_ROLE },
  deleteRole: { name: "deleteRole", gql: DELETE_ROLE },
  setUserBan: { name: "setUserBan", gql: SET_USER_BAN },
  upsertVehicleType: { name: "upsertVehicleType", gql: UPSERT_VEHICLE_TYPE },
  upsertVehicleTypeUniverse: {
    name: "upsertVehicleTypeUniverse",
    gql: UPSERT_VEHICLE_TYPE_UNIVERSE,
  },
  upsertUserCoverage: { name: "upsertUserCoverage", gql: UPSERT_USER_COVERAGE },
  deleteUserProducerCuit: {
    name: "deleteUserProducerCuit",
    gql: DELETE_USER_PRODUCER_CUIT,
  },
  createUserProducerCuit: {
    name: "createUserProducerCuit",
    gql: CREATE_USER_PRODUCER_CUIT,
  },
  setProducer: { name: "setProducer", gql: SET_PRODUCER },
  deleteProducers: { name: "deleteProducers", gql: DELETE_PRODUCERS },
  createUserProducerGroup: {
    name: "createUserProducerGroup",
    gql: CREATE_USER_PRODUCER_GROUP,
  },
  deleteUserProducerGroup: {
    name: "deleteUserProducerGroup",
    gql: DELETE_USER_PRODUCER_GROUP,
  },
  saveFile: { name: "saveFile", gql: SAVE_FILE },
  upsertBrandModel: { name: "upsertBrandModel", gql: UPSERT_BRAND_MODEL },
  upsertProducer: { name: "upsertProducer", gql: UPSERT_PRODUCER },
  upsertEndorsementType: {
    name: "upsertEndorsementType",
    gql: UPSERT_ENDORSEMENT_TYPE,
  },
  upsertTrackingType: { name: "upsertTrackingType", gql: UPSERT_TRACKING_TYPE },
  upsertAdjustmentRate: {
    name: "upsertAdjustmentRate",
    gql: UPSERT_ADJUSTMENT_RATE,
  },
  set_check_debt_summaries: {
    name: "set_check_debt_summaries",
    gql: SET_CHECK_DEBT_SUMMARIES,
  },
  updatePublication: {
    name: "updatePublication",
    gql: UPDATE_PUBLICATION,
  },
  upsertDestiny: {
    name: "upsertDestiny",
    gql: UPSERT_DESTINY,
  },
  upsertAccessory: {
    name: "upsertAccessory",
    gql: UPSERT_ACCESSORY,
  },
  upsertPublications: {
    name: "upsertPublications",
    gql: UPSERT_PUBLICATIONS,
  },
  upsertUserCoveragePeriod: {
    name: "upsertUserCoveragePeriod",
    gql: UPSERT_USER_COVERAGE_PERIOD,
  },
  quotationRecalculate: {
    name: "quotationRecalculate",
    gql: QUOTATION_RECALCULATE,
  },
  upsertCoverage: {
    name: "upsertCoverage",
    gql: UPSERT_COVERAGE,
  },
  unlockUserByLogin: {
    name: "unlockUserByLogin",
    gql: UNLOCK_USER_BY_LOGIN,
  },
  searchRenovationCSV: {
    name: "searchRenovationCSV",
    gql: SEARCH_RENOVATION_CSV,
  },
};

export { Mutation };
