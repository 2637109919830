export const UPDATE_PUBLICATION = `
mutation updatePublication($input: UpdatePublicationInput!) {
  updatePublication(input: $input) {
    __typename
    ... on Publication {
      id
      pub_archivo
      description
      visualiza
      publication_type_id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const UPSERT_PUBLICATIONS = `
mutation upsertPublications {
  upsertPublications {
    __typename
    ... on SyncBoolean {
      status
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
