import { Button } from "antd";
import { useHistory } from "react-router";
export interface IButtonLiderarProps {
  buttonName: string;
  color: "violet" | "white";
  onClick?: () => void;
  className?: string;
  htmlType?: "button" | "submit" | "reset";
  disabled?: boolean;
  minWidth?: number | "fit-content";
  margin?: number | string;
  flex?: number;
  icon?: JSX.Element;
  loading?: boolean;
  hidden?: boolean;
  useHistoryGoBack?: boolean;
}

const isDefined = (value: any): boolean => {
  return value !== undefined && value !== null;
};

const ButtonLiderar = (props: IButtonLiderarProps) => {
  const {
    buttonName,
    color,
    onClick,
    className,
    htmlType,
    disabled,
    minWidth,
    margin,
    flex,
    icon,
    loading,
    hidden,
    useHistoryGoBack,
  } = props;

  const history = useHistory();

  const violet = "#763790";
  const violetDisabled = "rgba(117, 55, 144, 0.4)";

  let violetStyle;
  let whiteStyle;

  violetStyle = {
    backgroundColor: violet,
    fontWeight: 400,
    minWidth: minWidth || 150,
    borderRadius: 5,
    margin: isDefined(margin) ? margin : 5,
    height: 50,
    maxWidth: 221,
    padding: 5,
    ...(flex ? { flex: flex } : {}),
  };

  whiteStyle = {
    borderRadius: 5,
    fontWeight: 400,
    minWidth: minWidth || 150,
    border: `1px solid ${violet}`,
    margin: isDefined(margin) ? margin : 5,
    height: 50,
    maxWidth: 221,
    padding: 5,
    ...(flex ? { flex: flex } : {}),
  };

  if (disabled) {
    violetStyle.backgroundColor = violetDisabled;
  }

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (useHistoryGoBack) {
      history.goBack();
    }
  };

  return (
    <Button
      type={color === "violet" ? "primary" : "default"}
      className={className}
      onClick={handleClick}
      icon={icon}
      size="large"
      style={color === "violet" ? violetStyle : whiteStyle}
      htmlType={htmlType}
      disabled={disabled || false}
      loading={loading}
      hidden={hidden}
    >
      {buttonName}
    </Button>
  );
};

export default ButtonLiderar;
