import { RouteConfigComponentProps } from "react-router-config";
import { useHistory } from "react-router";
import { useState, useRef, useCallback, useContext } from "react";
import { SorterResult } from "antd/es/table/interface";
import { DatePicker, Input } from "antd";

import ProTable, { ActionType } from "@ant-design/pro-table";
import CustomFilter from "../CustomFilter/CustomFilter";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import { ABM, MomentJS, Tools } from "../../shared";
import GraphqlService from "../../services/graphql/GraphqlService";
import { ExportableColumn } from "../ABM/LocalExporter/index";
import {
  FORMAT_DATE_TIME_1,
  FORMAT_DATE_TIME_2,
  FORMAT_DATE_TIME_4,
} from "../../shared/MomentJS";
import {
  formatPolicyNumber,
  formatStringToMoney,
  getPaginationArgs,
} from "../../shared/utils";
import { ParamsType } from "@ant-design/pro-provider";
import { IQuotation } from "../../services/IQuotation";
import { NavContext } from "@ionic/react";

import "./RenewedQuotesList.less";
import { useCheckAuthority } from "../../shared/CustomHooks";

enum renovationListItems {
  ID = "numerator_id",
  NAME = "name",
  ITS = "its",
  ITS_RANGE = "its_range",
  PRODUCER = "producer",
  VEHICLE_TYPE = "vehicle_type",
  COVERAGE_TYPE = "coverage_descrip_web",
  POLICY_TO_RENEW = "policy_number",
  AMOUNT_INSURED = "total_value",
  TOTAL_AWARD = "total_award",
  FECHA_VTO = "due_date",
  FECHA_VTO_RANGE = "due_date_range",
}

const {
  ID,
  NAME,
  ITS,
  ITS_RANGE,
  PRODUCER,
  VEHICLE_TYPE,
  COVERAGE_TYPE,
  POLICY_TO_RENEW,
  AMOUNT_INSURED,
  TOTAL_AWARD,
  FECHA_VTO,
  FECHA_VTO_RANGE,
} = renovationListItems;
/**
 * Configure manualmente los campos de filtrado
 */

const LIST_FILTER = [
  ID,
  NAME,
  ITS_RANGE,
  PRODUCER,
  VEHICLE_TYPE,
  COVERAGE_TYPE,
  POLICY_TO_RENEW,
  AMOUNT_INSURED,
  TOTAL_AWARD,
  FECHA_VTO,
  FECHA_VTO_RANGE,
];
const LIST_SORTER = [
  ID,
  NAME,
  ITS,
  PRODUCER,
  VEHICLE_TYPE,
  COVERAGE_TYPE,
  POLICY_TO_RENEW,
  AMOUNT_INSURED,
  TOTAL_AWARD,
  FECHA_VTO,
  FECHA_VTO_RANGE,
];

export const RenewedQuotesList = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  // // props

  // // states
  const [sorter, setSorter] = useState<string>("");
  // // services and hooks
  const { Query, customRequest } = GraphqlService();
  const history = useHistory();
  const { navigate } = useContext(NavContext);
  // // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // // methods

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
    }
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            variables.current.filter[element] = search[element];
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      variables.current.filter = {
        ...variables.current.filter,
        renovation: true,
      };

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const countPromise = customRequest({
        query: Query.countQuotations,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const dataPromise = customRequest({
        query: Query.quotations,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);

      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const columns = useCallback(
    (): ExportableColumn<IQuotation>[] => [
      {
        export: false,
        dataIndex: ID,
        title: "id",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_, record) => record?.quotation_request?.numerator_id || "-",
        renderFormItem: () => <Input placeholder="Ingrese id" />,
      },
      {
        export: false,
        dataIndex: NAME,
        title: "Nombre asegurado",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_, record) => record?.quotation_request.name || "-",
        renderFormItem: () => (
          <Input placeholder="Ingrese nombre de asegurado" />
        ),
      },
      {
        export: false,
        dataIndex: ITS,
        title: "Fecha Solicitud",
        align: "left",
        sorter: true,
        defaultSortOrder: "descend",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.DATE,
        render: (_, record) => (
          <p className="non-wrap-cell">
            {record.its
              ? MomentJS.specificMoment(
                  {
                    date: record.its,
                    format: FORMAT_DATE_TIME_2,
                    utc: true,
                  },
                  FORMAT_DATE_TIME_1,
                  true
                )
              : "-"}
          </p>
        ),
      },
      {
        export: false,
        dataIndex: FECHA_VTO,
        title: "Fecha inicio",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.DATE,
        render: (_, record) => (
          <p className="non-wrap-cell">
            {record.its
              ? MomentJS.specificMoment(
                  {
                    date: record.quotation_request.renovation_data.due_date,
                    format: FORMAT_DATE_TIME_2,
                    utc: true,
                  },
                  FORMAT_DATE_TIME_4,
                  true
                )
              : "-"}
          </p>
        ),
      },
      {
        export: false,
        dataIndex: ITS_RANGE,
        title: "Fecha solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        renderFormItem: () => (
          <DatePicker.RangePicker
            name="its_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={["Fecha desde", "Fecha hasta"]}
            allowEmpty={[true, true]}
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: FECHA_VTO_RANGE,
        title: "Fecha inicio",
        type: ABM.TYPE_COLUMN.DATE,
        renderFormItem: () => (
          <DatePicker.RangePicker
            name="due_date_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={["Fecha desde", "Fecha hasta"]}
            allowEmpty={[true, true]}
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: PRODUCER,
        title: "Productor",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_, record) =>
          record?.quotation_request?.producer?.nombre || "-",
        renderFormItem: () => <Input placeholder="Ingrese productor" />,
      },
      {
        export: false,
        dataIndex: VEHICLE_TYPE,
        title: "Tipo vehículo",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_, record) =>
          record?.quotation_request?.vehicle_type?.description || "-",
        renderFormItem: () => <Input placeholder="Ingrese tipo de vehículo" />,
      },
      {
        export: false,
        dataIndex: COVERAGE_TYPE,
        title: "Tipo de cobertura",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_, record) => record?.coverage?.descrip_web || "-",
        renderFormItem: () => <Input placeholder="Ingrese tipo de cobertura" />,
      },
      {
        export: false,
        dataIndex: POLICY_TO_RENEW,
        title: "Póliza a renovar",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        width: 180,
        render: (_, record) =>
          (
            <div
              style={{
                overflowWrap: "normal",
              }}
            >
              {formatPolicyNumber(
                record?.quotation_request?.renovation_data?.policy_number
              )}
            </div>
          ) || "-",
        renderFormItem: () => <Input placeholder="Ingrese póliza" />,
      },
      {
        export: false,
        dataIndex: AMOUNT_INSURED,
        title: Tools.leftAlign("Suma asegurada"),
        align: "right",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_, record) => (
          <p className="non-wrap-cell">
            {formatStringToMoney(record?.quotation_request?.insured_amount)}
          </p>
        ),
        renderFormItem: () => <Input placeholder="Ingrese suma asegurada" />,
      },
      {
        export: false,
        dataIndex: TOTAL_AWARD,
        title: Tools.leftAlign("Premio total"),
        align: "right",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_, record) => {
          return (
            <p className="non-wrap-cell">
              {formatStringToMoney(record?.total_ice || record?.total_no_ice)}
            </p>
          );
        },
        renderFormItem: () => <Input placeholder="Ingrese premio total" />,
      },
    ],
    []
  );

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>Listado de renovaciones cotizadas</h1>
        <CustomFilter header="Filtrar por" />
        <ProTable<IQuotation>
          defaultSize="small"
          actionRef={actionRef}
          rowKey="id"
          search={{
            collapsed: false,
            resetText: "Limpiar búsqueda",
            searchText: "Buscar",
            collapseRender: false,
          }}
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(
                  `/app/policy/renovation/renewed-quotes-list/${record.quotation_request.renovation_data.id}?quotationId=${record.id}`
                ),
            };
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IQuotation>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          params={{
            sorter,
          }}
          className="renewed-quotes-list-table"
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns()}
          scroll={{ x: "scroll" }}
        />
        <div className="buttons">
          <ButtonLiderar
            color="white"
            buttonName="Volver"
            className="button-left"
            onClick={() => history.goBack()}
          />
        </div>
      </>
    )
  );
};

export default RenewedQuotesList;
