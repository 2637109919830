export const POLICY_SECTIONS = `
query policy_sections(
  $filter: FilterPolicySectionInput
  $orderBy: PolicySectionOrderInput
  $skip: Int
  $take: Int
  $searchText: String
) {
  policy_sections(
    filter: $filter
    orderBy: $orderBy
    skip: $skip
    take: $take
    searchText: $searchText
  ) {
    __typename
    ... on PolicySection {
      id
      name
      id_liderar
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
