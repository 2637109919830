export const UPSERT_TRACKING_TYPE = `
mutation upsertTrackingType($key: String!) {
  upsertTrackingType(key: $key){
    __typename
    ... on SyncBoolean{
      status
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
