export const UPSERT_COVERAGE = `mutation upsertCoverage($key: String!) {
  upsertCoverage(key: $key){
    __typename
    ... on SyncBoolean{
      status
    }
    ... on ResultError{
      status_code
      message
    }
  }
}`;
