enum Functions {
  AppSettingRead = "APP_SETTING_R",
  AppSettingUpdate = "APP_SETTING_U",
  AppSetting = "APP_SETTING",

  AdministrationMenuRead = "ADMINISTRATION_MENU_R",
  CollectionMenuRead = "COLLECTION_MENU_R",
  PolizaMenuRead = "POLIZA_MENU_R",
  SearchPoliciesMenuRead = "SEARCH_POLICIES_MENU_R",
  QuotationMenuRead = "QUOTATION_MENU_R",
  QuotationListMenuRead = "QUOTATION_LIST_MENU_R",
  CoverageDetailsMenuRead = "COVERAGE_DETAILS_MENU_R",
  EmisionMenuRead = "EMISION_MENU_R",
  DownloadsMenuRead = "DOWNLOADS_MENU_R",

  Users = "USERS",
  UsersCreate = "USER_C",
  UsersRead = "USER_R",
  UsersUpdate = "USER_U",
  UsersDelete = "USER_D",
  UsersBan = "USER_B",

  getAllProducerList = "GET_ALL_PRODUCER_LIST",

  Roles = "ROLES",
  RoleCreate = "ROLE_C",
  RoleRead = "ROLE_R",
  RoleUpdate = "ROLE_U",
  RoleDelete = "ROLE_D",

  RolePermissionCreate = "ROLE_PERMISSION_C",
  RolePermissionRead = "ROLE_PERMISSION_R",
  RolePermissionUpdate = "ROLE_PERMISSION_U",
  RolePermissionDelete = "ROLE_PERMISSION_D",

  Permissions = "PERMISSIONS",
  PermissionsRead = "PERMISSION_R",
  PermissionUpdate = "PERMISSION_U",

  UsersRolesUpdate = "USER_ROLE_U",
  UsersRolesRead = "USER_ROLE_R",

  UserProducer = "USER_PRODUCER",
  UserCoverage = "USER_COVERAGE",
  UserVehicle = "USER_VEHICLE",
  UserBlock = "USER_BLOCK",

  Sync = "SYNC",

  ImpressionsQueueRequestRead = "IMPRESSIONS_QUEUE_REQUEST_R",

  PublicationsRead = "PUBLICATIONS_R",

  EmissionHistoricalRead = "EMISSION_HISTORICAL_R",

  IgnoreMaintenanceMode = "IGNORE_MAINTENANCE_MODE",
}

/**
 * Numeric Types
 * Name	Storage Size	Range
 * smallint	2 bytes	-32768 to +32767
 * integer	4 bytes	-2147483648 to +2147483647
 * bigint	8 bytes	-9223372036854775808 to 9223372036854775807
 */
enum MaxLengthInputs {
  SystemUser_UserName = 20,
  SystemUser_Email = 100,
  StringInputs = 200,
  NumberInputs = 20,
  NumberInputsMaxInt = 2147483647,
  NumberInputsMaxBigInt = 9223372036854775807,
  CodeSap = 4,
  Region_DeliveryTerm = 2,
  PolizaNumber = 9,
}

enum MinLengthInputs {
  PolizaNumber = 4,
}

enum Inboxes {
  HelpDesk = 1,
  ControlDesk = 2,
  ReverseLogistic = 3,
  Quality = 4,
  Finance = 5,
  Todos = 6,
}

enum LocalStorageKeys {
  RoleSelected = "role_selected",
  TranslationsVersions = "translation_versions",
  Translations = "translations",
}

enum SettingKeys {
  RecoveryPasswordFrom = "RECOVERY_PASSWORD_FROM",
  RecoveryPasswordSubject = "RECOVERY_PASSWORD_SUBJECT",
  UrlSite = "URL_SITE",
  NodeMailerHost = "NODEMAILER_HOST",
  NodeMailerPort = "NODEMAILER_PORT",
  NodeMailerSecure = "NODEMAILER_SECURE",
  NodeMailerUser = "NODEMAILER_USER",
  NodeMailerPassword = "NODEMAILER_PASSWORD",
  NodeMailerAccountType = "NODEMAILER_ACCOUNT_TYPE",
  SecurityTokenExpiresin = "SECURITY_TOKEN_EXPIRESIN",
  SecurityRefreshTokenExpiresin = "SECURITY_REFRESH_TOKEN_EXPIRESIN",
  PasswordRegex = "PASSWORD_REGEX",
  MinBornEmissionTime = "MIN_BORN_EMISSION_TIME",
  idsLiderarVehicleTypeMotorcycle = "IDS_LIDERAR_VEHICLE_TYPE_MOTORCYCLE",
  idsLiderarVehicleTypeTruck = "IDS_LIDERAR_VEHICLE_TYPE_TRUCK",
  amountInsuredVariation = "AMOUNT_INSURED_VARIATION",
  maxAmountAccessories = "MAX_AMOUNT_ACCESSORIES",
  SetHomeMessageForUserHasNoRole = "SET_HOME_MESSAGE_FOR_USER_HAS_NO_ROLE",
  MaintenanceMode = "MAINTENANCE_MODE",
  MaintenanceModeMessage = "MAINTENANCE_MODE_MESSAGE",
}

enum PolizaType {
  VIDA = "Vida",
  AUTO = "Automotor",
}

enum PolizaTypeByNumber {
  VIDA = "17",
  AUTO = "04",
}

enum ConstNumbers {
  zero = 0,
  one = 1,
  two = 2,
  three = 3,
  four = 4,
  five = 5,
  six = 6,
  seven = 7,
  eight = 8,
  nine = 9,
}

enum ConstStrings {
  zero = "0",
  one = "1",
  two = "2",
  three = "3",
  four = "4",
  five = "5",
  six = "6",
  seven = "7",
  eight = "8",
  nine = "9",
}

enum PolizaSearchType {
  POLIZA = "poliza",
  PATENTE = "patente",
  NAME = "name",
}

enum InsuredTypeID {
  DNI = "DNI",
  CUIT = "CUIT",
}

enum ErrorMessage {
  GET_FUEL_TYPES,
}

enum Provincia {
  CAPITAL = 2,
}

enum PostalCode {
  CAPITAL = "1121",
}

enum PaymentType {
  AMERICAN_EXPRESS = 1,
  CABAL = 2,
  MASTERCARD = 3,
  NARANJA = 4,
  VISA = 5,
  CABAL_LIDERARCARD = 6,
  VISA_DEBITO = 7,
  CBU = 8,
  CUPONES = 9,
}

enum PaymentTypeIdLiderar {
  AMERICAN_EXPRESS = "03",
  CABAL = "04",
  MASTERCARD = "01",
  NARANJA = "07",
  VISA = "02",
  CABAL_LIDERARCARD = "10",
  VISA_DEBITO = "05",
  CBU = "08",
  CUPONES = "00",
}

enum FuelTypeId {
  GASOIL = 1,
  GNC = 2,
  NAFTA = 3,
  GLP = 4,
  ELECTRICO = 5,
  HIBRIDO = 6,
}

enum FuelTypeIdLiderar {
  GASOIL = "02",
  GNC = "01",
  NAFTA = "03",
  GLP = "04",
  ELECTRICO = "05",
  HIBRIDO = "06",
}

enum PersonType {
  FISICA = "FISICA",
  JURIDICA = "JURIDICA",
}
enum RequestType {
  DNI_ASEGURADO = "DniAsegurado",
  USERS_TINY = "UsersTiny",
  PRODUCER = "Producer",
}

enum Gender {
  MASCULINO = "M",
  FEMENINO = "F",
  OTROS = "O",
}

enum GenderId {
  MASCULINO = 1,
  FEMENINO = 2,
  OTROS = 3,
}

enum ProducerCuit {
  LIDERAR = "30500059490",
}

enum SetUserProducerBy {
  CODE = 1,
}

enum FileType {
  IMAGEN_PATENTE_POLIZA = 1,
}

enum StatusQueueRequest {
  Creating = 1,
  Pending,
  Available,
  Error,
  Processing,
  Finalized,
}

enum StatusQueueRequestName {
  Creating = "Creando",
  Pending = "Pendiente",
  Available = "Disponible",
  Error = "Error",
  Processing = "Procesando",
  Finalized = "Finalizado",
}

enum FileRequestType {
  ResumenDeuda = "001",
  LibroEmision = "002",
  LibroCzas = "003",
  Poliza = "004",
  CertificadoCobranza = "005",
}

enum BookTypes {
  emission = "emission",
  collection = "collection",
}

enum PublicationType {
  Circulars = 1,
  Forms = 2,
  Manuals = 3,
}

enum accessoryIdLiderar {
  gnc = "001",
  tires = "004",
  freezer = "006",
  cupula = "014",
}

enum formType {
  renovation = "policies-to-renewed-list",
  directEmissionReadOnly = "direct-emission-read-only",
  directEmissionHistoricalReadOnly = "direct-emission-historical-read-only",
  directEmission = "direct-emission",
  renowedQuotesListReadOnly = "renewed-quotes-list",
}

enum adjustmentRate {
  zeroZero = "00",
}

enum FiscalCondition {
  Inscripto = "01",
  ConsFinal = "04",
  Exento = "06",
  Monotributo = "09",
}

enum coverageType {
  A = "01",
}

export enum coverageIdLiderar {
  A = "00101",
}

export enum coverageId {
  A = 1,
}

export class EnumsValues {
  static readonly ConstStrings = ConstStrings;

  readonly ConstStrings = EnumsValues.ConstStrings;

  static readonly coverageType = coverageType;

  readonly coverageType = EnumsValues.coverageType;

  static readonly FiscalCondition = FiscalCondition;

  readonly FiscalCondition = EnumsValues.FiscalCondition;

  static readonly BookTypes = BookTypes;

  readonly BookTypes = EnumsValues.BookTypes;

  static readonly adjustmentRate = adjustmentRate;

  readonly adjustmentRate = EnumsValues.adjustmentRate;

  static readonly formType = formType;

  readonly formType = EnumsValues.formType;

  static readonly accessoryIdLiderar = accessoryIdLiderar;

  readonly accessoryIdLiderar = EnumsValues.accessoryIdLiderar;

  static readonly FuelTypeIdLiderar = FuelTypeIdLiderar;

  readonly FuelTypeIdLiderar = EnumsValues.FuelTypeIdLiderar;

  static readonly FuelTypeId = FuelTypeId;

  readonly FuelTypeId = EnumsValues.FuelTypeId;

  static readonly PublicationType = PublicationType;

  readonly PublicationType = EnumsValues.PublicationType;

  static readonly SetUserProducerBy = SetUserProducerBy;

  readonly SetUserProducerBy = EnumsValues.SetUserProducerBy;

  static readonly ProducerCuit = ProducerCuit;

  readonly ProducerCuit = EnumsValues.ProducerCuit;

  static readonly GenderId = GenderId;

  readonly GenderId = EnumsValues.GenderId;

  static readonly Gender = Gender;

  readonly Gender = EnumsValues.Gender;

  static readonly RequestType = RequestType;

  readonly RequestType = EnumsValues.RequestType;

  static readonly PersonType = PersonType;

  readonly PersonType = EnumsValues.PersonType;

  static readonly PaymentType = PaymentType;

  readonly PaymentType = EnumsValues.PaymentType;

  static readonly PaymentTypeIdLiderar = PaymentTypeIdLiderar;

  readonly PaymentTypeIdLiderar = EnumsValues.PaymentTypeIdLiderar;

  static readonly PostalCode = PostalCode;

  readonly PostalCode = EnumsValues.PostalCode;

  static readonly Provincia = Provincia;

  readonly Provincia = EnumsValues.Provincia;

  static readonly ErrorMessage = ErrorMessage;

  readonly ErrorMessage = EnumsValues.ErrorMessage;

  static readonly InsuredTypeID = InsuredTypeID;

  readonly InsuredTypeID = EnumsValues.InsuredTypeID;

  static readonly PolizaSearchType = PolizaSearchType;

  readonly PolizaSearchType = EnumsValues.PolizaSearchType;

  static readonly PolizaType = PolizaType;

  readonly PolizaType = EnumsValues.PolizaType;

  static readonly PolizaTypeByNumber = PolizaTypeByNumber;

  readonly PolizaTypeByNumber = EnumsValues.PolizaTypeByNumber;

  static readonly Functions = Functions;

  readonly Functions = EnumsValues.Functions;

  static readonly MaxLengthInputs = MaxLengthInputs;

  readonly MaxLengthInputs = EnumsValues.MaxLengthInputs;

  static readonly MinLengthInputs = MinLengthInputs;

  readonly MinLengthInputs = EnumsValues.MinLengthInputs;

  static readonly Inboxes = Inboxes;

  readonly Inboxes = EnumsValues.Inboxes;

  static readonly LocalStorageKeys = LocalStorageKeys;

  readonly LocalStorageKeys = EnumsValues.LocalStorageKeys;

  static readonly SettingKeys = SettingKeys;

  readonly SettingKeys = EnumsValues.SettingKeys;

  static readonly ConstNumbers = ConstNumbers;

  readonly ConstNumbers = EnumsValues.ConstNumbers;

  static readonly FileType = FileType;

  readonly FileType = EnumsValues.FileType;

  static readonly StatusQueueRequest = StatusQueueRequest;

  readonly StatusQueueRequest = EnumsValues.StatusQueueRequest;

  static readonly StatusQueueRequestName = StatusQueueRequestName;

  readonly StatusQueueRequestName = EnumsValues.StatusQueueRequestName;

  static readonly FileRequestType = FileRequestType;

  readonly FileRequestType = EnumsValues.FileRequestType;

  constructor(init?: Partial<EnumsValues>) {
    Object.assign(this, init);
  }
}
