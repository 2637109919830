import { ReactElement, useState } from "react";
import {
  Card,
  Row,
  Col,
  Input,
  PageHeader,
  Radio,
  Space,
  RadioChangeEvent,
} from "antd";
import { SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { IAseguradoSearch } from "../../../../services/Asegurado";

import "./style.less";

interface TopBarProps {
  description: string;
  title: string;
  results: number;
  filter: string;
  filterFeature: boolean;
  handleClose: () => void;
  setFilter: (e: any) => void;
  sortList?: (value: "Nombre" | "DNI") => void;
  setAseguradosOrderedBy?: React.Dispatch<
    React.SetStateAction<IAseguradoSearch[]>
  >;
}

const TopBar: React.FC<TopBarProps> = ({
  title,
  description,
  results,
  handleClose,
  setFilter,
  filterFeature,
  sortList,
  setAseguradosOrderedBy,
}) => {
  const [value, setValue] = useState("Nombre");

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    sortList && sortList(e.target.value);
  };

  const getResults = (resultsParam: number): ReactElement => {
    if (resultsParam === 1) {
      return <p>un resultado</p>;
    } else {
      return <p>{resultsParam} resultados</p>;
    }
  };

  return (
    <div className="TopBar">
      {filterFeature ? (
        <>
          <div className="top-bar-header">
            <PageHeader>
              <h3 className="top-bar-title">{description}</h3>
            </PageHeader>
            <Radio.Group
              onChange={onChange}
              value={value}
              className="top-bar-radio-group"
            >
              <Space direction="vertical">
                <Radio value="Nombre">Ordenar por Nombre</Radio>
                <Radio value="DNI">Ordenar por DNI</Radio>
              </Space>
            </Radio.Group>
            <Input
              placeholder="Filtrar"
              style={{ width: 250 }}
              className="search"
              prefix={<SearchOutlined />}
              allowClear
              onChange={(e) => {
                if (e.target.value === "") {
                  setAseguradosOrderedBy && setAseguradosOrderedBy([]);
                  setValue("Nombre");
                  setFilter("");
                }
              }}
              onPressEnter={(e: any) => {
                setAseguradosOrderedBy && setAseguradosOrderedBy([]);
                setValue("Nombre");
                setFilter(e.target.value);
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div className="top-bar-header">
            <PageHeader>
              <h3 className="top-bar-title">{description}</h3>
            </PageHeader>
          </div>
        </>
      )}
      {results !== 0 && (
        <Card>
          <Row>
            <Col span={12}>
              <p>
                Búsqueda:{" "}
                <span className="insured-name">
                  {title ? (
                    <>
                      {title}
                      <CloseOutlined
                        onClick={handleClose}
                        style={{ marginLeft: 5 }}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </p>
            </Col>
            <Col span={12} className="results">
              {getResults(results)}
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default TopBar;
