export const DESTINO = `
query destinys(
  $filter: FilterDestinyInput
  $orderBy: DestinyOrderInput
  $skip: Int
  $take: Int
  $searchText:String
){
  destinys(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take,searchText:$searchText) {
    __typename
    ... on Destiny{
      id
      id_liderar
      description
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
