export const PUBLICATION_TYPES = `
query publication_types(
  $orderBy: PublicationTypeOrderInput
  $filter: FilterPublicationTypeInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  publication_types(
    skip: $skip
    take: $take
    orderBy: $orderBy
    filter: $filter
    searchText: $searchText
  ) {
    __typename
    ... on PublicationType {
      id
      publication_type
      description
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
