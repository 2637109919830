import {
  Col,
  Collapse,
  Row,
  Form,
  Switch,
  Input,
  Select,
  FormInstance,
  InputNumber,
} from "antd";
import moment from "moment";
import { useState } from "react";

import { ICuitOnline } from "../../../../services/CuitOnline";
import { IGender } from "../../../../services/Gender";
import { INationality } from "../../../../services/INationality";
import { IPostalCode } from "../../../../services/PostalCode";
import { IProvince } from "../../../../services/Province";
import useCuitVerification from "../../../../shared/hooks/useCuitVerification";
import useFormatData from "../../../../shared/hooks/useFormatData";
import useLocationAndPostalCodes from "../../../../shared/hooks/useLocationAndPostalCodes";
import { DEFAULT_FORMAT_DATE } from "../../../../shared/MomentJS";
import { camelCase } from "../../../../shared/utils";
import { Datepicker } from "../../../DatePicker/DatePicker";
import SelectFetchOnEnter from "../../../utils/SelectFetchOnEnter";

const { Panel } = Collapse;
const { Option } = Select;

export interface ILegalRepresentativeProps {
  form: FormInstance;
  provinces: IProvince[];
  setpostalCodeLegalRepresentative: React.Dispatch<
    React.SetStateAction<number>
  >;
  setLegalRepresentativeGender: React.Dispatch<React.SetStateAction<string>>;
  setLegalRepresentativeNationalityName: React.Dispatch<
    React.SetStateAction<string>
  >;
  setLegalRepresentativeProvinceName: React.Dispatch<
    React.SetStateAction<string>
  >;
  gendersTypes: IGender[];
  nationalities: INationality[];
}

const LegalRepresentativeFromItems = (props: ILegalRepresentativeProps) => {
  const {
    form,
    provinces,
    setpostalCodeLegalRepresentative,
    setLegalRepresentativeGender,
    setLegalRepresentativeNationalityName,
    setLegalRepresentativeProvinceName,
    gendersTypes,
    nationalities,
  } = props;
  const { verifyCuit, isLoading: isLoadingCuitVerification } =
    useCuitVerification();
  const { formatDataCuit } = useFormatData();
  const [isLegalRepresentative, setIsLegalRepresentative] =
    useState<boolean>(false);
  const [provinceIdSelected, setProvinceIdSelected] = useState<number>(0);
  const [selectedlocalidad, setSelectedlocalidad] = useState<string>("");

  const { localidades, postalCodes } = useLocationAndPostalCodes({
    selectedlocalidad,
    provinceIdSelected,
    formItemLocationName: "legal_localidad",
    formItemPostalCodeName: "legal_postal_code",
    form,
  });

  const handleChangeProvince = (id: number) => {
    setProvinceIdSelected(id);
    const province = provinces.find((provinceParam) => provinceParam.id === id);
    if (province) {
      setLegalRepresentativeProvinceName(province.description);
    }
  };

  const handleChangeLocationSelect = (location: string) => {
    setSelectedlocalidad(location);
  };

  const handleChangePostalCode = (codpostal: string) => {
    const postalCode = postalCodes.find(
      (postalCodeParam) =>
        postalCodeParam.codpostal === codpostal &&
        postalCodeParam.location === selectedlocalidad
    );
    if (postalCode) {
      setpostalCodeLegalRepresentative(postalCode?.id);
    }
  };

  const handleChangeGender = (id_liderar: string) => {
    const selectedGender = gendersTypes.find(
      (gender) => gender.id_liderar === id_liderar
    );
    if (selectedGender) {
      setLegalRepresentativeGender(selectedGender.description);
    }
  };

  const handleChangeNationality = (id: number) => {
    const nationality = nationalities.find((nation) => nation.id === id);
    if (nationality) {
      setLegalRepresentativeNationalityName(nationality.country);
    }
  };

  return (
    <Collapse className="collapse">
      <Panel header="Datos representante legal" key="4">
        <Row gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: false,
                },
              ]}
              label="Representante legal"
              name="is_legal_representative"
            >
              <Switch onChange={(value) => setIsLegalRepresentative(value)} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label="CUIT / CUIL"
              rules={[
                { required: isLegalRepresentative, message: "ingrese CUIT" },
              ]}
              name="legal_cuit"
            >
              <SelectFetchOnEnter
                query={verifyCuit}
                isLoading={isLoadingCuitVerification}
                functionToFormatData={formatDataCuit}
                placeholder="Ingrese CUIT, CUIL o DNI"
                form={form}
                disabled={!isLegalRepresentative}
                onChange={(_, data: ICuitOnline[]) => {
                  if (form) {
                    form.setFieldsValue({ legal_name_surname: data[0]?.name });
                    form.setFieldsValue({
                      legal_home_street: data[0]?.addressStreet,
                    });
                    form.setFieldsValue({
                      legal_home_number: data[0]?.addressNumber,
                    });
                    form.setFieldsValue({
                      legal_date_of_birth: data[0]?.birthDate
                        ? moment(data[0]?.birthDate, DEFAULT_FORMAT_DATE)
                        : undefined,
                    });
                    const getSelectedGender = () => {
                      const selectedGender = gendersTypes.find(
                        (gender) => gender.description === data[0]?.gender
                      );
                      if (selectedGender) {
                        setLegalRepresentativeGender(
                          selectedGender.description
                        );
                      }
                      return selectedGender?.id_liderar;
                    };
                    form.setFieldsValue({
                      legal_gender: getSelectedGender(),
                    });
                  }
                }}
                optionRender={(item: ICuitOnline, index: number) => (
                  <Option value={item.fiscalID} key={index}>
                    {item.fiscalID} - {item.name}
                  </Option>
                )}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                { required: isLegalRepresentative, message: "Ingrese nombre" },
              ]}
              label="Nombre y Apellido"
              name="legal_name_surname"
            >
              <Input
                type="text"
                placeholder="Ingrese nombre"
                maxLength={200}
                disabled={!isLegalRepresentative}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese domicilio",
                },
              ]}
              label="Domicilio"
              name="legal_home_street"
            >
              <Input
                type="text"
                maxLength={50}
                placeholder="Ingrese Domicilio"
                disabled={!isLegalRepresentative}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese número de domicilio",
                },
              ]}
              label="Número"
              name="legal_home_number"
            >
              <InputNumber
                type="number"
                controls={false}
                maxLength={10}
                placeholder="Ingrese número de domicilio"
                disabled={!isLegalRepresentative}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item label="Piso" name="legal_home_floor">
              <Input
                type="text"
                placeholder="Ingrese piso"
                disabled={!isLegalRepresentative}
                maxLength={40}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item label="Departamento" name="legal_home_department">
              <Input
                type="text"
                placeholder="Ingrese departamento"
                disabled={!isLegalRepresentative}
                maxLength={40}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese número de telefono",
                },
              ]}
              label="Teléfono"
              name="legal_phone_number"
            >
              <InputNumber
                controls={false}
                type="number"
                maxLength={30}
                placeholder="Ingrese número de telefono"
                disabled={!isLegalRepresentative}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label="Email"
              name="legal_email"
              rules={[
                {
                  type: "email",
                  message: "Debe ingresar un email válido",
                },
              ]}
            >
              <Input
                type="email"
                maxLength={100}
                placeholder="Ingrese email"
                disabled={!isLegalRepresentative}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label="Género"
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione género",
                },
              ]}
              name="legal_gender"
            >
              <Select
                placeholder="Seleccione"
                disabled={!isLegalRepresentative}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                onChange={handleChangeGender}
              >
                {gendersTypes.length !== 0 &&
                  gendersTypes.map((item: IGender) => (
                    <Option value={item.id_liderar} key={item.id}>
                      {camelCase(item.description)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione provincia",
                },
              ]}
              label="Provincia"
              name="legal_province_code"
            >
              <Select
                placeholder="Seleccione"
                onChange={handleChangeProvince}
                disabled={!isLegalRepresentative}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
              >
                {provinces.length !== 0 &&
                  provinces.map((item: IProvince) => (
                    <Option value={item.id} key={item.id}>
                      {camelCase(item.description)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione localidad",
                },
              ]}
              label="Localidad"
              name="legal_localidad"
            >
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                disabled={
                  !isLegalRepresentative ||
                  form.getFieldsValue().legal_province_code === undefined
                }
                placeholder="Seleccione"
                onSelect={handleChangeLocationSelect}
              >
                {localidades.length !== 0 &&
                  localidades.map((item: IPostalCode, index: number) => (
                    <Option value={item.location} key={index}>
                      {camelCase(item.location)}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese código postal",
                },
              ]}
              label="Código postal"
              name="legal_postal_code"
            >
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                placeholder="Seleccione"
                disabled={
                  !isLegalRepresentative ||
                  form.getFieldsValue().legal_localidad === undefined
                }
                onChange={handleChangePostalCode}
              >
                {postalCodes.length !== 0 &&
                  postalCodes.map((item: IPostalCode, index: number) => (
                    <Option value={item.codpostal} key={index}>
                      {item.codpostal}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label="Fecha de nacimiento"
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione fecha de nacimiento",
                },
              ]}
              name="legal_date_of_birth"
            >
              <Datepicker
                getPopupContainer={(trigger) => trigger}
                placeholder="Seleccione fecha"
                format={DEFAULT_FORMAT_DATE}
                allowClear
                disabled={!isLegalRepresentative}
                showToday={false}
                maxDate={moment().subtract(17, "years")}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label="Lugar de nacimiento"
              name="legal_birth_place"
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Ingrese lugar de nacimiento",
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Ingrese lugar de nacimiento"
                disabled={!isLegalRepresentative}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label="Nacionalidad"
              name="legal_nationality"
              rules={[
                {
                  required: isLegalRepresentative,
                  message: "Seleccione nacionalidad",
                },
              ]}
            >
              <Select
                placeholder="Seleccione"
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                allowClear
                disabled={!isLegalRepresentative}
                onChange={handleChangeNationality}
                filterOption={(input: string, option: any) => {
                  return (
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {nationalities.length !== 0 &&
                  nationalities.map((item: INationality) => (
                    <Option value={item.id} key={item.id}>
                      {item.country}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default LegalRepresentativeFromItems;
