export const UPSERT_USER_COVERAGE_PERIOD = `
mutation upsertUserCoveragePeriod($input: UpsertCoveragePeriodInput!) {
  upsertUserCoveragePeriod(input: $input) {
    __typename
    ... on SuccessfulResponse {
      status
      status_message
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
