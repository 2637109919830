import { Select } from "antd";
import { IEndoso } from "../../../../../services/Endoso";
import "./style.less";

const { Option } = Select;
interface Props {
  endosos: IEndoso[];
  handleChangeEndoso: (value: string) => void;
}

const EndosoSelect: React.FC<Props> = ({ endosos, handleChangeEndoso }) => {
  return (
    <div className="EndosoSelect">
      <p className="title">Endosos</p>
      <Select
        className="endoso-select"
        defaultValue={endosos[0].id}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        style={{ width: 290 }}
        onChange={handleChangeEndoso}
      >
        {endosos.map((endoso: IEndoso) => (
          <Option value={endoso.id} key={endoso.id}>
            <p>
              Número: {endoso.id.substring(endoso.id.length - 6)} desde:{" "}
              {endoso.vigente_desde}
            </p>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default EndosoSelect;
