export const GET_USER_COVERAGE = `
query getUserCoverage($searchText: String, $filter: FilterUserCoverageInput) {
  getUserCoverage(searchText: $searchText, filter: $filter) {
    __typename
    ... on UserCoverage {
      id
      user_id
      coverage_id
      coverage {
        id
        description
        id_liderar
        soloRC
        descrip_web
        activo_web
        description_long
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}


`;
