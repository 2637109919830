import { useEffect } from "react";
import { Table } from "antd";
import { IPoliza } from "../../../../../services/Poliza";
import "./style.less";

interface Props {
  title: string;
  policy: IPoliza;
  payments: boolean;
  endosoIndex: number;
}

const PaymentsTable: React.FC<Props> = ({
  title,
  policy,
  payments,
  endosoIndex,
}) => {
  const columns = [
    {
      title: "",
      dataIndex: "cuotas",
    },
    {
      title: "Fecha Cobro",
      dataIndex: "fecha",
    },
    {
      title: "Importe",
      dataIndex: "importe",
    },
  ];

  const dataSource = (
    policyParam: IPoliza,
    isPayment: boolean,
    endosoIndexParam: number
  ) => {
    if (isPayment) {
      return policyParam.cobranza;
    } else {
      const data: any[] = [];
      if (
        !policyParam ||
        !policyParam.endoso[endosoIndexParam].fecha_vencimiento_aseg.length
      )
        return;

      const dates = policyParam.endoso[endosoIndexParam].fecha_vencimiento_aseg;
      let i = 0;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const _date in dates) {
        const object = {
          fecha: policyParam.endoso[endosoIndexParam].fecha_vencimiento_aseg[i],
          importe: policyParam.endoso[endosoIndexParam].importe_vencimiento[i],
        };
        data.push(object);
        i++;
      }
      return data;
    }
  };

  useEffect(() => {
    dataSource(policy, payments, endosoIndex);
  }, [endosoIndex]);

  return (
    <div className="PaymentsTable_">
      <Table
        pagination={false}
        columns={columns}
        dataSource={dataSource(policy, payments, endosoIndex)}
        size="small"
        title={() => title}
      />
    </div>
  );
};

export default PaymentsTable;
