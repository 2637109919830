import { Form, notification } from "antd";
import { useEffect, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { IPolizaInput } from "../../components/BusquedaPolizas/BusquedaPolizas";
import { IAseguradoSearch } from "../../services/Asegurado";
import GraphqlService from "../../services/graphql/GraphqlService";
import { Query } from "../../services/graphql/query";
import { IPoliza } from "../../services/Poliza";
import { CustomMessage } from "../CustomMessage";
import { EnumsValues } from "../EnumsValues";
import { validPolicyNumber } from "../../utils/validPolicyNumber";

export interface IFormValues {
  endoso: string;
  poliza: string;
  radio: string;
  seccion: string;
}

const useBusquedaPoliza = () => {
  const { PolizaSearchType } = EnumsValues;
  const { NAME, PATENTE, POLIZA } = PolizaSearchType;
  const { customRequest } = GraphqlService();
  const { messageError } = CustomMessage();
  const [searchType, setSearchType] = useState<string>(POLIZA);
  const [searchTypeTitle, setSearchTypeTitle] = useState<string>("Póliza");
  const [clearButton, setClearButton] = useState<boolean>(false);
  const [polizas, setPolizas] = useState<IPoliza[]>([]);
  const [asegurados, setAsegurados] = useState<IAseguradoSearch[]>([]);
  const [termSearched, setTermSearched] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [aseguradosOriginal, setAseguradosOriginal] = useState<
    IAseguradoSearch[]
  >([]);
  const [formValuesOnLoad, setFormValuesOnLoad] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [aseguradosOrderedBy, setAseguradosOrderedBy] = useState<
    IAseguradoSearch[]
  >([]);

  const handleTopBarFilter = useCallback(() => {
    if (filter !== "") {
      const filteredAsegurados = aseguradosOriginal.filter(
        (asegurado: IAseguradoSearch) =>
          asegurado.localidad.toLowerCase().includes(filter.toLowerCase()) ||
          asegurado.provincia.toLowerCase().includes(filter.toLowerCase()) ||
          asegurado.nombre_aseg.toLowerCase().includes(filter.toLowerCase()) ||
          asegurado.nro_documento.toLowerCase().includes(filter.toLowerCase())
      );
      filteredAsegurados.length > 0
        ? setAsegurados(filteredAsegurados)
        : setAsegurados(aseguradosOriginal);
    } else {
      setAsegurados(aseguradosOriginal);
    }
  }, [filter, aseguradosOriginal]);

  useEffect(() => {
    if (clearButton === true) {
      handleTopBarFilter();
    }
  }, [filter, aseguradosOriginal]);

  const cleanSearch = (): void => {
    form.setFieldsValue({
      seccion: "",
      poliza: "",
      endoso: "",
      patente: "",
      name: "",
    });
    setPolizas([]);
    setAsegurados([]);
    setFilter("");
    setClearButton(false);
    setAseguradosOrderedBy([]);
  };

  const openNotification = (name: string): void => {
    notification.warn({
      message: "Póliza no encontrada",
      description: `No se encontraron pólizas asociadas a ${name}`,
      placement: "bottomLeft",
    });
  };

  const handleClickAsegurado = async (idAseg: string, nombre: string) => {
    setLoading(true);

    await customRequest({
      query: Query.polizasByIdAsegurado,
      variables: { idAseg },
    })
      .then((data: any) => {
        if (data.length > 0) {
          setPolizas(data);
        } else {
          openNotification(nombre);
        }
      })
      .catch((error: any) => {
        if (error?.status_code === 32) {
          openNotification(nombre);
        } else {
          //Intentional
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getPoliza = async (values: IPolizaInput) => {
    const { poliza, seccion, endoso, patente, name } = values;
    setFilter("");
    setAseguradosOrderedBy([]);

    let formatedPoliza = "";
    if (poliza) {
      formatedPoliza = validPolicyNumber(poliza);
    }

    const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ ]*$/;

    if (!regex.test(name)) {
      messageError({
        context: "get póliza by asegurado",
        message: "El término de búsqueda no es válido",
      });
      return;
    }
    setLoading(true);
    switch (searchType) {
      case POLIZA:
        setTermSearched(
          endoso
            ? `${seccion} - ${formatedPoliza} - ${endoso}`
            : `${seccion} - ${formatedPoliza}`
        );
        setPolizas([]);
        setSearchTypeTitle("Póliza");

        if (location.pathname === "/app/home") {
          history.push({
            pathname: "/app/policy/search",
            state: {
              typeOfSearch: POLIZA,
              seccion,
              poliza: formatedPoliza,
              endoso,
            },
          });
          return;
        }

        await customRequest({
          query: Query.polizaByNumber,
          variables: {
            section: seccion,
            policy: formatedPoliza,
            endorsement: endoso,
          },
        })
          .then((data: IPoliza[]) => {
            setPolizas(data);
            setClearButton(true);
          })
          .catch(() => {
            //Intentional
          })
          .finally(() => {
            setLoading(false);
          });
        break;

      case PATENTE:
        setTermSearched(patente);
        setPolizas([]);
        setSearchTypeTitle("Patente");
        if (location.pathname === "/app/home") {
          history.push({
            pathname: "/app/policy/search",
            state: {
              typeOfSearch: PATENTE,
              patente,
            },
          });
          return;
        }

        await customRequest({
          query: Query.polizaByPatente,
          variables: { patente: patente.toUpperCase() },
        })
          .then((data: IPoliza[]) => {
            setPolizas(data);
            setClearButton(true);
          })
          .catch(() => {
            //Intentional
          })
          .finally(() => {
            setLoading(false);
          });
        setClearButton(true);
        break;

      case NAME:
        setTermSearched(name);
        setPolizas([]);
        setAsegurados([]);
        setAseguradosOriginal([]);
        setSearchTypeTitle("Asegurado");

        if (location.pathname === "/app/home") {
          history.push({
            pathname: "/app/policy/search",
            state: { typeOfSearch: NAME, name },
          });
          return;
        }

        await customRequest({
          query: Query.asegurado,
          variables: { name },
        })
          .then((data: { asegurado: IAseguradoSearch[] }) => {
            const aseguradoEdited = addDocumentNumberWithCuil(data.asegurado);
            setAsegurados(aseguradoEdited);
            setAseguradosOriginal(aseguradoEdited);
            setClearButton(true);
          })
          .catch(() => {
            //Intentional
          })
          .finally(() => {
            setLoading(false);
          });
        break;
    }
  };

  const addDocumentNumberWithCuil = (insured: IAseguradoSearch[]) => {
    return [...insured].map((item) => {
      if (!item.nro_documento && item.cuit) {
        return {
          ...item,
          nro_documento: item.cuit.slice(2, -1),
        };
      }
      return item;
    });
  };

  return {
    getPoliza,
    cleanSearch,
    handleClickAsegurado,
    loading,
    setSearchType,
    searchType,
    clearButton,
    polizas,
    setPolizas,
    asegurados,
    termSearched,
    setFilter,
    filter,
    setAseguradosOriginal,
    formValuesOnLoad,
    setFormValuesOnLoad,
    form,
    searchTypeTitle,
    aseguradosOrderedBy,
    setAseguradosOrderedBy,
  };
};

export default useBusquedaPoliza;
