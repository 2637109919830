import { useHistory } from "react-router";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";
import "./style.less";

const CardCotizacionRapida = () => {
  const history = useHistory();
  return (
    <>
      <img
        src="/assets/290-coin-outline.gif"
        width={104}
        style={{ marginTop: "62px" }}
        alt="Aguarde un momento, estamos cotizando"
      />
      <h1>Cotización</h1>
      <p>
        Cotiza pólizas rápidamente y con pocos datos para compartir con tus
        clientes.
      </p>
      <ButtonLiderar
        buttonName="Cotizar"
        color="violet"
        onClick={() => history.push({ pathname: "/app/policy/quotation" })}
      />
    </>
  );
};

export default CardCotizacionRapida;
