import { Suspense } from "react";
import { renderRoutes, RouteConfigComponentProps } from "react-router-config";
import { Row, Col } from "antd";
import { PageLoading } from "@ant-design/pro-layout";
import ExceptionManager from "../../components/ExceptionManager";
import "./style.less";

const LoginLayout = (routeProps: RouteConfigComponentProps): JSX.Element => {
  return (
    <div className="container">
      <Row className="LoginLayoutContent">
        <Col span={14} className="leftSide">
          <h2 className="loginTitle">
            Bienvenido al portal de <span className="bold">Productores</span>
          </h2>
        </Col>

        <Col span={10} className="rightSide">
          <img
            src="/assets/logo.png"
            alt="Liderar Seguros logo"
            className="logo"
          />
          <h4 className="welcome">
            Portal <span className="bold">Productores</span>
          </h4>

          <div className="PublicLayoutContent">
            <Suspense fallback={<PageLoading />}>
              <ExceptionManager>
                {renderRoutes(routeProps?.route?.routes)}
              </ExceptionManager>
            </Suspense>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LoginLayout;
