import { FormInstance } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";

import { EmissionById } from "../../../services/EmissionById";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { CustomMessage } from "../../../shared";
import { FORMAT_DATE_TIME_4 } from "../../../shared/MomentJS";
import { formNames } from "../utils/formNames";
import { EnumsValues } from "../../../shared/EnumsValues";

interface IUseEmissionProloadFormProps {
  form: FormInstance;
  emissionId?: number;
  formType: string;
}

export const useEmissionPreloadForm = (
  props: IUseEmissionProloadFormProps
): {
  emissionReadOnly: boolean;
  emission: EmissionById | undefined;
} => {
  const { form, emissionId, formType } = props;

  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();
  const [emission, setEmission] = useState<EmissionById>();
  const [emissionReadOnly, setEmissionReadOnly] = useState<boolean>(false);

  const {
    clientInformation,
    generalData,
    naturalPerson,
    userInformation,
    vehicleData,
    generalDataAccessories,
    information,
    legalPerson,
    legalRepresentative,
    pledgeCreditorData,
    quote,
  } = formNames;

  const getEmission = async (id: number) => {
    try {
      const response: EmissionById = await customRequest({
        query: Query.emission,
        variables: {
          id,
        },
      });

      if (response) {
        setEmission(response);
        setEmissionReadOnly(true);
      }
    } catch (error) {
      messageError({
        context: "useEmissionPreloadForm - getEmission",
        message: "Error al obtener datos de emisión",
      });
    }
  };

  const getHistoricalEmission = async (id: number) => {
    try {
      const response: EmissionById = await customRequest({
        query: Query.emissionHistorical,
        variables: {
          id,
        },
      });

      if (response) {
        setEmission(response);
        setEmissionReadOnly(true);
      }
    } catch (error) {
      messageError({
        context: "useEmissionPreloadForm - emissionHistorical",
        message: "Error al obtener datos de emisión",
      });
    }
  };

  useEffect(() => {
    if (
      emissionId &&
      formType === EnumsValues.formType.directEmissionReadOnly
    ) {
      getEmission(emissionId);
    }
  }, [emissionId, formType]);

  useEffect(() => {
    if (
      emissionId &&
      formType === EnumsValues.formType.directEmissionHistoricalReadOnly
    ) {
      getHistoricalEmission(emissionId);
    }
  }, [emissionId, formType]);

  useEffect(() => {
    if (emission && form) {
      form.setFieldsValue({
        //SOLICITUD
        [information.REQUEST_ID.name]: emission?.numerator_id || "",
        [information.ASSIGNED_POLICY_NUMBER.name]: emission?.id_policy || "",
        [information.RENEW_POLICY_NUMBER.name]: "-" || "",
        [information.PRODUCER.name]: emission?.producer.nombre || "",
        [information.RISK_AREA.name]: emission?.producer.zona_riesgo || "",

        //DATOS CLIENTE
        [clientInformation.CUIT_CUIL.name]: emission?.cuit || "",
        [clientInformation.COMPANY_NAME_OR_SURNAME.name]:
          emission?.business_name || "",
        [clientInformation.FISCAL_CONDITION.name]:
          emission?.fiscal_condition.description || "",

        //DATOS GENERALES
        [generalData.VEHICLE_BRAND.name]: emission?.brand_model.brand || "",
        [generalData.VEHICLE_MODEL.name]: emission?.brand_model.model || "",
        [generalData.VEHICLE_YEAR.name]: emission?.vehicle_year || "",
        [generalData.VEHICLE_VERSION.name]:
          emission?.brand_model.id_liderar || "",
        [generalData.VEHICLE_TYPE.name]:
          emission?.vehicle_type.description || "",
        [generalData.DESTINY.name]: emission?.destiny.description || "",
        [generalData.FUEL_TYPE.name]: emission?.fuel_type.description || "",
        [generalData.BODYWORK_TYPE.name]:
          emission?.bodywork_type.description || "",
        [generalData.COVERAGE.name]: emission?.coverage_description || "",
        [generalData.START_DATE.name]:
          emission?.effective_date &&
          moment(emission?.effective_date, FORMAT_DATE_TIME_4).utc(false),
        [generalData.COVERAGE_TIME.name]:
          emission?.coverage_period?.description || "",
        [generalData.ADJUSTMENT_RATE.name]:
          emission?.quotation?.quotation_request?.adjustment_rate?.description,
        [generalData.INSURED_AMOUNT.name]: emission?.amount_insured || "",
        [generalData.TOTAL_ACCESSORIES.name]:
          (emission?.quotation?.quotation_request?.total_accessories &&
            String(
              Math.round(
                Number(
                  emission?.quotation?.quotation_request?.total_accessories
                )
              )
            )) ||
          "",
        [generalData.PRODUCT_CODE.name]: emission?.product_code || "",

        //ACCESORIOS
        [generalDataAccessories.ACCESSORIES_CUPULA.name]:
          emission?.quotation?.quotation_request?.dome || "",
        [generalDataAccessories.ACCESSORIES_FREEZER.name]:
          emission?.quotation?.quotation_request?.cold_equipment || "",
        [generalDataAccessories?.ACCESSORIES_GNC?.name]:
          emission?.quotation?.quotation_request?.cng || "",
        [generalDataAccessories?.ACCESSORIES_SPECIAL_RIM?.name]:
          emission?.quotation?.quotation_request?.special_tires || "",

        //DATOS ASEGURADO
        [userInformation.USER_HOME.name]: emission?.home_street || "",
        [userInformation.USER_HOME_NUMBER.name]: emission?.home_number || "",
        [userInformation.USER_HOME_FLOOR.name]: emission?.floor || "",
        [userInformation.USER_HOME_DEPARTMENT.name]: emission?.department || "",
        [userInformation.USER_PHONE.name]: emission?.phone_number || "",
        [userInformation.USER_PROVINCE.name]:
          emission?.province.description || "",
        [userInformation.USER_LOCATION.name]:
          emission?.postal_code.location || "",
        [userInformation.USER_POSTAL_CODE.name]:
          emission?.postal_code.codpostal || "",
        [userInformation.USER_EMAIL.name]: emission?.email_aseg || "",
        [userInformation.USER_PAYMENT_METHOD.name]: emission.credit_card || "",
        [userInformation.USER_PAYMENT_NUMBER.name]:
          emission?.payment_number || "",

        //DATOS ADICIONALES (PERSONAS FISICAS)
        [naturalPerson.NATURAL_PERSON_GENDER.name]:
          emission?.emission_natural_person?.gender?.description || "",
        [naturalPerson.NATURAL_PERSON_BIRTH_DATE.name]:
          emission?.emission_natural_person?.birth_date &&
          moment(
            emission?.emission_natural_person?.birth_date,
            FORMAT_DATE_TIME_4
          ).utc(false),
        [naturalPerson.NATURAL_PERSON_BIRTH_PLACE.name]:
          emission?.emission_natural_person?.birth_place || "",
        [naturalPerson.NATURAL_PERSON_NATIONALITY.name]:
          emission?.emission_natural_person?.nationality?.gentile || "",

        //DATOS ADICIONALES (PERSONAS JURIDICAS)
        [legalPerson.LEGAL_PERSON_REGISTRATION_DATE.name]:
          emission?.emission_legal_person?.registration_date &&
          moment(
            emission?.emission_legal_person?.registration_date,
            FORMAT_DATE_TIME_4
          ).utc(false),
        [legalPerson.LEGAL_PERSON_REGISTRATION_NUMBER.name]:
          emission?.emission_legal_person?.registration_number || "",
        [legalPerson.LEGAL_PERSON_CONTRACT_DATE.name]:
          emission?.emission_legal_person?.contract_date &&
          moment(
            emission?.emission_legal_person?.contract_date,
            FORMAT_DATE_TIME_4
          ).utc(false),
        [legalPerson.LEGAL_PERSON_MAIN_ACTIVITY.name]:
          emission?.emission_legal_person?.main_activity || "",

        //DATOS VEHICULO
        [vehicleData.VEHICLE_CHASIS_NUMBER.name]: emission?.chassis || "",
        [vehicleData.VEHICLE_ENGINE_NUMBER.name]: emission?.motor || "",
        [vehicleData.VEHICLE_PATENT.name]: emission?.patent || "",

        //DATOS ACREEDOR PRENDARIO
        [pledgeCreditorData.PLEDGE_CREDITOR_COMPANY_NAME_OR_SURNAME.name]:
          emission?.pledged_creditor?.business_name_surname || "",
        [pledgeCreditorData.PLEDGE_CREDITOR_NAME.name]:
          emission?.pledged_creditor?.name || "",
        [pledgeCreditorData.PLEDGE_CREDITOR_HOME.name]:
          emission?.pledged_creditor?.home_street || "",
        [pledgeCreditorData.PLEDGE_CREDITOR_HOME_NUMBER.name]:
          emission?.pledged_creditor?.home_number || "",
        [pledgeCreditorData.PLEDGE_CREDITOR_HOME_FLOOR.name]:
          emission?.pledged_creditor?.floor || "",
        [pledgeCreditorData.PLEDGE_CREDITOR_HOME_DEPARTMENT.name]:
          emission?.pledged_creditor?.department || "",
        [pledgeCreditorData.PLEDGE_CREDITOR_PROVINCE.name]:
          emission?.pledged_creditor?.province.description,
        [pledgeCreditorData.PLEDGE_CREDITOR_LOCATION.name]:
          emission?.pledged_creditor?.postal_code?.location,
        [pledgeCreditorData.PLEDGE_CREDITOR_POSTAL_CODE.name]:
          emission?.pledged_creditor?.postal_code?.codpostal,

        //DATOS REPRESENTANTE LEGAL
        [legalRepresentative.LEGAL_REPRESENTATIVE_CUIT.name]:
          emission?.legal_representative?.cuit || "",
        [legalRepresentative.LEGAL_REPRESENTATIVE_NAME_SURNAME.name]:
          emission?.legal_representative?.name_surname || "",
        [legalRepresentative.LEGAL_REPRESENTATIVE_HOME.name]:
          emission?.legal_representative?.home_street || "",
        [legalRepresentative.LEGAL_REPRESENTATIVE_NUMBER.name]:
          emission?.legal_representative?.home_number || "",
        [legalRepresentative.LEGAL_REPRESENTATIVE_FLOOR.name]:
          emission?.legal_representative?.floor || "",
        [legalRepresentative.LEGAL_REPRESENTATIVE_DEPARTMENT.name]:
          emission?.legal_representative?.department || "",
        [legalRepresentative.LEGAL_REPRESENTATIVE_PHONE.name]:
          emission?.legal_representative?.phone_number || "",
        [legalRepresentative.LEGAL_REPRESENTATIVE_EMAIL.name]:
          emission?.legal_representative?.email || "",
        [legalRepresentative.LEGAL_REPRESENTATIVE_GENDER.name]:
          emission?.legal_representative?.gender?.description,
        [legalRepresentative.LEGAL_REPRESENTATIVE_PROVINCE.name]:
          emission?.legal_representative?.province?.description,
        [legalRepresentative.LEGAL_REPRESENTATIVE_LOCATION.name]:
          emission?.legal_representative?.postal_code?.location,
        [legalRepresentative.LEGAL_REPRESENTATIVE_POSTAL_CODE.name]:
          emission?.legal_representative?.postal_code?.codpostal,
        [legalRepresentative.LEGAL_REPRESENTATIVE_BIRTH_DATE.name]:
          emission?.legal_representative?.birth_date &&
          moment(
            emission?.legal_representative?.birth_date,
            FORMAT_DATE_TIME_4
          ).utc(false),
        [legalRepresentative.LEGAL_REPRESENTATIVE_BIRTH_PLACE.name]:
          emission?.legal_representative?.birth_place || "",
        [legalRepresentative.LEGAL_REPRESENTATIVE_NATIONALITY.name]:
          emission?.legal_representative?.nationality?.gentile,

        //COTIZACION
        [quote.QUOTATION_PRIMA.name]: emission?.premium || "",
        [quote.QUOTATION_PREMIUM.name]: emission?.prize || "",
        [quote.QUOTATION_RESULT_MESSAGE.name]: emission?.error_notice || "",
      });
    }
  }, [emission, form]);

  return { emissionReadOnly, emission };
};

export default useEmissionPreloadForm;
