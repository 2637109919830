import { useContext, useState } from "react";
import { Avatar, Dropdown, Menu, Modal } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { UserService } from "../../../../services/user";
import { ContextApp } from "../../../../shared";
import { LanguageButton } from "./LanguageButton";
import "./index.less";

export const AvatarComponent = (): JSX.Element | null => {
  const { user, languages } = useContext(ContextApp);
  const { logout } = UserService();
  const [languageModalVisible, setLanguageModalVisible] = useState(false);
  const [, i18n] = useTranslation();

  const userMenu = (
    <Menu>
      <Menu.Item key="0" icon={<LogoutOutlined />}>
        <a onClick={() => logout()}>Cerrar sesión</a>
      </Menu.Item>
    </Menu>
  );

  return user?.firstname || user?.username ? (
    <>
      <div className="AvatarComponent">
        <Dropdown overlay={userMenu} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <Avatar className="Avatar">
              {user?.firstname
                ? user.firstname[0].toUpperCase()
                : user?.username[0].toUpperCase()}
            </Avatar>
            <span className="AvatarName">
              {user?.firstname || user?.username}
            </span>
          </a>
        </Dropdown>
      </div>
      <Modal
        visible={languageModalVisible}
        onCancel={() => setLanguageModalVisible(() => false)}
        footer={null}
        title="Lenguaje"
      >
        {languages.map((language, index) => (
          <LanguageButton
            onClick={() => {
              i18n.changeLanguage(language.language_code);
              setLanguageModalVisible(false);
            }}
            language={language}
            i18nCurrentLanguage={i18n.language}
            key={index}
          />
        ))}
      </Modal>
    </>
  ) : null;
};
