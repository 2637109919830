export const ROLE_PERMISSION = `
  query rolePermission($filter:  FilterRolePermissionInput) {
    rolePermission(filter: $filter) {
      __typename
      ... on RolePermission {
        role_id
        permission_id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const ROLE_PERMISSIONS = `
  query rolePermissions($filter:  FilterRolePermissionInput,
    $orderBy:  RolePermissionOrderInput,
    $skip: Int,
    $take: Int) {
    rolePermissions(filter: $filter
  orderBy: $orderBy
  skip: $skip
  take: $take) {
      __typename
      ... on RolePermission {
        role_id
        permission_id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
