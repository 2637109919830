import { EditOutlined } from "@ant-design/icons";
import { SorterResult } from "antd/es/table/interface";
import {
  Divider,
  Image,
  Input,
  InputNumber,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { RouteConfigComponentProps } from "react-router-config";

import { ParamsType } from "@ant-design/pro-provider";
import ProTable, { ActionType } from "@ant-design/pro-table";
import GraphqlService from "../../services/graphql/GraphqlService";
import { IPublication } from "../../services/Publication";
import { ABM, CustomMessage, Tools } from "../../shared";
import { ExportableColumn } from "../../shared/Exporter";
import { SaveForm } from "../ABM";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import { IBaseFile } from "../../services/defaultSchema";
import CustomFilter from "../CustomFilter/CustomFilter";
import { IPublicationTypes } from "../../services/PublicationTypes";
import { useCheckAuthority } from "../../shared/CustomHooks";
import pdfIcon from "../../assets/pdf-icon.png";

const LIST_FILTER = [
  "description",
  "pub_archivo",
  "pub_archivo",
  "publication_type_id",
  "visualiza",
];
const LIST_SORTER = ["description", "pub_archivo"];

const TITLE_TABLE = "Publicaciones";
const TITLE_UPDATE_FORM = "Editar configuración";

const { Option } = Select;

const Publication = (routeProps: RouteConfigComponentProps): JSX.Element => {
  const history = useHistory();
  const defaultPageSize = 20;
  const { Query, Mutation, customRequest } = GraphqlService();
  const {
    messageError,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
  } = CustomMessage();

  const [data, setData] = useState<IPublication[]>([]);
  const [editFormValues, setEditFormValues] = useState<IPublication>();
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [publicationTypes, setPublicationTypes] = useState<IPublicationTypes[]>(
    []
  );
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  const getPublicationTypes = async () => {
    await customRequest({
      query: Query.publication_types,
    })
      .then((response: IPublicationTypes[]) => {
        if (response) {
          setPublicationTypes(response);
        }
      })
      .catch(() => {
        messageError({
          context: "getPublicationTypes",
          message: "Error al obtener tipo de publicaciones",
        });
      });
  };

  useEffect(() => {
    getPublicationTypes();
  }, []);

  const request = useCallback(
    async (
      params: ParamsType & {
        pageSize?: number;
        current?: number;
        keyword?: string;
      }
    ) => {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            variables.current.filter[element] = search[element];
            if (element === "publication_type_id") {
              variables.current.filter[element] = Number(search[element]);
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      try {
        const publications: IPublication[] = await customRequest({
          query: Query.publications,
          variables: variables.current,
        });
        setData(publications);
        return {
          current: 1,
          data,
          pageSize: "1",
          success: true,
          total: data.length,
        };
      } catch (error: any) {
        messageError({
          context: "Publications.getDataFromServer",
          message:
            error.message || "Ocurrió un error obteniendo las publicaciones",
        });
        return {
          current: 1,
          data: [],
          pageSize: "1",
          success: true,
          total: 0,
        };
      }
    },
    []
  );

  const columns = useCallback(
    (): ExportableColumn<IPublication>[] => [
      {
        export: true,
        dataIndex: "id",
        title: "Id",
        render: (_, record) => record.id,
        renderFormItem: () => (
          <InputNumber
            placeholder="Ingrese valor..."
            controls={false}
            type="number"
          />
        ),
        align: "right",
        hideInSearch: true,
        hideInForm: true,
        hideInTable: true,
      },
      {
        export: true,
        dataIndex: "description",
        title: "Descripción",
        align: "left",
        render: (_, record) => record.description || "",
        renderFormItem: () => (
          <Input placeholder="Ingrese valor..." maxLength={256} />
        ),
        hideInSearch: false,
        hideInForm: false,
        sorter: true,
        defaultSortOrder: "ascend",
      },
      {
        export: true,
        dataIndex: "pub_archivo",
        title: "Nombre del archivo",
        align: "left",
        render: (_, record) => record.pub_archivo || "",
        hideInSearch: false,
        hideInForm: true,
        renderFormItem: () => (
          <Input placeholder="Ingrese valor..." maxLength={128} />
        ),
        sorter: true,
      },
      {
        export: true,
        dataIndex: "publication_type_id",
        title: "Tipo de publicación",
        align: "left",
        render: (_, record) => record.publication_type?.description || "",
        renderFormItem: () => (
          <Select
            placeholder="Seleccione estado"
            getPopupContainer={(triggerNode) => triggerNode}
            allowClear
          >
            {publicationTypes.map((item: IPublicationTypes, index: number) => (
              <Option value={item.id.toString()} key={index}>
                {item.description}
              </Option>
            ))}
          </Select>
        ),
        hideInSearch: false,
        hideInForm: false,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: "visualiza",
        title: "Visible",
        align: "left",
        render: (_, record) => record.visualiza,
        renderFormItem: (_, record) => (
          <Switch defaultChecked={record.value === "Si"} />
        ),
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: "visualiza",
        title: "Visible",
        align: "left",
        renderFormItem: () => (
          <Select
            placeholder="Seleccione estado"
            getPopupContainer={(triggerNode) => triggerNode}
            options={[
              { id: 1, value: "Si", description: "Si" },
              { id: 2, value: "No", description: "No" },
            ].map((item) => ({
              value: item.value,
              key: item.id,
              label: item.description,
            }))}
            allowClear
          />
        ),
        hideInSearch: false,
        hideInForm: true,
        hideInTable: true,
      },
      {
        export: false,
        dataIndex: "op",
        title: "Op.",
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            <Tooltip title="Modificar archivo">
              <EditOutlined
                className="pointer"
                onClick={() => {
                  setEditFormValues(() => record);
                  handleUpdateModalVisible(() => true);
                }}
              />
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Descargar archivo">
              <Image
                className="pointer"
                preview={false}
                src={pdfIcon}
                style={{ width: 30 }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDownload(record);
                }}
              />
            </Tooltip>
          </>
        ),
      },
    ],
    [publicationTypes]
  );

  const updatePublication = (value: any) => {
    setFormLoading(() => true);

    if (value.visualiza === undefined || value.visualiza === "No") {
      value.visualiza = "No";
    } else {
      value.visualiza = "Si";
    }

    if (
      editFormValues?.description === value.description &&
      editFormValues?.visualiza === value.visualiza &&
      editFormValues?.publication_type_id === value.publication_type_id
    ) {
      setFormLoading(() => false);
      handleUpdateModalVisible(false);
      return;
    }

    messageUpdating({
      context: "Publication.updatePublication.1",
      message: "Publication",
    });
    customRequest({
      mutation: Mutation.updatePublication,
      variables: { input: { ...value, id: editFormValues!.id } },
    })
      .then(() => {
        messageUpdateSuccess({
          context: "Publication.updatePublication.2",
        });
        handleUpdateModalVisible(false);
        if (actionRef.current) {
          actionRef.current.reload();
        }
      })
      .catch((error: any) => {
        if (error.status_code && error.message) {
          return messageError({
            context: "Publication.updatePublication.3",
            message: error.message,
          });
        }
        messageUpdateError({ context: "Publication.updatePublication.3" });
      })
      .finally(() => {
        setFormLoading(() => false);
      });
  };

  const handleDownload = useCallback(async (record: IPublication) => {
    try {
      const result: IBaseFile = await customRequest({
        query: Query.publicationFile,
        variables: {
          id: record.id,
        },
      });
      if (!result.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(result);
    } catch (error: any) {
      console.error(error);
      messageError({
        context: "Publication.handleDownload",
        message: error.message || "Ocurrió un error al descargar el archivo",
      });
    }
  }, []);

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>{TITLE_TABLE}</h1>
        <CustomFilter header="Filtrar por" />
        <ProTable<IPublication>
          defaultSize="small"
          id="PublicationDownloadsPageTable"
          actionRef={actionRef}
          rowKey="id"
          search={{
            collapsed: false,
            resetText: "Limpiar búsqueda",
            searchText: "Buscar",
            collapseRender: false,
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IPublication>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          params={{
            sorter,
          }}
          dataSource={data}
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns()}
          scroll={{ x: "scroll" }}
          pagination={{
            defaultCurrent: 1,
            defaultPageSize,
          }}
          onReset={() => {
            setSearchText("");
          }}
        />
        <div className="buttons">
          <ButtonLiderar
            color="white"
            buttonName="Volver"
            className="button-left"
            onClick={() => history.goBack()}
          />
        </div>
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={editFormValues}
          columns={columns()}
          onOk={(value) => updatePublication(value)}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues(() => undefined);
          }}
        />
      </>
    )
  );
};

export default Publication;
