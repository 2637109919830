export const USERS = `
query users(
  $filter: FilterUserInput
  $orderBy: UserOrderInput
  $skip: Int
  $take: Int
) {
  users(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on User {
      id
      username
      firstname
      lastname
      email
      already_logged_in
      active
      is_banned
      banned_time
      is_blocked_by_login
      blocked_by_login_time
      user_role {
        role {
          id
          name
          role_permission {
            permission {
              code
              name
            }
          }
        }
      }
      is_system_user
      lastAccess
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const USERS_TINY = `
query usersTiny(
  $filter: FilterUserInput
  $orderBy: UserOrderInput
  $skip: Int
  $take: Int
) {
  usersTiny(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on  UsersTiny{
      id
      username
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const IS_USERNAME_ALREADY_TAKEN = `
query isUsernameAlreadyTaken($username:String!){
  isUsernameAlreadyTaken(username: $username) {
    __typename
    ... on BooleanResult {
      result
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const IS_EMAIL_ALREADY_TAKEN = `
query isEmailAlreadyTaken($email:String!) {
  isEmailAlreadyTaken(email: $email) {
    __typename
    ... on BooleanResult {
      result
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const AUTH = `
query authenticate {
  authenticate {
    __typename
    ... on AuthOutput {
      id
      username
      email
      firstname
      lastname
      already_logged_in
      is_banned
      coverages {
        id_liderar
        description
      }
      producers {
        id
        id_liderar
        nombre
        cuit
        zona_riesgo
        matricula
        cod_prod_auto
        cod_prod_moto
      }
      vehicleTypes {
        id_liderar
        description
      }
      roles {
        id
        name
      }
      permissions
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;

export const LOGOUT = `
  query {
    logout {
      __typename
      ... on LogoutOutput {
        result
      }
      ... on ResultError {
        status
        error_code
        message
        stack
        level
      }
    }
  }
`;

export const RESET_PASSWORD = `
  query resetPassword($username: String!) {
    __typename
    resetPassword(input: { username: $username }) {
      __typename
      result
    }
  }
`;

export const VALIDATE_TOKEN = `
query verifyRecoveryPasswordToken( $token: String!){
  __typename
  verifyRecoveryPasswordToken(
    token: $token
  ) {
    __typename
    ...on RecoveryResult {
      result
    }
    ...on ResultError{
      status_code
      message
    }
  }
}
`;

export const COUNT_USERS = `
query countUsers(
  $filter: FilterUserInput
  $orderBy: UserOrderInput
) {
  countUsers(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
