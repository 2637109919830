import { message as antdMessage, Modal } from "antd";
import { ModalFuncProps } from "antd/lib/modal";

const MESSAGE_CREATE_SUCCESS = "Creado exitosamente";
const MESSAGE_CREATE_ERROR = "Error al crear, intente nuevamente!";
const MESSAGE_UPDATE_SUCCESS = "Actualizado exitosamente";
const MESSAGE_UPDATE_ERROR = "Error al actualizar, intente nuevamente!";
const MESSAGE_DELETE_SUCCESS = "Eliminado exitosamente";
const MESSAGE_DELETE_ERROR = "Error al eliminar, intente nuevamente!";

const DEFAULT_TIMEOUT_LOADING = 150000;
const DEFAULT_TIMEOUT_MESSAGE = 1200;
const DEFAULT_ERROR_MESSAGE =
  "Hubo un error obteniendo la información del lado del servidor";

export const CustomMessage = (): any => {
  // const { Logger } = LogService();

  /**
   * Iniciar un loading
   * @param op
   */
  const messageLoading = (op: {
    context: string;
    message?: string;
    time?: number;
  }) => {
    antdMessage.destroy();
    antdMessage.loading(
      op.message || "Cargando...",
      op.time || DEFAULT_TIMEOUT_LOADING
    );
  };

  const messageDestroy = (time?: number, callback?: () => void) => {
    setTimeout(() => {
      antdMessage.destroy();
      if (callback) {
        callback();
      }
    }, time || DEFAULT_TIMEOUT_MESSAGE);
  };

  const messageModalSuccess = (op: {
    context: string;
    message: string;
    props?: ModalFuncProps;
  }) => {
    antdMessage.destroy();
    Modal.success(
      op?.props || {
        content: op.message,
      }
    );
  };

  const messageModalError = (op: {
    context: string;
    message: string;
    err?: Error;
    props?: ModalFuncProps;
  }) => {
    antdMessage.destroy();
    Modal.error(
      op?.props || {
        content: op.message,
      }
    );
  };

  const messageModalInfo = (op: {
    context: string;
    message: string;
    props?: ModalFuncProps;
  }) => {
    antdMessage.destroy();
    Modal.info(
      op?.props || {
        content: op.message,
      }
    );
  };

  /**
   * Resultado de un proceso de exito
   * @param op
   */
  const messageSuccess = (op: {
    context: string;
    message: string;
    time?: number;
  }) => {
    antdMessage.destroy();
    antdMessage.success(op.message);
    messageDestroy(op.time);
    // Logger.info(op);
  };

  /**
   * Resultado de un proceso que dio error
   * @param err
   */
  const messageError = (op: {
    context: string;
    message: string;
    time?: number;
  }) => {
    antdMessage.destroy();
    antdMessage.error(op.message);
    messageDestroy(op.time);
    // Logger.warn(op);
  };

  /**
   * Para acciones de ABM
   */
  const messageCreating = (op: { context: string; message: string }) => {
    messageLoading({
      context: op.context,
      message: `Creando ${op.message} ...`,
    });
  };
  const messageUpdating = (op: { context: string; message: string }) => {
    messageLoading({
      context: op.context,
      message: `Actualizando ${op.message} ...`,
    });
  };
  const messageDeleting = (op: { context: string; message: string }) => {
    messageLoading({
      context: op.context,
      message: `Eliminando ${op.message} ...`,
    });
  };

  /**
   * Para resultados de ABM
   */
  const messageCreateSuccess = (op: { context: string }) => {
    messageSuccess({ context: op.context, message: MESSAGE_CREATE_SUCCESS });
  };
  const messageCreateError = (op: { context: string }) => {
    messageError({ context: op.context, message: MESSAGE_CREATE_ERROR });
  };
  const messageUpdateSuccess = (op: { context: string }) => {
    messageSuccess({ context: op.context, message: MESSAGE_UPDATE_SUCCESS });
  };
  const messageUpdateError = (op: { context: string }) => {
    messageError({ context: op.context, message: MESSAGE_UPDATE_ERROR });
  };
  const messageDeleteSuccess = (op: { context: string }) => {
    messageSuccess({ context: op.context, message: MESSAGE_DELETE_SUCCESS });
  };
  const messageDeleteError = (op: { context: string }) => {
    messageError({ context: op.context, message: MESSAGE_DELETE_ERROR });
  };

  return {
    messageDestroy,
    messageLoading,
    messageSuccess,
    messageError,
    messageModalSuccess,
    messageModalError,
    messageModalInfo,
    messageCreating,
    messageUpdating,
    messageDeleting,
    messageCreateSuccess,
    messageCreateError,
    messageUpdateSuccess,
    messageUpdateError,
    messageDeleteSuccess,
    messageDeleteError,
    DEFAULT_ERROR_MESSAGE,
  };
};
