export const CREATE_QUOTATION_REQUEST = `
mutation createQuotationRequest($input: QuotationRequestInput!) {
  createQuotationRequest(input: $input) {
    __typename
    ... on QuotationRequest {
      numerator_id
      dome
      cold_equipment
      cng
      special_tires
      total_accessories
      fiscal_condition {
        id
        id_liderar
        description
      }
      quotation {
        id
        request_id
        coverage_id
        total_ice
        total_no_ice
        coverage {
          id_liderar
          description
          soloRC
          descrip_web
          description_long
          activo_web
        }
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;
