import Service from "./graphql/GraphqlService";
import { ContextApp } from "../shared/ContextApp";
import { useContext } from "react";
import { IUserRole } from "./userRole";
import { IRole } from "./role";
import { IProducer } from "./Producer";

export const UserService = (): {
  authenticate: () => Promise<any>;
  logout: () => Promise<unknown>;
  expireSession: () => Promise<unknown>;
} => {
  const { customRequest, Query } = Service();
  const { setUser, setRoles, setFunctions, setAuth } = useContext(ContextApp);

  const authenticate = async (): Promise<any> => {
    return new Promise((resolve) => {
      customRequest({
        query: Query.authenticate,
      })
        .then((res: any) => {
          resolve(res);
        })
        .catch(() => {
          resolve(null);
        });
    });
  };

  const logout = async () => {
    return new Promise((resolve, reject) => {
      // TODO: falta implementar lista negra en servicio
      // customRequest({
      //   query: Query.logout,
      // })
      return new Promise((innerResolve) => {
        innerResolve({});
      })
        .then((res: any) => {
          localStorage.removeItem("token");
          setUser(() => undefined);
          setRoles(() => []);
          setFunctions(() => []);
          setAuth(false);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const expireSession = async () => {
    return new Promise((resolve, reject) => {
      return new Promise((innerResolve) => {
        innerResolve({});
      })
        .then((res: any) => {
          localStorage.removeItem("token");
          setUser(() => undefined);
          setRoles(() => []);
          setFunctions(() => []);
          setAuth(false);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return {
    authenticate,
    logout,
    expireSession,
  };
};

export interface IUser {
  id: number;
  username: string;
  password: string;
  email: string;
  firstname: string;
  lastname: string;
  is_banned: boolean;
  roles: IRole[];
  is_system_user?: boolean;
  is_blocked_by_login: boolean;
  blocked_by_login_time?: Date;
  banned_time?: Date;
  already_logged_in?: boolean;
  user_role?: IUserRole[];
  coverages: ICoverages[];
  producers: IProducer[];
  vehicleTypes: IVehicleTypes[];
}
interface ICoverages {
  id_liderar: string;
  description: string;
}

interface IVehicleTypes {
  id_liderar: string;
  description: string;
}

export interface IUserForSelect {
  id: number;
  username: string;
}
