export const POSTAL_CODES = `
query postal_codes(
  $filter: FilterPostalCodeInput
  $orderBy: PostalCodeOrderInput
  $skip: Int
  $take: Int
) {
  postal_codes(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on PostalCode {
      id
      location
      proid      
      codpostal
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const LOCATION_BY_PROVINCE_ID = `
query locationByProvinceId($provinceId: Int!){
  locationByProvinceId(provinceId:$provinceId) {
    __typename
    ... on PostalCode {
      id
      location
      proid
      codpostal
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
