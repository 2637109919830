export const COVERAGE_PERIODS = `
query coverage_periods(
  $orderBy: CoveragePeriodOrderInput
  $filter: FilterCoveragePeriodInput
  $skip: Int
  $take: Int
) {
  coverage_periods(
    skip: $skip
    take: $take
    orderBy: $orderBy
    filter: $filter
  ) {
    __typename
    ... on CoveragePeriod {
      id
      description
      id_liderar
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;
