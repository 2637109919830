import { useContext, useCallback, useRef, useState, useEffect } from "react";
import { RouteConfigComponentProps } from "react-router-config";
import { isPlatform } from "@ionic/react";
import {
  Badge,
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Tooltip,
  Switch,
} from "antd";
import { SorterResult } from "antd/lib/table/interface";
import {
  CarOutlined,
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SafetyCertificateOutlined,
  StopOutlined,
  UserOutlined,
  UserSwitchOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

import ProTable, { ActionType } from "@ant-design/pro-table";
import { IUser, IUserForSelect } from "./../../services/user";
import { SaveForm } from "../ABM";
import { ExportableColumn } from "../../shared/Exporter";
import GraphqlService from "../../services/graphql/GraphqlService";
import {
  Authorization,
  ContextApp,
  CustomMessage,
  Tools,
  ABM,
} from "../../shared";
import ModalConfigureMultiple from "../ModalConfigureMultiple/Index";
import ModalUserProducer from "./ModalUserProducer/ModalUserProducer";
import { IRole } from "../../services/role";
import { IAppSetting } from "../../services/AppSetting";
import CustomFilter from "../CustomFilter/CustomFilter";
import { ICoverageType } from "../../services/ICoverageType";
import { IUserCoverage } from "../../services/UserCoverage";
import { IVehicleType } from "../../services/IVehicleType";
import { IUserVehicleType } from "../../services/UserVehicleType";
import { EnumsValues } from "../../shared/EnumsValues";
import { getPaginationArgs, hasPermission } from "../../shared/utils";
import { ICoveragePeriod } from "../../services/CoveragePeriod";
import { IUserCoveragePeriod } from "../../services/UserCoveragePeriod";
import { useCheckAuthority } from "../../shared/CustomHooks";

import "./User.less";
import { ParamsType } from "@ant-design/pro-provider";
import SelectWithFetch from "../utils/SelectWithFetch";
import moment from "moment";
const { Option } = Select;

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  "id",
  "username",
  "firstname",
  "lastname",
  "email",
  "role_id",
  "lock_type",
];
const LIST_SORTER = ["username", "firstname", "lastname"];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = "Usuarios";
const TITLE_CREATE_FORM = "Crear usuario";
const TITLE_UPDATE_FORM = "Editar usuario";

export default function User(
  routeProps: RouteConfigComponentProps
): JSX.Element {
  // props

  // states
  const { functions } = useContext(ContextApp);
  const [searchText, setSearchText] = useState<string>("");
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [manageRolesModalVisible, setManageRolesModalVisible] =
    useState<boolean>(false);
  const [coverageTypesModalVisible, setCoverageTypesModalVisible] =
    useState<boolean>(false);
  const [coveragePeriodsModalVisible, setCoveragePeriodsModalVisible] =
    useState<boolean>(false);
  const [vehicleTypesModalVisible, setVehicleTypesModalVisible] =
    useState<boolean>(false);
  const [manageProducerModalVisible, setManageProducerModalVisible] =
    useState<boolean>(false);
  const [dataProducerModal, setDataProducerModal] = useState<IUser>();
  const [userId, setUserId] = useState<number>(0);
  const [withPasswordSelected, setWithPasswordSelected] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] = useState<IUser>();
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<string>("");
  const [dataRoles, setDataRoles] = useState<IRole[]>([]);
  const [coverageTypes, setCoverageTypes] = useState<string[]>([]);
  const [coveragePeriods, setCoveragePeriods] = useState<string[]>([]);
  const [vehicleTypes, setVehicleTypes] = useState<IVehicleType[]>([]);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();
  const [coverageAsignedOnUser, setCoverageAsignedOnUser] = useState<string[]>(
    []
  );
  const [vehicleTypeAsignedOnUser, setVehicleTypeAsignedOnUser] = useState<
    string[]
  >([]);
  const [coveragePeriodAsignedOnUser, setCoveragePeriodAsignedOnUser] =
    useState<string[]>([]);
  const [dataManageOnTable, setDataManageOnTable] = useState<IUser>({
    id: 0,
    username: "",
    password: "",
    email: "",
    firstname: "",
    lastname: "",
    roles: [],
    is_system_user: false,
    is_banned: false,
    is_blocked_by_login: false,
    coverages: [],
    producers: [],
    vehicleTypes: [],
  });

  // services and hooks
  const { Query, Mutation, customRequest } = GraphqlService();
  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageCreating,
    messageCreateSuccess,
    messageCreateError,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // contexts

  // methods

  useEffect(() => {
    getRegex();
    fetchCoverages();
    fetchVehicleTypes();
    fetchCoveragePeriods();
  }, []);

  useEffect(() => {
    customRequest({
      query: Query.roles,
    })
      .then((data: IRole[]) => {
        setDataRoles(() => data);
        return {
          current: 1,
          data: data,
          pageSize: "1",
          success: true,
          total: data.length,
        };
      })
      .catch(() => {
        return {
          current: 1,
          data: [],
          pageSize: "1",
          success: true,
          total: 0,
        };
      });
  }, [setDataRoles]);

  const fetchCoverages = () => {
    customRequest({
      query: Query.coverages,
      variables: {
        filter: {
          activo_web: true,
        },
      },
    })
      .then((data: ICoverageType[]) => {
        const coverageByDescripWeb = Array.from(
          new Set(data.map((coverage) => coverage.descrip_web))
        );

        setCoverageTypes(() => coverageByDescripWeb);
      })
      .catch((error: any) => {
        messageError({
          context: "fetchCoverages",
          message: error?.message,
        });
      });
  };

  const getUserCoverage = (user: number) => {
    customRequest({
      query: Query.getUserCoverage,
      variables: {
        filter: {
          user_id: user,
        },
      },
    })
      .then((data: IUserCoverage[]) => {
        const coverageArrayForUser = Array.from(
          new Set(data.map((coverage) => coverage.coverage.descrip_web))
        );
        setCoverageAsignedOnUser(coverageArrayForUser);
      })
      .catch((error: any) => {
        messageError({
          context: "getUserCoverage",
          message: error?.message,
        });
      });
  };

  const fetchCoveragePeriods = useCallback(async () => {
    try {
      const data: ICoveragePeriod[] = await customRequest({
        query: Query.coverage_periods,
        variables: {
          filter: {
            activo_web: true,
          },
        },
      });
      const coveragePeriodsArray = Array.from(
        new Set(data.map((coveragePeriod) => coveragePeriod.description))
      );

      setCoveragePeriods(() => coveragePeriodsArray);
    } catch (error: any) {
      messageError({
        context: "fetchCoveragePeriods",
        message: error?.message,
      });
    }
  }, []);

  const getUserCoveragePeriod = useCallback(async (user: number) => {
    try {
      const data: IUserCoveragePeriod[] = await customRequest({
        query: Query.getUserCoveragePeriod,
        variables: {
          filter: {
            user_id: user,
          },
        },
      });
      const coveragePeriodArrayForUser = Array.from(
        new Set(
          data.map(
            (coveragePeriod) => coveragePeriod.coverage_period.description
          )
        )
      );
      setCoveragePeriodAsignedOnUser(coveragePeriodArrayForUser);
    } catch (error: any) {
      messageError({
        context: "getUserCoveragePeriod",
        message: error?.message,
      });
    }
  }, []);

  const fetchVehicleTypes = () => {
    customRequest({
      query: Query.vehicle_types,
      variables: {
        filter: {
          activo_web: true,
        },
        orderBy: { direction: "asc", field: "description" },
      },
    })
      .then((data: IVehicleType[]) => {
        setVehicleTypes(() => data);
      })
      .catch(() => {
        //Intentional
      });
  };

  const getUserVehicleType = (user: number) => {
    customRequest({
      query: Query.getVehicleType,
      variables: {
        filter: {
          user_id: user,
        },
      },
    })
      .then((data: IUserVehicleType[]) => {
        const vehicleTypeArrayForUser = Array.from(
          new Set(data.map((vehicleType) => vehicleType.vehicle_type_id))
        );
        setVehicleTypeAsignedOnUser(vehicleTypeArrayForUser);
      })
      .catch((error: any) => {
        messageError({
          context: "getUserVehicleType",
          message: error?.message,
        });
      });
  };

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);
      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            variables.current.filter[element] = search[element];
            if (element === "id") {
              variables.current.filter[element] = search[element].key;
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.countUsers,
        variables: variables.current,
      }).then((response: { count: number }) => response.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.users,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);

      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const getUsersTiny = async (username: string) => {
    try {
      return await customRequest({
        query: Query.usersTiny,
        variables: {
          filter: {
            username,
          },
          take: 20,
        },
      });
    } catch (error: any) {
      messageError({
        context: "User.usersTiny.1",
        message: error.message,
      });
      return undefined;
    }
  };

  const isUsernameAlreadyTaken = async (username: string) => {
    try {
      return await customRequest({
        query: Query.isUsernameAlreadyTaken,
        variables: {
          username,
        },
      });
    } catch (error: any) {
      messageError({
        context: "User.isUsernameAlreadyTaken.1",
        message: error.message,
      });
    }
  };

  const isEmailAlreadyTaken = async (email: string) => {
    try {
      return await customRequest({
        query: Query.isEmailAlreadyTaken,
        variables: {
          email,
        },
      });
    } catch (error: any) {
      messageError({
        context: "User.isEmailAlreadyTaken.1",
        message: error.message,
      });
    }
  };

  const createUser = async (value: any) => {
    delete value.repeatPassword;
    const responseUsername = await isUsernameAlreadyTaken(value.username);
    const responseEmail = await isEmailAlreadyTaken(value.email);
    if (responseUsername.result) {
      messageError({
        context: "TableUser.createUser.4",
        message: "Ese nombre de usuario ya existe. Intente de nuevo",
      });
    } else if (responseEmail.result) {
      messageError({
        context: "TableUser.createUser.5",
        message: "El email seleccionado ya existe. Intente de nuevo",
      });
    } else {
      setFormLoading(() => true);
      messageCreating({
        context: "TableUser.createUser.1",
        message: "Usuario",
      });
      customRequest({
        mutation: Mutation.createUser,
        variables: {
          input: value,
        },
      })
        .then(() => {
          setCreateModalVisible(false);
          messageCreateSuccess({
            context: "TableUser.createUser.2",
          });
          if (actionRef.current?.reset) {
            actionRef.current.reset();
          }
        })
        .catch((error: any) => {
          if (error.status_code && error.message) {
            return messageError({
              context: "TableUser.createUser.3",
              message: error.message,
            });
          }
          messageCreateError({ context: "TableUser.createUser.3" });
        })
        .finally(() => {
          setFormLoading(() => false);
        });
    }
  };

  const upsertUserRoles = async (
    id: number,
    addRoles: number[],
    deleteRoles: number[]
  ) => {
    if (!addRoles.length && !deleteRoles.length) {
      return;
    }
    messageUpdating({
      context: "TableUser.updateUser.1",
      message: "usuario",
    });
    return customRequest({
      mutation: Mutation.upsertUserRole,
      variables: {
        input: {
          user_id: id,
          addRoleIds: addRoles,
          deleteRoleIds: deleteRoles,
        },
      },
    })
      .then(() => {
        messageUpdateSuccess({
          context: "TableUser.updateUserRoles.2",
        });
        if (actionRef.current?.reset) {
          actionRef.current.reset();
        }
      })
      .catch((error: any) => {
        if (error.status_code && error.message) {
          return messageError({
            context: "TableUser.createUser.3",
            message: error.message,
          });
        }
        messageUpdateError({ context: "TableUser.updateUserRoles.3" });
      })
      .finally(() => {
        setFormLoading(() => false);
        setManageRolesModalVisible(false);
      });
  };

  const upsertUserVehicleTypes = async (
    id_user: number,
    addTypes: number[],
    deleteTypes: number[]
  ) => {
    if (!addTypes.length && !deleteTypes.length) {
      return;
    }
    messageUpdating({
      context: "upsertVehicleType",
      message: "usuario",
    });
    return customRequest({
      mutation: Mutation.upsertVehicleType,
      variables: {
        input: {
          user_id: id_user,
          addVehicleTypeIds: addTypes,
          deleteVehicleTypeIds: deleteTypes,
        },
      },
    })
      .then(() => {
        messageUpdateSuccess({
          context: "upsertVehicleType",
        });
        if (actionRef.current?.reset) {
          actionRef.current.reset();
        }
      })
      .catch((error: any) => {
        if (error.status_code && error.message) {
          return messageError({
            context: "upsertVehicleType",
            message: error.message,
          });
        }
        messageUpdateError({ context: "upsertVehicleType" });
      })
      .finally(() => {
        setFormLoading(() => false);
        setVehicleTypesModalVisible(false);
      });
  };

  const upsertUserCoverageTypes = async (
    id_user: number,
    addTypes: number[],
    deleteTypes: number[]
  ) => {
    if (!addTypes.length && !deleteTypes.length) {
      return;
    }
    messageUpdating({
      context: "upsertUserCoverage",
      message: "usuario",
    });
    return customRequest({
      mutation: Mutation.upsertUserCoverage,
      variables: {
        input: {
          user_id: id_user,
          addCoverageIds: addTypes,
          deleteCoverageIds: deleteTypes,
        },
      },
    })
      .then(() => {
        messageUpdateSuccess({
          context: "upsertUserCoverage",
        });
        if (actionRef.current?.reset) {
          actionRef.current.reset();
        }
      })
      .catch((error: any) => {
        if (error.status_code && error.message) {
          return messageError({
            context: "upsertUserCoverage",
            message: error.message,
          });
        }
        messageUpdateError({ context: "upsertUserCoverage" });
      })
      .finally(() => {
        setFormLoading(() => false);
        setCoverageTypesModalVisible(false);
      });
  };

  const upsertUserCoveragePeriod = async (
    user_id: number,
    addPeriods: number[],
    deletePeriods: number[]
  ) => {
    if (!addPeriods.length && !deletePeriods.length) {
      return;
    }
    messageUpdating({
      context: "upsertUserCoverage",
      message: "usuario",
    });
    try {
      const data: IUserCoveragePeriod = await customRequest({
        mutation: Mutation.upsertUserCoveragePeriod,
        variables: {
          input: {
            user_id,
            addCoveragePeriodIds: addPeriods,
            deleteCoveragePeriodIds: deletePeriods,
          },
        },
      });
      if (data) {
        messageUpdateSuccess({
          context: "upsertUserCoveragePeriod",
        });
        if (actionRef.current?.reset) {
          actionRef.current.reset();
        }
        setFormLoading(() => false);
        setCoveragePeriodsModalVisible(false);
      }
    } catch (error: any) {
      messageError({
        context: "User.upsertUserCoveragePeriod.1",
        message: error?.message,
      });
    }
  };

  const updateUser = (value: any) => {
    delete value.repeatPassword;

    setFormLoading(() => true);
    messageUpdating({
      context: "TableUser.updateUser.1",
      message: "usuario",
    });
    customRequest({
      mutation: Mutation.updateUser,
      variables: { input: { ...value, id: editForm?.id } },
    })
      .then(() => {
        messageUpdateSuccess({
          context: "TableUser.updateUser.2",
        });
        handleUpdateModalVisible(false);
        if (actionRef.current?.reset) {
          actionRef.current.reset();
        }
      })
      .catch((error: any) => {
        if (error.status_code && error.message) {
          return messageError({
            context: "TableUser.createUser.3",
            message: error.message,
          });
        }
        messageUpdateError({ context: "TableUser.updateUser.3" });
      })
      .finally(() => {
        setFormLoading(() => false);
      });
  };

  const removeConfirmedUser = (value: any) => {
    messageDeleting({
      context: "User.removeConfirmedUser.1",
      message: "usuario",
    });
    customRequest({
      mutation: Mutation.deleteUser,
      variables: {
        id: value.id,
      },
    })
      .then(() => {
        messageDeleteSuccess({
          context: "User.removeConfirmedUser.2",
        });
        if (actionRef.current?.reset) {
          actionRef.current.reset();
        }
      })
      .catch((error: any) => {
        messageError({
          context: "User.removeConfirmedUser.3",
          message: error.message,
        });
      });
  };

  const removeUser = (value: any) => {
    Modal.confirm({
      content: (
        <>
          <div>¿Seguro que desea eliminar al usuario {value.username}?</div>
        </>
      ),
      cancelText: "Cancelar",
      okText: "Aceptar",
      onOk: () => {
        removeConfirmedUser(value);
      },
    });
  };

  const getRegex = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.PasswordRegex },
        },
      })
        .then((data: IAppSetting) => {
          setPasswordRegex(() => data);
          return resolve();
        })
        .catch((error: any) => {
          messageError({
            context: "User.getRegex.1",
            message: error?.message,
          });
          return resolve();
        });
    });
  };

  const unlockUserByLoginModal = (user: IUser) => {
    Modal.confirm({
      content: (
        <>
          <div>
            ¿Seguro que desea quitar el bloqueo temporal al usuario{" "}
            {user.username}?
          </div>
        </>
      ),
      cancelText: "Cancelar",
      okText: "Aceptar",
      onOk: () => {
        unlockUserByLoginConfirmed(user.id);
      },
    });
  };

  const unlockUserByLoginConfirmed = async (user_id: number) => {
    messageUpdating({
      context: "User.unlockUserByLoginConfirmed.1",
      message: "usuario",
    });

    try {
      await customRequest({
        mutation: Mutation.unlockUserByLogin,
        variables: { user_id },
      });
      messageUpdateSuccess({
        context: "User.unlockUserByLoginConfirmed.2",
      });
      if (actionRef.current?.reset) {
        actionRef.current.reset();
      }
    } catch (error: any) {
      messageError({
        context: "User.unlockUserByLoginConfirmed.3",
        message: error.message,
      });
    }
  };

  const blockUser = (value: any) => {
    Modal.confirm({
      content: (
        <>
          <div>
            ¿Seguro que desea {value.is_banned ? "desbloquear" : "bloquear"} al
            usuario {value.username}?
          </div>
        </>
      ),
      cancelText: "Cancelar",
      okText: "Aceptar",
      onOk: () => {
        blockConfirmedUser(value);
      },
    });
  };

  const blockConfirmedUser = (value: any) => {
    messageUpdating({
      context: "User.blockConfirmedUser.1",
      message: "usuario",
    });
    customRequest({
      mutation: Mutation.setUserBan,
      variables: {
        input: { user_id: value.id, is_banned: !value.is_banned },
      },
    })
      .then(() => {
        messageUpdateSuccess({
          context: "User.blockConfirmedUser.2",
        });
        if (actionRef.current?.reset) {
          actionRef.current.reset();
        }
      })
      .catch((error: any) => {
        messageError({
          context: "User.blockConfirmedUser.3",
          message: error.message,
        });
      });
  };

  const blockedTime = (value: Date) => {
    return Math.abs(moment(value).diff(moment(), "minutes"));
  };

  const columns = useCallback(
    (editMode: boolean): ExportableColumn<IUser>[] => [
      {
        export: false,
        dataIndex: "id",
        title: "id",
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: "username",
        title: "Usuario",
        sorter: true,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El nombre de usuario es obligatorio",
            },
            {
              max: EnumsValues.MaxLengthInputs.SystemUser_UserName,
              message: `El nombre de usuario debe tener un maximo de ${EnumsValues.MaxLengthInputs.SystemUser_UserName} caracteres`,
            },
            {
              validator: async (_rule, username: string) => {
                if (!username) {
                  return Promise.resolve();
                }
                const response = await isUsernameAlreadyTaken(username);
                if (response.result) {
                  return Promise.reject(
                    new Error("El nombre de usuario no está disponible.")
                  );
                }
                return Promise.resolve();
              },
              validateTrigger: "onBlur",
            },
          ],
        },
        render: (_: any, record: { username: any }) => record.username || "-",
        renderFormItem: (_, __, formInstance) => (
          <Input
            placeholder="Ingrese nombre de usuario..."
            maxLength={100}
            onBlur={() => formInstance.validateFields(["username"])}
          />
        ),
        hideInTable: false,
        hideInSearch: true,
        hideInForm: editMode,
      },
      {
        export: false,
        dataIndex: "id",
        title: "Usuario",
        valueType: "select",
        renderFormItem: () => (
          <SelectWithFetch
            lengthValueToFetch={3}
            query={getUsersTiny}
            placeholder="Ingrese usuario..."
            optionRender={({ id, username }: IUserForSelect) => (
              <Option value={id} key={id}>
                {username}
              </Option>
            )}
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "WithoutPasswordSwitch",
        title: editForm ? "Cambiar contraseña" : "Crear con contraseña",
        renderFormItem: () => <Switch onChange={setWithPasswordSelected} />,
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: "password",
        title: "Contraseña",
        formItemProps: {
          rules: [
            {
              required: true,
              message: "Debe ingresar una contraseña",
            },
            {
              validator(_, value) {
                const regex = new RegExp(String(passwordRegex?.setting_value));
                if (regex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("La contraseña no cumple con los requisitos")
                );
              },
            },
          ],
        },
        renderFormItem: () => (
          <Input.Password
            autoComplete="new-password"
            placeholder="Ingrese una contraseña..."
            maxLength={100}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: !withPasswordSelected,
      },
      {
        export: false,
        renderFormItem: () => (
          <div className="messageRegExp">
            <Badge color="#606366" status="default" />
            <p className="textRegExp">{passwordRegex?.description}</p>
          </div>
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: !withPasswordSelected,
      },
      {
        export: false,
        dataIndex: "repeatPassword",
        title: "Repetir contraseña",
        formItemProps: {
          rules: [
            {
              required: true,
              message: "Debe repetir la contraseña",
            },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Las contraseñas no coinciden!")
                );
              },
            }),
          ],
        },
        renderFormItem: () => (
          <Input.Password
            placeholder="Repita la contraseña..."
            maxLength={200}
          />
        ),
        hideInTable: true,
        hideInSearch: true,
        hideInForm: !withPasswordSelected,
      },
      {
        export: true,
        dataIndex: "firstname",
        title: "Nombre",
        sorter: true,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El nombre es obligatorio",
            },
          ],
        },
        render: (_: any, record: { firstname: any }) => record.firstname || "-",
        renderFormItem: () => (
          <Input placeholder="Ingrese nombre..." maxLength={200} />
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: "lastname",
        title: "Apellido",
        sorter: true,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El nombre es obligatorio",
            },
          ],
        },
        render: (_: any, record: { lastname: any }) => record.lastname || "-",
        renderFormItem: () => (
          <Input placeholder="Ingrese apellido..." maxLength={200} />
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: "email",
        title: "Email",
        width: 250,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El nombre es obligatorio",
            },
            {
              max: EnumsValues.MaxLengthInputs.SystemUser_Email,
              message: `El email debe tener un maximo de ${EnumsValues.MaxLengthInputs.SystemUser_Email} caracteres`,
            },
            {
              type: "email",
              message: "No es un E-mail válido!",
            },
            {
              validator: async (_rule, email: string) => {
                if (!email) {
                  return Promise.resolve();
                }
                if (editMode) {
                  if (editForm?.email === email) {
                    return Promise.resolve();
                  }

                  const response = await isEmailAlreadyTaken(email);
                  if (response.result) {
                    return Promise.reject(
                      new Error("El email no está disponible.")
                    );
                  }
                  return Promise.resolve();
                } else {
                  const response = await isEmailAlreadyTaken(email);
                  if (response.result) {
                    return Promise.reject(
                      new Error("El email no está disponible.")
                    );
                  }
                  return Promise.resolve();
                }
              },
              validateTrigger: "onBlur",
            },
          ],
        },
        render: (_: any, record: { email: any }) => record.email || "-",
        renderFormItem: (_, __, formInstance) => (
          <Input
            placeholder="Ingrese email..."
            maxLength={200}
            onBlur={() => formInstance.validateFields(["email"])}
          />
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        dataIndex: "role",
        export: true,
        title: "Rol",
        render: (_: any, record: IUser) => {
          return (
            <>
              {record.user_role?.map((user, index) => (
                <p key={index}>{user.role.name}</p>
              ))}
            </>
          );
        },
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        renderDataExport: (record: any) => {
          let roles = "";
          if (record.user_role?.length) {
            record.user_role.map((element: any, index: number) => {
              roles = roles + element.role.name;
              if (index < record.user_role.length - 1) {
                roles = roles + " - ";
              }
              return roles;
            });
          }
          return roles;
        },
      },
      {
        export: false,
        dataIndex: "role_id",
        title: "Rol",
        valueType: "select",
        renderFormItem: () => (
          <Select
            options={dataRoles.map((rol) => ({
              label: rol.name,
              value: rol.id,
            }))}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            dropdownMatchSelectWidth={false}
            placeholder="Seleccione rol..."
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "lock_type",
        title: "Tipo de bloqueo",
        valueType: "select",
        renderFormItem: () => (
          <Select
            options={[
              { id: 1, name: "Temporal" },
              { id: 2, name: "Permanente" },
            ].map((lock_type) => ({
              label: lock_type.name,
              value: lock_type.id,
            }))}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            dropdownMatchSelectWidth={false}
            placeholder="Seleccione tipo de bloqueo..."
            allowClear
            showSearch
            filterOption={(inputValue, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        title: "Op.",
        dataIndex: "option",
        valueType: "option",
        fixed: "right",
        width: 300,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.UsersUpdate
            ) &&
              !record.is_system_user && (
                <>
                  <Tooltip
                    key="edit_user"
                    trigger={isPlatform("desktop") ? "hover" : " focus"}
                    title="Modificar usuario"
                  >
                    <EditOutlined
                      className="pointer"
                      onClick={() => {
                        setEditFormValues(() => record);
                        handleUpdateModalVisible(() => true);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UsersRolesUpdate
            ) &&
              !record.is_system_user && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="configure_rol_tooltip"
                    title="Configurar rol"
                    trigger={isPlatform("desktop") ? "hover" : " focus"}
                  >
                    <Badge dot count={record.user_role?.length === 0 ? 1 : 0}>
                      <UserOutlined
                        className="pointer"
                        onClick={() => {
                          setDataManageOnTable(() => record);
                          setManageRolesModalVisible(true);
                        }}
                      />
                    </Badge>
                  </Tooltip>
                </>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UserCoverage
            ) &&
              !record.is_system_user && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="configure_coverage_type_tooltip"
                    title="Configurar tipo de cobertura"
                    trigger={isPlatform("desktop") ? "hover" : " focus"}
                  >
                    <SafetyCertificateOutlined
                      className="pointer"
                      onClick={() => {
                        getUserCoverage(record.id);
                        setDataManageOnTable(() => record);
                        setCoverageTypesModalVisible(true);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UserProducer
            ) &&
              !record.is_system_user && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="configure_producer_tooltip"
                    title={
                      hasPermission(
                        record,
                        EnumsValues.Functions.getAllProducerList
                      )
                        ? "Todos los productores estan asignados por defecto"
                        : "Asignar Productores"
                    }
                    trigger={isPlatform("desktop") ? "hover" : " focus"}
                  >
                    <UserSwitchOutlined
                      style={{
                        color: hasPermission(
                          record,
                          EnumsValues.Functions.getAllProducerList
                        )
                          ? "gray"
                          : "#763790",
                        cursor: hasPermission(
                          record,
                          EnumsValues.Functions.getAllProducerList
                        )
                          ? "default"
                          : "pointer",
                      }}
                      className="pointer"
                      onClick={() => {
                        if (
                          hasPermission(
                            record,
                            EnumsValues.Functions.getAllProducerList
                          )
                        ) {
                          return;
                        }
                        setDataProducerModal(() => record);
                        setManageProducerModalVisible(true);
                        setUserId(record.id);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UserVehicle
            ) &&
              !record.is_system_user && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="configure_vehicle_type_tooltip"
                    title="Configurar tipo de vehículo"
                    trigger={isPlatform("desktop") ? "hover" : " focus"}
                  >
                    <CarOutlined
                      className="pointer"
                      onClick={() => {
                        getUserVehicleType(record.id);
                        setDataManageOnTable(() => record);
                        setVehicleTypesModalVisible(true);
                      }}
                    />
                  </Tooltip>
                </>
              )}

            {Authorization.security(
              functions,
              EnumsValues.Functions.UserCoverage
            ) &&
              !record.is_system_user && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="configure_coverage_period_tooltip"
                    title="Configurar período de cobertura"
                    trigger={isPlatform("desktop") ? "hover" : " focus"}
                  >
                    <CalendarOutlined
                      className="pointer"
                      onClick={() => {
                        getUserCoveragePeriod(record.id);
                        setDataManageOnTable(() => record);
                        setCoveragePeriodsModalVisible(true);
                      }}
                    />
                  </Tooltip>
                </>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UsersBan
            ) &&
              !record.is_system_user && (
                <>
                  <Divider type="vertical" />
                  {
                    <Tooltip
                      key="blocked_by_login_user_tooltip"
                      trigger={isPlatform("desktop") ? "hover" : " focus"}
                      title={
                        record.is_blocked_by_login &&
                        record.blocked_by_login_time
                          ? `Reseteo de bloqueo temporal, bloqueado hace ${blockedTime(
                              record.blocked_by_login_time
                            )} minutos`
                          : "Usuario sin bloqueo temporal"
                      }
                    >
                      <ClockCircleOutlined
                        className="pointer"
                        onClick={() => {
                          record.is_blocked_by_login &&
                            unlockUserByLoginModal(record);
                        }}
                        style={{
                          color: record.is_blocked_by_login
                            ? "#763790"
                            : "gray",
                          cursor: record.is_blocked_by_login
                            ? "pointer"
                            : "default",
                        }}
                      />
                    </Tooltip>
                  }
                </>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UsersBan
            ) &&
              !record.is_system_user && (
                <>
                  <Divider type="vertical" />
                  {!record.is_banned ? (
                    <Tooltip
                      key="block_user_tooltip"
                      trigger={isPlatform("desktop") ? "hover" : " focus"}
                      title="Bloquear usuario"
                    >
                      <StopOutlined
                        className="pointer"
                        onClick={() => {
                          blockUser(record);
                        }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      key="block_user_tooltip"
                      trigger={isPlatform("desktop") ? "hover" : " focus"}
                      title={`Desbloquear usuario - Fecha de bloqueo: ${
                        record.banned_time
                          ? moment(record.banned_time).format("DD/MM/YY HH:mm")
                          : "-"
                      }`}
                    >
                      <CheckCircleOutlined
                        className="pointer"
                        onClick={() => {
                          blockUser(record);
                        }}
                      />
                    </Tooltip>
                  )}
                </>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.UsersDelete
            ) &&
              !record.is_system_user && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="remove_user_tooltip"
                    trigger={isPlatform("desktop") ? "hover" : " focus"}
                    title="Eliminar usuario"
                  >
                    <DeleteOutlined
                      className="pointer"
                      onClick={() => {
                        removeUser(record);
                      }}
                    />
                  </Tooltip>
                </>
              )}
          </>
        ),
      },
    ],
    [editForm, passwordRegex, dataRoles, withPasswordSelected]
  );

  const clearForm = () => {
    setEditFormValues(() => undefined);
    setWithPasswordSelected(false);
  };

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>{TITLE_PRO_TABLE}</h1>
        <CustomFilter header="Filtrar por" />
        <ProTable<IUser>
          defaultSize="small"
          actionRef={actionRef}
          rowClassName={(record) => (record.is_banned ? "inactive-row" : "")}
          rowKey="id"
          search={{
            collapsed: false,
            resetText: "Limpiar",
            searchText: "Buscar",
            collapseRender: false,
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IUser>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          onReset={() => {
            setSearchText("");
          }}
          params={{
            sorter,
          }}
          toolBarRender={() => [
            <>
              {Authorization.security(
                functions,
                EnumsValues.Functions.UsersCreate
              ) && (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateModalVisible(true);
                    setWithPasswordSelected(false);
                  }}
                >
                  <PlusOutlined /> Nuevo
                </Button>
              )}
            </>,
          ]}
          /**
           * @description este metodo debe poder ejecutar siempre la consulta al backend
           */
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns(false)}
        />
        <SaveForm
          loading={formLoading}
          title={TITLE_CREATE_FORM}
          onCancel={() => {
            setCreateModalVisible(false);
            clearForm();
          }}
          modalVisible={createModalVisible}
          onOk={(value) => {
            createUser(value);
            clearForm();
          }}
          columns={columns(false)}
          onReset={() => clearForm()}
          style={{ marginTop: "-50px" }}
        />
        {editForm && (
          <SaveForm
            loading={formLoading}
            title={TITLE_UPDATE_FORM}
            modalVisible={updateModalVisible}
            values={editForm}
            columns={columns(true)}
            onOk={(value) => {
              updateUser(value);
              clearForm();
            }}
            onReset={() => {
              setWithPasswordSelected(false);
            }}
            onCancel={() => {
              handleUpdateModalVisible(false);
              clearForm();
            }}
          />
        )}
        {Authorization.security(
          functions,
          EnumsValues.Functions.UsersRolesUpdate
        ) && (
          <ModalConfigureMultiple<IRole>
            renderLabel={(entity) => entity.name}
            checks={dataRoles}
            data={[
              dataManageOnTable.id,
              dataManageOnTable.user_role?.map(
                (dataRole) => dataRole.role.id
              ) || [],
            ]}
            onCancel={() => {
              setManageRolesModalVisible(false);
            }}
            onOk={(id_user, add_roles, delete_roles) => {
              return upsertUserRoles(id_user, add_roles, delete_roles);
            }}
            modalVisible={manageRolesModalVisible}
            titleModal="Administrar roles"
          />
        )}
        {Authorization.security(
          functions,
          EnumsValues.Functions.UserCoverage
        ) && (
          <ModalConfigureMultiple<ICoverageType>
            renderLabel={(entity) => entity}
            checks={coverageTypes}
            data={[dataManageOnTable?.id, coverageAsignedOnUser || []]}
            isStringArray
            onCancel={() => {
              setCoverageTypesModalVisible(false);
            }}
            onOk={(id_user, add_types, delete_types) => {
              return upsertUserCoverageTypes(id_user, add_types, delete_types);
            }}
            modalVisible={coverageTypesModalVisible}
            titleModal="Administrar tipos de cobertura"
          />
        )}
        {Authorization.security(
          functions,
          EnumsValues.Functions.UserVehicle
        ) && (
          <ModalConfigureMultiple<IVehicleType>
            renderLabel={(entity) => entity.description}
            checks={vehicleTypes}
            data={[dataManageOnTable?.id, vehicleTypeAsignedOnUser || []]}
            onCancel={() => {
              setVehicleTypesModalVisible(false);
            }}
            onOk={(id_user, add_vehicle_types, delete_vehicle_types) => {
              return upsertUserVehicleTypes(
                id_user,
                add_vehicle_types,
                delete_vehicle_types
              );
            }}
            modalVisible={vehicleTypesModalVisible}
            titleModal="Administrar tipos de vehículo"
          />
        )}
        {Authorization.security(
          functions,
          EnumsValues.Functions.UserCoverage
        ) && (
          <ModalConfigureMultiple<ICoveragePeriod>
            renderLabel={(entity) => entity}
            checks={coveragePeriods}
            data={[dataManageOnTable?.id, coveragePeriodAsignedOnUser || []]}
            isStringArray
            onCancel={() => {
              setCoveragePeriodsModalVisible(false);
            }}
            onOk={(user_id, add_coverage_periods, delete_coverage_periods) => {
              return upsertUserCoveragePeriod(
                user_id,
                add_coverage_periods,
                delete_coverage_periods
              );
            }}
            modalVisible={coveragePeriodsModalVisible}
            titleModal="Administrar períodos de cobertura"
          />
        )}
        {Authorization.security(
          functions,
          EnumsValues.Functions.UserProducer
        ) && (
          <ModalUserProducer
            manageProducerModalVisible={manageProducerModalVisible}
            dataProducerModal={dataProducerModal}
            setManageProducerModalVisible={setManageProducerModalVisible}
            userId={userId}
          />
        )}
      </>
    )
  );
}
