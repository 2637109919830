import { Select } from "antd";
import { useEffect, useState, useCallback, useContext } from "react";

import { IAjustmentRates } from "../../../services/AjustmentRates";
import { IAppSetting } from "../../../services/AppSetting";
import { IBodyWork } from "../../../services/BodyWork";
import { IbrandModelByBrandModelYear } from "../../../services/BrandModel";
import { ICoveragePeriod } from "../../../services/CoveragePeriod";
import { IFiscalCondition } from "../../../services/FiscalCondition";
import { IFuel } from "../../../services/Fuel";
import { IGender } from "../../../services/Gender";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { IcoverageTypeSelect } from "../../../services/ICoverageType";
import { ICreditCardTypes } from "../../../services/ICreditCardTypes";
import { INationality } from "../../../services/INationality";
import { IProvince } from "../../../services/Province";
import { IRenovation } from "../../../services/Renovation";
import { ContextApp, CustomMessage, EnumsValues } from "../../../shared";
import { camelCase } from "../../../shared/utils";

const { Option } = Select;

interface IUseSelectInputProps {
  renovationData: IRenovation | undefined;
  formType: string;
}

const useSelectInput = (props: IUseSelectInputProps) => {
  const { renovationData, formType } = props;
  const { user } = useContext(ContextApp);
  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();

  const [fuelTypes, setFuelTypes] = useState<IFuel[]>([]);
  const [bodyworkTypes, setBodyworkTypes] = useState<IBodyWork[]>([]);

  const [gendersTypes, setGendersTypes] = useState<IGender[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<ICreditCardTypes[]>([]);
  const [nationalities, setNationalities] = useState<INationality[]>([]);
  const [minBornTime, setMinBornTime] = useState<IAppSetting>();
  const [idsLiderarForMotorcycles, setIdsLiderarForMotorcycles] = useState<
    string[]
  >([]);
  const [idsLiderarForTrucks, setIdsLiderarForTrucks] = useState<string[]>([]);
  const [fiscalConditions, setFiscalConditions] = useState<IFiscalCondition[]>(
    []
  );
  const [provinces, setProvinces] = useState<IProvince[]>([]);
  const [coveragePeriods, setCoveragePeriods] = useState<ICoveragePeriod[]>([]);
  const [coverageType, setCoverageType] = useState<IcoverageTypeSelect[]>([]);
  const [adjustmentRates, setAdjustmentRates] = useState<IAjustmentRates[]>([]);

  const getFiscalConditions = async () => {
    try {
      const response = await customRequest({
        query: Query.fiscal_conditions,
        variables: {
          filter: {
            activo_web: true,
          },
        },
      });
      setFiscalConditions(response);
    } catch (error) {
      messageError({
        context: "getFiscalConditions",
        message: "Error al obtener las condiciones fiscales",
      });
    }
  };

  const fiscalConditionOptions = () =>
    fiscalConditions.length !== 0 &&
    fiscalConditions.map((item: IFiscalCondition) => (
      <Option value={item.id_liderar} key={item.id}>
        {item.description}
      </Option>
    ));
  const getMinBornTime = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.MinBornEmissionTime },
        },
      })
        .then((data: IAppSetting) => {
          setMinBornTime(() => data);
          return resolve();
        })
        .catch((error: any) => {
          messageError({
            context: "Emission.getMinBornTime.1",
            message: error?.message,
          });
          return resolve();
        });
    });
  };

  const getIdsLiderarForMotorcycles = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: EnumsValues.SettingKeys.idsLiderarVehicleTypeMotorcycle,
          },
        },
      });
      if (data) {
        setIdsLiderarForMotorcycles(() =>
          data.setting_value.replace(/ /g, "").split(",")
        );
      }
    } catch (error: any) {
      messageError({
        context: "Emission.idsLiderarVehicleTypeMotorcycle.1",
        message: error?.message,
      });
    }
  };

  const getIdsLiderarForTrucks = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.idsLiderarVehicleTypeTruck },
        },
      });

      if (data) {
        setIdsLiderarForTrucks(() =>
          data.setting_value.replace(/ /g, "").split(",")
        );
      }
    } catch (error: any) {
      messageError({
        context: "Emission.idsLiderarVehicleTypeTruck.1",
        message: error?.message,
      });
    }
  };

  const getFuelTypes = async (
    selectedVehicle?: IbrandModelByBrandModelYear
  ) => {
    let fuel_type_available: string[] | null = null;

    if (
      selectedVehicle &&
      (formType === EnumsValues.formType.directEmission ||
        formType === EnumsValues.formType.renovation)
    ) {
      const defaultFuelTypeIdLiderar: string | null | undefined =
        selectedVehicle.fuel_type_default_id_liderar;

      switch (defaultFuelTypeIdLiderar) {
        case EnumsValues.FuelTypeIdLiderar.NAFTA:
          fuel_type_available = [
            EnumsValues.FuelTypeIdLiderar.GNC,
            EnumsValues.FuelTypeIdLiderar.NAFTA,
          ];
          break;
        case "":
        case null:
        case undefined:
          fuel_type_available = [EnumsValues.FuelTypeIdLiderar.NAFTA];
          break;
        default:
          fuel_type_available = [defaultFuelTypeIdLiderar];
          break;
      }
    }

    try {
      const data: IFuel[] = await customRequest({
        query: Query.fuel_types,
        variables: {
          filter: {
            activo_web: true,
            id_liderar: fuel_type_available,
          },
        },
      });
      setFuelTypes(data);
    } catch (error) {
      messageError({
        context: "getFuelTypes",
        message: "Error al obtener tipo de combustibles",
      });
    }
  };

  const fuelOptions = () =>
    fuelTypes.length !== 0 &&
    fuelTypes.map((item: IFuel) => (
      <Option value={item.id_liderar} key={item.id_liderar}>
        {camelCase(item.description)}
      </Option>
    ));

  const getBodyworkTypes = async (
    selectedVehicleIdLiderar?: string,
    selectedVehicleTypeIdLiderar?: string
  ) => {
    let bodywork_type_available: string[] | null = [];

    if (
      selectedVehicleIdLiderar &&
      selectedVehicleTypeIdLiderar &&
      (formType === EnumsValues.formType.directEmission ||
        formType === EnumsValues.formType.renovation)
    ) {
      const isTruck = idsLiderarForTrucks.includes(
        selectedVehicleTypeIdLiderar
      );

      if (isTruck || selectedVehicleTypeIdLiderar === "") {
        bodywork_type_available = null;
      } else {
        bodywork_type_available = [selectedVehicleIdLiderar];
      }
    }

    try {
      const data: IBodyWork[] = await customRequest({
        query: Query.bodywork_types,
        variables: {
          filter: {
            activo_web: true,
            id_liderar: bodywork_type_available?.length
              ? bodywork_type_available
              : undefined,
          },
        },
      });
      setBodyworkTypes(data);
    } catch (error) {
      messageError({
        context: "bodywork_types",
        message: "Error al obtener tipos de carrocería",
      });
    }
  };

  const bodyWorkOptions = () =>
    bodyworkTypes.length !== 0 &&
    bodyworkTypes.map((item) => (
      <Option value={item.id_liderar} key={item.id_liderar}>
        {camelCase(item.description)}
      </Option>
    ));

  const getGendersTypes = async () => {
    try {
      const data: IGender[] = await customRequest({
        query: Query.genders,
        variables: {
          filter: {
            activo_web: true,
          },
        },
      });
      setGendersTypes(data);
    } catch (error) {
      messageError({
        context: "getGenders",
        message: "Error al obtener géneros",
      });
    }
  };

  const genderOptions = () =>
    gendersTypes.length !== 0 &&
    gendersTypes.map((item: IGender) => (
      <Option value={item.id_liderar} key={item.id}>
        {camelCase(item.description)}
      </Option>
    ));

  const getPaymentsTypes = async () => {
    try {
      const data: ICreditCardTypes[] = await customRequest({
        query: Query.credit_cards,
        variables: {
          filter: {
            activo_web: true,
          },
        },
      });
      setPaymentTypes(data);
    } catch (error) {
      messageError({
        context: "credit_cards",
        message: "Error al obtener tipos de pago",
      });
    }
  };

  const paymentOptions = () =>
    paymentTypes.length !== 0 &&
    paymentTypes.map((item: ICreditCardTypes) => (
      <Option value={item.id_liderar} key={item.id_liderar}>
        {item.description === "CBU"
          ? item.description.toUpperCase()
          : camelCase(item.description)}
      </Option>
    ));

  const getNationalities = async () => {
    try {
      const data: INationality[] = await customRequest({
        query: Query.nationalities,
        variables: {
          filter: {
            activo_web: true,
          },
        },
      });
      setNationalities(data);
    } catch (error) {
      messageError({
        context: "getNationalities",
        message: "Error al obtener listado de nacionalidades",
      });
    }
  };
  const nationalityOptions = () =>
    nationalities.length !== 0 &&
    nationalities.map((item: INationality) => (
      <Option value={item.id} key={item.id}>
        {camelCase(item.gentile)}
      </Option>
    ));

  const getProvinces = async () => {
    try {
      const response: IProvince[] = await customRequest({
        query: Query.provinces,
      });
      setProvinces(response);
    } catch (error) {
      messageError({
        context: "getProvinces",
        message: "Error al obtener las provincias",
      });
    }
  };
  const provinceOptions = () =>
    provinces.length !== 0 &&
    provinces.map((item: IProvince) => (
      <Option value={item.id} key={item.id_liderar}>
        {camelCase(item.description)}
      </Option>
    ));

  const provinceOptionsId = () =>
    provinces.length !== 0 &&
    provinces.map((item: IProvince) => (
      <Option value={item.id_liderar} key={item.id}>
        {camelCase(item.description)}
      </Option>
    ));

  const getCoveragePeriods = async (formTypeValue: string) => {
    try {
      const response: ICoveragePeriod[] = await customRequest({
        query: Query.coverage_periods,
        variables: {
          filter: {
            activo_web: true,
            user_id:
              formTypeValue === EnumsValues.formType.directEmission ||
              formTypeValue === EnumsValues.formType.renovation
                ? user?.id
                : undefined,
          },
          orderBy: { field: "description", direction: "asc" },
        },
      });
      setCoveragePeriods(response);
    } catch (error) {
      messageError({
        context: "getCoveragePeriods",
        message: "Error al obtener los períodos de cobertura",
      });
    }
  };

  const coveragePeriedOptions = () =>
    coveragePeriods.length !== 0 &&
    coveragePeriods.map((item) => (
      <Option value={item.id_liderar} key={item.id_liderar}>
        {camelCase(item.description)}
      </Option>
    ));

  const getCoverageTypes = useCallback(
    async (soloRC?: boolean) => {
      try {
        const soloRCFilter =
          formType === EnumsValues.formType.directEmission ||
          formType === EnumsValues.formType.renovation;

        const response: IcoverageTypeSelect[] = await customRequest({
          query: Query.coverageTypes,
          variables: {
            coverage: renovationData
              ? renovationData.policy?.vehiculo[0].cobertura_codigo.slice(-2)
              : undefined,
            soloRC: soloRCFilter ? soloRC : undefined,
          },
        });
        setCoverageType(response);
      } catch (error) {
        messageError({
          context: "getCoverages",
          message: "Error al obtener los tipos de cobertura",
        });
      }
    },
    [renovationData, formType]
  );

  const coverageTypeOptions = () =>
    coverageType.length !== 0 &&
    coverageType.map((item, index) => (
      <Option value={item.id_liderar} key={index}>
        {item.description.toLocaleUpperCase()}
      </Option>
    ));

  const getAdjustmentRates = async () => {
    try {
      const response: IAjustmentRates[] = await customRequest({
        query: Query.ajustment_rates,
      });
      setAdjustmentRates(response);
    } catch (error) {
      messageError({
        context: "getAdjustmentRates",
        message: "Error al obtener las tasas de ajuste",
      });
    }
  };

  const adjustmentRateOptions = () =>
    adjustmentRates.length !== 0 &&
    adjustmentRates.map((item) => (
      <Option value={item.id_liderar} key={item.id_liderar}>
        {item.description.toLocaleUpperCase()}
      </Option>
    ));

  useEffect(() => {
    getMinBornTime();
    getGendersTypes();
    getPaymentsTypes();
    getNationalities();
    getIdsLiderarForMotorcycles();
    getIdsLiderarForTrucks();
    getFiscalConditions();
    getProvinces();
    getCoverageTypes();
    getAdjustmentRates();
  }, []);

  useEffect(() => {
    if (formType) {
      getCoveragePeriods(formType);
    }
  }, [formType]);

  useEffect(() => {
    if (renovationData) {
      getCoverageTypes();
    }
  }, [renovationData]);

  return {
    fuelTypes,
    fuelOptions,
    bodyworkTypes,
    gendersTypes,
    genderOptions,
    paymentTypes,
    paymentOptions,
    nationalities,
    nationalityOptions,
    minBornTime,
    idsLiderarForMotorcycles,
    fiscalConditions,
    fiscalConditionOptions,
    provinces,
    provinceOptions,
    provinceOptionsId,
    bodyWorkOptions,
    coveragePeriedOptions,
    coveragePeriods,
    coverageTypeOptions,
    adjustmentRateOptions,
    adjustmentRates,
    getFuelTypes,
    getBodyworkTypes,
    getCoverageTypes,
  };
};
export default useSelectInput;
