import { Button, Col, Descriptions, Modal, Row } from "antd";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";

import { IDebtSummaryQueueRequests } from "../../../services/DebtSummary";
import { EnumsValues, MomentJS } from "../../../shared";
import {
  DEFAULT_FORMAT_DATE,
  FORMAT_DATE_TIME_1,
} from "../../../shared/MomentJS";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";
import "./style.less";

interface IModalSeeMoreProps {
  isModalOpenAndData: {
    isOpen: boolean;
    data: IDebtSummaryQueueRequests | undefined;
  };
  setIsModalOpenAndData: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      data: IDebtSummaryQueueRequests | undefined;
    }>
  >;
}

const ModalSeeMore = (props: IModalSeeMoreProps): JSX.Element => {
  const { isModalOpenAndData, setIsModalOpenAndData } = props;
  const { data } = isModalOpenAndData;
  const {
    id,
    request_id,
    FECHA_CORTE,
    Pedido,
    producer,
    liderar_message,
    status_queue_requests,
  } = data!;

  return (
    <Modal
      title="Solicitud premios a rendir"
      className="modal-collection"
      visible={isModalOpenAndData.isOpen}
      onCancel={() => setIsModalOpenAndData({ isOpen: false, data: undefined })}
      footer={[
        <ButtonLiderar
          key={0}
          color="white"
          buttonName="Volver"
          onClick={() =>
            setIsModalOpenAndData({ isOpen: false, data: undefined })
          }
          className="go-back-button"
        />,
        <Button
          key={1}
          disabled={
            !(
              status_queue_requests.status ===
                EnumsValues.StatusQueueRequestName.Available ||
              status_queue_requests.status ===
                EnumsValues.StatusQueueRequestName.Finalized
            )
          }
          className="see-data-button"
          type="primary"
        >
          <Link
            to={{
              pathname: `/app/requests/debt/${request_id}`,
              state: id,
            }}
          >
            Ver datos
          </Link>
        </Button>,
      ]}
    >
      <Row>
        <Col span={24}>
          <Descriptions column={1}>
            <Descriptions.Item label="ID">{request_id}</Descriptions.Item>
            <Descriptions.Item label="Productor">{`${producer.id_liderar} - ${producer.nombre}`}</Descriptions.Item>
            <Descriptions.Item label="Fecha hasta">
              {MomentJS.specificMoment(
                { date: FECHA_CORTE },
                DEFAULT_FORMAT_DATE
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Fecha de solicitud">
              {moment(Pedido).format(FORMAT_DATE_TIME_1) || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Estado">
              {status_queue_requests.status}
            </Descriptions.Item>
            <Descriptions.Item label="Mensaje">
              {liderar_message || "-"}
            </Descriptions.Item>
          </Descriptions>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalSeeMore;
