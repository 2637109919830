import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import ms from "ms";
import { CustomMessage } from "../CustomMessage";
import GraphqlService from "../../services/graphql/GraphqlService";
import { EnumsValues } from "../EnumsValues";
import { IAppSetting } from "../../services/AppSetting";

const useAgeVerification = () => {
  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();
  const [isAdult, setIsAdult] = useState<boolean>(false);
  const [minBornTime, setMinBornTime] = useState<IAppSetting>();
  const [clientAgeInYears, setclientAgeInYears] = useState<number>(0);

  const getMinBornTime = useCallback(async () => {
    await customRequest({
      query: Query.getAppSettingByKey,
      variables: {
        input: { key: EnumsValues.SettingKeys.MinBornEmissionTime },
      },
    })
      .then((data: IAppSetting) => {
        setMinBornTime(() => data);
      })
      .catch((error: any) => {
        messageError({
          context: "getMinBornTime",
          message: error?.message,
        });
      });
  }, []);

  useEffect(() => {
    getMinBornTime();
  }, []);

  const ageVerification = useCallback(
    (birthDate: string | undefined | null) => {
      if (birthDate) {
        const clientBirthDate = moment(birthDate, "DD/MM/YYYY").valueOf();
        const todayDate = Date.now();
        const minClientAge = ms(minBornTime?.setting_value || "18y");
        const clientAge = todayDate - clientBirthDate;
        setclientAgeInYears(clientAge / 1000 / 60 / 60 / 24 / 365);
        const ageDifference =
          (clientAge - minClientAge) / 1000 / 60 / 60 / 24 / 365;
        ageDifference >= 0 ? setIsAdult(true) : setIsAdult(false);
      } else {
        setIsAdult(true);
      }
    },
    [minBornTime]
  );

  return { ageVerification, isAdult, clientAgeInYears, minBornTime };
};

export default useAgeVerification;
