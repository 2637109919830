export const BRAND_MODEL = `
query brandModelByBrand($brand: String!) {
  brandModelByBrand(brand:$brand) {
    __typename
    ... on BrandModel {
      id
      brand
      model
      id_liderar
      valid_years_array
      amount_insured_array
      fuel_type_default_id_liderar
      bodywork_type_default_id_liderar
      vehicle_type_array
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const BRAND_MODEL_BY_BRAND_LIST = `
query brandModelByBrandList($brand: String!) {
  brandModelByBrandList(brand: $brand) {
    __typename
    ... on Model {
      array_model
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;

export const YEARS_BY_BRAND_AND_MODEL = `
query yearsByBrandAndModel($brand: String!,$model:String!) {
  yearsByBrandAndModel(brand: $brand,model:$model) {
    __typename
    ... on  Year{
            array_year
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const BRAND_MODEL_BY_BRAND_MODEL_YEAR = `
query brandModelByBrandModelYear(
  $brand: String!
  $model: String!
  $year: String!
) {
  brandModelByBrandModelYear(brand: $brand, model: $model, year: $year) {
    __typename
    ... on BrandModel {
      id
      id_liderar
      brand
      model
      valid_years_list
      valid_years_array
      valid_years_array_by_brand
      amount_insured_list
      amount_insured_array
      activo_web
      fuel_type_default_id_liderar
      bodywork_type_default_id_liderar
      vehicle_type_list
      vehicle_type_array
      vehicle_type_description
      brand_and_model_list
      brand_and_model_array
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
