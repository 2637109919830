export const COVERAGES = `
query coverages(
  $orderBy: CoverageOrderInput
  $filter: FilterCoverageInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  coverages(
    skip: $skip
    take: $take
    orderBy: $orderBy
    filter: $filter
    searchText: $searchText
  ) {
    __typename
    ... on Coverage {
      id
      description
      id_liderar
      soloRC
      descrip_web
      activo_web
      description_long
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const COVERAGE_TYPES = `
query coverageTypes($coverage: String,$soloRC:Boolean ) {
  coverageTypes(coverage: $coverage,soloRC:$soloRC) {
    __typename
    ... on  CoverageType{
      id_liderar
      description
      ranking
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
