import { RouteConfig, RouteConfigComponentProps } from "react-router-config";

import DefaultRedirect from "../components/DefaultRedirect/DefaultRedirect";
import Home from "../components/Home/Home";
import LoginForm from "../components/Login/Login";
import Perfil from "../components/perfil/Perfil";
import RegisteredLayout from "../layouts/registered";
import AppSetting from "../components/AppSetting/AppSetting";
import { EmptyLayout } from "../layouts/emptyLayout";
import LoginLayout from "../layouts/login/LoginLayout";
import ResetPassword from "../components/ResetPassword";
import SetPassword from "../components/SetPassword";
import BusquedaPolizas from "../components/BusquedaPolizas/BusquedaPolizas";
import Quotation from "../components/Quotation/Quotation";
import CoverageDetails from "../components/Quotation/CoverageDetails/CoverageDetails";
import { PolizaLayout } from "../components/Quotation/PolizaLayout";
import Collection from "../components/Collection/Collection";
import CollectionPrizeToBePaid from "../components/Collection/CollectionPrizeToBePaid/CollectionPrizeToBePaid";
import QuotationList from "../components/QuotationList/QuotationList";
import Emission from "../components/Emission/Emission";
import FirstPasswordSet from "../components/firstPasswordSet/index";
import Role from "../components/Role/Role";
import Permission from "../components/Permission/Permission";
import { EnumsValues } from "../shared";
import User from "../components/User/User";
import PolizaDownloadsPage from "../components/Downloads/Poliza/PolizaDownloadsPage";
import CertificadoCoberturaDownloadsPage from "../components/Downloads/CertificadoCobertura/CertificadoCoberturaDownloadsPage";
import Sync from "../components/Sync/Sync";
import PublicationsDownloadPage from "../components/Downloads/Publications/PublicationsDownloadsPage";
import Publication from "../components/Publication/Publications";
import RenovationFormPage from "../components/RenovationFormPage/page/RenovationFormPage";
import RenovationList from "../components/RenovationList/RenovationList";
import RenewedQuotesList from "../components/RenewedQuotesList/RenewedQuotesList";
import EmissionList from "../components/EmissionList/EmissionList";
import Books from "../components/Books/Books";
import EmissionListHistorical from "../components/EmissionListHistorical/EmissionListHistorical";
import { useCheckAuthority } from "../shared/CustomHooks";
import { MaintenancePage } from "../pages/Maintenance/MaintenancePage";

export interface IRouteConfig extends RouteConfig {
  authority?: string;
  routes?: IRouteConfig[];
  name?: string;
  icon?: React.ReactNode;
  path: string;
  hideInMenu?: boolean;
}

const routes: IRouteConfig[] = [
  {
    path: "/",
    exact: true,
    component: DefaultRedirect,
  },
  {
    path: "/app",
    component: RegisteredLayout,
    routes: [
      {
        path: "/app/home",
        exact: true,
        component: Home,
        name: "Inicio",
      },
      {
        path: "/app/policy",
        component: PolizaLayout,
        authority: EnumsValues.Functions.PolizaMenuRead,
        name: "Poliza",
        routes: [
          {
            path: "/app/policy/search",
            exact: true,
            component: BusquedaPolizas,
            name: "Busqueda y consulta",
            authority: EnumsValues.Functions.SearchPoliciesMenuRead,
          },
          {
            path: "/app/policy/quotation",
            exact: true,
            component: Quotation,
            name: "Cotizar",
            authority: EnumsValues.Functions.QuotationMenuRead,
          },
          {
            path: "/app/policy/direct-emission",
            exact: true,
            component: RenovationFormPage,
            name: "Emision directa",
            authority: EnumsValues.Functions.EmisionMenuRead,
            hideInMenu: false,
          },
          {
            path: "/app/policy/direct-emission/:id",
            exact: true,
            component: RenovationFormPage,
            name: "Emision directa",
            authority: EnumsValues.Functions.EmisionMenuRead,
            hideInMenu: true,
          },
          {
            path: "/app/policy/quotation-list",
            exact: true,
            component: QuotationList,
            name: "Listado de cotizaciones",
            authority: EnumsValues.Functions.QuotationListMenuRead,
          },
          {
            path: "/app/policy/emission-list",
            exact: true,
            component: EmissionList,
            name: "Listado de emisiones",
            authority: EnumsValues.Functions.EmisionMenuRead,
            hideInMenu: false,
          },
          {
            path: "/app/policy/emission-list-historical",
            exact: true,
            component: EmissionListHistorical,
            name: "Listado de emisiones historico",
            authority: EnumsValues.Functions.EmissionHistoricalRead,
            hideInMenu: false,
          },
          {
            path: "/app/policy/renovation",
            component: EmptyLayout,
            name: "Renovaciones",
            authority: EnumsValues.Functions.QuotationListMenuRead,
            routes: [
              {
                path: "/app/policy/renovation/policies-to-renewed-list",
                exact: true,
                component: RenovationList,
                name: "Listado de pólizas a renovar",
                authority: EnumsValues.Functions.QuotationListMenuRead,
                hideInMenu: false,
              },
              {
                path: "/app/policy/renovation/renewed-quotes-list",
                exact: true,
                component: RenewedQuotesList,
                name: "Listado de renovaciones cotizadas",
                authority: EnumsValues.Functions.QuotationListMenuRead,
                hideInMenu: false,
              },
              {
                path: "/app/policy/renovation/renewed-quotes-list/:id",
                exact: true,
                component: RenovationFormPage,
                authority: EnumsValues.Functions.QuotationListMenuRead,
                name: "Renovar",
                hideInMenu: true,
              },
              {
                path: "/app/policy/renovation/policies-to-renewed-list/:id",
                exact: true,
                component: RenovationFormPage,
                authority: EnumsValues.Functions.QuotationListMenuRead,
                name: "Renovar",
                hideInMenu: true,
              },
            ],
          },
          {
            path: "/app/policy/quotation-details",
            exact: true,
            component: CoverageDetails,
            name: "Detalles",
            authority: EnumsValues.Functions.CoverageDetailsMenuRead,
            hideInMenu: true,
          },
          {
            path: "/app/policy/emission",
            exact: true,
            component: Emission,
            name: "Emision",
            authority: EnumsValues.Functions.EmisionMenuRead,
            hideInMenu: true,
          },
          {
            path: "/app/policy/emission/quotationid/:id",
            exact: true,
            component: Emission,
            name: "Emision",
            authority: EnumsValues.Functions.EmisionMenuRead,
            hideInMenu: true,
          },
          {
            path: "/",
            component: DefaultRedirect,
            hideInMenu: true,
          },
        ],
      },
      {
        path: "/app/requests",
        name: "Solicitudes",
        component: EmptyLayout,
        authority: EnumsValues.Functions.CollectionMenuRead,
        routes: [
          {
            path: "/app/requests/debt",
            component: Collection,
            name: "Premios a rendir",
            authority: EnumsValues.Functions.CollectionMenuRead,
            exact: true,
          },
          {
            path: "/app/requests/emission",
            component: (routeProps: RouteConfigComponentProps): JSX.Element =>
              useCheckAuthority(routeProps) || (
                <Books
                  bookType="emission"
                  title="Libro de emisiones"
                  createTitle="Solicitud libro emisión"
                  contextName="EmissionsBook"
                />
              ),
            name: "Libro de emisiones",
            authority: EnumsValues.Functions.CollectionMenuRead,
            exact: true,
          },
          {
            path: "/app/requests/collection",
            component: (routeProps: RouteConfigComponentProps): JSX.Element =>
              useCheckAuthority(routeProps) || (
                <Books
                  bookType="collection"
                  title="Libro de cobranzas"
                  createTitle="Solicitud libro cobranzas"
                  contextName="CollectionBook"
                />
              ),
            name: "Libro de Cobranzas",
            authority: EnumsValues.Functions.CollectionMenuRead,
            exact: true,
          },
          {
            path: "/app/requests/debt/:id",
            exact: true,
            component: CollectionPrizeToBePaid,
            name: "Premios a rendir",
            authority: EnumsValues.Functions.CollectionMenuRead,
            hideInMenu: true,
          },
          {
            path: "/",
            component: DefaultRedirect,
            hideInMenu: true,
          },
        ],
      },
      {
        path: "/app/perfil/:id",
        component: Perfil,
        name: "Perfil",
        authority: "SinPermisoTest",
      },

      {
        path: "/app/administration",
        name: "Administración",
        component: EmptyLayout,
        authority: EnumsValues.Functions.AdministrationMenuRead,
        routes: [
          {
            path: "/app/administration/users",
            exact: true,
            component: User,
            authority: EnumsValues.Functions.Users,
            name: "Usuarios",
          },
          {
            path: "/app/administration/role",
            exact: true,
            component: Role,
            authority: EnumsValues.Functions.Roles,
            name: "Roles",
          },
          {
            path: "/app/administration/sync",
            exact: true,
            component: Sync,
            authority: EnumsValues.Functions.Sync,
            name: "Sincronizar",
          },
          {
            path: "/app/administration/permission",
            exact: true,
            component: Permission,
            authority: EnumsValues.Functions.Permissions,
            name: "Permisos",
            hideInMenu: true,
          },
          {
            path: "/app/administration/publication",
            exact: true,
            component: Publication,
            name: "Publicaciones",
            authority: EnumsValues.Functions.PublicationsRead,
            hideInMenu: false,
          },
          {
            path: "/",
            component: DefaultRedirect,
            hideInMenu: true,
          },
        ],
      },
      {
        path: "/app/downloads",
        name: "Descargas",
        component: EmptyLayout,
        authority: EnumsValues.Functions.DownloadsMenuRead,
        routes: [
          {
            path: "/app/downloads/policy",
            exact: true,
            component: PolizaDownloadsPage,
            name: "Polizas",
            authority: EnumsValues.Functions.ImpressionsQueueRequestRead,
          },
          {
            path: "/app/downloads/coverage-certificate",
            exact: true,
            component: CertificadoCoberturaDownloadsPage,
            name: "Certificados de Cobertura",
            authority: EnumsValues.Functions.ImpressionsQueueRequestRead,
          },
          {
            path: "/app/downloads/publications",
            component: EmptyLayout,
            authority: EnumsValues.Functions.PublicationsRead,
            name: "Publicaciones",
            routes: [
              {
                path: "/app/downloads/publications/circulars",
                exact: true,
                component: (
                  routeProps: RouteConfigComponentProps
                ): JSX.Element =>
                  useCheckAuthority(routeProps) || (
                    <PublicationsDownloadPage
                      publicationType="circulars"
                      title="Circulares"
                    />
                  ),
                authority: EnumsValues.Functions.PublicationsRead,
                name: "Circulares",
              },
              {
                path: "/app/downloads/publications/forms",
                exact: true,
                component: (
                  routeProps: RouteConfigComponentProps
                ): JSX.Element =>
                  useCheckAuthority(routeProps) || (
                    <PublicationsDownloadPage
                      publicationType="forms"
                      title="Formularios"
                    />
                  ),
                authority: EnumsValues.Functions.PublicationsRead,
                name: "Formularios",
              },
              {
                path: "/app/downloads/publications/manuals",
                exact: true,
                component: (
                  routeProps: RouteConfigComponentProps
                ): JSX.Element =>
                  useCheckAuthority(routeProps) || (
                    <PublicationsDownloadPage
                      publicationType="manuals"
                      title="Manuales"
                    />
                  ),
                authority: EnumsValues.Functions.PublicationsRead,
                name: "Manuales",
              },
              {
                path: "/",
                component: DefaultRedirect,
                hideInMenu: true,
              },
            ],
          },
          {
            path: "/",
            component: DefaultRedirect,
            hideInMenu: true,
          },
        ],
      },
      {
        path: "/app/setting",
        exact: true,
        component: AppSetting,
        authority: EnumsValues.Functions.AppSetting,
        name: "Configuración",
      },
      {
        path: "/",
        component: DefaultRedirect,
        hideInMenu: true,
      },
    ],
  },
  {
    path: "/",
    component: LoginLayout,
    routes: [
      {
        path: "/login",
        exact: true,
        component: LoginForm,
      },
      {
        path: "/resetPassword",
        exact: true,
        component: ResetPassword,
      },
      {
        path: "/setPassword",
        exact: true,
        component: SetPassword,
      },
      {
        path: "/password",
        exact: true,
        component: FirstPasswordSet,
        name: "Cambiar Contraseña",
        hideInMenu: true,
      },
      {
        path: "/maintenance",
        exact: true,
        component: MaintenancePage,
        name: "En mantenimiento",
        hideInMenu: true,
      },
      {
        path: "/",
        component: DefaultRedirect,
      },
    ],
  },
];

export default routes;
