import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  ToolOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Divider, Input, Modal, Select, Tooltip } from "antd";
import { useCallback, useContext, useRef, useState, useEffect } from "react";
import { SorterResult } from "antd/lib/table/interface";
import { RouteConfigComponentProps } from "react-router-config";
import { isPlatform } from "@ionic/react";

import { SaveForm } from "../ABM";
import { ExportableColumn } from "../../shared/Exporter";
import GraphqlService from "../../services/graphql/GraphqlService";
import ProTable, { ActionType } from "@ant-design/pro-table";
import ModalConfigureMultiple from "../ModalConfigureMultiple/Index";
import { IRole } from "../../services/role";
import { IPermission } from "../../services/permission";
import CustomFilter from "../CustomFilter/CustomFilter";
import {
  ABM,
  Authorization,
  CustomMessage,
  ContextApp,
  EnumsValues,
  Tools,
} from "../../shared";
import { useCheckAuthority } from "../../shared/CustomHooks";
import { ParamsType } from "@ant-design/pro-provider";

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ["name", "role_id"];
const LIST_SORTER = ["name"];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = "Formulario de roles";
const TITLE_CREATE_FORM = "Crear rol";
const TITLE_UPDATE_FORM = "Editar rol";

export default function Role(
  routeProps: RouteConfigComponentProps
): JSX.Element {
  // props

  // states
  const [searchText, setSearchText] = useState("");
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [sorter, setSorter] = useState<string>("");
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [managePermissionsModalVisible, setManagePermissionsModalVisible] =
    useState<boolean>(false);
  const [dataManagePermissions, setDataManagePermissions] = useState<IRole>({
    id: 0,
    name: "",
    is_system_role: false,
  });
  const [dataRolePermission, setDataRolePermission] = useState<IPermission[]>(
    []
  );
  const [rolesCombo, setRolesCombo] = useState<IRole[]>([]);
  const [modalConfigureMultipleLoading, setModalConfigureMultipleLoading] =
    useState<boolean>(false);
  const [dataPermissions, setDataPermissions] = useState<IPermission[]>([]);
  // services and hooks
  const { Query, Mutation, customRequest } = GraphqlService();
  const {
    messageError,
    messageDeleteSuccess,
    messageDeleting,
    messageCreating,
    messageCreateSuccess,
    messageCreateError,
    messageUpdating,
    messageUpdateSuccess,
    messageUpdateError,
  } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const reloadRoleCombo = useRef<boolean>(true);

  // contexts
  const { functions } = useContext(ContextApp);

  // methods

  useEffect(() => {
    customRequest({
      query: Query.permissions,
    })
      .then((data: IPermission[]) => {
        setDataPermissions(data);
        return {
          current: 1,
          data: data,
          pageSize: "1",
          success: true,
          total: data.length,
        };
      })
      .catch(() => {
        return {
          current: 1,
          data: [],
          pageSize: "1",
          success: true,
          total: 0,
        };
      });
  }, []);

  useEffect(() => {
    customRequest({
      query: Query.roles,
    })
      .then((data: IRole[]) => {
        setRolesCombo(() => data);
      })
      .catch(() => {
        //intentional
      });
  }, []);

  const getRolePermission = (id_role: number) => {
    setModalConfigureMultipleLoading(() => true);
    customRequest({
      query: Query.permissions,
      variables: { filter: { role_id: id_role } },
    })
      .then((data: IPermission[]) => {
        setDataRolePermission(() => data);
        return {
          current: 1,
          data: data,
          pageSize: "1",
          success: true,
          total: data.length,
        };
      })
      .catch(() => {
        return {
          current: 1,
          data: [],
          pageSize: "1",
          success: true,
          total: 0,
        };
      })
      .finally(() => {
        setModalConfigureMultipleLoading(() => false);
      });
  };

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    const search: any = ABM.valuesResult(params);

    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element]) {
          if (!variables.current.filter) {
            variables.current.filter = {};
          }
          variables.current.filter[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search._sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search._sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    return customRequest({
      query: Query.roles,
      variables: variables.current,
    })
      .then((data: IRole[]) => {
        setDataTable(data);
        return {
          current: 1,
          data: data,
          pageSize: "1",
          success: true,
          total: data.length,
        };
      })
      .catch(() => {
        return {
          current: 1,
          data: [],
          pageSize: "1",
          success: true,
          total: 0,
        };
      });
  };

  const createRole = (value: any) => {
    setFormLoading(() => true);
    messageCreating({
      context: "TableRole.createRole.1",
      message: "Rol",
    });
    customRequest({
      mutation: Mutation.createRole,
      variables: {
        input: value,
      },
    })
      .then(() => {
        setCreateModalVisible(false);
        messageCreateSuccess({
          context: "TableRole.createRole.2",
        });
        if (actionRef.current) {
          actionRef.current.reload();
        }
      })
      .catch((error: any) => {
        if (error.status_code && error.message) {
          return messageError({
            context: "TableRole.createRole.3",
            message: error.message,
          });
        }
        messageCreateError({ context: "TableRole.createRole.3" });
      })
      .finally(() => {
        setFormLoading(() => false);
      });
  };

  const updateRolePermissions = async (
    id: number,
    addPermissions: number[],
    deletePermissions: number[]
  ) => {
    if (!addPermissions.length && !deletePermissions.length) {
      return;
    }

    messageUpdating({
      context: "TableRole.updateRole.1",
      message: "rol",
    });
    return customRequest({
      mutation: Mutation.upsertRolePermission,
      variables: {
        input: {
          role_id: id,
          addPermissionIds: addPermissions,
          deletePermissionIds: deletePermissions,
        },
      },
    })
      .then(() => {
        messageUpdateSuccess({
          context: "TableRole.updateRolePermissions.2",
        });
        setManagePermissionsModalVisible(false);
        if (actionRef.current?.reset) {
          reloadRoleCombo.current = true;
          actionRef.current.reset();
        }
      })
      .catch((error: any) => {
        if (error.status_code && error.message) {
          return messageError({
            context: "TableRole.updateRolePermissions.3",
            message: error.message,
          });
        }
        messageUpdateError({ context: "TableRole.updateRolePermissions.3" });
      })
      .finally(() => {
        setFormLoading(() => false);
      });
  };

  const updateRole = (value: any) => {
    setFormLoading(() => true);
    messageUpdating({
      context: "TableRole.updateRole.1",
      message: "rol",
    });
    customRequest({
      mutation: Mutation.updateRole,
      variables: { input: { ...value, id: parseInt(editForm.id) } },
    })
      .then(() => {
        messageUpdateSuccess({
          context: "TableRole.updateRole.2",
        });
        handleUpdateModalVisible(false);
        if (actionRef.current) {
          actionRef.current.reload();
        }
      })
      .catch((error: any) => {
        if (error.status_code && error.message) {
          return messageError({
            context: "TableRole.updateRole.3",
            message: error.message,
          });
        }
        messageUpdateError({ context: "TableRole.updateRole.3" });
      })
      .finally(() => {
        setFormLoading(() => false);
      });
  };

  const removeConfirmedRole = (value: any) => {
    messageDeleting({
      context: "Role.removeConfirmedRole.1",
      message: "rol",
    });
    customRequest({
      mutation: Mutation.deleteRole,
      variables: {
        id: parseInt(value.id),
      },
    })
      .then(() => {
        messageDeleteSuccess({
          context: "Role.removeConfirmedRole.2",
        });
        if (actionRef.current) {
          actionRef.current.reload();
        }
      })
      .catch((error: any) => {
        messageError({
          context: "Role.removeConfirmedRole.3",
          message: error.message,
        });
      });
  };

  const removeRole = (value: any) => {
    Modal.confirm({
      content: (
        <>
          <div>¿Seguro que desea eliminar el rol {value.name}?</div>
        </>
      ),
      cancelText: "Cancelar",
      okText: "Aceptar",
      onOk: () => {
        removeConfirmedRole(value);
      },
    });
  };

  const columns = useCallback(
    (): ExportableColumn<IRole>[] => [
      {
        export: false,
        dataIndex: "id",
        title: "id",
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: "name",
        title: "Rol",
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El rol es obligatorio",
            },
          ],
        },
        render: (_: any, record: { name: any }) => record.name || "-",
        renderFormItem: () => <Input placeholder="Ingrese rol" minLength={4} />,
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: "role_id",
        title: "Role",
        valueType: "select",
        renderFormItem: () => (
          <Select
            options={rolesCombo.map((rol) => ({
              label: rol.name,
              value: rol.id,
            }))}
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            dropdownMatchSelectWidth={false}
            placeholder="Seleccione rol..."
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        title: "Op.",
        dataIndex: "option",
        valueType: "option",
        fixed: "right",
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleUpdate
            ) &&
              !record.is_system_role && (
                <Tooltip
                  key="edit_rol_tooltip"
                  trigger={isPlatform("desktop") ? "hover" : " focus"}
                  title="Modificar rol"
                >
                  <EditOutlined
                    className="pointer"
                    onClick={() => {
                      handleUpdateModalVisible(true);
                      setEditFormValues(record);
                    }}
                  />
                </Tooltip>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleUpdate
            ) && (
              <>
                {!record.is_system_role && <Divider type="vertical" />}
                <Tooltip
                  key="manage_permissions_tooltip"
                  trigger={isPlatform("desktop") ? "hover" : " focus"}
                  title={
                    record.is_system_role
                      ? "Ver permisos"
                      : "Administrar permisos"
                  }
                >
                  {record.is_system_role ? (
                    <SearchOutlined
                      className="pointer textAlignCenter"
                      onClick={() => {
                        setManagePermissionsModalVisible(true);
                        setDataManagePermissions(() => record);
                        getRolePermission(record.id);
                      }}
                    />
                  ) : (
                    <ToolOutlined
                      className="pointer"
                      onClick={() => {
                        setManagePermissionsModalVisible(true);
                        setDataManagePermissions(() => record);
                        getRolePermission(record.id);
                      }}
                    />
                  )}
                </Tooltip>
              </>
            )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleDelete
            ) &&
              !record.is_system_role && (
                <>
                  <Divider type="vertical" />
                  <Tooltip
                    key="remove_rol_tooltip"
                    trigger={isPlatform("desktop") ? "hover" : " focus"}
                    title="Eliminar rol"
                  >
                    <DeleteOutlined
                      className="pointer"
                      onClick={() => {
                        removeRole(record);
                      }}
                    />
                  </Tooltip>
                </>
              )}
          </>
        ),
      },
    ],
    [dataTable]
  );

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>{TITLE_PRO_TABLE}</h1>
        <CustomFilter header="Filtrar por" />
        <ProTable<IRole>
          defaultSize="small"
          actionRef={actionRef}
          className="RolePageTable"
          rowKey="id"
          search={{
            resetText: "Limpiar",
            searchText: "Buscar",
            collapsed: false,
            collapseRender: false,
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IRole>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          onReset={() => {
            setSearchText("");
          }}
          params={{
            sorter,
          }}
          toolBarRender={() => [
            <>
              {Authorization.security(
                functions,
                EnumsValues.Functions.RoleCreate
              ) && (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                >
                  <PlusOutlined /> Nuevo
                </Button>
              )}
            </>,
          ]}
          /**
           * @description este metodo debe poder ejecutar siempre la consulta al backend
           */
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns()}
        />
        <SaveForm
          loading={formLoading}
          title={TITLE_CREATE_FORM}
          onCancel={() => {
            setCreateModalVisible(false);
          }}
          modalVisible={createModalVisible}
          onOk={async (value) => createRole(value)}
          columns={columns()}
        />
        {editForm && (
          <SaveForm
            loading={formLoading}
            title={TITLE_UPDATE_FORM}
            modalVisible={updateModalVisible}
            values={editForm}
            columns={columns()}
            onOk={async (value) => updateRole(value)}
            onCancel={() => {
              handleUpdateModalVisible(false);
              setEditFormValues({});
            }}
          />
        )}
        {Authorization.security(
          functions,
          EnumsValues.Functions.RolePermissionUpdate
        ) && (
          <ModalConfigureMultiple<IRole>
            renderLabel={(entity) => entity.name}
            checks={dataPermissions}
            data={[
              dataManagePermissions.id,
              dataRolePermission?.map((dataRole) => dataRole.id) || [],
            ]}
            onCancel={() => {
              setManagePermissionsModalVisible(false);
            }}
            onOk={(id_role, add_permissions, delete_permissions) => {
              return updateRolePermissions(
                id_role,
                add_permissions,
                delete_permissions
              );
            }}
            readOnly={dataManagePermissions.is_system_role}
            modalVisible={managePermissionsModalVisible}
            titleModal="Administrar permisos"
            loading={modalConfigureMultipleLoading}
          />
        )}
      </>
    )
  );
}
