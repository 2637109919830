import {
  Form,
  Row,
  Col,
  Select,
  List,
  Button,
  Space,
  FormInstance,
  Checkbox,
} from "antd";
import VirtualList from "rc-virtual-list";
import { createElement, useState } from "react";

import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import ButtonLiderar from "../../../ButtonLiderar/ButtonLiderar";

import "./styles.less";

export interface IFormWithProducerListProps {
  labelForInput: string;
  labelForList: string;
  onFinish: (formValues: any, form: FormInstance) => void;
  listToRenderOnInput: any[];
  producersSelected: any[];
  handleDelete: (item: any) => void;
  renderListFormat: (value: any) => React.ReactNode;
  seeProducers?: (value: string) => void;
  height?: number;
  hide?: boolean;
  isLoading?: boolean;
  showCheckBox?: boolean;
  onCheckBoxChange?: (e: any) => void;
}

const FormWithProducerList = (
  props: IFormWithProducerListProps
): JSX.Element => {
  const {
    labelForInput,
    labelForList,
    onFinish,
    listToRenderOnInput,
    producersSelected,
    handleDelete,
    renderListFormat,
    height = 200,
    hide,
    seeProducers,
    isLoading,
    showCheckBox,
    onCheckBoxChange,
  } = props;

  const [openSelect, setOpenSelect] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [form] = Form.useForm();

  return (
    <>
      <Row gutter={40}>
        <Col span={10}>
          <Form
            name="set-producer-form"
            className="set-producer-form"
            layout="vertical"
            form={form}
            onFinish={(value) => onFinish(value, form)}
          >
            {showCheckBox && (
              <Form.Item
                label={labelForInput}
                preserve={false}
                required
                style={{ marginBottom: 0 }}
              >
                <Checkbox onChange={onCheckBoxChange}>
                  Solo seguro directo
                </Checkbox>
              </Form.Item>
            )}
            <Form.Item
              label={!showCheckBox ? labelForInput : undefined}
              name="productor_search"
              rules={[{ required: true, message: `Por favor, ingrese valor` }]}
            >
              <Select
                showSearch
                allowClear
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                disabled={hide}
                placeholder={showPlaceholder ? "Seleccione" : ""}
                open={openSelect}
                onSearch={(value) => {
                  setShowPlaceholder(false);
                  if (value.length > 2) {
                    setOpenSelect(true);
                  } else {
                    setOpenSelect(false);
                  }
                }}
                onBlur={() => {
                  setOpenSelect(false);
                  setShowPlaceholder(true);
                }}
                onClear={() => setShowPlaceholder(true)}
                onSelect={() => {
                  setOpenSelect(false);
                  setShowPlaceholder(true);
                }}
                onInputKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    form.submit();
                  }
                }}
                options={listToRenderOnInput}
                optionFilterProp="label"
              />
            </Form.Item>
            <div className="buttons">
              <ButtonLiderar
                color="violet"
                buttonName="Guardar"
                className="form-button search-button"
                htmlType="submit"
                disabled={hide}
              />
            </div>
          </Form>
        </Col>
        <Col span={14}>
          <List
            size="small"
            header={<b>{labelForList}</b>}
            bordered
            style={{ marginBottom: "10px" }}
          >
            <VirtualList data={producersSelected} height={height} itemKey="id">
              {(item) => {
                return (
                  <List.Item
                    key={item}
                    actions={[
                      seeProducers && (
                        <Button onClick={() => seeProducers(item)} key={0}>
                          <Space>
                            {createElement(EyeOutlined)}
                            Ver
                          </Space>
                        </Button>
                      ),
                      <Button
                        key={1}
                        onClick={() => handleDelete(item)}
                        loading={isLoading}
                      >
                        <Space>
                          {createElement(DeleteOutlined)}
                          Quitar
                        </Space>
                      </Button>,
                    ]}
                  >
                    {renderListFormat(item)}
                  </List.Item>
                );
              }}
            </VirtualList>
          </List>
        </Col>
      </Row>
    </>
  );
};

export default FormWithProducerList;
