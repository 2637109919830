import "./style.less";

interface Props {
  validity: boolean;
  title?: string;
}

const TagValidity: React.FC<Props> = ({ title }) => {
  return (
    <div className="TagValidity">
      <span>{title && title} </span>
      {/*<Tag color={validity ? "#87d068" : "#f50"}>*/}
      {/*  {validity ? "VIGENTE" : "NO VIGENTE"}*/}
      {/*</Tag>*/}
      {/*TODO: se oculta el tag de vigencia hasta que Liderar defina una*/}
      {/*estrategia de validación de vigencia de las polizas*/}
    </div>
  );
};

export default TagValidity;
