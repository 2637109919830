import { useEffect, useState } from "react";
import { Collapse, Row, Col, Form, Upload, Button } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";

import { CustomMessage } from "../../../shared";
import Pictures from "./Pictures";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { IUploadedFiles } from "../interfaces/IValuesEmisionRenovationForm";

const { Panel } = Collapse;

interface IVehiclePictureProps {
  patent: string | undefined;
  uploadedFiles: IUploadedFiles | undefined;
  setUploadedFiles: React.Dispatch<
    React.SetStateAction<IUploadedFiles | undefined>
  >;
}

export interface IImageData {
  file: string;
  mimetype: string;
}

export const VehiclePicture = (props: IVehiclePictureProps): JSX.Element => {
  const { patent, uploadedFiles, setUploadedFiles } = props;

  const fileTypes = ["image/png", "image/jpeg"];
  const fileTypesNames = ["png", "jpeg", "jpg", "jfif", "pjpeg", "pjp"];
  const [hasImage, setHasImage] = useState<boolean>(false);

  const { messageError } = CustomMessage();
  const { customRequest, Query } = GraphqlService();
  const [imagesIds, setImagesIds] = useState<number[]>([]);

  useEffect(() => {
    if (patent) {
      getImagesByLicensePlate(String(patent));
    }
  }, [patent]);

  const getImagesByLicensePlate = async (licensePlate: string) => {
    try {
      const response = await customRequest({
        query: Query.filesIdsByLicensePlate,
        variables: {
          licensePlate,
        },
      });
      if (response.ids.length > 0) {
        setHasImage(true);
        setImagesIds(response.ids);
      } else {
        setHasImage(false);
      }
    } catch (error: any) {
      messageError({
        context: "VehiclePicture.getImagesByLicensePlate.1",
        message: error?.message,
      });
    }
  };

  const handleBeforeUpload = (file: RcFile) => {
    if (!fileTypes.includes(file.type)) {
      messageError({
        context: "VehiclePicture.beforeUpload.1",
        message: "El formato del archivo es incorrecto",
      });
      return Upload.LIST_IGNORE;
    }
    if (uploadedFiles && Object.keys(uploadedFiles).length >= 4) {
      messageError({
        context: "VehiclePicture.beforeUpload.2",
        message: "Máximo de archivos alcanzado",
      });
      return Upload.LIST_IGNORE;
    }
    uploadedFiles
      ? setUploadedFiles(() => ({
          ...uploadedFiles,
          [file.uid]: file,
        }))
      : setUploadedFiles(() => ({ [file.uid]: file }));
    return fileTypes.includes(file.type) ? false : Upload.LIST_IGNORE;
  };

  const handleOnRemove = (file: UploadFile<any>) => {
    if (uploadedFiles) {
      delete uploadedFiles[file.uid];
    }
    const newUploadedFiles = { ...uploadedFiles };
    setUploadedFiles(() => newUploadedFiles);
  };

  return (
    <Collapse
      className="collapse"
      defaultActiveKey={["1"]}
      collapsible={"disabled"}
    >
      <Panel header="Fotos del vehículo" key="1">
        <Row gutter={40}>
          <Col>
            {!hasImage && (
              <Form.Item name="upload" valuePropName="imageList" noStyle>
                <Upload
                  listType="picture"
                  type="select"
                  maxCount={4}
                  beforeUpload={handleBeforeUpload}
                  onRemove={handleOnRemove}
                >
                  <Button className="button-upload">
                    Subir archivo
                    <CloudUploadOutlined />
                  </Button>
                  <div className="texto-upload">
                    Formatos válidos: {fileTypesNames.join(", ")}
                  </div>
                </Upload>
              </Form.Item>
            )}
            {hasImage && <Pictures imagesIds={imagesIds} />}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default VehiclePicture;
