import { RouteConfigComponentProps } from "react-router-config";
import { EditOutlined } from "@ant-design/icons";
import { Input, Tooltip } from "antd";
import { useState, useRef, useContext } from "react";
import ProTable, { ProColumns, ActionType } from "@ant-design/pro-table";
import { SorterResult } from "antd/es/table/interface";
import ms from "ms";

import { IAppSetting } from "../../services/AppSetting";
import GraphqlService from "../../services/graphql/GraphqlService";
import { SaveForm } from "../ABM";
import {
  ABM,
  ContextApp,
  Authorization,
  EnumsValues,
  CustomMessage,
  Tools,
} from "../../shared";
import { useCheckAuthority } from "../../shared/CustomHooks";
import { ParamsType } from "@ant-design/pro-provider";

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  "screen_name",
  "setting_value",
  "description",
  "setting_name",
];
const LIST_SORTER = ["screen_name"];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_PRO_TABLE = "Formulario de configuración";
const TITLE_UPDATE_FORM = "Editar configuración";

/**
 * @description ABM Setting
 */

const AppSetting = (routeProps: RouteConfigComponentProps): JSX.Element => {
  const {
    messageUpdateError,
    messageUpdating,
    messageUpdateSuccess,
    messageModalInfo,
  } = CustomMessage();
  const { Query, Mutation, customRequest } = GraphqlService();
  const { functions, setMaintenanceMode } = useContext(ContextApp);
  const [dataTable, setDataTable] = useState<IAppSetting[]>([]);
  const [sorter, setSorter] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const actionRef = useRef<ActionType>();
  const [formLoading, setFormLoading] = useState(false);
  const variables = useRef<any>({});

  const checkForInitFilter = () => {
    if (!variables.current.filter) {
      variables.current.filter = {};
    }
  };

  const checkForInitOrderBy = () => {
    if (!variables.current.orderBy) {
      variables.current.orderBy = {};
    }
  };

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    const search: any = ABM.valuesResult(params);

    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    LIST_FILTER.forEach((element) => {
      try {
        if (search[element]) {
          checkForInitFilter();
          variables.current.filter[element] = search[element];
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    LIST_SORTER.forEach((element) => {
      try {
        if (search._sorter[element]) {
          checkForInitOrderBy();
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search._sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });

    return customRequest({
      query: Query.getAppSettings,
      variables: variables.current,
    })
      .then((data: IAppSetting[]) => {
        setDataTable(data);
        return {
          current: 1,
          data,
          pageSize: "1",
          success: true,
          total: data.length,
        };
      })
      .catch(() => {
        return {
          current: 1,
          data: [],
          pageSize: "1",
          success: true,
          total: 0,
        };
      });
  };

  /**
   * @description update setting
   */
  const updateSetting = (value: any) => {
    setFormLoading(() => true);
    messageUpdating({
      context: "AppSetting.updateSetting.1",
      message: "configuración",
    });
    if (
      editForm.setting_name ===
        EnumsValues.SettingKeys.SecurityTokenExpiresin ||
      editForm.setting_name ===
        EnumsValues.SettingKeys.SecurityRefreshTokenExpiresin
    ) {
      if (ms(value.setting_value) === undefined) {
        setFormLoading(() => false);
        return messageModalInfo({
          context: "AppSetting.updateSetting.1",
          message: "Valor de tiempo inválido. Ej: 15m, 10h, 1d.",
        });
      }
      if (
        editForm.setting_name === EnumsValues.SettingKeys.SecurityTokenExpiresin
      ) {
        const refreshTokenExpiresin = dataTable.find(
          (setting) =>
            setting.setting_name ===
            EnumsValues.SettingKeys.SecurityRefreshTokenExpiresin
        )?.setting_value;
        const tokenExpiresin = value.setting_value;
        if (
          refreshTokenExpiresin &&
          Number(ms(tokenExpiresin)) > Number(ms(refreshTokenExpiresin))
        ) {
          setFormLoading(() => false);
          return messageModalInfo({
            context: "AppSetting.updateSetting.2",
            message:
              "El ExpiresIn del Token debe ser menor al del RefreshToken",
          });
        }
      } else if (
        editForm.setting_name ===
        EnumsValues.SettingKeys.SecurityRefreshTokenExpiresin
      ) {
        const tokenExpiresin = dataTable.find(
          (setting) =>
            setting.setting_name ===
            EnumsValues.SettingKeys.SecurityTokenExpiresin
        )?.setting_value;
        const refreshTokenExpiresin: string = value.setting_value;
        if (tokenExpiresin && ms(tokenExpiresin) > ms(refreshTokenExpiresin)) {
          setFormLoading(() => false);
          return messageModalInfo({
            context: "AppSetting.updateSetting.2",
            message:
              "El ExpiresIn del Token debe ser menor al del RefreshToken",
          });
        }
      }
    }
    customRequest({
      mutation: Mutation.updateAppSetting,
      variables: { input: { ...value, id: editForm.id } },
    })
      .then(() => {
        messageUpdateSuccess({ context: "AppSetting.updateSetting.2" });
        handleUpdateModalVisible(false);
        if (actionRef.current) {
          actionRef.current.reload();
        }
      })
      .catch(() => {
        messageUpdateError({ context: "AppSetting.updateSetting.3" });
      })
      .finally(() => {
        setFormLoading(() => false);
        if (editForm.setting_name === EnumsValues.SettingKeys.MaintenanceMode) {
          setMaintenanceMode(value.setting_value === "true");
        }
      });
  };

  const columns: ProColumns<IAppSetting>[] = [
    {
      title: "ID",
      dataIndex: "id",
      hideInTable: true,
      hideInSearch: true,
      hideInForm: true,
      fixed: "left",
      width: 1,
    },
    {
      title: "Nombre pantalla",
      dataIndex: "screen_name",
      sorter: true,
      defaultSortOrder: "ascend",
      hideInSearch: false,
      hideInForm: true,
      render: (_: any, record: IAppSetting) => (
        <Tooltip placement="top" title={record?.screen_name || ""}>
          <div style={{ width: "15vw" }} className="ResumeText">
            {record?.screen_name || ""}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Valor",
      dataIndex: "setting_value",
      hideInSearch: true,
      render: (_: any, record: IAppSetting) => (
        <Tooltip placement="top" title={record?.setting_value || ""}>
          <div style={{ width: "10vw" }} className="ResumeText">
            {record?.setting_value || ""}
          </div>
        </Tooltip>
      ),
      renderFormItem: () => (
        <Input placeholder="Ingrese valor..." maxLength={200} />
      ),
    },
    {
      title: "Descripción",
      dataIndex: "description",
      hideInSearch: true,
      hideInForm: false,
      render: (_: any, record: IAppSetting) => record?.description || "",
      renderFormItem: () => (
        <Input placeholder="Ingrese descripción..." maxLength={200} />
      ),
    },
    {
      title: "Clave",
      dataIndex: "setting_name",
      hideInSearch: true,
      hideInForm: true,
      render: (_: any, record: IAppSetting) => (
        <Tooltip placement="top" title={record?.setting_name || ""}>
          <div style={{ width: "15vw" }} className="ResumeText">
            {record?.setting_name || ""}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Op.",
      dataIndex: "option",
      valueType: "option",
      fixed: "right",
      width: 50,
      render: (_, record: IAppSetting) => (
        <>
          {Authorization.security(
            functions,
            EnumsValues.Functions.AppSettingUpdate
          ) && (
            <Tooltip key="edit_setting" title="Modificar configuración">
              <EditOutlined
                hidden={record.is_readonly}
                className="pointer"
                onClick={() => {
                  handleUpdateModalVisible(true);
                  setEditFormValues(() => record);
                }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
  ];

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>{TITLE_PRO_TABLE}</h1>
        <ProTable<IAppSetting>
          defaultSize="small"
          style={{ marginTop: "5vh" }}
          actionRef={actionRef}
          rowKey="id"
          search={false}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IAppSetting>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          onReset={() => {
            setSearchText("");
          }}
          params={{
            sorter,
          }}
          /**
           * @description este metodo debe poder ejecutar siempre la consulta al backend
           */ request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns}
        />

        {editForm ? (
          <SaveForm
            loading={formLoading}
            title={TITLE_UPDATE_FORM}
            modalVisible={updateModalVisible}
            values={editForm}
            columns={columns}
            onOk={async (value) => updateSetting(value)}
            onCancel={() => {
              handleUpdateModalVisible(false);
              setEditFormValues({});
            }}
          />
        ) : null}
      </>
    )
  );
};

export default AppSetting;
