export const UPSERT_ROLE_PERMISSION = `
  mutation upsertRolePermission($input:  UpsertRolePermissionInput!) {
    upsertRolePermission(input: $input) {
      __typename
      ... on RolePermission {
        role_id
        permission_id
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;
