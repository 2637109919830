import { createContext } from "react";
import { IConfigApp, IConfig, IEnvironment } from "../services/environment";
import { IUser } from "../services/user";
import { IRole } from "../services/role";
import { Language } from "../services/Language";
const configApp: any = {};
const environment: any = {};
const config: any = {};
const roles: any[] = [];
const functions: string[] = [];

interface IContextApp {
  configApp: IConfigApp;
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  user?: IUser;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  setLoadingMessage: React.Dispatch<React.SetStateAction<string>>;
  loadingMessage: string;
  setShowLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showLoading: boolean;
  environment: IEnvironment;
  setEnvironment: React.Dispatch<
    React.SetStateAction<IEnvironment | undefined>
  >;
  config: IConfig;
  setConfig: React.Dispatch<React.SetStateAction<IConfig | undefined>>;
  roles: IRole[];
  setRoles: React.Dispatch<React.SetStateAction<IRole[]>>;
  functions: string[];
  setFunctions: React.Dispatch<React.SetStateAction<string[]>>;
  dataLoaded: boolean;
  setDataLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  checkAuth: () => Promise<void>;
  setAuth: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  mainMenuCollapsed: boolean;
  setMainMenuCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  menuBreakpointCollapsed: boolean;
  setMenuBreakpointCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  sessionExpired: boolean;
  setSessionExpired: React.Dispatch<React.SetStateAction<boolean>>;
  languages: Language[];
  maintenanceMode: boolean;
  setMaintenanceMode: React.Dispatch<React.SetStateAction<boolean>>;
}

const context: IContextApp = {
  configApp,
  setUser: () => {
    // Intentional
  },
  user: undefined,
  setLoading: () => {
    // Intentional
  },
  loading: false,
  setLoadingMessage: () => {
    // Intentional
  },
  loadingMessage: "",
  setShowLoading: () => {
    // Intentional
  },
  showLoading: true,
  environment,
  setEnvironment: () => {
    // Intentional
  },
  config,
  setConfig: () => {
    // Intentional
  },
  setRoles: () => {
    // Intentional
  },
  setFunctions: () => {
    // Intentional
  },
  roles,
  functions,
  dataLoaded: false,
  setDataLoaded: () => {
    // Intentional
  },
  checkAuth: async () => {
    // Intentional
  },
  setAuth: () => {
    // Intentional
  },
  mainMenuCollapsed: false,
  setMainMenuCollapsed: () => {
    // Intentional
  },
  menuBreakpointCollapsed: false,
  setMenuBreakpointCollapsed: () => {
    // Intentional
  },
  sessionExpired: false,
  setSessionExpired: () => {
    // Intentional
  },
  languages: [],
  maintenanceMode: false,
  setMaintenanceMode: () => {
    // Intentional
  },
};

export const ContextApp = createContext(context);
