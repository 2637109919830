import { startCase, toLower } from "lodash";
import { IUser } from "../services/user";
import { EnumsValues } from "./EnumsValues";

const { DNI, CUIT } = EnumsValues.InsuredTypeID;

export function camelCase(str: string): string {
  return startCase(toLower(str));
}

export const zeroPad = (num: number, places: number): string =>
  String(num).padStart(places, "0");

export const formatPolicyNumber = (number: string): string => {
  if (number?.length === 11) {
    return `${number?.substring(0, 2)} - ${number?.substring(2, 11)}`;
  } else {
    return `${number?.substring(0, 2)} - ${number?.substring(
      2,
      11
    )} - ${number?.substring(11)}`;
  }
};

export const formatStringToMoney = (input: string): string => {
  if (input == null) {
    return "-";
  }
  return `$ ${Intl.NumberFormat("es-AR").format(Number(input))}`;
};

export const moneyToTwoDecimals = (
  input: string,
  separator: string
): string => {
  const commaSeparated = input.split(separator);
  if (commaSeparated.length > 2) return input;
  if (commaSeparated.length === 1) return input + ",00";

  const beforeComma = commaSeparated[0];
  const afterComma = commaSeparated[1];

  if (afterComma.length === 2) return input;
  if (afterComma.length > 2)
    return beforeComma + separator + afterComma.slice(0, 2);
  if (afterComma.length === 1)
    return beforeComma + separator + afterComma + "0";
  if (afterComma.length === 0)
    return beforeComma + separator + afterComma + "00";
  return input;
};

export const formatMoney = (input: string): string => {
  if (input == null) return "-";
  if (input === "") return "0";
  const value =
    input.substring(0, input.length - 2) +
    "." +
    input.substring(input.length - 2, input.length);

  return formatStringToMoney(value);
};

export const handleID = (
  tipo_doc: string,
  dniValue: string,
  cuitValue: string
): {
  tipo: any;
  value: string;
} => {
  let value = "";
  let tipo: any;

  if (tipo_doc === DNI && dniValue !== "") {
    tipo = DNI;
    value = dniValue;
  } else if (tipo_doc === CUIT && cuitValue !== "") {
    tipo = CUIT;
    value = cuitValue;
  } else if (tipo_doc === "") {
    if (dniValue !== "") {
      tipo = DNI;
      value = dniValue;
    } else {
      tipo = CUIT;
      value = cuitValue;
    }
  }

  return { tipo, value };
};

export const isCreditCardValid = (
  value: string,
  creditCardType: number | string
): boolean => {
  const inputValue = value.toString();
  switch (creditCardType) {
    case EnumsValues.PaymentType.AMERICAN_EXPRESS:
    case EnumsValues.PaymentTypeIdLiderar.AMERICAN_EXPRESS:
      return /^3[47]\d{13}$/.test(inputValue);

    case EnumsValues.PaymentType.MASTERCARD:
    case EnumsValues.PaymentTypeIdLiderar.MASTERCARD:
      return /^(?:5[1-5]\d{2}|222[1-9]|22[3-9]\d|2[3-6]\d{2}|27[01]\d|2720)\d{12}$/.test(
        inputValue
      );

    case EnumsValues.PaymentType.NARANJA:
    case EnumsValues.PaymentTypeIdLiderar.NARANJA:
      return inputValue.length === 16 || inputValue.length === 15;

    case EnumsValues.PaymentType.VISA:
    case EnumsValues.PaymentTypeIdLiderar.VISA:
      return /^4\d{12}(?:\d{3})?$/.test(inputValue);

    case EnumsValues.PaymentType.CABAL:
    case EnumsValues.PaymentTypeIdLiderar.CABAL:
      return inputValue.length === 16;

    case EnumsValues.PaymentType.CABAL_LIDERARCARD:
    case EnumsValues.PaymentTypeIdLiderar.CABAL_LIDERARCARD:
      return inputValue.length === 16;

    case EnumsValues.PaymentType.VISA_DEBITO:
    case EnumsValues.PaymentTypeIdLiderar.VISA_DEBITO:
      return inputValue.length === 16;

    case EnumsValues.PaymentType.CBU:
    case EnumsValues.PaymentTypeIdLiderar.CBU:
      return inputValue.length === 22;

    default:
      return false;
  }
};

export const addDays = (date: Date, days: number): Date => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const hasPermission = (
  user: IUser,
  permission: string
): boolean | undefined => {
  return user?.user_role?.some((role) =>
    role?.role?.role_permission?.some(
      (role_permission) => role_permission.permission?.code === permission
    )
  );
};

export const getPaginationArgs = (
  pageSize: number,
  currentPage = 1
): { skip: number; take: number } => {
  return {
    skip: pageSize * (currentPage - 1),
    take: pageSize,
  };
};

export const getMaxAmount = (inputNum: number, percent: number): number => {
  return inputNum * (percent / 100) + inputNum;
};

export const getMinAmount = (inputNum: number, percent: number): number => {
  return inputNum - inputNum * (percent / 100);
};

export const policyFormat = (text: string): string => {
  const section = text.slice(0, 2);
  const policy = text.slice(2, -6);
  const endorsement = text.slice(-6);
  return section + "-" + policy + "-" + endorsement;
};

export const parcialTypeImpressions = [
  "Frente",
  "Cuponera",
  "Tarjeta",
  "Mercosur",
];
