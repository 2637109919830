import { Col, Collapse, Form, Input, Row, Select } from "antd";

import { DEFAULT_FORMAT_DATE } from "../../../shared/MomentJS";
import { Datepicker } from "../../DatePicker/DatePicker";
import ms from "ms";
import { IAppSetting } from "../../../services/AppSetting";
import { formNames } from "../utils/formNames";

const { Panel } = Collapse;

interface INaturalPersonProps {
  genderOptions: () => JSX.Element[] | false;
  minBornTime: IAppSetting | undefined;
  nationalityOptions: () => JSX.Element[] | false;
  isRenovation: boolean;
  readOnlyOr: boolean;
}

export const NaturalPersonForm = (props: INaturalPersonProps): JSX.Element => {
  const {
    genderOptions,
    minBornTime,
    nationalityOptions,
    isRenovation,
    readOnlyOr,
  } = props;

  const {
    NATURAL_PERSON_BIRTH_DATE,
    NATURAL_PERSON_BIRTH_PLACE,
    NATURAL_PERSON_GENDER,
    NATURAL_PERSON_NATIONALITY,
    title,
  } = formNames.naturalPerson;

  return (
    <Collapse defaultActiveKey={["1"]} className="collapse">
      <Panel header={title} key="1">
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Seleccione género" }]}
              label={NATURAL_PERSON_GENDER.label}
              name={NATURAL_PERSON_GENDER.name}
            >
              <Select
                placeholder={NATURAL_PERSON_GENDER.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                disabled={isRenovation || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {genderOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Ingrese fecha de nacimiento",
                },
              ]}
              label={NATURAL_PERSON_BIRTH_DATE.label}
              name={NATURAL_PERSON_BIRTH_DATE.name}
            >
              <Datepicker
                placeholder={NATURAL_PERSON_BIRTH_DATE.placeholder}
                format={DEFAULT_FORMAT_DATE}
                allowClear
                getPopupContainer={(trigger) => trigger}
                disabled={isRenovation || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
                defaultDate={
                  new Date(
                    new Date().getTime() -
                      ms(minBornTime?.setting_value || "18y")
                  )
                }
                maxDate={
                  new Date(
                    new Date().getTime() -
                      ms(minBornTime?.setting_value || "18y")
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={NATURAL_PERSON_BIRTH_PLACE.label}
              rules={[
                { required: true, message: "Ingrese lugar de nacimiento" },
              ]}
              name={NATURAL_PERSON_BIRTH_PLACE.name}
            >
              <Input
                placeholder={NATURAL_PERSON_BIRTH_PLACE.placeholder}
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[{ required: true, message: "Seleccione nacionalidad" }]}
              label={NATURAL_PERSON_NATIONALITY.label}
              name={NATURAL_PERSON_NATIONALITY.name}
            >
              <Select
                placeholder={NATURAL_PERSON_NATIONALITY.placeholder}
                showSearch
                allowClear
                disabled={readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                filterOption={(input: string, option: any) => {
                  return (
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
              >
                {nationalityOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default NaturalPersonForm;
