export const QUOTATION = `
mutation quotation($input: QuotationInput!) {
  quotation(input: $input) {
    __typename
    ... on Quotation {
      coverage_code
      total_no_ice
      total_ice
      coverage_detail
      coverage_code_description
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const QUOTATION_RECALCULATE = `
mutation quotationRecalculate($id: Int!) {
  quotationRecalculate(id: $id) {
    __typename
    ... on QuotationRequest {
        quotation {
          id
          request_id
          coverage_id
          total_ice
          total_no_ice
          coverage {
            id_liderar
            description
            soloRC
            descrip_web
            description_long
            activo_web
          }
        }
        }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
