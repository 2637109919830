import ProTable, { ActionType } from "@ant-design/pro-table";
import { DatePicker, Input, Select, Tooltip, Divider, Image } from "antd";
import { SaveForm } from "../ABM";
import { useCallback, useContext, useRef, useState } from "react";
import { ExportableColumn } from "../../shared/Exporter";
import { SorterResult } from "antd/lib/table/interface";
import GraphqlService from "../../services/graphql/GraphqlService";
import {
  ABM,
  CustomMessage,
  ContextApp,
  Tools,
  EnumsValues,
} from "../../shared";
import { ParamsType } from "@ant-design/pro-provider";
import { Datepicker } from "../DatePicker/DatePicker";
import {
  DEFAULT_FORMAT_DATE,
  FORMAT_DATE_TIME_1,
  FORMAT_DATE_TIME_2,
  FORMAT_DATE_TIME_3,
  FORMAT_DATE_TIME_4,
} from "../../shared/MomentJS";
import moment from "moment";
import { IDebtSummaryQueueRequests } from "../../services/DebtSummary";
import "./style.less";
import CustomFilter from "../CustomFilter/CustomFilter";
import ModalSeeMore from "./ModalSeeMore/ModalSeeMore";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import { useHistory } from "react-router";
import { addDays, camelCase, getPaginationArgs } from "../../shared/utils";
import SelectWithFetch from "../utils/SelectWithFetch";
import { IProducer } from "../../services/Producer";
import { StatusQueueRequestArray } from "../../services/StatusQueueRequest";
import { DownloadOutlined } from "@ant-design/icons";
import { IBaseFile } from "../../services/defaultSchema";
import { useCheckAuthority } from "../../shared/CustomHooks";
import { RouteConfigComponentProps } from "react-router-config";
import pdfIcon from "../../assets/pdf-icon.png";
import csvIcon from "../../assets/csv-icon.png";

const { Option } = Select;

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  "id",
  "FECHA_CORTE",
  "COD_PROD",
  "status_id",
  "Pedido",
  "liderar_message",
  "its_range",
];
const LIST_SORTER = [
  "id",
  "request_id",
  "insured",
  "FECHA_CORTE",
  "COD_PROD",
  "status_id",
  "Pedido",
  "username",
];

/**
 * Se configura por cada ABM diferente
 */
const TITLE_CREATE_FORM = "Crear solicitudes de premios a rendir";
const TITLE_UPDATE_FORM = "Editar Solicitudes de premios a rendir";

export default function Collection(
  routeProps: RouteConfigComponentProps
): JSX.Element {
  // props

  // states

  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const [sorter, setSorter] = useState<string>("");
  const [isModalOpenAndData, setIsModalOpenAndData] = useState<{
    isOpen: boolean;
    data: IDebtSummaryQueueRequests | undefined;
  }>({
    isOpen: false,
    data: undefined,
  });
  const [producer, setProducer] = useState<IProducer>();

  // services and hooks
  const { Query, customRequest } = GraphqlService();
  const { messageError, messageSuccess } = CustomMessage();
  const history = useHistory();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // contexts
  const { user } = useContext(ContextApp);

  // methods

  const canDownloadFile = useCallback((record: IDebtSummaryQueueRequests) => {
    return (
      record.status_queue_requests.status ===
      EnumsValues.StatusQueueRequestName.Finalized
    );
  }, []);

  const canDownloadCsvFile = useCallback(
    (record: IDebtSummaryQueueRequests) => {
      return (
        record.status_queue_requests.status ===
          EnumsValues.StatusQueueRequestName.Available ||
        record.status_queue_requests.status ===
          EnumsValues.StatusQueueRequestName.Finalized ||
        record.status_queue_requests.status ===
          EnumsValues.StatusQueueRequestName.Processing
      );
    },
    []
  );

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);
      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            if (element === "FECHA_CORTE") {
              variables.current.filter[element] = moment(
                new Date(search[element])
              ).format(FORMAT_DATE_TIME_4);
            } else if (element === "COD_PROD") {
              variables.current.filter["COD_PROD"] = String(
                producer?.id_liderar
              );
            } else if (element === "Pedido") {
              variables.current.filter[element] = moment(
                new Date(search[element])
              ).format(FORMAT_DATE_TIME_3);
            } else {
              variables.current.filter[element] = search[element];
            }
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const countPromise = customRequest({
        query: Query.debtSummaryQueueRequestsCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.listDebtSummaryQueueRequests,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);

      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const handleProducerSearch = (value: string): Promise<any[]> => {
    return new Promise((resolve) => {
      if (Number.isNaN(value)) {
        return resolve([]);
      }
      if (!value) {
        return resolve([]);
      }
      return customRequest({
        query: Query.producers,
        variables: {
          searchText: value,
          filter: {
            user_id: user?.id,
            activo_web: true,
          },
        },
      })
        .then((data: IProducer[]) => {
          resolve(data);
        })
        .catch(() => {
          messageError({
            context: "handleProducerSearch",
            message: "Error al obtener los productores",
          });
        });
    });
  };

  const columns = useCallback(
    (editMode: boolean): ExportableColumn<IDebtSummaryQueueRequests>[] => [
      {
        export: true,
        dataIndex: "request_id",
        title: "ID",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        defaultSortOrder: "descend",
        align: "left",
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => record.request_id || "-",
      },
      {
        export: true,
        sorter: true,
        dataIndex: "Pedido",
        title: "Fecha de solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "La Fecha es obligatoria",
            },
          ],
        },
        render: (_: any, record) =>
          moment(new Date(record.Pedido)).format(FORMAT_DATE_TIME_1) || "-",
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "its_range",
        title: "Fecha solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        renderFormItem: () => (
          <DatePicker.RangePicker
            name="its_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={["Fecha desde", "Fecha hasta"]}
            allowEmpty={[true, true]}
            className="renderFormItem"
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "username",
        title: "Solicitante",
        sorter: true,
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record.createdBy.username || "-",
      },
      {
        export: true,
        sorter: true,
        title: "Fecha Hasta",
        dataIndex: "FECHA_CORTE",
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "La Fecha es obligatoria",
            },
          ],
        },
        render: (_: any, record) =>
          moment(new Date(record.FECHA_CORTE)).format(DEFAULT_FORMAT_DATE) ||
          "-",
        renderFormItem: () => (
          <Datepicker
            showToday
            placeholder="Seleccione fecha"
            format={FORMAT_DATE_TIME_4}
            allowClear={!editMode}
            minDate={new Date()}
            maxDate={addDays(new Date(), 35)}
          />
        ),
        align: "left",
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        sorter: true,
        title: "Fecha Hasta",
        dataIndex: "FECHA_CORTE",
        type: ABM.TYPE_COLUMN.DATE,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "La Fecha es obligatoria",
            },
          ],
        },
        renderFormItem: () => {
          return (
            <Datepicker
              showToday
              placeholder="Seleccione fecha"
              format={FORMAT_DATE_TIME_4}
              allowClear={!editMode}
            />
          );
        },
        align: "left",
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: "COD_PROD",
        title: "Productor",
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El productor es obligatorio",
            },
          ],
        },
        render: (_: any, record) =>
          `${record.producer.id_liderar} ${record.producer.nombre}` || "-",
        renderFormItem: () => (
          <SelectWithFetch
            lengthValueToFetch={3}
            onSelect={(value: IProducer) => {
              setProducer(value);
            }}
            query={handleProducerSearch}
            placeholder={"Ingrese nombre/número de productor"}
            optionRender={(item: IProducer) => (
              <Option value={item.id_liderar} key={item.id_liderar}>
                {item.id_liderar} - {camelCase(item.nombre)}
              </Option>
            )}
            onClear={() => {
              setProducer(undefined);
            }}
          />
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: "status_id",
        title: "Estado",
        align: "left",
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
        renderFormItem: () => (
          <Select
            placeholder="Seleccione estado"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            dropdownMatchSelectWidth={false}
            options={StatusQueueRequestArray.map((item) => ({
              value: item.id,
              key: item.id,
              label: item.status,
            }))}
            allowClear
          />
        ),
      },
      {
        export: true,
        dataIndex: "status",
        title: "Estado",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record.status_queue_requests.status || "-",
        sorter: false,
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: "liderar_message",
        title: "Mensaje",
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El mensaje es obligatorio",
            },
          ],
        },
        className: "ellipsisColumn",
        render: (_: any, record) => (
          <Tooltip title={record.liderar_message}>
            <div className="ellipsisColumn">
              <span>{record.liderar_message || "-"}</span>
            </div>
          </Tooltip>
        ),
        renderFormItem: () => (
          <Input placeholder="Ingrese mensaje" minLength={4} />
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "op",
        title: "Op.",
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {canDownloadFile(record) ? (
              <Tooltip title="Descargar archivo">
                <Image
                  preview={false}
                  src={pdfIcon}
                  style={{ width: 30 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDownload(record, "pdf");
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="El PDF de premios a rendir no esta disponible">
                <DownloadOutlined
                  className="disabled-icon"
                  style={{ width: 30 }}
                />
              </Tooltip>
            )}
            <Divider type="vertical" />
            {canDownloadCsvFile(record) ? (
              <Tooltip title="Descargar archivo">
                <Image
                  preview={false}
                  src={csvIcon}
                  style={{ width: 30 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDownload(record, "csv");
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title="El CSV de premios a rendir no esta disponible"
                style={{ width: 30 }}
              >
                <DownloadOutlined className="disabled-icon" />
              </Tooltip>
            )}
          </>
        ),
      },
    ],
    []
  );

  type fileType = "pdf" | "csv";

  const handleDownload = useCallback(
    async (record: IDebtSummaryQueueRequests, type: fileType) => {
      try {
        const result: IBaseFile = await customRequest({
          query: Query.debtSummaryFile,
          variables: {
            id: record.id,
            type,
          },
        });
        if (!result.file) {
          throw new Error("Ocurrió un error al descargar el archivo");
        }

        Tools.downloadFilePDF(result);
      } catch (error: any) {
        console.error(error);
        messageError({
          context: "Collection.handleDownload",
          message: error.message || "Ocurrió un error al descargar el archivo",
        });
      }
    },
    []
  );

  return (
    useCheckAuthority(routeProps) || (
      <div className="Collection">
        <h1>Solicitudes de premios a rendir</h1>
        <CustomFilter header="Filtrar por" />
        <ProTable<IDebtSummaryQueueRequests>
          defaultSize="small"
          className="pro-table-collection"
          onRow={(record: IDebtSummaryQueueRequests) => {
            return {
              onClick: () => {
                setIsModalOpenAndData({ isOpen: true, data: record });
              },
            };
          }}
          actionRef={actionRef}
          rowKey="id"
          search={{
            collapsed: false,
            resetText: "Limpiar búsqueda",
            searchText: "Buscar",
            collapseRender: false,
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult =
              _sorter as SorterResult<IDebtSummaryQueueRequests>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          params={{
            sorter,
          }}
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns(false)}
          pagination={{
            className: "pagination-bar",
          }}
          toolbar={{
            className: "collection-toolbar",
          }}
        />
        {isModalOpenAndData.isOpen && (
          <ModalSeeMore
            isModalOpenAndData={isModalOpenAndData}
            setIsModalOpenAndData={setIsModalOpenAndData}
          />
        )}
        <div className="buttons">
          <ButtonLiderar
            color="white"
            buttonName="Volver"
            onClick={() => history.goBack()}
          />
          <ButtonLiderar
            color="violet"
            buttonName="Nueva solicitud"
            onClick={() => setCreateModalVisible(true)}
          />
        </div>
        <SaveForm
          title={TITLE_CREATE_FORM}
          submitText="Enviar"
          onCancel={() => {
            setCreateModalVisible(false);
          }}
          modalVisible={createModalVisible}
          className="modal-create-collection"
          columns={columns(false)}
          onOk={(formValues) => {
            if (!producer) {
              messageError({
                context: "Collection.SaveForm.onOk.1",
                message: "Seleccione un productor",
              });
              return;
            }
            customRequest({
              query: Query.debtSummaryFromUniverse,
              variables: {
                input: {
                  FECHA_CORTE: moment(new Date(formValues.FECHA_CORTE)).format(
                    FORMAT_DATE_TIME_4
                  ),
                  COD_PROD: producer.id_liderar,
                  TIPO: "001",
                  Pedido: moment(new Date()).format(FORMAT_DATE_TIME_2),
                },
              },
            })
              .then(() => {
                messageSuccess({
                  context: "Collection.SaveForm.onOk.2",
                  message: "Solicitud creada correctamente",
                  time: 2000,
                });
                setCreateModalVisible(() => false);
              })
              .catch((error: any) => {
                messageError({
                  context: "Collection.SaveForm.onOk.3",
                  message: error.message,
                });
              })
              .finally(() => {
                actionRef.current?.reload();
              });
          }}
        />
        {editForm && (
          <SaveForm
            title={TITLE_UPDATE_FORM}
            modalVisible={updateModalVisible}
            values={{
              ...editForm,
              start_date: moment(new Date(editForm?.start_date)),
              end_date: moment(new Date(editForm?.end_date)),
            }}
            columns={columns(true)}
            onCancel={() => {
              handleUpdateModalVisible(false);
              setEditFormValues({});
            }}
          />
        )}
      </div>
    )
  );
}
