export const UPSERT_ENDORSEMENT_TYPE = `
mutation upsertEndorsementType($key: String!) {
  upsertEndorsementType(key: $key){
    __typename
    ... on SyncBoolean{
      status
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
