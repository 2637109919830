import { Card, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { IAseguradoSearch } from "../../../../services/Asegurado";
import "./style.less";

interface IAseguradoItemProps {
  asegurado: IAseguradoSearch;
  handleClickAsegurado: (id_asegurado: string, nombre: string) => void;
}

const AseguradoItem = (props: IAseguradoItemProps): JSX.Element => {
  const { handleClickAsegurado } = props;

  const {
    nombre_aseg,
    id_asegurado,
    localidad,
    nro_documento,
    provincia,
    cuit,
    cod_tipo_documento,
  }: IAseguradoSearch = props.asegurado;

  return (
    <div
      className="AseguradoItem"
      onClick={() => handleClickAsegurado(id_asegurado, nombre_aseg)}
    >
      <Card>
        <Col span="4" className="asegurado-icon">
          <UserOutlined />
        </Col>
        <Row className="asegurado-row">
          <Col span="4" className="second-col">
            Asegurado:
          </Col>
          <Col span="8" className="aseg-value">
            {nombre_aseg}
          </Col>
          <Col span="4" className="second-col">
            Provincia:
          </Col>
          <Col span="8" className="aseg-value">
            {provincia}
          </Col>
        </Row>
        <Row className="asegurado-row">
          <Col span="4" className="second-col">
            Nº asegurado:
          </Col>
          <Col span="8" className="aseg-value">
            {id_asegurado}
          </Col>
          <Col span="4" className="second-col">
            Localidad:
          </Col>
          <Col span="8" className="aseg-value">
            {localidad}
          </Col>
          <Col span="4" className="second-col">
            {cod_tipo_documento || "DNI"}:
          </Col>
          <Col span="8" className="aseg-value">
            {Number(nro_documento) || "-"}
          </Col>
          <Col span="4" className="second-col">
            CUIT:
          </Col>
          <Col span="8" className="aseg-value">
            {Number(cuit) || "-"}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default AseguradoItem;
