export const FISCAL_CONDITIONS = `
query fiscal_conditions(
  $filter: FilterFiscalConditionInput
  $orderBy: FiscalConditionOrderInput
  $skip: Int
  $take: Int
) {
  fiscal_conditions(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take){
    __typename
    ... on  FiscalCondition{
      id
      description
      id_liderar
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
