export const SET_PRODUCER = `
mutation setProducer($input: UserProducerInput!) {
  setProducer(input: $input) {
    __typename
    ... on SuccessfulResponse {
      status
      status_message
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;

export const DELETE_USER_PRODUCER_CUIT = `
mutation deleteUserProducerCuit($input: UserProducerCuitInput!) {
  deleteUserProducerCuit(input: $input) {
    __typename
    ... on UserProducerCuit {
      id
      user_id
      producer_cuit
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_USER_PRODUCER_CUIT = `
mutation createUserProducerCuit($input: UserProducerCuitInput!) {
  createUserProducerCuit(input: $input) {
    __typename
    ... on UserProducerCuit {
      id
      user_id
      producer_cuit
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_PRODUCERS = `mutation deleteProducers($input: UserProducerInput!) {
  deleteProducers(input: $input) {
    __typename
    ... on SuccessfulResponse {
      status
      status_message
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const CREATE_USER_PRODUCER_GROUP = `
mutation createUserProducerGroup($input: UserProducerGroupInput!) {
  createUserProducerGroup(input: $input) {
    __typename
    ... on UserProducerGroup {
      id
      user_id
      producer_group
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const DELETE_USER_PRODUCER_GROUP = `
mutation deleteUserProducerGroup($input: UserProducerGroupInput!) {
  deleteUserProducerGroup(input: $input) {
    __typename
    ... on UserProducerGroup {
      id
      user_id
      producer_group
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
