import { useContext } from "react";
import { Mutation } from "./mutation/index";
import { Query } from "./query/index";
import { ContextApp, Tools } from "../../shared";
import axios, { AxiosRequestConfig } from "axios";
import { BackendError } from "../../shared/BackendError";

const backendWSUrl: any = process.env.REACT_APP_BACKEND_WS;
console.warn("backendWSUrl");
console.warn(backendWSUrl);
const rmTypenameObj = (obj: any) => {
  let values: any = "{}";
  values = JSON.parse(values);
  Object.keys(obj).forEach((key) => {
    if (key !== "__typename") {
      values[key] = obj[key];
    }
  });
  return values;
};

const checkMaintenanceModeAndRedirect = () => {
  if (window.location.pathname !== "/maintenance") {
    window.location.href = "/maintenance";
  }
};

const parseObj = (obj: any) => {
  const result: any = {};
  Object.keys(obj).forEach((key: any) => {
    const nan: any = Number(obj[key]);
    if (!(nan.toString() === "NaN")) {
      result[key] = Number(obj[key]);
    } else if (obj[key] === "false") {
      result[key] = false;
    } else if (obj[key] === "true") {
      result[key] = true;
    } else {
      result[key] = obj[key];
    }
  });
  return result;
};

const resultQuery = async (data: any): Promise<any | any[]> => {
  return new Promise((resolve, reject) => {
    const result = async (
      responseQuery: any,
      success: (value: any) => void,
      error: (error: any) => void
    ) => {
      try {
        const response: any | any[] = responseQuery;

        if (Array.isArray(response) && !response.length) {
          success(response);
        } else if (!response) {
          success(response);
        } else {
          const typename: any =
            Array.isArray(response) && response.length
              ? response[0]?.__typename
              : response?.__typename;
          if (!typename) {
            error({
              status_code: null,
              message: 'Error: data incorrecta pasado a "resultQuery".',
            });
          } else if (typename && typename === "ResultError") {
            if (response.status_code === 9009) {
              checkMaintenanceModeAndRedirect();
            }
            if (Array.isArray(response) && response.length) {
              error({
                status_code: response[0].status_code,
                message: response[0].message,
              });
            } else {
              error({
                status_code: response.status_code,
                message: response.message,
              });
            }
          } else {
            success(response);
          }
        }
      } catch (err: any) {
        error({
          status: false,
          status_code: null,
          message: err.message,
        });
      }
    };
    result(
      data,
      (success: any) => {
        resolve(success);
      },
      (err: BackendError) => {
        reject(err);
      }
    );
  });
};

/**
 * @description Lista de códigos de error
 */
export const ERROR_CODE = {
  SERVER_ERROR: "SERVER_ERROR",
  AUTHORIZATION_ERROR: "AUTHORIZATION_ERROR",
  AUTHENTICATION_ERROR: "AUTHENTICATION_ERROR",
  GENERAL_ERROR: "GENERAL_ERROR",
};

const GraphqlService = (): any => {
  const { setLoading, setUser, setSessionExpired } = useContext(ContextApp);
  // const { client } = useContext(ContextApollo);

  /**
   * @description Lista de códigos de error
   */
  const resolveResultError = (
    resultError: BackendError,
    callback: () => void
  ) => {
    if (resultError && resultError.status_code) {
      switch (resultError.status_code) {
        case ERROR_CODE.SERVER_ERROR:
          // @TODO: loguer
          callback();
          break;
        case ERROR_CODE.AUTHENTICATION_ERROR:
          Tools.messageModalInfo(resultError.message);
          setUser(undefined);
          break;
        case ERROR_CODE.AUTHORIZATION_ERROR:
          callback();
          break;
        case ERROR_CODE.GENERAL_ERROR:
          // @TODO: loguer
          callback();
          break;
        default:
          // @TODO: loguer
          callback();
          break;
      }
    } else {
      // @TODO: loguer
      callback();
    }
  };

  const customRequest = async (
    options: IRequestOptions,
    isRetry?: boolean
  ): Promise<any | any[] | BackendError> => {
    return new Promise((resolve, reject) => {
      const req = {
        type: "",
        name: "",
      };
      const call: any = {};

      if (options.variables) {
        call.variables = options.variables;
      }

      if (options && options.query) {
        if (!options.cache) {
          call.fetchPolicy = "no-cache";
        }
        req.type = "query";
        req.name = options.query.name;
        call.query = options.query.gql;
      } else if (options && options.mutation) {
        req.type = "mutate";
        req.name = options.mutation.name;
        call.mutation = options.mutation.gql;
      } else {
        reject(new Error("Error customRequest: query and mutation is null."));
      }

      const loading =
        options.loading !== undefined && options.loading === false
          ? false
          : true;

      if (loading) {
        setLoading(() => true);
      }

      const axiosConfig: AxiosRequestConfig = {};
      const token = localStorage.getItem("token");
      if (token) {
        axiosConfig.timeout = 90000;
        axiosConfig.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      axiosConfig.withCredentials = true;
      axios
        .post(
          backendWSUrl,
          {
            variables: options.variables || {},
            operationName: req.name,
            query: options.query?.gql || options.mutation?.gql,
          },
          axiosConfig
        )
        .then(async (res: any) => {
          if (res.data.data) {
            resultQuery(res.data.data[req.name])
              .then((data: any) => {
                resolve(data);
              })
              .catch((err: BackendError) => {
                resolveResultError(err, () => {
                  reject(err);
                });
              })
              .finally(() => {
                if (loading) {
                  setLoading(() => false);
                }
              });
          } else if (res.data.errors) {
            if (res.data.errors[0].status_code === 9009) {
              checkMaintenanceModeAndRedirect();
            }
            if (res.data.errors[0].status_code === 403) {
              throw new BackendError(
                res.data.errors[0].status_code,
                res.data.errors[0].message
              );
            } else if (res.data.errors[0].status_code === 401) {
              if (!isRetry) {
                await customRequest(
                  {
                    mutation: Mutation.refreshToken,
                  },
                  true
                ).then(async (data) => {
                  localStorage.setItem("token", data.accessToken);
                  const retryData = await customRequest(options, true);
                  resolve(retryData);
                });
              } else {
                setSessionExpired(true);
                resolve(null);
              }
            } else {
              throw new BackendError(
                res.data.errors[0]?.status_code,
                res.data.errors[0]?.message
              );
            }
          }
        })
        .catch((err: BackendError) => {
          reject(err);
          if (loading) {
            setLoading(() => false);
          }
        });
    });
  };

  const customFileRequest = async (
    options: Omit<IRequestOptions, "query"> & { mutation: IRequest },
    fileData: IFileData[]
  ): Promise<any | any[] | BackendError> => {
    return new Promise((resolve, reject) => {
      /* Armamos el form data */
      const formData = new FormData();
      const operations = JSON.stringify({
        operationName: options.mutation.name,
        query: options.mutation.gql,
        variables: options.variables,
      });
      formData.append("operations", operations);
      const map: any = {};
      fileData.map((file, index) => {
        map[(index + 1).toString()] = [file.path];
      });

      formData.append("map", JSON.stringify(map));

      fileData.map((file, index) => {
        formData.append((index + 1).toString(), file.file || "null");
      });

      /* Armamos el objeto config para axios */
      const axiosConfig: AxiosRequestConfig = {};
      const token = localStorage.getItem("token");
      if (token) {
        axiosConfig.headers = {
          Authorization: `Bearer ${token}`,
        };
      }

      const loading =
        options.loading !== undefined && options.loading === false
          ? false
          : true;

      if (loading) {
        setLoading(() => true);
      }

      axios
        .post(backendWSUrl, formData, axiosConfig)
        .then((res: any) => {
          resultQuery(res.data.data[options.mutation.name])
            .then((data: any) => {
              resolve(data);
            })
            .catch((err: BackendError) => {
              resolveResultError(err, () => {
                reject(err);
              });
            })
            .finally(() => {
              if (loading) {
                setLoading(() => false);
              }
            });
        })
        .catch((err: BackendError) => {
          console.error(err);
          reject(err);
          if (loading) {
            setLoading(() => false);
          }
        });
    });
  };

  return {
    Query,
    Mutation,
    rmTypenameObj,
    parseObj,
    customRequest,
    customFileRequest,
  };
};

export default GraphqlService;

export interface IFileData {
  file: File | null;
  path: string;
}

export interface IRequest {
  name: string;
  gql: any;
}

export interface IRequestOptions {
  query?: IRequest;
  mutation?: IRequest;
  variables?:
    | {
        input?: Record<string, unknown>;
        orderBy?: Record<string, unknown>;
        skip?: number;
        take?: number;
      }
    | any;
  cache?: boolean;
  loading?: boolean;
}
