import {
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Switch,
  Select,
  FormInstance,
  InputNumber,
} from "antd";
import { useState } from "react";
import { EmissionById } from "../../../services/EmissionById";
import { EnumsValues } from "../../../shared";

import useLocationAndPostalCodes from "../../../shared/hooks/useLocationAndPostalCodes";
import { formNames } from "../utils/formNames";

const { Panel } = Collapse;

interface IPledgeCreditorDataProps {
  form: FormInstance;
  provinceOptions: () => false | JSX.Element[];
  formType: string;
  emission: EmissionById | undefined;
  readOnlyOr: boolean;
}

export const PledgeCreditorDataForm = (
  props: IPledgeCreditorDataProps
): JSX.Element => {
  const { form, provinceOptions, formType, emission, readOnlyOr } = props;

  const {
    PLEDGE_CREDITOR_COMPANY_NAME_OR_SURNAME,
    PLEDGE_CREDITOR_HOME,
    PLEDGE_CREDITOR_HOME_DEPARTMENT,
    PLEDGE_CREDITOR_HOME_FLOOR,
    PLEDGE_CREDITOR_HOME_NUMBER,
    PLEDGE_CREDITOR_LOCATION,
    PLEDGE_CREDITOR_NAME,
    PLEDGE_CREDITOR_POSTAL_CODE,
    PLEDGE_CREDITOR_PROVINCE,
    PLEDGE_CREDITOR_YES_OR_NO,
    title,
  } = formNames.pledgeCreditorData;

  const [provinceIdAcreedorSelected, setProvinceIdAcreedorSelected] =
    useState<number>(0);
  const [selectedlocalidadAcreedor, setSelectedlocalidadAcreedor] =
    useState<string>("");

  const { locationOptions, postalCodeOptions } = useLocationAndPostalCodes({
    selectedlocalidad: selectedlocalidadAcreedor,
    provinceIdSelected: provinceIdAcreedorSelected,
    formItemLocationName: PLEDGE_CREDITOR_LOCATION.name,
    formItemPostalCodeName: PLEDGE_CREDITOR_POSTAL_CODE.name,
    form,
  });

  const [isAcreedorPrendario, setIsAcreedorPrendario] =
    useState<boolean>(false);

  const handleChangeLocationSelectAcreedor = (location: string) => {
    setSelectedlocalidadAcreedor(location);
  };

  const handleChangeProvinceAcreedor = (id: number) => {
    setProvinceIdAcreedorSelected(id);
  };

  return (
    <Collapse className="collapse">
      <Panel header={title} key="1">
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={PLEDGE_CREDITOR_YES_OR_NO.label}
              name={PLEDGE_CREDITOR_YES_OR_NO.name}
            >
              {formType !== EnumsValues.formType.directEmissionReadOnly &&
                formType !== EnumsValues.formType.renowedQuotesListReadOnly &&
                formType !==
                  EnumsValues.formType.directEmissionHistoricalReadOnly && (
                  <Switch onChange={(value) => setIsAcreedorPrendario(value)} />
                )}
              {readOnlyOr && (
                <Switch
                  checked={
                    emission?.pledged_creditor?.business_name_surname !==
                    undefined
                  }
                  disabled={readOnlyOr}
                />
              )}
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Ingrese apellido",
                },
              ]}
              label={PLEDGE_CREDITOR_COMPANY_NAME_OR_SURNAME.label}
              name={PLEDGE_CREDITOR_COMPANY_NAME_OR_SURNAME.name}
            >
              <Input
                type="text"
                placeholder={
                  PLEDGE_CREDITOR_COMPANY_NAME_OR_SURNAME.placeholder
                }
                maxLength={100}
                disabled={!isAcreedorPrendario || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                { required: isAcreedorPrendario, message: "Ingrese nombre" },
              ]}
              label={PLEDGE_CREDITOR_NAME.label}
              name={PLEDGE_CREDITOR_NAME.name}
            >
              <Input
                type="text"
                placeholder={PLEDGE_CREDITOR_NAME.placeholder}
                maxLength={100}
                disabled={!isAcreedorPrendario || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                { required: isAcreedorPrendario, message: "Ingrese domicilio" },
              ]}
              label={PLEDGE_CREDITOR_HOME.label}
              name={PLEDGE_CREDITOR_HOME.name}
            >
              <Input
                type="text"
                placeholder={PLEDGE_CREDITOR_HOME.placeholder}
                maxLength={30}
                disabled={!isAcreedorPrendario || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={PLEDGE_CREDITOR_HOME_NUMBER.label}
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Ingrese número de domicilio",
                },
                {
                  pattern: /^[\d]{0,10}$/,
                  message: "Máximo 10 dígitos",
                },
              ]}
              name={PLEDGE_CREDITOR_HOME_NUMBER.name}
            >
              <InputNumber
                type="number"
                controls={false}
                placeholder={PLEDGE_CREDITOR_HOME_NUMBER.placeholder}
                disabled={!isAcreedorPrendario || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={PLEDGE_CREDITOR_HOME_FLOOR.label}
              name={PLEDGE_CREDITOR_HOME_FLOOR.name}
            >
              <Input
                type="text"
                maxLength={40}
                placeholder={PLEDGE_CREDITOR_HOME_FLOOR.placeholder}
                disabled={!isAcreedorPrendario || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={PLEDGE_CREDITOR_HOME_DEPARTMENT.label}
              name={PLEDGE_CREDITOR_HOME_DEPARTMENT.name}
            >
              <Input
                type="text"
                placeholder={PLEDGE_CREDITOR_HOME_DEPARTMENT.placeholder}
                maxLength={40}
                disabled={!isAcreedorPrendario || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Seleccione provincia",
                },
              ]}
              label={PLEDGE_CREDITOR_PROVINCE.label}
              name={PLEDGE_CREDITOR_PROVINCE.name}
            >
              <Select
                placeholder={PLEDGE_CREDITOR_PROVINCE.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={handleChangeProvinceAcreedor}
                disabled={!isAcreedorPrendario || readOnlyOr}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {provinceOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="start" gutter={40}>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              label={PLEDGE_CREDITOR_LOCATION.label}
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Seleccione localidad",
                },
              ]}
              name={PLEDGE_CREDITOR_LOCATION.name}
            >
              <Select
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                disabled={
                  !isAcreedorPrendario ||
                  form.getFieldsValue([PLEDGE_CREDITOR_PROVINCE.name]) ===
                    undefined ||
                  readOnlyOr
                }
                placeholder={PLEDGE_CREDITOR_LOCATION.placeholder}
                onSelect={handleChangeLocationSelectAcreedor}
                className={readOnlyOr ? "read-only" : undefined}
              >
                {locationOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12} xl={6}>
            <Form.Item
              rules={[
                {
                  required: isAcreedorPrendario,
                  message: "Seleccione código postal",
                },
              ]}
              label={PLEDGE_CREDITOR_POSTAL_CODE.label}
              name={PLEDGE_CREDITOR_POSTAL_CODE.name}
            >
              <Select
                showSearch
                placeholder={PLEDGE_CREDITOR_POSTAL_CODE.placeholder}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                dropdownMatchSelectWidth={false}
                disabled={
                  !isAcreedorPrendario ||
                  form.getFieldsValue([PLEDGE_CREDITOR_LOCATION.name]) ===
                    undefined ||
                  readOnlyOr
                }
                className={readOnlyOr ? "read-only" : undefined}
              >
                {postalCodeOptions()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
};

export default PledgeCreditorDataForm;
