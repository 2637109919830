import { Card, Divider } from "antd";
import {
  DollarCircleOutlined,
  CarOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import "./style.less";

export interface HomeCardProps {
  cardProps: {
    cardTitle: string;
    cardIcon: string;
    hideHeader?: boolean;
  };
  children: React.ReactNode;
}

const HomeCard = ({ cardProps, children }: HomeCardProps) => {
  const { cardTitle, cardIcon, hideHeader } = cardProps;

  const renderIcon = (icon: string) => {
    switch (icon) {
      case "money":
        return <DollarCircleOutlined className="card-icon" />;
      case "car":
        return <CarOutlined className="card-icon" />;
      case "search":
        return <SearchOutlined className="card-icon" />;
      default:
        break;
    }
  };
  return (
    <div className="HomeCard">
      <Card className="card-home">
        {!hideHeader && (
          <>
            <div className="card-header">
              {renderIcon(cardIcon)}
              <h2 className="card-title">{cardTitle} </h2>
            </div>
            <Divider className="card-divider" />
          </>
        )}
        <div className="card-body">{children}</div>
      </Card>
    </div>
  );
};

export default HomeCard;
