export const EMISSIONS = `
query emissions(
  $filter: FilterEmissionInput
  $orderBy: EmissionOrderInput
  $skip: Int
  $take: Int
) {
  emissions(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on Emission {
      id
      its
      numerator_id
      createdBy{
        username
      }
      business_name
      effective_date
      prize
      coverage_description
      producer {
        nombre
      }
      brand_model {
        brand
        model
      } 
      quotation {
        coverage{
          descrip_web
        }
      }
      vehicle_year
      id_policy
      amount_insured
      premium
      error_notice
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const EMISSION = `
query emission($id: Int!) {
  emission(id: $id) {
    __typename
    ... on Emission {
      id
      id_policy
      numerator_id
      coverage_description
      product_code
      credit_card
      producer {
        zona_riesgo
        nombre
        id_liderar
      }
      vehicle_type{
        description
      }
      destiny{
        description
      }
      amount_insured
      error_notice
      its
      vehicle_year
      business_name
      cuit
      home_street
      home_number
      floor
      department
      phone_number
      web_user
      email_aseg
      card_code
      payment_number
      prize
      premium
      fiscal_condition {
        description
      }
      province {
        description
      }
      postal_code {
        location
        codpostal
      }
      brand_model {
        brand
        model
        id_liderar
      }
      fuel_type {
        description
      }
      bodywork_type {
        description
      }
      additional_hail
      coverage_period {
        description
      }
      effective_date
      quotation {
        total_ice
        total_no_ice
        coverage{
          descrip_web
        }
        quotation_request {
          cng
          dome
          cold_equipment
          special_tires
          total_accessories
          adjustment_rate{
            description
          }
        }
      }
      chassis
      motor
      patent
      emission_legal_person {
        registration_date
        registration_number
        main_activity
        contract_date
      }
      emission_natural_person {
        birth_date
        birth_place
        gender {
          description
        }
        nationality{
          gentile
        }
      }
      pledged_creditor {
        business_name_surname
        name
        home_street
        home_number
        floor
        department
        province{
          description
        }
        postal_code{
          location
          codpostal
        }
      }
      legal_representative {
        cuit
        name_surname
        home_street
        home_number
        floor
        department
        phone_number
        email
        gender {
          description
        }
        province {
          description
        }
        postal_code {
          location
          codpostal
        }
        birth_date
        birth_place
        nationality {
          gentile
        }
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const COUNT_EMISSION = `
query countEmission($filter: FilterEmissionInput, $orderBy: EmissionOrderInput) {
  countEmission(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_EMISSION_FILE = `
query getEmissionFile($id: Int!) {
  getEmissionFile(id: $id) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_INSURED_FILE = `
query getInsuredFile($id: Int!) {
  getInsuredFile(id: $id) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
