import { RouteConfigComponentProps } from "react-router-config";
import { DownloadOutlined } from "@ant-design/icons";
import { ParamsType } from "@ant-design/pro-provider";
import ProTable, { ActionType } from "@ant-design/pro-table";
import { SorterResult } from "antd/lib/table/interface";
import moment from "moment";
import { useCallback, useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import { IImpressionsQueueRequest } from "../../../services/ImpressionsQueueRequest";
import GraphqlService from "../../../services/graphql/GraphqlService";
import {
  ContextApp,
  CustomMessage,
  EnumsValues,
  MomentJS,
  Tools,
} from "../../../shared";
import { ABM } from "../../../shared/ABM";
import { ExportableColumn } from "../../../shared/Exporter";
import {
  FORMAT_DATE_TIME_1,
  FORMAT_DATE_TIME_2,
  FORMAT_DATE_TIME_4,
  FORMAT_DATE_TIME_6,
} from "../../../shared/MomentJS";
import {
  camelCase,
  getPaginationArgs,
  parcialTypeImpressions,
} from "../../../shared/utils";
import { SaveForm } from "../../ABM";
import { ImpressionsInput } from "../../BusquedaPolizas/ResultsScreen/PolizaDetails/PolizaDetails";
import PolizaInput from "../../BusquedaPolizas/SearchForm/PolizaInput";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";
import CustomFilter from "../../CustomFilter/CustomFilter";
import {
  Checkbox,
  DatePicker,
  Image,
  Input,
  Select,
  Switch,
  Tooltip,
} from "antd";
import SelectWithFetch from "../../utils/SelectWithFetch";
import { IProducer } from "../../../services/Producer";
import { StatusQueueRequestArray } from "../../../services/StatusQueueRequest";
import "./PolizaDownloadsPage.less";
import { IBaseFile } from "../../../services/defaultSchema";
import { useCheckAuthority } from "../../../shared/CustomHooks";
import { validPolicyNumber } from "../../../utils/validPolicyNumber";
import { IformValuesNewRequest } from "../interfaces/formValuesNewRequest";
import pdfIcon from "../../../assets/pdf-icon.png";
import { createBinaryArrayForPartialImpressions } from "../../utils/createBinaryArrayForPartialImpressions";

const { Option } = Select;

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ["status_id", "Id_PV", "its_range"];
const LIST_SORTER = ["request_id", "its", "username"];

const TITLE_CREATE_FORM = "Crear solicitud de descarga de póliza";

export interface ISendDownloadRequestProps {
  Id_PV: string;
  mail_direction: string;
  ImpresionParcialFlag?: string[];
}

const PolizaDownloadsPage = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  const history = useHistory();
  const { user } = useContext(ContextApp);

  const [sorter, setSorter] = useState<string>("");
  const [producer, setProducer] = useState<IProducer | undefined>();

  const [producerOnForm, setProducerOnForm] = useState<IProducer | undefined>();
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [sendParcial, setSendParcial] = useState<boolean>(false);

  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  const { customRequest, Query } = GraphqlService();
  const { messageError, messageModalSuccess, messageModalError } =
    CustomMessage();

  const handleProducerSearch = useCallback(
    (value: string): Promise<any[]> => {
      return new Promise((resolve) => {
        if (Number.isNaN(value)) {
          return resolve([]);
        }
        if (!value) {
          return resolve([]);
        }
        return customRequest({
          query: Query.producers,
          variables: {
            searchText: value,
            filter: {
              user_id: user?.id,
              activo_web: true,
            },
          },
        })
          .then((data: IProducer[]) => {
            resolve(data);
          })
          .catch(() => {
            messageError({
              context: "handleProducerSearch",
              message: "Error al obtener los productores",
            });
          });
      });
    },
    [user?.id]
  );

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);

      variables.current.filter = {
        TIPO: EnumsValues.FileRequestType.Poliza,
      };

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            variables.current.filter[element] = search[element];
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      if (producer) {
        if (!variables.current.filter) {
          variables.current.filter = {};
        }
        variables.current.filter.producer_id = producer.id;
      }

      const countPromise = customRequest({
        query: Query.impressionsQueueRequestsCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.impressionsQueueRequests,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);

      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      console.error(error);
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const canDownloadFile = useCallback((record: IImpressionsQueueRequest) => {
    return (
      record.status_queue_requests_id ===
      EnumsValues.StatusQueueRequest.Available
    );
  }, []);

  const handleDownload = useCallback(
    async (record: IImpressionsQueueRequest) => {
      if (!canDownloadFile(record)) {
        return;
      }
      try {
        const result: IBaseFile = await customRequest({
          query: Query.impressionsQueueRequestFile,
          variables: {
            id: record.id,
          },
        });

        if (!result.file) {
          throw new Error("Ocurrió un error al descargar el archivo");
        }

        Tools.downloadFilePDF(result);
      } catch (error: any) {
        console.error(error);
        messageError({
          context: "PolizaDownloadsPage.handleDownload",
          message: error.message || "Ocurrió un error al descargar el archivo",
        });
      }
    },
    [canDownloadFile]
  );

  const columns = useCallback(
    (): ExportableColumn<IImpressionsQueueRequest>[] => [
      {
        export: true,
        dataIndex: "request_id",
        title: "ID",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        defaultSortOrder: "descend",
        align: "left",
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record) => record.request_id || "-",
      },
      {
        export: true,
        dataIndex: "its",
        title: "Fecha solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        render: (_: any, record: IImpressionsQueueRequest) => (
          <p className="non-wrap-cell">
            {record.its
              ? MomentJS.specificMoment(
                  {
                    date: record.its,
                    format: FORMAT_DATE_TIME_2,
                    utc: true,
                  },
                  FORMAT_DATE_TIME_1,
                  true
                )
              : "-"}
          </p>
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "its_range",
        title: "Fecha solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        renderFormItem: () => (
          <DatePicker.RangePicker
            name="its_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={["Fecha desde", "Fecha hasta"]}
            allowEmpty={[true, true]}
            className="renderFormItem"
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "username",
        title: "Solicitante",
        sorter: true,
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record) => record.createdBy.username || "-",
        renderFormItem: () => <Input />,
      },
      {
        export: true,
        dataIndex: "COD_PROD",
        title: "Productor",
        render: (_: any, record: IImpressionsQueueRequest) => (
          <p className="non-wrap-cell">{`${record.producer.nombre} (${record.producer.id_liderar})`}</p>
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "producer_id",
        title: "Productor",
        align: "left",
        hideInForm: true,
        hideInTable: true,
        renderFormItem: () => (
          <SelectWithFetch
            lengthValueToFetch={3}
            onSelect={(value: IProducer) => setProducer(value)}
            query={handleProducerSearch}
            placeholder={"Ingrese nombre/número de productor"}
            optionRender={(item: IProducer) => (
              <Option value={item.id_liderar} key={item.id_liderar}>
                {camelCase(item.nombre)} - {item.id_liderar}
              </Option>
            )}
            onClear={() => {
              setProducer(undefined);
            }}
          />
        ),
      },
      {
        export: false,
        dataIndex: "producer_id_on_form",
        title: "Productor",
        align: "left",
        hideInForm: false,
        hideInTable: true,
        hideInSearch: true,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "El productor es obligatorio",
            },
          ],
        },
        renderFormItem: () => (
          <SelectWithFetch
            lengthValueToFetch={3}
            onSelect={(value: IProducer) => setProducerOnForm(value)}
            query={handleProducerSearch}
            placeholder={"Ingrese nombre/número de productor"}
            optionRender={(item: IProducer) => (
              <Option value={item.id_liderar} key={item.id_liderar}>
                {camelCase(item.nombre)} - {item.id_liderar}
              </Option>
            )}
            onClear={() => setProducerOnForm(undefined)}
          />
        ),
      },
      {
        export: true,
        dataIndex: "Id_PV",
        title: "Póliza",
        render: (_: any, record: IImpressionsQueueRequest) => (
          <p className="non-wrap-cell">{record.Id_PV}</p>
        ),
        type: ABM.TYPE_COLUMN.STRING,
        renderFormItem: () => <Input placeholder="Ingrese póliza" />,
        formItemProps: {
          rules: [
            {
              required: true,
              message: "La póliza es obligatoria",
            },
          ],
        },
        align: "left",
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: "Id_PV",
        title: "Póliza",
        type: ABM.TYPE_COLUMN.STRING,
        renderFormItem: () => (
          <div className="polizaDownloadsPage">
            <PolizaInput />
          </div>
        ),
        align: "left",
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: "mail",
        title: "Envía e-mail",
        render: (_, record) => {
          return record.Envia_mail === "S"
            ? `${record.Envia_mail} - ${record.Mail}`
            : `${record.Envia_mail}`;
        },
        hideInForm: true,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: "parcial_sent",
        title: "Impresión Parcial",
        renderFormItem: () => (
          <Switch checked={sendParcial} onChange={setSendParcial} />
        ),
        hideInTable: true,
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: "ImpresionParcialFlag",
        renderFormItem: () => (
          <Checkbox.Group
            options={parcialTypeImpressions.map((item) => ({
              label: item,
              value: item,
            }))}
          ></Checkbox.Group>
        ),
        formItemProps: {
          rules: [
            {
              required: sendParcial,
              message:
                "Debe seleccionar al menos un tipo de impresión o desactivar la impresión parcial",
            },
          ],
        },
        hideInTable: true,
        hideInForm: !sendParcial,
        hideInSearch: true,
      },
      {
        export: true,
        dataIndex: "mail_sent",
        title: "Envía e-mail?",
        renderFormItem: () => <Switch onChange={setSendEmail} />,
        hideInTable: true,
        hideInForm: false,
        hideInSearch: true,
      },
      {
        export: true,
        dataIndex: "mail_direction",
        title: "Dirección de e-mail",
        renderFormItem: () => (
          <Input placeholder="Dirección de e-mail" maxLength={250} />
        ),
        formItemProps: {
          rules: [
            {
              required: sendEmail,
              message: "La Dirección de e-mail es obligatoria",
            },
            {
              type: "email",
              message: "No es un E-mail válido!",
            },
            {
              max: EnumsValues.MaxLengthInputs.SystemUser_Email,
              message: `El email debe tener un maximo de ${EnumsValues.MaxLengthInputs.SystemUser_Email} caracteres`,
            },
          ],
        },
        hideInTable: true,
        hideInForm: !sendEmail,
        hideInSearch: true,
      },
      {
        export: false,
        dataIndex: "status_id",
        title: "Estado",
        align: "left",
        hideInTable: true,
        hideInForm: true,
        renderFormItem: () => (
          <Select
            placeholder="Seleccione estado"
            getPopupContainer={(triggerNode) => triggerNode.parentElement}
            dropdownMatchSelectWidth={false}
            options={StatusQueueRequestArray.map((item) => ({
              value: item.id,
              key: item.id,
              label: item.status,
            }))}
            allowClear
          />
        ),
      },
      {
        export: true,
        dataIndex: "estado",
        title: "Estado",
        render: (_: any, record: IImpressionsQueueRequest) => (
          <p className="non-wrap-cell">{record.status_queue_requests.status}</p>
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: "liderar_message",
        title: "Mensaje",
        width: 200,
        className: "ellipsisColumn",
        render: (_: any, record: IImpressionsQueueRequest) => (
          <Tooltip title={record.liderar_message}>
            <div className="ellipsisColumn">
              <span>{record.liderar_message}</span>
            </div>
          </Tooltip>
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "op",
        title: "Op.",
        render: (_: any, record: IImpressionsQueueRequest) => (
          <p className="non-wrap-cell">
            {canDownloadFile(record) ? (
              <Tooltip title="Descargar poliza">
                <Image
                  className="pointer"
                  preview={false}
                  src={pdfIcon}
                  style={{ width: 30 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDownload(record);
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="La poliza no esta disponible">
                <DownloadOutlined className="disabled-icon" />
              </Tooltip>
            )}
          </p>
        ),
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
    ],
    [
      canDownloadFile,
      handleDownload,
      handleProducerSearch,
      sendEmail,
      sendParcial,
    ]
  );

  const sendDownloadRequest = (formValues: ISendDownloadRequestProps) => {
    if (!producerOnForm) {
      messageModalError({
        context: "polizaDownloadsPage.sendDownloadRequest.3",
        message: "Error en la solicitud, no se encontró el productor",
      });
      return;
    }

    const { Id_PV, mail_direction } = formValues;

    const input: ImpressionsInput = {
      Pedido: moment(new Date()).format(FORMAT_DATE_TIME_6),
      TIPO: EnumsValues.FileRequestType.Poliza,
      COD_PROD: producerOnForm.id_liderar,
      Id_PV,
      Envia_mail: sendEmail ? "S" : "N",
      Mail: mail_direction ? mail_direction : "",
      Graba_pdf: "S",
      Estado: "01",
      ...(formValues.ImpresionParcialFlag && {
        ImpresionParcialTipo: parcialTypeImpressions,
        ImpresionParcialFlag: formValues.ImpresionParcialFlag,
      }),
    };

    customRequest({
      query: Query.impressionsFromUniverse,
      variables: { input },
    })
      .then(() => {
        messageModalSuccess({
          context: "polizaDownloadsPage.sendDownloadRequest.1",
          message: "Solicitud de descarga de póliza realizada exitosamente",
        });
        setCreateModalVisible(false);
        setSendEmail(false);
        setSendParcial(false);
        setProducerOnForm(undefined);
        if (actionRef.current) {
          actionRef.current.reload();
        }
      })
      .catch((error: any) => {
        messageModalError({
          context: "polizaDownloadsPage.sendDownloadRequest.2",
          message: error?.message,
        });
      });
  };

  const handleFinish = (formValues: IformValuesNewRequest) => {
    const seccion = formValues.seccion;
    const poliza = validPolicyNumber(formValues.poliza);
    const endoso = formValues?.endoso || "000000";

    const Id_PV = seccion + poliza + endoso;
    const mail_direction = formValues?.mail_direction;

    sendDownloadRequest({
      Id_PV,
      mail_direction,
      ImpresionParcialFlag: createBinaryArrayForPartialImpressions(
        formValues?.ImpresionParcialFlag
      ),
    });
  };

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>Polizas</h1>
        <CustomFilter header="Filtrar por" />
        <ProTable<IImpressionsQueueRequest>
          defaultSize="small"
          id="PolizaDownloadsPageTable"
          actionRef={actionRef}
          rowKey="id"
          search={{
            collapsed: false,
            resetText: "Limpiar búsqueda",
            searchText: "Buscar",
            collapseRender: false,
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult =
              _sorter as SorterResult<IImpressionsQueueRequest>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          params={{
            sorter,
          }}
          onReset={() => setProducer(undefined)}
          className="quotation-table"
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns()}
          scroll={{ x: "scroll" }}
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: 10,
          }}
        />
        <div className="buttons">
          <ButtonLiderar
            color="white"
            buttonName="Volver"
            className="button-left"
            onClick={() => history.goBack()}
          />
          <ButtonLiderar
            color="violet"
            buttonName="Nueva solicitud"
            onClick={() => setCreateModalVisible(true)}
          />
        </div>
        <SaveForm
          title={TITLE_CREATE_FORM}
          submitText="Confirmar"
          className="create-modal"
          onReset={() => {
            setSendEmail(false);
            setSendParcial(false);
            setProducerOnForm(undefined);
          }}
          onCancel={() => {
            setCreateModalVisible(false);
            setSendEmail(false);
            setSendParcial(false);
            setProducerOnForm(undefined);
          }}
          modalVisible={createModalVisible}
          columns={columns()}
          onOk={(formValues) => {
            handleFinish(formValues);
          }}
        />
      </>
    )
  );
};

export default PolizaDownloadsPage;
