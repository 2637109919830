import { RouteConfigComponentProps } from "react-router-config";
import { useContext, useEffect, useState, useCallback } from "react";
import { useHistory, useParams } from "react-router";
import ms from "ms";
import moment from "moment";
import {
  Row,
  Col,
  Form,
  Input,
  Collapse,
  InputNumber,
  Select,
  Upload,
  Button,
} from "antd";
import { NavContext } from "@ionic/react";
import { CloudUploadOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import GraphqlService, {
  IFileData,
} from "../../services/graphql/GraphqlService";
import { CustomMessage, EnumsValues } from "../../shared";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import {
  DEFAULT_FORMAT_DATE,
  FORMAT_DATE_TIME_4,
  FORMAT_DATE_TIME_6,
} from "../../shared/MomentJS";
import {
  camelCase,
  formatStringToMoney,
  isCreditCardValid,
  addDays,
} from "../../shared/utils";
import { useQuotation } from "../../shared/hooks/useQuotation";
import { Datepicker } from "../DatePicker/DatePicker";
import CardVehicle from "./Cards/CardVehicle";
import CardCoverage from "./Cards/CardCoverage";
import { ICuitOnline } from "../../services/CuitOnline";
import ModalSummary, { IModalSummaryDataProps } from "../ModalSummary/Index";
import useAgeVerification from "../../shared/hooks/useAgeVerification";
import useEmission from "../../shared/hooks/useEmission";
import PledgedCreditorFormItems from "./Form/PledgedCreditor/PledgedCreditorFormItems";
import LegalRepresentativeFromItems from "./Form/LegalRepresentative/LegalRepresentativeFromItems";
import useLocationAndPostalCodes from "../../shared/hooks/useLocationAndPostalCodes";
import { ICard } from "../ModalSummary/ContentCard/ContentCard";
import { IQuotationForEmision } from "../../services/IQuotationForEmision";
import {
  IEmissionParams,
  ILegalPersonParams,
  ILegalRepresentativeParams,
  INaturalPersonParams,
  IPledgedCreditorParams,
} from "../../services/EmissionParamsForm";

import "./style.less";
import { Query } from "../../services/graphql/query";
import SelectFetchOnEnter from "../utils/SelectFetchOnEnter";
import useCuitVerification from "../../shared/hooks/useCuitVerification";
import { ModalConfirm } from "../utils/ModalConfirm";
import { useCheckAuthority } from "../../shared/CustomHooks";
import useFormatData from "../../shared/hooks/useFormatData";
import CardAccessories from "./Cards/CardAccessories";
import CardExtraInfo from "./Cards/CardExtraInfo";
import { coverageIdLiderar } from "../../shared/EnumsValues";

const { Panel } = Collapse;
const { Option } = Select;

interface IUploadedFiles {
  [name: string]: RcFile;
}

interface ImpressionsInput {
  File?: string;
  Id?: string;
  Pedido: string;
  TIPO: string;
  COD_PROD: string;
  Id_PV: string;
  Envia_mail: string;
  Mail: string;
  Graba_pdf: string;
  Ruta_PDF?: string;
  Estado: string;
}

const Emission = (routeProps: RouteConfigComponentProps): JSX.Element => {
  const { verifyCuit, isLoading: isLoadingCuitVerification } =
    useCuitVerification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const history: any = useHistory();
  const { formatDataCuit } = useFormatData();
  const { navigate } = useContext(NavContext);
  const { ageVerification, isAdult } = useAgeVerification();
  const { customRequest, Mutation, customFileRequest } = GraphqlService();
  const {
    messageError,
    messageSuccess,
    messageModalError,
    messageModalSuccess,
  } = CustomMessage();
  const { provinces } = useQuotation();

  const [provinceIdLiderar, setProvinceIdLiderar] = useState<string>("");
  const [provinceName, setProvinceName] = useState<string>("");
  const [provinceIdSelected, setProvinceIdSelected] = useState<number>(0);
  const [acreedorProvinceName, setAcreedorProvinceName] = useState<string>("");
  useState<number>(0);
  const [selectedlocalidad, setSelectedlocalidad] = useState<string>("");
  const [bodyWorkName, setBodyWorkName] = useState<string>("");
  const [fuelTypeLiderar, setfuelTypeLiderar] = useState<string>("");
  const [fuelTypeName, setFuelTypeName] = useState<string>("");
  const [paymentTypeLiderar, setPaymentTypeLiderar] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [legalRepresentativeGender, setLegalRepresentativeGender] =
    useState<string>("");
  const [nationalityName, setNationalityName] = useState<string>("");
  const [
    legalRepresentativeNationalityName,
    setLegalRepresentativeNationalityName,
  ] = useState<string>("");
  const [legalRepresentativeProvinceName, setLegalRepresentativeProvinceName] =
    useState<string>("");
  const [paymentTypeDescription, setPaymentTypeDescription] =
    useState<string>("");
  const [modalSummaryVisible, setModalSummaryVisible] =
    useState<boolean>(false);
  const [issuedPolicySummary, setIssuedPolicySummary] =
    useState<IModalSummaryDataProps>({});
  const [closedSummary, setClosedSummary] = useState<boolean>(false);
  const [personType, setPersonType] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<IUploadedFiles>();
  const { localidades, postalCodes, getLocationByProvince } =
    useLocationAndPostalCodes({
      selectedlocalidad,
      provinceIdSelected,
      formItemLocationName: "localidad",
      formItemPostalCodeName: "postal_code",
      form,
    });
  const [postalCodeLegalRepresentative, setpostalCodeLegalRepresentative] =
    useState<number>(0);
  const [postalCodePledgedCreditor, setPostalCodePledgedCreditor] =
    useState<number>(0);
  const [productCode, setProductCode] = useState<string>("");
  const [emissionResponse, setEmissionResponse] = useState<any>();
  const [isCuponPaymentSelected, setIsCuponPaymentSelected] =
    useState<boolean>(false);

  const params: { id?: string } = useParams();

  const {
    quotation,
    fuelTypes,
    bodyworkTypes,
    gendersTypes,
    paymentTypes,
    nationalities,
    minBornTime,
    idsLiderarForMotorcycles,
  } = useEmission({ quotationId: params?.id });

  const getProductCode = useCallback((): void => {
    if (quotation) {
      if (
        idsLiderarForMotorcycles.includes(
          quotation.quotation_request.vehicle_type.id_liderar
        )
      ) {
        setProductCode(quotation.quotation_request.producer.cod_prod_moto);
      } else {
        setProductCode(quotation.quotation_request.producer.cod_prod_auto);
      }
    }
  }, [quotation, idsLiderarForMotorcycles]);

  useEffect(() => {
    if (quotation && idsLiderarForMotorcycles) {
      getProductCode();
    }
  }, [quotation, idsLiderarForMotorcycles]);

  useEffect(() => {
    if (closedSummary) {
      form.resetFields();
    }
  }, [closedSummary]);

  const handleChangeLocationSelect = (location: string) => {
    setSelectedlocalidad(location);
  };

  const handleChangeProvince = (id: number) => {
    setProvinceIdSelected(id);
    const selectedProvince = provinces.find((province) => province.id === id);
    if (selectedProvince) {
      setProvinceIdLiderar(selectedProvince?.id_liderar);
      setProvinceName(selectedProvince?.description);
    }
  };

  const handleChangeBodyWorkTypes = (id_liderar: string) => {
    const bodywork = bodyworkTypes.find(
      (bodyWork) => bodyWork.id_liderar === id_liderar
    );
    if (bodywork) {
      setBodyWorkName(bodywork.description);
    }
  };

  const handleChangeFuelType = (id: number) => {
    const fuelType = fuelTypes.find((fueltype) => fueltype.id === id);
    if (fuelType) {
      setFuelTypeName(fuelType.description);
      setfuelTypeLiderar(fuelType.id_liderar);
    }
  };

  const handleChangePaymentType = (id: number) => {
    const paymentSelected = paymentTypes.find((payment) => payment.id === id);
    if (paymentSelected) {
      setPaymentTypeLiderar(paymentSelected?.id_liderar);
      setPaymentTypeDescription(paymentSelected?.description);
    }
  };

  const handleChangeGender = (id_liderar: string) => {
    const selectedGender = gendersTypes.find(
      (genderParam) => genderParam.id_liderar === id_liderar
    );
    if (selectedGender) {
      setGender(selectedGender.description);
    }
  };

  const handleChangeNationality = (id: number) => {
    const nationality = nationalities.find((nation) => nation.id === id);
    if (nationality) {
      setNationalityName(nationality.gentile);
    }
  };

  const setLocationAndProviceCode = async (
    quotationParam: IQuotationForEmision
  ) => {
    //province_code
    setProvinceIdSelected(quotationParam.quotation_request?.province?.id);
    setProvinceName(quotationParam.quotation_request?.province?.description);
    form.setFieldsValue({
      province_code: quotationParam.quotation_request?.province?.id,
    });
    await getLocationByProvince();

    // localidad
    setSelectedlocalidad(
      quotationParam.quotation_request?.postal_code?.location
    );
    form.setFieldsValue({
      localidad: quotationParam.quotation_request?.postal_code?.location,
    });
  };

  useEffect(() => {
    if (postalCodes.length === 1) {
      form.setFieldsValue({
        postal_code: postalCodes[0].codpostal,
      });
    }
  }, [postalCodes]);

  //seteo de carrocería por defecto
  const setDefaultBodyworkType = async (
    quotationParam: IQuotationForEmision
  ) => {
    const bodywork =
      bodyworkTypes &&
      bodyworkTypes.find((bodyWork) => {
        return (
          bodyWork.id_liderar ===
          quotationParam.quotation_request?.brand_model
            .bodywork_type_default_id_liderar
        );
      });
    form.setFieldsValue({
      bodywork: bodywork ? bodywork?.id_liderar : null,
    });
    setBodyWorkName(bodywork ? bodywork?.description : "");
  };

  //seteo de combustible por defecto
  const setDefaultFuelType = async (quotationParams: IQuotationForEmision) => {
    const fuelType =
      fuelTypes &&
      fuelTypes.find((fueltype) => {
        return (
          fueltype.id_liderar ===
          quotationParams.quotation_request?.brand_model
            ?.fuel_type_default_id_liderar
        );
      });
    form.setFieldsValue({
      fuel_type: fuelType ? fuelType?.id : null,
    });
    setFuelTypeName(fuelType ? fuelType?.description : "");
    setfuelTypeLiderar(String(fuelType?.id_liderar));
  };

  //Precarga de valores en input location and province
  useEffect(() => {
    if (quotation) {
      setLocationAndProviceCode(quotation);
    }
  }, [quotation]);

  //Precarga de valores en input bodywork type
  useEffect(() => {
    if (quotation && bodyworkTypes.length) {
      setDefaultBodyworkType(quotation);
    }
  }, [quotation, bodyworkTypes]);

  //Precarga de valores en input fuel type
  useEffect(() => {
    if (quotation && fuelTypes.length) {
      setDefaultFuelType(quotation);
    }
  }, [quotation, fuelTypes]);

  const lifePolicyModal = useCallback(() => {
    if (quotation) {
      const fiscalCondition =
        quotation?.quotation_request?.fiscal_condition?.id_liderar;

      const showLifePolicyModal =
        fiscalCondition === EnumsValues.FiscalCondition.ConsFinal ||
        fiscalCondition === EnumsValues.FiscalCondition.Monotributo;
      return showLifePolicyModal ? true : undefined;
    }
  }, [quotation]);

  const getAccesoriesArray = (quotationParam: IQuotationForEmision) => {
    const code_acc: string[] = [];
    const value_acc: string[] = [];

    const gnc = quotationParam.quotation_request?.cng;
    const tires = quotationParam.quotation_request?.special_tires;
    const freezer = quotationParam.quotation_request?.cold_equipment;
    const cupula = quotationParam.quotation_request?.dome;

    if (gnc) {
      code_acc.push("001");
      value_acc.push(String(gnc));
    }

    if (tires) {
      code_acc.push("004");
      value_acc.push(String(tires));
    }

    if (freezer) {
      code_acc.push("006");
      value_acc.push(String(freezer));
    }

    if (cupula) {
      code_acc.push("014");
      value_acc.push(String(cupula));
    }

    return { code_acc, value_acc };
  };

  const onFinish = async (values: any) => {
    setIsLoading(true);
    if (personType === EnumsValues.PersonType.FISICA) {
      if (form.getFieldsValue().document_number && !isAdult) {
        setIsLoading(false);
        return messageModalError({
          context: "onFinish",
          message: "Debe ser mayor de edad para emitir",
        });
      }
    }

    if (!quotation) {
      setIsLoading(false);
      return messageModalError({
        context: "onFinish",
        message: "Fallo al emitir, error en datos obtenidos de la cotización",
      });
    }

    const { code_acc, value_acc } = getAccesoriesArray(quotation);

    const emissionParams: IEmissionParams = {
      // RECUPERAR DESDE COTIZACION
      COD_PROD: quotation.quotation_request?.producer?.id_liderar,
      ZONA: quotation.quotation_request?.producer?.zona_riesgo,
      COND_FISCAL: quotation.quotation_request?.fiscal_condition?.id_liderar,
      CUIT:
        quotation.quotation_request?.dni_cuit || values?.document_number?.key,
      PERIODO_COB: quotation.quotation_request?.coverage_period?.id_liderar,
      PRODUCTO: productCode,
      TIPO_VEH: quotation.quotation_request?.vehicle_type?.id_liderar,
      MARCA: quotation.quotation_request?.brand_model?.brand,
      MODELO: quotation.quotation_request?.brand_model?.model,
      ANIO: quotation.quotation_request?.year,
      COBERTURA: quotation.coverage?.id_liderar,
      SUMA_ASEG: quotation.quotation_request?.insured_amount.toString(),
      USO: quotation.quotation_request?.destiny?.id_liderar,
      COD_PROV:
        provinceIdLiderar || quotation.quotation_request?.province?.id_liderar,
      LOCALIDAD:
        values.localidad || quotation.quotation_request?.postal_code?.location,
      COS_POS: values.postal_code,
      RAZON_SOCIAL: values.name,
      ADIC_GRANIZO: quotation.quotation_request.additional_hail,
      AJU_AUT: quotation.quotation_request.adjustment_rate.description,
      COD_ACC: code_acc,
      SUMA_ACC: value_acc,

      // INGRESAR EN EMISION
      TIPO_COMB: fuelTypeLiderar,
      TIPO_DOC: "DNI",
      NRO_DOC: values.document_number?.key.slice(2, -1),
      DOMIC_CALLE: values.home_street,
      DOMIC_NRO: String(values.home_number),
      SEXO:
        personType === EnumsValues.PersonType.FISICA
          ? values.gender
          : EnumsValues.Gender.OTROS,
      FECHA_NAC:
        personType === EnumsValues.PersonType.FISICA
          ? values.date_of_birth?.format(FORMAT_DATE_TIME_4)
          : values.registration_date?.format(FORMAT_DATE_TIME_4),
      COD_TARJETA: paymentTypeLiderar,
      MOTOR: values.motor,
      CHASIS: values.chasis_number,
      PATENTE: values.patent,
      CARROCERIA: values.bodywork,
      TEL: String(values.phone_number),
      MAIL_ASEG: values.email,
      VIG_DESDE: values.date_of_emission.format(FORMAT_DATE_TIME_4),
      PISO: values.home_floor,
      DEPARTAMENTO: values.home_department,
      NRO_PAGO: values.payment_number,
      LIFE_POLICY_ACCEPTED: lifePolicyModal(),
    };

    const pledgedCreditorParams: IPledgedCreditorParams = {
      business_name_surname: values.acreedor_surname,
      name: values.acreedor_name,
      home_street: values.acreedor_home_street,
      home_number: String(values.acreedor_home_number),
      floor: values.acreedor_home_floor,
      department: values.acreedor_home_department,
      province_id: values.acreedor_province_code,
      postal_code_id: postalCodePledgedCreditor,
    };

    const genderId = (gender_id_liderar: string) => {
      const selectedGender = gendersTypes.find(
        (genderParam) => genderParam.id_liderar === gender_id_liderar
      );
      return selectedGender ? selectedGender.id : 3;
    };

    const naturalPersonParams: INaturalPersonParams = {
      birth_date: values.date_of_birth?.format(FORMAT_DATE_TIME_4),
      birth_place: values.birth_place,
      gender_id: genderId(values.gender),
      nationality_id: values.nationality,
    };

    const legalPersonParams: ILegalPersonParams = {
      registration_date: values.registration_date?.format(FORMAT_DATE_TIME_4),
      registration_number: values.registration_number,
      contract_date: values.contract_date?.format(FORMAT_DATE_TIME_4),
      main_activity: values.main_activity,
    };

    const legalRepresentativeParams: ILegalRepresentativeParams = {
      cuit: values.legal_cuit?.key,
      name_surname: values.legal_name_surname,
      home_street: values.legal_home_street,
      home_number: String(values.legal_home_number),
      floor: values.legal_home_floor,
      department: values.legal_home_department,
      phone_number: String(values.legal_phone_number),
      birth_date: values.legal_date_of_birth?.format(FORMAT_DATE_TIME_4),
      birth_place: values.legal_birth_place,
      email: values.legal_email,
      province_id: values.legal_province_code,
      postal_code_id: postalCodeLegalRepresentative,
      gender_id: genderId(values.legal_gender),
      nationality_id: values.legal_nationality,
    };

    const quotationSummaryForModal: ICard[] = [
      { title: "Marca", content: emissionParams.MARCA },
      { title: "Modelo", content: emissionParams.MODELO },
      { title: "Año", content: emissionParams.ANIO },
      {
        title: "Suma asegurada",
        content: formatStringToMoney(
          quotation.coverage?.id_liderar === coverageIdLiderar.A
            ? "0"
            : emissionParams.SUMA_ASEG || "0"
        ),
      },
      {
        title: "Nombre",
        content: quotation.coverage?.descrip_web,
      },
      {
        title: "Detalles",
        content: quotation.coverage?.description,
      },
      {
        title: "Premio",
        content: quotation.total_ice
          ? formatStringToMoney(String(quotation?.total_ice))
          : "-",
      },
      {
        title: "Premio",
        content: quotation.total_no_ice
          ? formatStringToMoney(String(quotation?.total_no_ice))
          : "-",
      },
      {
        title: "Período",
        content: quotation.quotation_request?.coverage_period?.description,
      },
    ];

    const InsuredDataForModal: ICard[] = [
      { title: "Cuit / Cuil", content: emissionParams.CUIT },
      {
        title: "Razón social / Nombre y Apellido",
        content: emissionParams.RAZON_SOCIAL,
      },
      { title: "Domicilio", content: emissionParams.DOMIC_CALLE },
      { title: "Número", content: emissionParams.DOMIC_NRO },
      { title: "Piso", content: values.home_floor },
      { title: "Departamento", content: values.home_department },
      { title: "Teléfono", content: emissionParams.TEL },
      { title: "Email", content: emissionParams.MAIL_ASEG },
      { title: "Tipo de pago", content: paymentTypeDescription },
      { title: "Provincia", content: provinceName },
      { title: "Localidad", content: emissionParams.LOCALIDAD },
      { title: "Código postal", content: emissionParams.COS_POS },
      { title: "Número de pago", content: values.payment_number },
      {
        title: "Fecha de inicio de vigencia",
        content: emissionParams.VIG_DESDE,
      },
      {
        title: "Condición fiscal",
        content: quotation?.quotation_request?.fiscal_condition?.description,
      },
    ];

    const naturalPersonDataForModal: ICard[] = [
      { title: "Fecha de nacimiento", content: naturalPersonParams.birth_date },
      {
        title: "Lugar de nacimiento",
        content: naturalPersonParams.birth_place,
      },
      { title: "Género", content: gender },
      { title: "Nacionalidad", content: nationalityName },
    ];

    const legalPersonDataForModal: ICard[] = [
      {
        title: "Fecha registración",
        content: legalPersonParams.registration_date,
      },
      {
        title: "Nro insc registral",
        content: legalPersonParams.registration_number,
      },
      { title: "Fecha contrato", content: legalPersonParams.contract_date },
      {
        title: "Actividad principal",
        content: legalPersonParams.main_activity,
      },
    ];

    const vehicleDataForModal: ICard[] = [
      { title: "Número de chasis", content: emissionParams.CHASIS },
      { title: "Número de motor", content: emissionParams.MOTOR },
      { title: "Patente", content: emissionParams.PATENTE },
      { title: "Carroceria", content: bodyWorkName },
      { title: "Tipo de combustible", content: fuelTypeName },
      {
        title: "Adicional granizo",
        content: emissionParams.ADIC_GRANIZO === "S" ? "Sí" : "No",
      },
    ];

    const accessoriesForModal: ICard[] = [
      {
        title: "Cúpula",
        content: formatStringToMoney(quotation?.quotation_request?.dome || "0"),
      },
      {
        title: "Equipo de frío",
        content: formatStringToMoney(
          quotation?.quotation_request?.cold_equipment || "0"
        ),
      },
      {
        title: "GNC",
        content: formatStringToMoney(quotation?.quotation_request?.cng || "0"),
      },
      {
        title: "Llantas especiales",
        content: formatStringToMoney(
          quotation?.quotation_request?.special_tires || "0"
        ),
      },
      {
        title: "Total accesorios",
        content: formatStringToMoney(
          quotation?.quotation_request?.total_accessories || "0"
        ),
      },
    ];

    const pledgedCreditorDataForModal: ICard[] = [
      {
        title: "Apellido",
        content: pledgedCreditorParams.business_name_surname,
      },
      { title: "Nombre", content: pledgedCreditorParams.name },
      { title: "Domicilio", content: pledgedCreditorParams.home_street },
      { title: "Número", content: pledgedCreditorParams.home_number },
      { title: "Piso", content: pledgedCreditorParams.floor },
      { title: "Departamento", content: pledgedCreditorParams.department },
      { title: "Provincia", content: acreedorProvinceName },
      { title: "Localidad", content: values.acreedor_localidad },
      { title: "Código postal", content: values.acreedor_postal_code },
    ];

    const legalRepresentativeDataForModal: ICard[] = [
      { title: "Cuit", content: legalRepresentativeParams.cuit },
      {
        title: "Nombre y apellido",
        content: legalRepresentativeParams.name_surname,
      },
      { title: "Domicilio", content: legalRepresentativeParams.home_street },
      { title: "Número", content: legalRepresentativeParams.home_number },
      { title: "Piso", content: legalRepresentativeParams.floor },
      { title: "Departamento", content: legalRepresentativeParams.department },
      { title: "Teléfono", content: legalRepresentativeParams.phone_number },
      { title: "Email", content: legalRepresentativeParams.email },
      { title: "Género", content: legalRepresentativeGender },
      { title: "Provincia", content: legalRepresentativeProvinceName },
      { title: "Localidad", content: values.legal_localidad },
      {
        title: "Código postal",
        content: legalRepresentativeParams.postal_code_id,
      },
      {
        title: "Fecha de nacimiento",
        content: legalRepresentativeParams.birth_date,
      },
      {
        title: "Lugar de nacimiento",
        content: legalRepresentativeParams.birth_place,
      },
      {
        title: "Nacionalidad",
        content: legalRepresentativeNationalityName,
      },
    ];

    setIssuedPolicySummary({
      quotationSummaryForModal,
      InsuredDataForModal,
      naturalPersonDataForModal,
      legalPersonDataForModal,
      vehicleDataForModal,
      accessoriesForModal,
      pledgedCreditorDataForModal,
      legalRepresentativeDataForModal,
    });

    await customRequest({
      mutation: Mutation.createEmission,
      variables: {
        input: emissionParams,
        quotationId: quotation.id,
        pledgedCreditor: form.getFieldsValue().is_acreedor_prendario
          ? pledgedCreditorParams
          : null,
        naturalPerson:
          personType === EnumsValues.PersonType.FISICA
            ? naturalPersonParams
            : null,
        legalPerson:
          personType === EnumsValues.PersonType.JURIDICA
            ? legalPersonParams
            : null,
        legalRepresentative: form.getFieldsValue().is_legal_representative
          ? legalRepresentativeParams
          : null,
      },
    })
      .then((response: any) => {
        setEmissionResponse(response);
        messageSuccess({
          context: "Emission.onFinish.1",
          message: "Emisión creada correctamente",
          time: 2000,
        });
        setModalSummaryVisible(true);
        quotationSummaryForModal.unshift({
          title: "Número de póliza",
          content: response.id_policy,
        });
      })
      .then(() => {
        if (uploadedFiles && Object.keys(uploadedFiles).length) {
          Object.keys(uploadedFiles).forEach((fileKey) => {
            const file = uploadedFiles[fileKey];
            const variables: any = {
              input: {
                file_type_id: EnumsValues.FileType.IMAGEN_PATENTE_POLIZA,
              },
              licensePlate: emissionParams.PATENTE,
            };
            if (fileKey && file) {
              variables.file = null;
              let fileArray: IFileData[] = [];
              fileArray = [
                {
                  file,
                  path: "variables.file",
                },
              ];
              customFileRequest(
                {
                  mutation: Mutation.saveFile,
                  variables,
                },
                fileArray
              )
                .then((result: any) => {
                  if (!result) {
                    throw new Error();
                  }
                })
                .catch((error: any) => {
                  messageModalError({
                    context: "Emission.onFinish.3",
                    message:
                      error.message || `Error al cargar la imagen ${file.name}`,
                  });
                });
            }
          });
        }
      })
      .catch((error: any) => {
        messageModalError({
          context: "Emission.onFinish.2",
          message: error.message,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fileTypes = ["image/png", "image/jpeg"];
  const fileTypesNames = ["png", "jpeg", "jpg", "jfif", "pjpeg", "pjp"];

  const sendDownloadRequest = (sendToMail?: boolean, email?: string) => {
    const input: ImpressionsInput = {
      Pedido: moment(new Date()).format(FORMAT_DATE_TIME_6),
      TIPO: "004",
      COD_PROD: String(quotation?.quotation_request.producer.id_liderar),
      Id_PV: String(emissionResponse.id_policy),
      Envia_mail: sendToMail ? "S" : "N",
      Mail: sendToMail && email ? email : "",
      Graba_pdf: "S",
      Estado: "01",
    };
    const variables = { input };
    customRequest({
      query: Query.impressionsFromUniverse,
      variables,
    })
      .then(() => {
        messageModalSuccess({
          context: "PolizaDetails.sendDownloadRequest.1",
          message:
            "Solicitud de descarga de póliza realizada exitosamente. Diríjase a la sección descargas.",
        });
      })
      .catch((error: any) => {
        messageModalError({
          context: "PolizaDetails.sendDownloadRequest.2",
          message: error?.message,
        });
      });
  };

  return (
    useCheckAuthority(routeProps) || (
      <div className="emission">
        <div className="cards">
          <CardVehicle quotation={quotation} />
          <CardCoverage quotation={quotation} />
        </div>
        <div className="cards">
          <CardAccessories quotation={quotation} />
          <CardExtraInfo quotation={quotation} />
        </div>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          wrapperCol={{ span: 24 }}
        >
          <Collapse defaultActiveKey={["1"]} className="collapse">
            <Panel header="Datos del asegurado" key="1">
              <Row justify="space-between" gutter={40}>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    label="CUIT / CUIL"
                    rules={[{ required: true, message: "ingrese CUIT" }]}
                    name="document_number"
                  >
                    <SelectFetchOnEnter
                      query={verifyCuit}
                      isLoading={isLoadingCuitVerification}
                      initialValue={
                        quotation?.quotation_request?.dni_cuit || null
                      }
                      disabled={!!quotation?.quotation_request?.dni_cuit}
                      functionToFormatData={formatDataCuit}
                      placeholder="Ingrese CUIT, CUIL o DNI"
                      form={form}
                      onChange={(_, data: ICuitOnline[]) => {
                        if (data[0]?.personType) {
                          setPersonType(data[0]?.personType);
                        } else {
                          setPersonType("");
                        }
                        const birthDate = data[0]?.birthDate;
                        ageVerification(birthDate);

                        if (form) {
                          form.setFieldsValue({ name: data[0]?.name });
                          form.setFieldsValue({
                            home_street: data[0]?.addressStreet,
                          });
                          form.setFieldsValue({
                            home_number: data[0]?.addressNumber,
                          });
                          form.setFieldsValue({
                            date_of_birth: data[0]?.birthDate
                              ? moment(data[0]?.birthDate, DEFAULT_FORMAT_DATE)
                              : undefined,
                          });

                          const setGenderValue = () => {
                            const genderWasFound = gendersTypes.find(
                              (genderParam) =>
                                genderParam.description === data[0]?.gender
                            );
                            if (genderWasFound) {
                              setGender(genderWasFound.description);
                            }
                            return genderWasFound?.id_liderar;
                          };

                          form.setFieldsValue({
                            gender: setGenderValue(),
                          });
                        }
                      }}
                      optionRender={(item: ICuitOnline, index: number) => (
                        <Option value={item.fiscalID} key={index}>
                          {item.fiscalID} - {item.name}
                        </Option>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      { required: true, message: "No hay datos en AFIP" },
                    ]}
                    label="Razón social / Nombre y Apellido"
                    name="name"
                  >
                    <Input
                      type="text"
                      placeholder="Se completa automáticamente al ingresar DNI/CUIT/CUIL"
                      maxLength={50}
                      defaultValue={quotation?.quotation_request?.name}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[{ required: true, message: "Ingrese domicilio" }]}
                    label="Domicilio"
                    name="home_street"
                  >
                    <Input
                      type="text"
                      maxLength={30}
                      placeholder="Ingrese Domicilio"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Ingrese número de domicilio",
                      },
                    ]}
                    label="Número"
                    name="home_number"
                  >
                    <InputNumber
                      type="number"
                      maxLength={10}
                      controls={false}
                      placeholder="Ingrese número de domicilio"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={40}>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item label="Piso" name="home_floor">
                    <Input type="text" placeholder="Ingrese piso" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item label="Departamento" name="home_department">
                    <Input type="text" placeholder="Ingrese departamento" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Ingrese número de telefono",
                      },
                    ]}
                    label="Teléfono"
                    name="phone_number"
                  >
                    <InputNumber
                      type="number"
                      maxLength={30}
                      controls={false}
                      placeholder="Ingrese número de telefono"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Ingrese email" },
                      {
                        type: "email",
                        message: "Debe ingresar un email válido",
                      },
                    ]}
                    label="Email"
                    name="email"
                  >
                    <Input
                      type="email"
                      maxLength={50}
                      placeholder="Ingrese email"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={40}>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Seleccione tipo de pago",
                      },
                    ]}
                    label="Tipo de pago"
                    name="payment_type"
                  >
                    <Select
                      placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      onChange={handleChangePaymentType}
                      onSelect={(value) => {
                        if (value === EnumsValues.PaymentType.CUPONES) {
                          setIsCuponPaymentSelected(true);
                          form.setFieldsValue({
                            payment_number: "",
                          });
                        } else {
                          setIsCuponPaymentSelected(false);
                        }
                      }}
                    >
                      {paymentTypes.length !== 0 &&
                        paymentTypes.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {item.description === "CBU"
                              ? item.description.toUpperCase()
                              : camelCase(item.description)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Seleccione provincia" },
                    ]}
                    label="Provincia"
                    name="province_code"
                  >
                    <Select
                      placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      onChange={handleChangeProvince}
                    >
                      {provinces.length !== 0 &&
                        provinces.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {camelCase(item.description)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Seleccione localidad" },
                    ]}
                    label="Localidad"
                    name="localidad"
                  >
                    <Select
                      showSearch
                      placeholder="Seleccione"
                      onSelect={handleChangeLocationSelect}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      disabled={
                        form.getFieldsValue().province_code === undefined
                      }
                    >
                      {localidades.length !== 0 &&
                        localidades.map((item) => (
                          <Option value={item.location} key={item.id}>
                            {camelCase(item.location)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Ingrese código postal" },
                    ]}
                    label="Código postal"
                    name="postal_code"
                  >
                    <Select
                      showSearch
                      placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                    >
                      {postalCodes.length !== 0 &&
                        postalCodes.map((item) => (
                          <Option value={item.codpostal} key={item.id}>
                            {item.codpostal}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={40} justify="start">
                <Col xs={24} md={12} xl={6} hidden={isCuponPaymentSelected}>
                  <Form.Item
                    rules={[
                      {
                        required: !isCuponPaymentSelected,
                        message: "Ingrese número de pago",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const paymentNumber = value;
                          const paymentType = getFieldValue("payment_type");
                          if (!value) {
                            return Promise.resolve();
                          }
                          if (isCreditCardValid(paymentNumber, paymentType)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              paymentType === EnumsValues.PaymentType.CBU
                                ? "El número de CBU es inválido"
                                : "El número de tarjeta es inválido"
                            )
                          );
                        },
                      }),
                    ]}
                    label="Número de pago"
                    name="payment_number"
                    dependencies={["payment_type"]}
                  >
                    <Input type="text" placeholder="Ingrese número" />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    label="Fecha de inicio de cobertura"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione fecha de inicio de cobertura",
                      },
                    ]}
                    name="date_of_emission"
                  >
                    <Datepicker
                      placeholder="Seleccione fecha"
                      format={DEFAULT_FORMAT_DATE}
                      allowClear
                      minDate={new Date()}
                      maxDate={addDays(new Date(), 30)}
                      getPopupContainer={(trigger) => trigger}
                      showToday={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {form.getFieldsValue().document_number &&
                personType === EnumsValues.PersonType.FISICA && (
                  <>
                    <h1>{`Datos adicionales (Persona física)`}</h1>
                    <Row gutter={40} justify="start">
                      <Col xs={24} md={12} xl={6}>
                        <Form.Item
                          label="Género"
                          rules={[
                            { required: true, message: "Seleccione género" },
                          ]}
                          name="gender"
                        >
                          <Select
                            placeholder="Seleccione"
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentElement
                            }
                            dropdownMatchSelectWidth={false}
                            onChange={handleChangeGender}
                          >
                            {gendersTypes.length !== 0 &&
                              gendersTypes.map((item) => (
                                <Option value={item.id_liderar} key={item.id}>
                                  {camelCase(item.description)}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} xl={6}>
                        <Form.Item
                          label="Fecha de nacimiento"
                          rules={[
                            {
                              required: true,
                              message: "Seleccione fecha de nacimiento",
                            },
                          ]}
                          name="date_of_birth"
                        >
                          <Datepicker
                            placeholder="Seleccione fecha"
                            format={DEFAULT_FORMAT_DATE}
                            allowClear
                            getPopupContainer={(trigger) => trigger}
                            defaultDate={
                              new Date(
                                new Date().getTime() -
                                  ms(minBornTime?.setting_value || "18y")
                              )
                            }
                            maxDate={
                              new Date(
                                new Date().getTime() -
                                  ms(minBornTime?.setting_value || "18y")
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} xl={6}>
                        <Form.Item
                          label="Lugar de nacimiento"
                          name="birth_place"
                          rules={[
                            {
                              required: true,
                              message: "Ingrese lugar de nacimiento",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            placeholder="Ingrese lugar de nacimiento"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} xl={6}>
                        <Form.Item
                          label="Nacionalidad"
                          name="nationality"
                          rules={[
                            {
                              required: true,
                              message: "Seleccione nacionalidad",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Seleccione"
                            showSearch
                            allowClear
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentElement
                            }
                            dropdownMatchSelectWidth={false}
                            onChange={handleChangeNationality}
                            filterOption={(input: string, option: any) => {
                              return (
                                option?.children
                                  ?.toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {nationalities.length !== 0 &&
                              nationalities.map((item) => (
                                <Option value={item.id} key={item.id}>
                                  {camelCase(item.gentile)}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              {form.getFieldsValue().document_number &&
                personType === "JURIDICA" && (
                  <>
                    <h1>{`Datos adicionales (Persona jurídica)`}</h1>
                    <Row gutter={40} justify="start">
                      <Col xs={24} md={12} xl={6}>
                        <Form.Item
                          label="Fecha registración"
                          name="registration_date"
                          rules={[
                            {
                              required: true,
                              message: "Ingrese fecha de registración",
                            },
                          ]}
                        >
                          <Datepicker
                            showTime
                            placeholder="Seleccione fecha"
                            format={DEFAULT_FORMAT_DATE}
                            allowClear
                            maxDate={new Date()}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} xl={6}>
                        <Form.Item
                          label="Nro insc registral"
                          name="registration_number"
                          rules={[
                            {
                              required: true,
                              message: "Ingrese número de inscripción",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            placeholder="Ingrese número"
                            maxLength={20}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} xl={6}>
                        <Form.Item
                          label="Fecha contrato"
                          name="contract_date"
                          rules={[
                            {
                              required: true,
                              message: "Ingrese fecha de contrato",
                            },
                          ]}
                        >
                          <Datepicker
                            showTime
                            placeholder="Seleccione fecha"
                            format={DEFAULT_FORMAT_DATE}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={12} xl={6}>
                        <Form.Item
                          label="Actividad principal"
                          name="main_activity"
                          rules={[
                            {
                              required: true,
                              message: "Ingrese actividad principal",
                            },
                          ]}
                        >
                          <Input
                            type="text"
                            placeholder="Ingrese actividad principal"
                            maxLength={100}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
            </Panel>
          </Collapse>
          <Collapse className="collapse" defaultActiveKey={["2"]}>
            <Panel header="Datos del vehículo" key="2">
              <Row gutter={40}>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Seleccione número de chasis",
                      },
                    ]}
                    label="Número de chasis"
                    name="chasis_number"
                  >
                    <Input
                      type="text"
                      maxLength={30}
                      placeholder="Ingrese número de chasis"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Ingrese número de motor" },
                    ]}
                    label="Número de motor"
                    name="motor"
                  >
                    <Input
                      type="text"
                      maxLength={30}
                      placeholder="Ingrese número de motor"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[{ required: true, message: "Ingrese patente" }]}
                    label="Patente"
                    name="patent"
                  >
                    <Input
                      type="text"
                      maxLength={20}
                      placeholder="Ingrese patente"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      { required: true, message: "Seleccione carroceria" },
                    ]}
                    label="Carroceria"
                    name="bodywork"
                  >
                    <Select
                      placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      onChange={handleChangeBodyWorkTypes}
                    >
                      {bodyworkTypes.length !== 0 &&
                        bodyworkTypes.map((item) => (
                          <Option value={item.id_liderar} key={item.id}>
                            {camelCase(item.description)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={40}>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Seleccione tipo de combustible",
                      },
                    ]}
                    label="Tipo de combustible"
                    name="fuel_type"
                  >
                    <Select
                      placeholder="Seleccione"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentElement
                      }
                      dropdownMatchSelectWidth={false}
                      onChange={handleChangeFuelType}
                    >
                      {fuelTypes.length !== 0 &&
                        fuelTypes.map((item) => (
                          <Option value={item.id} key={item.id}>
                            {camelCase(item.description)}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Collapse className="collapse" defaultActiveKey={["2"]}>
            <Panel header="Fotos del vehículo" key="3">
              <Row gutter={40}>
                <Col xs={24} md={12} xl={6}>
                  <Form.Item name="upload" valuePropName="imageList" noStyle>
                    <Upload
                      listType="picture"
                      type="select"
                      maxCount={4}
                      beforeUpload={(file) => {
                        if (!fileTypes.includes(file.type)) {
                          messageError({
                            context: "Upload.beforeUpload.1",
                            message: "El formato del archivo es incorrecto",
                          });
                          return Upload.LIST_IGNORE;
                        }
                        if (
                          uploadedFiles &&
                          Object.keys(uploadedFiles).length >= 4
                        ) {
                          messageError({
                            context: "Upload.beforeUpload.2",
                            message: "Máximo de archivos alcanzado",
                          });
                          return Upload.LIST_IGNORE;
                        }
                        uploadedFiles
                          ? setUploadedFiles(() => ({
                              ...uploadedFiles,
                              [file.uid]: file,
                            }))
                          : setUploadedFiles(() => ({ [file.uid]: file }));
                        return fileTypes.includes(file.type)
                          ? false
                          : Upload.LIST_IGNORE;
                      }}
                      onRemove={(data) => {
                        if (uploadedFiles) {
                          delete uploadedFiles[data.uid];
                        }
                        const newUploadedFiles = { ...uploadedFiles };
                        setUploadedFiles(() => newUploadedFiles);
                      }}
                    >
                      <Button className="button-upload">
                        Subir archivo
                        <CloudUploadOutlined />
                      </Button>
                      <div className="texto-upload">
                        Formatos válidos: {fileTypesNames.join(", ")}
                      </div>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <LegalRepresentativeFromItems
            form={form}
            provinces={provinces}
            setpostalCodeLegalRepresentative={setpostalCodeLegalRepresentative}
            setLegalRepresentativeGender={setLegalRepresentativeGender}
            setLegalRepresentativeNationalityName={
              setLegalRepresentativeNationalityName
            }
            setLegalRepresentativeProvinceName={
              setLegalRepresentativeProvinceName
            }
            gendersTypes={gendersTypes}
            nationalities={nationalities}
          />

          <PledgedCreditorFormItems
            form={form}
            provinces={provinces}
            setAcreedorProvinceName={setAcreedorProvinceName}
            setPostalCodePledgedCreditor={setPostalCodePledgedCreditor}
          />
          <div className="buttons">
            <ButtonLiderar
              color="white"
              buttonName="Volver"
              onClick={() => history.goBack()}
              loading={isLoading}
            />
            <ButtonLiderar
              color="violet"
              buttonName="Emitir póliza"
              className="form-button search-button"
              htmlType={lifePolicyModal() ? "button" : "submit"}
              onClick={() =>
                lifePolicyModal()
                  ? ModalConfirm({
                      onOk: () => form.submit(),
                      title: "Atención",
                      content:
                        "Al emitir la póliza, se generará una Póliza de Vida asociada a la de auto, solicite conformidad al asegurado y seleccione Confirmar",
                    })
                  : onFinish
              }
              loading={isLoading}
            />
          </div>
        </Form>
        {
          <ModalSummary
            onDownloadRequest={(...args) => {
              sendDownloadRequest(...args);
            }}
            onCancel={() => {
              setModalSummaryVisible(false);
              navigate("/app/home");
              setClosedSummary(true);
            }}
            data={issuedPolicySummary}
            onOk={() => {
              setModalSummaryVisible(false);
              setClosedSummary(true);
              navigate("/app/home");
            }}
            modalVisible={modalSummaryVisible}
            titleModal="Resumen Póliza Emitida"
          />
        }
      </div>
    )
  );
};

export default Emission;
