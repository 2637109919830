import { DashboardFilled } from "@ant-design/icons";
import { Card, Col, Row } from "antd";

import { IQuotationForEmision } from "../../../services/IQuotationForEmision";
import { formatStringToMoney } from "../../../shared/utils";

interface ICardAccesories {
  quotation: IQuotationForEmision | undefined;
}

const CardAccessories = (props: ICardAccesories): JSX.Element => {
  const { quotation } = props;
  const dome = quotation?.quotation_request?.dome;
  const special_tires = quotation?.quotation_request?.special_tires;
  const cng = quotation?.quotation_request?.cng;
  const cold_equipment = quotation?.quotation_request?.cold_equipment;
  const total_accessories = quotation?.quotation_request?.total_accessories;
  return (
    <Card bordered={false} className="ant-card-1">
      <Col span={2}>
        <DashboardFilled
          className="icon"
          style={{ fontSize: "30px", color: "#763790" }}
        />
      </Col>
      <Col span={12}>
        <Row style={{ display: "flex", flexDirection: "column" }}>
          <p>
            Cúpula: <b>{formatStringToMoney(dome || "0")}</b>
          </p>
          <p>
            Equipo de frío: <b>{formatStringToMoney(cold_equipment || "0")}</b>
          </p>
        </Row>
      </Col>
      <Col
        span={10}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <p>
          GNC: <b>{formatStringToMoney(cng || "0")}</b>
        </p>
        <p>
          Llantas especiales: <b>{formatStringToMoney(special_tires || "0")}</b>
        </p>
        <p>
          Total accesorios:{" "}
          <b>{formatStringToMoney(total_accessories || "0")}</b>
        </p>
      </Col>
    </Card>
  );
};

export default CardAccessories;
