export const DOCUMENT_TYPES = `query document_types(
  $filter: FilterDocumentTypeInput
  $orderBy: DocumentTypeOrderInput
  $skip: Int
  $take: Int
  $searchText:String
) {
  document_types(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take,searchText:$searchText) {
    __typename
    ... on DocumentType {
      id
      description
      id_liderar
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
