import { PageLoading } from "@ant-design/pro-layout";
import Checkbox from "antd/lib/checkbox/Checkbox";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useRef, useState } from "react";
import "./style.less";
/**
 * Las opciones se renderizan con una función en el componenete renderLabel.
 * Ejemplo:
 * renderLabel={renderModalConfigureLabel}
 * 
 * dentro del componente padre (PumpOrder en este caso). Defino la función de renderizado
 * const renderModalConfigureLabel: IModalConfigureMultipleProps<IOperator>["renderLabel"] =
    (entity: IOperator) => {
      return `${entity.lastname}, ${entity.firstname} (${entity.employee_code})`;
    };
 */
export interface IModalConfigureMultipleProps<T> {
  data: any;
  modalVisible: boolean;
  checks: (any & {
    id: number;
  })[];
  onCancel: () => void;
  onOk: (id: number, add: number[], remove: number[]) => Promise<void>;
  titleModal: string;
  renderLabel: (entity: T) => React.ReactNode;
  isStringArray?: boolean;
  loading?: boolean;
  readOnly?: boolean;
}

export default function ModalConfigureMultiple<T>(
  props: IModalConfigureMultipleProps<T>
): JSX.Element {
  const [originalIds, setOriginalIds] = useState<number[]>([]);
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  const [unCheckedIds, setUncheckedIds] = useState<number[]>([]);
  const {
    modalVisible,
    onCancel,
    checks,
    titleModal,
    data,
    onOk,
    renderLabel,
    isStringArray,
    loading,
    readOnly,
  } = {
    ...props,
  };
  const preventDataChange = useRef<boolean>(false);
  const cleanUpState = () => {
    setCheckedIds(() => []);
    setUncheckedIds(() => []);
    setOriginalIds(() => []);
  };

  useEffect(() => {
    if (!preventDataChange.current) {
      cleanUpState();
      if (modalVisible) {
        data[1].map((checkId: number) =>
          setCheckedIds((oldCheckedIds) => [...oldCheckedIds, checkId])
        );
        data[1].map((checkId: number) =>
          setOriginalIds((oldCheckedIds) => [...oldCheckedIds, checkId])
        );
      }
    }
  }, [data, modalVisible]);

  const handleCheck = (id: number) => {
    if (checkedIds.some((checkedId) => checkedId === id)) {
      setCheckedIds(() => checkedIds.filter((checkedId) => checkedId !== id));
      setUncheckedIds(() => [...unCheckedIds, id]);
    } else {
      setUncheckedIds(() =>
        unCheckedIds.filter((unCheckedId) => unCheckedId !== id)
      );
      setCheckedIds(() => [...checkedIds, id]);
    }
  };

  const remove = (originalIdsParam: number[], unchecked: number[]) => {
    return unchecked.filter((value) => originalIdsParam.includes(value));
  };

  const add = (originalIdsParam: number[], checked: number[]) => {
    return checked.filter((item) => originalIdsParam.indexOf(item) < 0);
  };

  return (
    <>
      <Modal
        title={titleModal}
        destroyOnClose
        className="modal-configure-multiple"
        visible={modalVisible}
        onCancel={() => onCancel()}
        onOk={() => {
          preventDataChange.current = true;
          onOk(
            data[0],
            add(originalIds, checkedIds),
            remove(originalIds, unCheckedIds)
          ).finally(() => {
            preventDataChange.current = false;
          });
        }}
      >
        {loading ? (
          <PageLoading />
        ) : (
          <div className="containerChecks">
            {checks.map((check) => {
              let checkValue = check.id;
              if (isStringArray) {
                checkValue = check;
              }
              return (
                <Checkbox
                  key={checkValue}
                  onChange={() => handleCheck(checkValue)}
                  checked={checkedIds.some(
                    (checkedId) => checkedId === checkValue
                  )}
                  disabled={readOnly}
                >
                  {renderLabel(check)}
                </Checkbox>
              );
            })}
          </div>
        )}
      </Modal>
    </>
  );
}
