import { useState, useCallback, useLayoutEffect, useEffect, memo } from "react";
import { Select, Spin, FormInstance, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ICuitOnline } from "../../services/CuitOnline";

import LoadingModal from "../LoadingModal/LoadingModal";

export interface ISelectFetchOnEnterProps {
  query: (value: string) => Promise<ICuitOnline[]>;
  functionToFormatData: (data: ICuitOnline) => {
    value: string;
    label: string[];
    key: string;
  };
  optionRender: (item: any, index: number) => JSX.Element;
  isLoading?: boolean;
  onChange?: (value: string | number | undefined, data?: any) => void;
  onSelect?: (value: any) => void;
  onClear?: () => void;
  placeholder?: string;
  initialValue?: any;
  initialData?: any[];
  disabled?: boolean;
  form?: FormInstance;
  className?: string;
}

const SelectFetchOnEnter = memo((props: ISelectFetchOnEnterProps) => {
  const {
    query,
    functionToFormatData,
    isLoading,
    optionRender,
    onChange,
    onSelect,
    onClear,
    placeholder,
    initialValue = undefined,
    initialData = [],
    disabled,
    className,
  } = props;

  const [value, setValue] = useState(initialValue);
  const [data, setData] = useState<ICuitOnline[]>(initialData);

  const fetchData = useCallback((searchValue: number): void => {
    query(String(searchValue)).then(setData);
  }, []);

  const handleClear = () => {
    if (onClear) {
      onClear();
    }
    setData([]);
  };

  const handleSelect = (element: any) => {
    if (onSelect) {
      onSelect(element);
    }
  };

  const handleChange = (searchValue: any) => {
    if (onChange) {
      onChange(searchValue, data);
    }

    setValue(searchValue);
    setData([]);
  };

  useLayoutEffect(() => {
    if (initialValue) {
      initialFetch(initialValue);
    }
  }, [initialValue]);

  const initialFetch = async (valueParam: number) => {
    await fetchData(valueParam);
  };

  useEffect(() => {
    if (data.length === 1 && functionToFormatData) {
      handleChange(functionToFormatData(data[0]));
    }
  }, [data, functionToFormatData]);

  return (
    <>
      <Tooltip placement="top" title="Ingrese número y presione enter">
        <Select
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          showSearch
          allowClear
          className={className}
          value={value}
          dropdownMatchSelectWidth={false}
          placeholder={placeholder}
          labelInValue
          defaultOpen={data.length > 1}
          optionFilterProp="children"
          notFoundContent={isLoading && <Spin size="small" />}
          filterOption={false}
          onInputKeyDown={(e: any) => {
            if (e.key === "Enter") {
              e.preventDefault();
              fetchData(e.target.value);
            }
          }}
          loading={isLoading}
          onClear={handleClear}
          onChange={handleChange}
          onSelect={handleSelect}
          onBlur={() => {
            setData([]);
          }}
          suffixIcon={<SearchOutlined />}
          disabled={disabled}
        >
          {data && data.map((d, index) => optionRender(d, index))}
        </Select>
      </Tooltip>
      <LoadingModal
        message="Cargando datos de CUIT / CUIL"
        showModal={isLoading || false}
        title="Espere por favor"
      />
    </>
  );
});
export default SelectFetchOnEnter;
