export const UPSERT_DESTINY = `
mutation upsertDestiny($key: String!) {
  upsertDestiny(key: $key){
    __typename
    ... on SyncBoolean{
      status
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
