import { useEffect, useState, useContext } from "react";
import { Badge, Button, Form, Input, Result } from "antd";
import {
  LockOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from "@ant-design/icons";
import { PageLoading } from "@ant-design/pro-layout";
import GraphqlService from "../../services/graphql/GraphqlService";
import { Query } from "../../services/graphql/query";
import { CustomMessage, EnumsValues } from "../../shared";
import { IAppSetting } from "../../services/AppSetting";
import "./style.less";
import { ContextApp } from "../../shared/ContextApp";
import { NavContext } from "@ionic/react";

interface IDataSetPassword {
  password: string;
  confirmPassword: string;
}

const FirstPasswordSet = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordReseted, setPasswordReseted] = useState<boolean>(false);
  const [validToken, setValidToken] = useState<boolean | null>(null);
  const [passwordRegex, setPasswordRegex] = useState<IAppSetting>();
  const [token] = useState<string | null>(localStorage.getItem("token"));
  const { checkAuth } = useContext(ContextApp);
  const { navigate } = useContext(NavContext);
  const { customRequest, Mutation } = GraphqlService();
  const { messageError } = CustomMessage();

  const validateToken = async (userToken: string) => {
    try {
      const response = await customRequest({
        mutation: Query.validateToken,
        variables: { token: userToken },
      });
      if (response) {
        setValidToken(true);
      }
    } catch (error) {
      setValidToken(false);
      navigate("/login");
    }
  };

  useEffect(() => {
    getRegex();
    if (token) {
      validateToken(token);
    } else {
      navigate("/login");
    }
  }, [token]);

  const onFinish = (values: any) => {
    const { password } = values as IDataSetPassword;
    setLoading(true);
    setPasswordReseted(false);
    setValidToken(true);
    setLoading(false);
    customRequest({
      mutation: Mutation.setPassword,
      variables: { password, token },
    })
      .then(() => {
        setPasswordReseted(true);
      })
      .catch((error: any) => {
        messageError({
          context: "SetPassword.onFinish.1",
          message: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
        checkAuth();
        navigate("/app/home");
      });
  };

  const getRegex = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.PasswordRegex },
        },
      })
        .then((data: IAppSetting) => {
          setPasswordRegex(() => data);
          return resolve();
        })
        .catch((error: any) => {
          messageError({
            context: "User.getRegex.1",
            message: error?.message,
          });
          return resolve();
        });
    });
  };

  return loading || validToken === null ? (
    <PageLoading />
  ) : (
    <div className="SetInitialPassword">
      {passwordReseted ? (
        <Result
          status="success"
          title="Contraseña guardada"
          extra={
            <Button className="form-button" type="primary" href="/">
              Volver al Login
            </Button>
          }
        />
      ) : (
        <Form
          name="set-password"
          className="SetPassword__form"
          layout="vertical"
          requiredMark={"optional"}
          onFinish={onFinish}
        >
          <h2 className="top-text">Por favor, cambie su contraseña</h2>
          <Form.Item
            name="password"
            label="Contraseña"
            rules={[
              { required: true, message: "Por favor ingrese una contraseña" },
              {
                validator(_, value) {
                  if (passwordRegex) {
                    const regex = new RegExp(
                      String(passwordRegex?.setting_value),
                      "g"
                    );
                    if (regex.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("La contraseña no cumple con los requisitos")
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password
              aria-label="Contraseña"
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <div className="messageRegExp">
            <Badge color="#606366" status="default" />
            <p className="textRegExp">{passwordRegex?.description}</p>
          </div>
          <Form.Item
            name="confirmPassword"
            label="Repetir contraseña"
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "Por favor repita su contraseña" },
              ({ getFieldValue }) => ({
                validator(_rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Las contraseñas ingresadas no coinciden")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              aria-label="Repetir contraseña"
              type="password"
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
          <Form.Item>
            <Button className="form-button" type="primary" htmlType="submit">
              Cambiar contraseña
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default FirstPasswordSet;
