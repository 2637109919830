export const CREATE_EMISSION = `mutation createEmission(
  $input: EmissionRequestInput!
  $quotationId: Int
  $renovation_id: Int
  $pledgedCreditor: PledgedCreditorInput
  $naturalPerson: EmissionNaturalPersonInput
  $legalPerson: EmissionLegalPersonInput
  $legalRepresentative: LegalRepresentativeInput
) {
  createEmission(
    input: $input
    renovation_id: $renovation_id
    quotationId: $quotationId
    pledgedCreditor: $pledgedCreditor
    naturalPerson: $naturalPerson
    legalPerson: $legalPerson
    legalRepresentative: $legalRepresentative
  ) {
    __typename
    ... on Emission {
      id
      id_policy
      error_notice
      
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
