import { createContext } from "react";
import {
  coverageInitial,
  IAccessories,
  initialAccessories,
} from "../components/Quotation/PolizaLayout";
import { CoverageSelected } from "../services/ICoverage";
import { IQuotationRequest } from "../services/IQuotation";
import { IVehicleInfo } from "../services/IVehicleType";

interface IContextPoliza {
  setContextFormData: React.Dispatch<React.SetStateAction<any>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setVehicleInfo: React.Dispatch<React.SetStateAction<IVehicleInfo>>;
  vehicleInfo: any;
  loading: boolean;
  contextFormData: any | undefined;
  setQuotationError: React.Dispatch<React.SetStateAction<boolean>>;
  quotationError: boolean;
  setCoverageSelected: React.Dispatch<React.SetStateAction<CoverageSelected>>;
  coverageSelected: CoverageSelected;
  setAccessories: React.Dispatch<React.SetStateAction<IAccessories>>;
  accessories: IAccessories;
  setFiscalCondition: React.Dispatch<React.SetStateAction<string>>;
  FiscalCondition: string;
  setQuotationRequest: React.Dispatch<
    React.SetStateAction<IQuotationRequest | undefined>
  >;
  quotationRequest: IQuotationRequest | undefined;
}

const context: IContextPoliza = {
  setContextFormData: () => {
    // Intentional
  },
  setLoading: () => {
    // Intentional
  },
  setVehicleInfo: () => {
    // Intentional
  },
  vehicleInfo: {},
  loading: false,
  contextFormData: undefined,
  setQuotationError: () => {
    // Intentional
  },
  quotationError: false,
  setCoverageSelected: () => {
    // Intentional
  },
  coverageSelected: coverageInitial,
  setAccessories: () => {
    // Intentional
  },
  accessories: initialAccessories,
  setFiscalCondition: () => {
    // Intentional
  },
  FiscalCondition: "",

  setQuotationRequest: () => {
    // Intentional
  },
  quotationRequest: undefined,
};

export const ContextPoliza = createContext(context);
