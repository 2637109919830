export const SAVE_FILE = `
mutation saveFile($file: Upload!, $input: IFileInput!, $licensePlate: String){
  saveFile(input: $input, file:$file, licensePlate: $licensePlate){
    __typename
    ... on IFile{
      id
      its
      uts
      dts
      filename
      file_type_id
      mimetype
      encoding
    }
    ... on ResultError{
      status_code
      message
    }
  }
}
`;
