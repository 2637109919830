import { Col } from "antd";
import "./style.less";

interface Props {
  property: string;
  value: string;
}

const PolizaDetailsItem: React.FC<Props> = ({ property, value }) => {
  return (
    <div className="PolizaDetailsItem">
      <Col span={8} className="detail-property">
        {property}
      </Col>
      <Col span={14} className="detail-value">
        {value}
      </Col>
    </div>
  );
};

export default PolizaDetailsItem;
