import { ParamsType } from "@ant-design/pro-provider";
import ProTable, { ActionType } from "@ant-design/pro-table";
import { Image, Input, Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import { IBaseFile } from "../../../services/defaultSchema";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { IPublication } from "../../../services/Publication";
import { ABM, CustomMessage, Tools } from "../../../shared";
import { ExportableColumn } from "../../../shared/Exporter";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";
import { EnumsValues } from "../../../shared/EnumsValues";
import CustomFilter from "../../CustomFilter/CustomFilter";
import pdfIcon from "../../../assets/pdf-icon.png";

export interface IPublicationsDownloadPageProps {
  publicationType: "forms" | "manuals" | "circulars";
  title: string;
}

const LIST_FILTER = ["description"];

const PublicationsDownloadPage = (
  props: IPublicationsDownloadPageProps
): JSX.Element => {
  const { publicationType, title } = props;

  let publicationTypeName: number;

  switch (publicationType) {
    case "forms":
      publicationTypeName = EnumsValues.PublicationType.Forms;
      break;
    case "manuals":
      publicationTypeName = EnumsValues.PublicationType.Manuals;
      break;
    case "circulars":
      publicationTypeName = EnumsValues.PublicationType.Circulars;
      break;
  }

  const history = useHistory();
  const defaultPageSize = 20;
  const { Query, customRequest } = GraphqlService();
  const { messageError } = CustomMessage();

  const [data, setData] = useState<IPublication[]>([]);

  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  const request = useCallback(
    async (
      _params: ParamsType & {
        pageSize?: number;
        current?: number;
        keyword?: string;
      }
    ) => {
      try {
        delete variables.current.filter;
        variables.current = {};
        const search: any = ABM.valuesResult(_params);

        LIST_FILTER.forEach((element) => {
          try {
            if (search[element]) {
              if (!variables.current.filter) {
                variables.current.filter = {};
              }
              variables.current.filter[element] = search[element];
            }
          } catch (error) {
            // este error esta contemplado porque seguro el filtro que busca no se encuentra
          }
        });

        const publications: IPublication[] = await customRequest({
          query: Query.publications,
          variables: {
            filter: {
              visualiza: "Si",
              publication_type_id: publicationTypeName,
              ...variables.current.filter,
            },
            orderBy: {
              field: "description",
              direction: "asc",
            },
          },
        });
        setData(publications);
        return {
          current: 1,
          data,
          pageSize: "1",
          success: true,
          total: data.length,
        };
      } catch (error: any) {
        return {
          current: 1,
          data: [],
          pageSize: "1",
          success: true,
          total: 0,
        };
      }
    },
    []
  );

  useEffect(() => {
    request({});
  }, []);

  const handleDownload = useCallback(async (record: IPublication) => {
    try {
      const result: IBaseFile = await customRequest({
        query: Query.publicationFile,
        variables: {
          id: record.id,
        },
      });
      if (!result.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(result);
    } catch (error: any) {
      console.error(error);
      messageError({
        context: "PublicationsDownloadsPage.handleDownload",
        message: error.message || "Ocurrió un error al descargar el archivo",
      });
    }
  }, []);

  const columns = useCallback(
    (): ExportableColumn<IPublication>[] => [
      {
        export: true,
        dataIndex: "description",
        title: "Descripción",
        align: "left",
        render: (_, record) => record.description || "",
        renderFormItem: () => <Input placeholder="Ingrese descripción" />,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: "pub_archivo",
        title: "Nombre del archivo",
        align: "left",
        render: (_, record) => record.pub_archivo || "",
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "op",
        title: "Op.",
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            <Tooltip title="Descargar archivo">
              <Image
                className="pointer"
                preview={false}
                src={pdfIcon}
                style={{ width: 30 }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleDownload(record);
                }}
              />
            </Tooltip>
          </>
        ),
      },
    ],
    []
  );

  const handleTableChange = useCallback(() => request({}), []);

  return (
    <>
      <h1>{title}</h1>
      <CustomFilter header="Filtrar por" />
      <ProTable<IPublication>
        defaultSize="small"
        id="PublicationDownloadsPageTableForAdmin"
        actionRef={actionRef}
        rowKey="id"
        search={{
          collapsed: false,
          resetText: "Limpiar búsqueda",
          searchText: "Buscar",
          collapseRender: false,
        }}
        options={{
          reload: handleTableChange,
        }}
        dataSource={data}
        columns={columns()}
        request={async (_params, _sorter, _filter) =>
          request({ ..._params, _sorter, _filter })
        }
        scroll={{ x: "scroll" }}
        pagination={{
          defaultCurrent: 1,
          defaultPageSize,
        }}
      />
      <div className="buttons">
        <ButtonLiderar
          color="white"
          buttonName="Volver"
          className="button-left"
          onClick={() => history.goBack()}
        />
      </div>
    </>
  );
};

export default PublicationsDownloadPage;
