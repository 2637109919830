import { useCallback, useRef, useState, useContext } from "react";
import { SorterResult } from "antd/lib/table/interface";
import { useHistory } from "react-router";
import { RouteConfigComponentProps } from "react-router-config";
import { DatePicker, Form, Input } from "antd";

import ProTable, { ActionType } from "@ant-design/pro-table";
import { ExportableColumn } from "../../shared/Exporter";
import GraphqlService from "../../services/graphql/GraphqlService";
import { ABM, MomentJS, Tools } from "../../shared";
import CustomFilter from "../CustomFilter/CustomFilter";
import ButtonLiderar from "../ButtonLiderar/ButtonLiderar";
import { IQuotation } from "../../services/IQuotation";
import { formatStringToMoney, getPaginationArgs } from "../../shared/utils";
import {
  FORMAT_DATE_TIME_1,
  FORMAT_DATE_TIME_2,
  FORMAT_DATE_TIME_4,
} from "../../shared/MomentJS";
import { NavContext } from "@ionic/react";
import { useCheckAuthority } from "../../shared/CustomHooks";
import { ParamsType } from "@ant-design/pro-provider";
import { coverageId } from "../../shared/EnumsValues";

import "./style.less";

/**
 * Configure manualmente los campos de filtrado
 */
const TITLE_TABLE = "Cotizaciones";
const LIST_FILTER = [
  "name",
  "producer",
  "brand",
  "model",
  "year",
  "its_range",
  "numerator_id",
];
const LIST_SORTER = [
  "name",
  "producer",
  "brand",
  "model",
  "year",
  "dni_cuit",
  "vehicle",
  "coverage",
  "total_value",
  "total_ice",
  "total_no_ice",
  "username",
  "its",
  "numerator_id",
];

export default function QuotationList(
  routeProps: RouteConfigComponentProps
): JSX.Element {
  // // props

  // // states
  const [sorter, setSorter] = useState<string>("");

  // // services and hooks
  const { Query, customRequest } = GraphqlService();
  const history = useHistory();
  const { navigate } = useContext(NavContext);
  // // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});

  // // methods

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      const search: any = ABM.valuesResult(params);

      LIST_FILTER.forEach((element) => {
        try {
          if (search[element]) {
            if (!variables.current.filter) {
              variables.current.filter = {};
            }
            variables.current.filter[element] = search[element];
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      variables.current.filter = {
        ...variables.current.filter,
        renovation: false,
      };

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const countPromise = customRequest({
        query: Query.countQuotations,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const dataPromise = customRequest({
        query: Query.quotations,
        variables: variables.current,
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);

      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const columns = useCallback(
    (): ExportableColumn<IQuotation>[] => [
      {
        export: false,
        dataIndex: "id",
        title: "id",
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: false,
        dataIndex: "numerator_id",
        title: "Id",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IQuotation) =>
          record?.quotation_request?.numerator_id || "-",
        renderFormItem: () => <Input placeholder="Ingrese id..." />,
      },
      {
        export: false,
        dataIndex: "its",
        title: "Fecha solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        render: (_: any, record: IQuotation) => (
          <p className="non-wrap-cell">
            {record.its
              ? MomentJS.specificMoment(
                  {
                    date: record.its,
                    format: FORMAT_DATE_TIME_2,
                    utc: true,
                  },
                  FORMAT_DATE_TIME_1,
                  true
                )
              : "-"}
          </p>
        ),
        align: "left",
        sorter: true,
        defaultSortOrder: "descend",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "its_range",
        title: "Fecha solicitud",
        type: ABM.TYPE_COLUMN.DATE,
        renderFormItem: () => (
          <DatePicker.RangePicker
            name="its_range"
            format={FORMAT_DATE_TIME_4}
            placeholder={["Fecha desde", "Fecha hasta"]}
            allowEmpty={[true, true]}
            className="renderFormItem"
            allowClear
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "username",
        title: "Usuario que cotizó",
        sorter: true,
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IQuotation) =>
          record.createdBy.username || "-",
      },
      {
        export: false,
        dataIndex: "dni_cuit",
        title: "CUIT",
        sorter: true,
        align: "left",
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record: IQuotation) =>
          record?.quotation_request?.dni_cuit || "-",
      },
      {
        export: false,
        dataIndex: "name",
        title: "Nombre del asegurado",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IQuotation) =>
          record?.quotation_request?.name || "-",
        renderFormItem: () => (
          <div>
            <Form.Item name="name">
              <Input
                placeholder="Ingrese nombre del asegurado..."
                className="renderFormItem"
              />
            </Form.Item>
          </div>
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "producer",
        title: "Productor",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IQuotation) =>
          record?.quotation_request?.producer?.nombre || "-",
        renderFormItem: () => (
          <div>
            <Form.Item name="producer">
              <Input
                placeholder="Ingrese productor..."
                className="renderFormItem"
              />
            </Form.Item>
          </div>
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "vehicle",
        title: "Vehículo",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IQuotation) =>
          record?.quotation_request?.destiny?.description || "-",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "coverage",
        title: "Cobertura",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IQuotation) =>
          record?.coverage?.descrip_web || "-",
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "brand",
        title: "Marca",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IQuotation) =>
          record?.quotation_request?.brand_model?.brand || "-",
        renderFormItem: () => (
          <div>
            <Form.Item name="brand">
              <Input
                placeholder="Ingrese marca..."
                className="renderFormItem"
              />
            </Form.Item>
          </div>
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "model",
        title: "Modelo",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IQuotation) =>
          record?.quotation_request?.brand_model?.model || "-",
        renderFormItem: () => (
          <div>
            <Form.Item name="model">
              <Input
                placeholder="Ingrese modelo..."
                className="renderFormItem"
              />
            </Form.Item>
          </div>
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "year",
        title: "Año",
        type: ABM.TYPE_COLUMN.STRING,
        render: (_: any, record: IQuotation) =>
          record?.quotation_request?.year || "-",
        renderFormItem: () => (
          <div>
            <Form.Item name="year">
              <Input
                type="number"
                placeholder="Ingrese año..."
                className="renderFormItem"
              />
            </Form.Item>
          </div>
        ),
        align: "left",
        sorter: true,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "total_value",
        title: Tools.leftAlign("Suma asegurada"),
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record: IQuotation) => (
          <p className="non-wrap-cell">
            {formatStringToMoney(
              record.coverage_id === coverageId.A
                ? "0"
                : record?.quotation_request?.insured_amount
            )}
          </p>
        ),
        align: "right",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "total_ice",
        title: Tools.leftAlign("Premio con granizo"),
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record: IQuotation) => (
          <p className="non-wrap-cell">
            {formatStringToMoney(record?.total_ice)}
          </p>
        ),
        align: "right",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
      {
        export: false,
        dataIndex: "total_no_ice",
        title: Tools.leftAlign("Premio sin granizo"),
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_: any, record: IQuotation) => (
          <p className="non-wrap-cell">
            {formatStringToMoney(record?.total_no_ice)}
          </p>
        ),
        align: "right",
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
      },
    ],
    []
  );

  return (
    useCheckAuthority(routeProps) || (
      <>
        <h1>{TITLE_TABLE}</h1>
        <CustomFilter header="Filtrar por" />
        <ProTable<IQuotation>
          defaultSize="small"
          actionRef={actionRef}
          rowKey="id"
          search={{
            collapsed: false,
            resetText: "Limpiar búsqueda",
            searchText: "Buscar",
            collapseRender: false,
          }}
          onChange={(_, _filter, _sorter) => {
            const sorterResult = _sorter as SorterResult<IQuotation>;
            if (sorterResult.field) {
              setSorter(`${sorterResult.field}_${sorterResult.order}`);
            }
          }}
          onRow={(record) => {
            return {
              onClick: () =>
                navigate(`/app/policy/emission/quotationid/${record.id}`),
            };
          }}
          params={{
            sorter,
          }}
          className="quotation-table"
          request={async (_params, _sorter, _filter) =>
            request({ ..._params, _sorter, _filter })
          }
          columns={columns()}
          scroll={{ x: "scroll" }}
        />
        <div className="buttons">
          <ButtonLiderar
            color="white"
            buttonName="Volver"
            className="button-left"
            onClick={() => history.goBack()}
          />
        </div>
      </>
    )
  );
}
