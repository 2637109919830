export const FUEL_TYPES = `
query fuel_types(
  $orderBy: FuelTypeOrderInput
  $filter: FilterFuelTypeInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  fuel_types(
    skip: $skip
    take: $take
    orderBy: $orderBy
    filter: $filter
    searchText: $searchText
  ) {
    __typename
    ... on  FuelType{
      id
      description
      id_liderar
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
