import "./style.less";

const NoResults: React.FC = () => {
  return (
    <div className="NoResults">
      <img src="/assets/search.png" alt="search" />
      <h1>No hay datos para mostrar</h1>
      <p>Utiliza el buscador para encontrar pólizas</p>
    </div>
  );
};

export default NoResults;
