const ABM = (): any => {
  //Intentional
};

ABM.TYPE_COLUMN = {
  STRING: "STRING",
  NUMBER: "NUMBER",
  BOOLEAN: "BOOLEAN",
  DATE: "DATE",
};

ABM.valuesResult = (set: any) => {
  const values: any = {};
  Object.keys(set).forEach((key) => {
    if (set[key]) {
      values[key] = set[key];
    }
  });
  return values;
};

ABM.valuesResultWithFalsyValues = (columns: any, set: any) => {
  const values: any = {};
  columns.map((column: any) => {
    if (column.renderFormItem && column.dataIndex) {
      if (set[column.dataIndex] && column.dataIndex !== "id") {
        values[column.dataIndex] = set[column.dataIndex];
      } else if (column.dataIndex !== "id") {
        if (
          (!column.formItemProps ||
            !column.formItemProps?.rules ||
            column.formItemProps?.rules?.some((item: any) => !item.required)) &&
          !column.hideInForm
        ) {
          if (column.type === ABM.TYPE_COLUMN.BOOLEAN) {
            values[column.dataIndex] = false;
          } else if (column.type === ABM.TYPE_COLUMN.NUMBER) {
            values[column.dataIndex] = undefined;
          } else {
            values[column.dataIndex] = null;
          }
        }
      }
    }
  });
  return values;
};

ABM.parseObjByConfigColumn = (
  columns: any[],
  values: any,
  notIgnoreFalsyValues?: boolean
): any => {
  const getColumnsTypes = (): any[] => {
    const list: any[] = [];
    columns.forEach((element) => {
      if (element.type) {
        list[element.dataIndex] = element.type;
      }
    });
    return list;
  };
  const typesC: any = getColumnsTypes();
  let valuesP: any;
  if (notIgnoreFalsyValues) {
    valuesP = ABM.valuesResultWithFalsyValues(columns, values);
  } else {
    valuesP = ABM.valuesResult(values);
  }
  Object.keys(valuesP).forEach((key) => {
    switch (typesC[key]) {
      case ABM.TYPE_COLUMN.STRING:
        valuesP[key] = `${valuesP[key]}`;
        break;
      case ABM.TYPE_COLUMN.NUMBER:
        valuesP[key] = Number(valuesP[key]);
        break;
      case ABM.TYPE_COLUMN.BOOLEAN:
        if (valuesP[key] === "false") {
          valuesP[key] = false;
        }
        if (valuesP[key] === "true") {
          valuesP[key] = true;
        }
        break;
      case ABM.TYPE_COLUMN.DATE:
        valuesP[key] = new Date(valuesP[key]).toISOString();
        break;
      default:
        break;
    }
  });
  return valuesP;
};

export { ABM };
