export const VEHICLE_TYPES = `query vehicle_types(
  $orderBy: VehicleTypeOrderInput
  $filter: FilterVehicleTypeInput
  $searchText: String
  $skip: Int
  $take: Int
) {
  vehicle_types(
    skip: $skip
    take: $take
    orderBy: $orderBy
    filter: $filter
    searchText: $searchText
  ) {
    __typename
    ... on VehicleType {
      id
      description
      id_liderar
      codigo_tarifacion
      destiny_list
      destiny_array
      activo_web
      by_default
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
