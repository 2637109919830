import { Image } from "antd";
import { useEffect, useMemo, useState } from "react";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { CustomMessage } from "../../../shared";
import { IImageData } from "./VehiclePicture";

interface IPicturesProps {
  imagesIds: number[];
}

const Pictures = (props: IPicturesProps): JSX.Element => {
  const { imagesIds } = props;

  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();

  const [imagesData, setImagesData] = useState<IImageData[]>();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (imagesIds.length) {
      imagesIds.forEach((id) => getImageById(id));
    }
  }, [imagesIds]);

  const getImageById = async (id: number) => {
    try {
      const response: IImageData = await customRequest({
        query: Query.file,
        variables: {
          id,
        },
      });

      if (response) {
        setImagesData((prevState) =>
          prevState ? [...prevState, response] : [response]
        );
      }
    } catch (error: any) {
      messageError({
        context: "Pictures.getImageById.1",
        message: error?.message,
      });
    }
  };

  const imagesArr = useMemo(() => {
    return imagesData?.map((imageData, index: number) => {
      return (
        <Image
          height={320}
          width={200}
          key={index}
          src={`data:${imageData.mimetype};base64,${imageData.file}`}
          preview={{ getContainer: "#root" }}
          onClick={() => setVisible(true)}
        />
      );
    });
  }, [imagesData]);

  return (
    <>
      <>
        <>{imagesData?.length && <p>{imagesData?.length} fotos</p>}</>
        {imagesData?.length && (
          <Image
            height={320}
            width={"100%"}
            src={`data:${imagesData[0].mimetype};base64,${imagesData[0].file}`}
            preview={{ visible: false }}
            onClick={() => setVisible(true)}
          />
        )}
      </>
      <div style={{ display: "none" }}>
        {imagesArr ? (
          <Image.PreviewGroup
            preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
          >
            {imagesArr}
          </Image.PreviewGroup>
        ) : (
          <div className="not_image">
            <p>No hay fotos cargadas para el vehículo</p>
          </div>
        )}
      </div>
    </>
  );
};

export default Pictures;
