import { useState } from "react";
import { Link } from "react-router-dom";

import { Button, Form, Input, Result, Divider } from "antd";
import { PageLoading } from "@ant-design/pro-layout";
import { CustomMessage } from "../../shared";

import GraphqlService from "../../services/graphql/GraphqlService";

import "./style.less";

interface IDataResetPassword {
  email: string;
}

const ResetPassword = (): JSX.Element => {
  const { customRequest, Mutation } = GraphqlService();
  const { messageError } = CustomMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [resetPasswordSent, setResetPasswordSent] = useState<boolean>(false);

  //TODO: facundo: implementar funcionalidad.

  const onFinish = async (values: any) => {
    setLoading(true);

    const { email } = values as IDataResetPassword;

    customRequest({
      query: Mutation.resetPassword,
      variables: { email },
    })
      .then(() => {
        setResetPasswordSent(true);
      })
      .catch((error: any) => {
        messageError({
          context: "ResetPassword.onFinish.1",
          message: error.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return loading ? (
    <div className="loading">
      <PageLoading />
    </div>
  ) : (
    <div className="ResetPassword">
      {resetPasswordSent ? (
        <Result
          status="success"
          title="Petición enviada"
          subTitle="En caso de ser correcto el correo, enviamos un email con instrucciones para restablecer la contraseña"
          extra={
            <Button className="form-button" type="primary" href="/login">
              Volver
            </Button>
          }
        />
      ) : (
        <Form
          form={form}
          name="reset_password"
          layout="vertical"
          requiredMark={"optional"}
          onFinish={onFinish}
        >
          <h2 className="top-text">Ingresa tu email</h2>
          <p>
            Te enviaremos las instrucciones para que puedas recuperar tu
            contraseña
          </p>
          <Divider />
          <Form.Item
            className="formItem"
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message:
                  "Por favor, ingrese la dirección de correo electrónico asignada a su cuenta",
              },
              {
                type: "email",
                message: "Formato de email incorrecto",
              },
            ]}
          >
            <Input aria-label="email" className="inputForm" />
          </Form.Item>

          <Form.Item shouldUpdate>
            {({ getFieldsValue }) => {
              const { email } = getFieldsValue();
              const formIsComplete = !!email;
              return (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="form-button"
                  disabled={
                    !formIsComplete ||
                    !!form
                      .getFieldsError()
                      .filter(({ errors }) => errors.length).length
                  }
                >
                  Recuperar contraseña
                </Button>
              );
            }}
          </Form.Item>
          <Link to="/login" className="form-link">
            Volver al login
          </Link>
        </Form>
      )}
    </div>
  );
};

export default ResetPassword;
