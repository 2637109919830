import { useState } from "react";
import { renderRoutes, RouteConfigComponentProps } from "react-router-config";
import { ContextPoliza } from "../../shared/ContextPoliza";
import { IQuotationRequest } from "../../services/IQuotation";
import { CoverageSelected } from "../../services/ICoverage";
import { IVehicleInfo } from "../../services/IVehicleType";
import { IForm } from "../../services/IForm";
import { useCheckAuthority } from "../../shared/CustomHooks";

const vehicleInfoInitial: IVehicleInfo = {
  brand: "-",
  model: "-",
  year: "-",
  amount_insured: "-",
  period: "-",
  adjustment_rate: "-",
};

export const coverageInitial: CoverageSelected = {
  quotation_id: 0,
  name: "-",
  period: "-",
  total_ice: "-",
  total_no_ice: "-",
  details: "-",
  request_id: 0,
  coverage_id: 0,
};

export interface IAccessories {
  cng: string;
  cold_equipment: string;
  dome: string;
  special_tires: string;
  total_accessories: string;
}

export const initialAccessories: IAccessories = {
  cng: "",
  cold_equipment: "",
  dome: "",
  special_tires: "",
  total_accessories: "",
};

export const PolizaLayout = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  const [quotationError, setQuotationError] = useState<boolean>(false);
  const [accessories, setAccessories] =
    useState<IAccessories>(initialAccessories);
  const [FiscalCondition, setFiscalCondition] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [vehicleInfo, setVehicleInfo] =
    useState<IVehicleInfo>(vehicleInfoInitial);
  const [coverageSelected, setCoverageSelected] =
    useState<CoverageSelected>(coverageInitial);
  const [contextFormData, setContextFormData] = useState<IForm>();
  const [quotationRequest, setQuotationRequest] = useState<IQuotationRequest>();

  return (
    useCheckAuthority(routeProps) || (
      <ContextPoliza.Provider
        value={{
          setLoading,
          loading,
          setVehicleInfo,
          vehicleInfo,
          setQuotationError,
          quotationError,
          setCoverageSelected,
          coverageSelected,
          setContextFormData,
          contextFormData,
          accessories,
          setAccessories,
          setFiscalCondition,
          FiscalCondition,
          setQuotationRequest,
          quotationRequest,
        }}
      >
        {renderRoutes(routeProps?.route?.routes)}
      </ContextPoliza.Provider>
    )
  );
};
