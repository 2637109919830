export const GENDERS = `
query genders(
  $filter: FilterGenderInput
  $orderBy: GenderOrderInput
  $skip: Int
  $take: Int
  $searchText:String
) {
  genders(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take,searchText:$searchText) {
    __typename
    ... on  Gender{
      id
      description
      id_liderar
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
