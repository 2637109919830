export const IMPRESSIONS_FROM_UNIVERSE = `
  query impressionsFromUniverse($input: ImpressionsInput!){
    impressionsFromUniverse(input: $input){
      __typename
      ... on ImpressionsQueueRequests {
        id
      }
      ... on ResultError{
        status_code
        message
      } 
    }
  }
`;

export const LIST_DEBT_SUMMARY_QUEUE_REQUESTS = `
  query listDebtSummaryQueueRequests(
    $filter: FilterDebtSummaryQueueRequestsInput,
    $orderBy: DebtSummaryQueueRequestsOrderInput,
    $skip: Int,
    $take: Int
  ) {
    listDebtSummaryQueueRequests(
      filter: $filter,
      orderBy: $orderBy,
      skip: $skip,
      take: $take,
    ){
      __typename
      ... on DebtSummaryQueueRequests{
        id
        File
        request_id
        Pedido
        TIPO
        FECHA_CORTE
        COD_PROD
        Estado
        createdBy{
          username
        }
        status_queue_requests{
          id
          status
        }
        liderar_message
        producer {
          nombre
          id_liderar
        }
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`;

export const DEBT_SUMMARY_QUEUE_REQUESTS_COUNT = `
  query debtSummaryQueueRequestsCount(
    $filter: FilterDebtSummaryQueueRequestsInput,
    $orderBy: DebtSummaryQueueRequestsOrderInput,
  ) {
    debtSummaryQueueRequestsCount(
      filter: $filter,
      orderBy: $orderBy,
    ){
      __typename
      ... on Count{
        count
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`;

export const DEBT_SUMMARY_FROM_UNIVERSE = `
  query debtSummaryFromUniverse(
    $input: DebtSummaryInput!,
  ) {
    debtSummaryFromUniverse(
      input: $input,
    ){
      __typename
      ... on DebtSummaryQueueRequests {
        id
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`;

export const DEBT_SUMMARIES = `
  query debt_summaries(
    $filter: FilterDebtSummaryInput,
    $orderBy: DebtSummaryOrderInput,
    $skip: Int,
    $take: Int
  ) {
    debt_summaries(
      filter: $filter,
      orderBy: $orderBy,
      skip: $skip,
      take: $take,
    ){
      __typename
      ... on DebtSummary{
        id
        policy_number
        insured
        sum_amount
        is_selected
        queue_requests_id
        debt_summary_detail{
          id
          debt_summary_id
          policy_id
          instalment
          due_date
          amount
          patent
        }
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`;

export const DEBT_SUMMARY_COUNT = `
  query debtSummaryCount($filter: FilterDebtSummaryInput, $searchText: String){
    debtSummaryCount(
      filter: $filter,
      searchText: $searchText
    ){
      __typename
      ... on Count{
        count
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const DEBT_SUMMARY_SELECTED_TOTAL_AMOUNT = `
  query debtSummarySelectedTotalAmount($id: Int!){
    debtSummarySelectedTotalAmount(id: $id){
      __typename
      ... on ISum{
        sum
      }
      ... on ResultError{
        status_code
        message
      }
    }
  }
`;
