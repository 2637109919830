import { useState, useEffect, useMemo, useRef, ReactNode } from "react";
import moment from "moment";
import {
  Col,
  Modal,
  Collapse,
  Carousel,
  Image,
  Button,
  Checkbox,
  Input,
  Form,
  Switch,
} from "antd";
import PolizaDetailsItem from "./PolizaDetailsItem";
import { IAsegurado } from "../../../../services/Asegurado";
import { IPoliza } from "../../../../services/Poliza";
import { IEndoso } from "../../../../services/Endoso";
import TagValidity from "../PolizaItem/TagValidity";
import EndosoSelect from "./EndosoSelect/EndosoSelect";
import PaymentsTable from "./PaymentsTable/PaymentsTable";
import {
  formatPolicyNumber,
  formatStringToMoney,
  handleID,
  parcialTypeImpressions,
} from "../../../../shared/utils";
import GraphqlService from "../../../../services/graphql/GraphqlService";
import { CustomMessage, EnumsValues } from "../../../../shared";
import { CloudDownloadOutlined, SendOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import Vehicle from "./Vehicle";
import "./style.less";
import {
  FORMAT_DATE_TIME_4,
  FORMAT_DATE_TIME_6,
} from "../../../../shared/MomentJS";
import { createBinaryArrayForPartialImpressions } from "../../../utils/createBinaryArrayForPartialImpressions";

const { Panel } = Collapse;

interface PolizaDetailsProps {
  isModalVisible: boolean;
  polizaProp: IPoliza;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

export interface ImpressionsInput {
  File?: string;
  Id?: string;
  Pedido: string;
  TIPO: string;
  COD_PROD: string;
  Id_PV: string;
  Envia_mail: string;
  Mail: string;
  Graba_pdf: string;
  Ruta_PDF?: string;
  Estado: string;
  ImpresionParcialTipo?: string[];
  ImpresionParcialFlag?: string[];
}

const PolizaDetails: React.FC<PolizaDetailsProps> = (props) => {
  const { isModalVisible, setIsModalVisible, polizaProp } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [poliza, setPoliza] = useState<IPoliza>(polizaProp);
  const [selectedEndoso, setSelectedEndoso] = useState<any>(null);
  const [endosoFiltered, setEndosoFiltered] = useState<IEndoso>(
    poliza.endoso[0]
  );
  const [endosoIndex, setEndosoIndex] = useState<number>(0);

  const [totalSaldo, setTotalSaldo] = useState<string>("");
  const [consolidadaSaldo, setConsolidadaSaldo] = useState<string>("");
  const [totalDeuda, setTotalDeuda] = useState<string>("");
  const [consolidadaDeuda, setConsolidadaDeuda] = useState<string>("");
  const [IDInsured, setIDInsured] = useState({
    tipo: "DNI",
    value: "No Disponible",
  });
  const [imagesIds, setImagesIds] = useState<number[]>([]);
  const [activePanels, setActivePanels] = useState<string[]>();
  const [imagesData, setImagesData] =
    useState<{ file: string; mimetype: string }[]>();
  const [isDownloadModalVisible, setIsDownloadModalVisible] =
    useState<boolean>();
  const [sendToMail, setSendToMail] = useState<boolean>();
  const [sendParcial, setSendParcial] = useState<boolean>(false);

  const { customRequest, Query } = GraphqlService();
  const { messageError, messageModalError, messageModalSuccess } =
    CustomMessage();
  const inputRef = useRef<Input | null>(null);
  const [downloadRequestForm] = useForm();

  const PANEL_KEY_VEHICLE_IMAGES = "4";

  const getImagesByLicensePlate = (licensePlate: string) => {
    if (!licensePlate) return;
    customRequest({
      query: Query.filesIdsByLicensePlate,
      variables: {
        licensePlate,
      },
    })
      .then((data: any) => {
        setImagesIds(data.ids);
      })
      .catch((error: any) => {
        messageError({
          context: "PolizaDetails.getImagesByLicensePlate.1",
          message: error?.message,
        });
      });
  };

  const getImageById = (id: number) => {
    if (!id) return;
    customRequest({
      query: Query.file,
      variables: {
        id,
      },
    })
      .then((data: any) => {
        const fileData = { file: data.file, mimetype: data.mimetype };
        setImagesData((prevState) =>
          prevState ? [...prevState, fileData] : [fileData]
        );
      })
      .catch((error: any) => {
        messageError({
          context: "PolizaDetails.getImageById.1",
          message: error?.message,
        });
      });
  };

  const polizaType = poliza.endoso[0].id.substring(0, 2);

  const {
    calle,
    numero,
    localidad,
    provincia,
    nro_documento,
    codigo_postal,
    fiscal_condition,
    cuit,
    cod_tipo_documento,
  }: IAsegurado = poliza.asegurado[0];

  const nombre_productor = poliza.productor.length
    ? poliza.productor[0].nombre
    : "";

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDownloadOk = () => {
    setIsDownloadModalVisible(false);
    setSendParcial(false);
    downloadRequestForm.resetFields();
  };

  const handleDownloadCancel = () => {
    setIsDownloadModalVisible(false);
    setSendParcial(false);
    downloadRequestForm.resetFields();
  };

  const getEndosoById = (id: string) => {
    setEndosoFiltered(
      poliza.endoso.filter((endoso: IEndoso) => endoso.id === id)[0]
    );
  };

  const handleChangeEndoso = (value: string) => {
    value && setSelectedEndoso(value);
    const index = poliza.endoso.findIndex(
      (endoso: IEndoso) => endoso.id === value
    );
    setEndosoIndex(index);
  };

  useEffect(() => {
    const { tipo, value } = handleID(cod_tipo_documento, nro_documento, cuit);
    tipo && setIDInsured({ tipo, value });
  }, [cod_tipo_documento, nro_documento, cuit]);

  const imagesArr = useMemo(() => {
    return imagesData?.map((imageData, index: number) => {
      return (
        <Image
          height={320}
          width="100%"
          key={index}
          src={`data:${imageData.mimetype};base64,${imageData.file}`}
          preview={{ getContainer: "#root" }}
        />
      );
    });
  }, [imagesData]);

  const sendDownloadRequest = () => {
    const formValues = downloadRequestForm.getFieldsValue();

    const input: ImpressionsInput = {
      Pedido: moment(new Date()).format(FORMAT_DATE_TIME_6),
      TIPO: EnumsValues.FileRequestType.Poliza,
      COD_PROD: endosoFiltered?.codigo_productor[0],
      Id_PV: endosoFiltered?.id,
      Envia_mail: sendToMail ? "S" : "N",
      Mail:
        sendToMail && inputRef.current?.input.value.toString()
          ? inputRef.current?.input.value.toString()
          : "",
      Graba_pdf: "S",
      Estado: "01",
      ...(sendParcial &&
        formValues.ImpresionParcialFlag && {
          ImpresionParcialTipo: parcialTypeImpressions,
          ImpresionParcialFlag: createBinaryArrayForPartialImpressions(
            formValues.ImpresionParcialFlag
          ),
        }),
    };

    customRequest({
      query: Query.impressionsFromUniverse,
      variables: { input },
    })
      .then(() => {
        setIsDownloadModalVisible(() => false);
        messageModalSuccess({
          context: "PolizaDetails.sendDownloadRequest.1",
          message:
            "Solicitud de descarga de póliza realizada exitosamente. Diríjase a la sección descargas.",
        });
      })
      .catch((error: any) => {
        setIsDownloadModalVisible(() => false);
        messageModalError({
          context: "PolizaDetails.sendDownloadRequest.2",
          message: error?.message,
        });
      });
  };

  const getPolicyRelated = async (input: string) => {
    if (!input || typeof input !== "string") return;
    try {
      setLoading(true);
      const data: IPoliza[] = await customRequest({
        query: Query.polizaByNumber,
        variables: {
          section: input.substring(0, 2),
          policy: input.substring(2, 11),
          endorsement: input.substring(11, 17),
        },
      });
      if (data.length) {
        setPoliza(data[0]);
        setEndosoFiltered(data[0].endoso[0]);
      }
    } catch (error: any) {
      messageModalError({
        context: "PolizaDetails.getPolicyRelated.2",
        message: error?.message,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    selectedEndoso === null
      ? setEndosoFiltered(poliza.endoso[0])
      : getEndosoById(selectedEndoso);
  }, [selectedEndoso, poliza]);

  useEffect(() => {
    setTotalSaldo(poliza.saldo[0]?.total);
    setConsolidadaSaldo(poliza.saldo[0]?.consolidada);
    setTotalDeuda(poliza.deuda_exigible[0]?.total);
    setConsolidadaDeuda(poliza.deuda_exigible[0]?.consolidada);
    getImagesByLicensePlate(poliza.vehiculo[0]?.patente);
  }, [poliza]);

  useEffect(() => {
    setPoliza(polizaProp);
  }, [polizaProp]);

  const getMonthDifference = (date1: string, date2: string, format: string) => {
    const difference = Math.abs(
      moment(date2, format).diff(moment(date1, format), "months")
    );
    const text = difference === 1 ? "mes" : "meses";
    return `${difference} ${text}`;
  };

  useEffect(() => {
    if (
      !imagesData &&
      activePanels &&
      activePanels.indexOf(PANEL_KEY_VEHICLE_IMAGES) > -1
    ) {
      imagesIds.map((id) => getImageById(id));
    }
  }, [activePanels]);

  const renderVehicle = (): ReactNode => {
    return (
      <Collapse
        bordered={false}
        defaultActiveKey={["0"]}
        className="site-collapse-custom-collapse"
      >
        {poliza.vehiculo.map((vehicle, index) => {
          return (
            <Panel
              header={`${index + 1} - ${vehicle.patente} - ${vehicle.marca} `}
              key={index}
              className="site-collapse-custom-panel"
            >
              <Vehicle vehiculo={vehicle} key={index} />
            </Panel>
          );
        })}
      </Collapse>
    );
  };

  return (
    <>
      <Modal
        title={
          <>
            <div className="modal-title">
              <TagValidity
                title="Detalles de la póliza"
                validity={endosoFiltered?.vigencia}
              />
              <EndosoSelect
                endosos={poliza.endoso}
                handleChangeEndoso={handleChangeEndoso}
              />
            </div>

            <div className="modal-header">
              <p>
                {endosoFiltered?.descripicion_endosos?.length
                  ? endosoFiltered?.descripicion_endosos[0]
                  : ""}
              </p>
            </div>
          </>
        }
        visible={isModalVisible}
        style={{ top: 5 }}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={
          <div className="ModalButtons">
            {endosoFiltered?.poliza_vinculada !== "" && (
              <Button
                type="primary"
                size={"large"}
                className="button"
                onClick={() =>
                  getPolicyRelated(String(endosoFiltered?.poliza_vinculada))
                }
                loading={loading}
                disabled={loading || !endosoFiltered?.poliza_vinculada}
              >
                {!loading ? "Póliza vinculada" : "Cargando..."}
              </Button>
            )}
            <Button
              type="primary"
              icon={<CloudDownloadOutlined />}
              size={"large"}
              className="button"
              onClick={() => {
                setSendToMail(false);
                setIsDownloadModalVisible(() => true);
              }}
            >
              Descargar poliza
            </Button>
          </div>
        }
        bodyStyle={{ height: "75vh" }}
        className="PolizaDetails__modal"
      >
        <Collapse
          defaultActiveKey={["1", "2", "3", "5"]}
          onChange={(panelsIds) =>
            typeof panelsIds === "string"
              ? setActivePanels([panelsIds])
              : setActivePanels(panelsIds)
          }
          className="collapse"
        >
          <Panel header="Detalles del asegurado" key="1">
            <Col span={14}>
              <PolizaDetailsItem
                property="Asegurado:"
                value={poliza.asegurado[0].nombre_aseg}
              />
              <PolizaDetailsItem
                property="Nº Asegurado:"
                value={poliza.asegurado[0].id_asegurado}
              />
              <PolizaDetailsItem
                property="Condición Fiscal:"
                value={fiscal_condition}
              />
              <PolizaDetailsItem
                property={IDInsured.tipo + ":"}
                value={IDInsured.value}
              />
            </Col>
            <Col span={10}>
              <PolizaDetailsItem
                property="Dirección:"
                value={`${calle} ${numero}`}
              />
              <PolizaDetailsItem
                property="Código postal:"
                value={codigo_postal}
              />
              <PolizaDetailsItem property="Localidad:" value={localidad} />
              <PolizaDetailsItem property="Provincia:" value={provincia} />
            </Col>
          </Panel>
          <Panel
            header="Detalles de la póliza"
            className="detalle-poliza"
            key="2"
          >
            <Col span={14}>
              <PolizaDetailsItem
                property="Póliza:"
                value={formatPolicyNumber(endosoFiltered?.id)}
              />
              <PolizaDetailsItem
                property="Emisión:"
                value={endosoFiltered?.fecha_emision}
              />
              <PolizaDetailsItem
                property="Vigencia:"
                value={`${endosoFiltered?.vigente_desde} al ${
                  endosoFiltered?.vigente_hasta
                }  (${getMonthDifference(
                  endosoFiltered?.vigente_hasta,
                  endosoFiltered?.vigente_desde,
                  FORMAT_DATE_TIME_4
                )})`}
              />
              <PolizaDetailsItem
                property="Prima:"
                value={formatStringToMoney(endosoFiltered?.prima)}
              />
              <PolizaDetailsItem
                property="Premio:"
                value={formatStringToMoney(endosoFiltered?.premio)}
              />
              <PolizaDetailsItem
                property="Cod. Productor:"
                value={
                  endosoFiltered?.codigo_productor?.length
                    ? endosoFiltered?.codigo_productor[0]
                    : ""
                }
              />
              <PolizaDetailsItem
                property="Productor:"
                value={`${nombre_productor} (${
                  endosoFiltered?.codigo_productor?.length
                    ? endosoFiltered?.codigo_productor[0]
                    : ""
                })`}
              />
            </Col>
            <Col span={10}>
              <div className="saldos">
                <PolizaDetailsItem property="Saldo" value={""} />
              </div>
              <PolizaDetailsItem
                property="Total:"
                value={formatStringToMoney(totalSaldo)}
              />
              <PolizaDetailsItem
                property="Consolidada:"
                value={formatStringToMoney(consolidadaSaldo)}
              />
              <div className="saldos deuda-exigible">
                <PolizaDetailsItem property="Deuda exigible" value={""} />
              </div>
              <PolizaDetailsItem
                property="Total:"
                value={formatStringToMoney(totalDeuda)}
              />
              <PolizaDetailsItem
                property="Consolidada:"
                value={formatStringToMoney(consolidadaDeuda)}
              />
            </Col>
          </Panel>

          {polizaType === "04" ? (
            <>
              <Panel
                header="Detalles del vehículo"
                key="3"
                className="vehicle-panel"
              >
                {renderVehicle()}
              </Panel>
              <Panel
                header="Imágenes del vehículo"
                key={PANEL_KEY_VEHICLE_IMAGES}
              >
                <Carousel style={{ height: 320, width: "100%" }}>
                  {imagesArr ? (
                    imagesArr
                  ) : (
                    <div className="not_image">
                      <p>No hay fotos cargadas para el vehículo</p>
                    </div>
                  )}
                </Carousel>
              </Panel>
            </>
          ) : null}

          <Panel header="Detalles de las cuotas" key="5">
            <div className="PaymentsTable">
              <Col span="6" className="tablas">
                <PaymentsTable
                  title="Cuotas"
                  policy={poliza}
                  payments={false}
                  endosoIndex={endosoIndex}
                />
              </Col>
              <Col span="6" className="tablas">
                <PaymentsTable
                  title="Cobranzas"
                  policy={poliza}
                  payments={true}
                  endosoIndex={0}
                />
              </Col>
              <Col span="8" className="tablas">
                <p className="payments">Forma de pago</p>
                {endosoFiltered?.paymentMethod[2] || "-"}
              </Col>
            </div>
          </Panel>
        </Collapse>
      </Modal>
      <Modal
        title={
          <div className="download-modal-title">
            <span>Solicitud de descarga de póliza</span>
          </div>
        }
        className="Download__modal"
        visible={isDownloadModalVisible}
        style={{ top: 5 }}
        onOk={handleDownloadOk}
        centered
        destroyOnClose
        onCancel={handleDownloadCancel}
        footer={
          <div className="ModalButtons">
            <Button
              type="primary"
              icon={<SendOutlined />}
              size={"large"}
              className="button"
              onClick={() => downloadRequestForm.submit()}
            >
              Confirmar
            </Button>
          </div>
        }
        bodyStyle={{ minHeight: 270 }}
      >
        <Form
          form={downloadRequestForm}
          onFinish={() => sendDownloadRequest()}
          onChange={() =>
            downloadRequestForm.validateFields(["ImpresionParcialFlag"])
          }
        >
          <Checkbox
            onChange={(e) => {
              setSendToMail(() => e.target.checked);
              if (!e.target.checked)
                downloadRequestForm.setFieldsValue({
                  email: undefined,
                });
              downloadRequestForm.validateFields(["email"]);
            }}
          >
            Enviar por email
          </Checkbox>
          <Form.Item
            name="email"
            rules={[
              {
                required: sendToMail,
                message: "Ingresar el email es obligatorio",
              },
              {
                type: "email",
                message: "Debe ingresar un email válido",
              },
            ]}
          >
            <Input
              type="email"
              ref={inputRef}
              className="download-input-email"
              style={!sendToMail ? { backgroundColor: "#eee" } : {}}
              disabled={!sendToMail}
              placeholder="Ingrese email..."
            ></Input>
          </Form.Item>
          <Form.Item name="parcial_sent" label={"Impresión Parcial"}>
            <Switch checked={sendParcial} onChange={setSendParcial} />
          </Form.Item>
          <Form.Item
            name="ImpresionParcialFlag"
            hidden={!sendParcial}
            rules={[
              {
                required: sendParcial,
                message:
                  "Debe seleccionar al menos un tipo de impresión o desactivar la impresión parcial",
              },
            ]}
          >
            <Checkbox.Group
              options={parcialTypeImpressions.map((item) => ({
                label: item,
                value: item,
              }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PolizaDetails;
