export const GET_VEHICLE_TYPE = `
query getVehicleType($searchText: String, $filter: FilterUserVehicleTypeInput) {
  getVehicleType(searchText: $searchText, filter: $filter) {
    __typename
    ... on UserVehicleType {
      id
      user_id
      vehicle_type_id
      vehicle_type {
        id
        description
        id_liderar
        codigo_tarifacion
        destiny_list
        destiny_array
        activo_web
        by_default
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}

`;
