import { useHistory, useLocation } from "react-router";
import { useCallback, useEffect, useRef, useState } from "react";
import { RouteConfigComponentProps } from "react-router-config";
import { ParamsType } from "@ant-design/pro-provider";
import { Button, Table } from "antd";
import ProTable from "@ant-design/pro-table";

import { ABM, CustomMessage, Tools } from "../../../shared";
import { ExportableColumn } from "../../ABM/LocalExporter/index";
import ModalRelatedInsurancePolicy, {
  IDebtSummaryDetailProps,
} from "./ModalRelatedInsurancePolicy/ModalRelatedInsurancePolicy";
import {
  formatPolicyNumber,
  formatStringToMoney,
  moneyToTwoDecimals,
} from "../../../shared/utils";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";

import "./style.less";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { Query } from "../../../services/graphql/query";
import { IDebtSummary } from "../../../services/DebtSummary";

import { Mutation } from "../../../services/graphql/mutation";
import { useCheckAuthority } from "../../../shared/CustomHooks";

const CollectionPrizeToBePaid = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [debtSummaryDetails, setDebtSummaryDetails] = useState<
    IDebtSummaryDetailProps[]
  >([]);
  const [originalIdsSelected, setOriginalIdsSelected] = useState<number[]>([]);
  const [rowsIdsSelected, setRowsIdsSelected] = useState<number[]>([]);
  const { customRequest } = GraphqlService();
  const { messageError, messageSuccess } = CustomMessage();
  const variables = useRef<any>({});
  const [debt, setDebt] = useState<IDebtSummary[]>([]);
  const { state, pathname } = useLocation();

  const history = useHistory();

  const LIST_SORTER = ["id", "insured", "sum_amount"];

  useEffect(() => {
    setTotalAmount(getTotalAmount(rowsIdsSelected));
  }, [rowsIdsSelected]);

  const getTotalAmount = (ids: number[]) => {
    let total = 0;
    ids.forEach((id) => {
      debt.forEach((detail) => {
        if (detail.id === id) {
          total += detail.sum_amount;
        }
      });
    });
    return total;
  };

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    }
  ) => {
    try {
      delete variables.current.orderBy;
      delete variables.current.queue_requests_id;
      variables.current = {};
      const search: any = ABM.valuesResult(params);

      if (!variables.current.filter) {
        variables.current.filter = {};
      }
      variables.current.filter.queue_requests_id = state;

      LIST_SORTER.forEach((element) => {
        try {
          if (search._sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search._sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });

      const data: IDebtSummary[] = await customRequest({
        query: Query.debt_summaries,
        variables: variables.current,
      });

      setDebt(data);

      const idArr: number[] = [];
      const keyArr: number[] = [];
      data.map((entry, id) => {
        if (entry.is_selected) {
          idArr.push(entry.id);
          keyArr.push(id + 1);
        }
      });

      setOriginalIdsSelected(idArr);
      setRowsIdsSelected(idArr);

      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total: debt.length,
      };
    } catch (error) {
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const handleSave = useCallback(
    (send: boolean) => {
      const newUnselectedRowsIds: number[] = [];
      const newSelectedRowsIds: number[] = [];
      rowsIdsSelected.forEach((id) => {
        if (originalIdsSelected.indexOf(id) === -1) {
          newSelectedRowsIds.push(id);
        }
      });

      originalIdsSelected.forEach((id) => {
        if (rowsIdsSelected.indexOf(id) === -1) {
          newUnselectedRowsIds.push(id);
        }
      });

      const input = {
        selected: newSelectedRowsIds,
        unselected: newUnselectedRowsIds,
        terminate: send,
        queueRequestId: state,
      };

      if (
        newSelectedRowsIds.length === 0 &&
        newUnselectedRowsIds.length === 0 &&
        !send
      ) {
        messageError({
          context: "CollectionPrizeToBePaid",
          message: "Debe seleccionar al menos una fila para guardar",
          time: 2300,
        });
        return;
      }

      if (send && !rowsIdsSelected.length) {
        messageError({
          context: "CollectionPrizeToBePaid",
          message:
            "Debe seleccionar al menos una fila para guardar y finalizar",
          time: 2300,
        });
        return;
      }

      customRequest({
        mutation: Mutation.set_check_debt_summaries,
        variables: { input },
      })
        .then(() => {
          setOriginalIdsSelected(rowsIdsSelected);
          customRequest({
            query: Query.debtSummarySelectedTotalAmount,
            variables: { id: state },
          }).then(() => {
            messageSuccess({
              context: "CollectionPrizeToBePaid.handleSave.success",
              message: "Operacion realizada exitosamente",
            });
            if (send) {
              setTimeout(() => {
                history.goBack();
              }, 1200);
            }
          });
        })
        .catch((error: any) => {
          messageError({
            context: "CollectionPrizeToBePaid.handleSave.error",
            message: error.message,
          });
        });
    },
    [originalIdsSelected, rowsIdsSelected]
  );

  const columns = useCallback(
    (): ExportableColumn<IDebtSummary>[] => [
      {
        export: true,
        sorter: true,
        dataIndex: "id",
        title: "Número de póliza",
        align: "left",
        type: ABM.TYPE_COLUMN.STRING,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_: any, record) =>
          formatPolicyNumber(record.policy_number) || "-",
      },
      {
        export: true,
        sorter: true,
        defaultSortOrder: "ascend",
        dataIndex: "insured",
        title: "Nombre del asegurado",
        type: ABM.TYPE_COLUMN.STRING,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
        render: (_: any, record) => record.insured || "-",
      },
      {
        export: true,
        sorter: true,
        dataIndex: "sum_amount",
        title: Tools.leftAlign("Importe"),
        align: "right",
        type: ABM.TYPE_COLUMN.NUMBER,
        hideInTable: false,
        hideInSearch: false,
        hideInForm: false,
        render: (_: any, record) =>
          moneyToTwoDecimals(
            formatStringToMoney(String(record.sum_amount)),
            ","
          ) || "-",
      },
      {
        title: "",
        dataIndex: "option",
        valueType: "option",
        fixed: "right",
        align: "left",
        width: "auto",
        export: false,
        hideInForm: true,
        hideInSearch: true,
        hideInTable: false,
        render: (_, record) => {
          return (
            <Button
              className="button-details"
              type="link"
              onClick={(event) => {
                event.stopPropagation();
                const recordData = {
                  name: record.insured,
                };
                const parseAmount = (amount: number): string => {
                  return (
                    moneyToTwoDecimals(
                      formatStringToMoney(String(amount)),
                      ","
                    ) || "-"
                  );
                };
                setDebtSummaryDetails(
                  record.debt_summary_detail?.map((detail) => {
                    return {
                      ...detail,
                      ...recordData,
                      parsedAmount: parseAmount(detail.amount),
                    };
                  }) || []
                );
                setModalVisible(true);
              }}
            >
              Ver detalle
            </Button>
          );
        },
      },
    ],
    []
  );
  const onSelectChange = (selectedRowKeys: any) => {
    setRowsIdsSelected(selectedRowKeys);
  };

  const rowSelection = {
    onChange: onSelectChange,
    selectedRowKeys: rowsIdsSelected,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  return (
    useCheckAuthority(routeProps) || (
      <div className="CollectionPrizeToBePaid">
        <ProTable<IDebtSummary>
          defaultSize="small"
          headerTitle={
            <h1 className="PrizeToBePaidTitle">
              Premios a rendir ID:{" "}
              {pathname.substring(pathname.lastIndexOf("/") + 1)}
            </h1>
          }
          onRow={(record) => ({
            onClick: () => {
              const selectedRowKeys = [...rowsIdsSelected];
              const index = selectedRowKeys.indexOf(record.id);
              if (index >= 0) {
                selectedRowKeys.splice(index, 1);
              } else {
                selectedRowKeys.push(record.id);
              }
              setRowsIdsSelected(selectedRowKeys);
            },
          })}
          tableAlertRender={false}
          className="collection-prize-to-be-paid-table"
          search={false}
          columns={columns()}
          options={false}
          rowKey="id"
          toolBarRender={() => [
            <div className="total-amount-container" key={0}>
              Importe a rendir:
              <span className="total-amount">
                {formatStringToMoney(totalAmount.toString())}
              </span>
            </div>,
          ]}
          rowSelection={rowSelection}
          request={async (params, _sorter, filter) =>
            request({ ...params, _sorter, filter })
          }
          dataSource={debt}
          pagination={{
            hideOnSinglePage: true,
            className: "pagination-bar",
          }}
        />
        <div className="buttons">
          <ButtonLiderar
            color="white"
            buttonName="Volver"
            onClick={() => history.goBack()}
          />
          <ButtonLiderar
            color="violet"
            buttonName="Guardar"
            onClick={() => handleSave(false)}
          />
          <ButtonLiderar
            color="violet"
            buttonName="Guardar y finalizar"
            onClick={() => handleSave(true)}
          />
        </div>
        {
          <ModalRelatedInsurancePolicy
            data={debtSummaryDetails}
            onCancel={() => setModalVisible(false)}
            onOk={() => {
              setModalVisible(false);
            }}
            modalVisible={modalVisible}
            titleModal="Lista de pólizas relacionadas"
          />
        }
      </div>
    )
  );
};

export default CollectionPrizeToBePaid;
