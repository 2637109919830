import { Col, Row } from "antd";

export interface ICard {
  title: string;
  content: string;
}

export interface IContentCardProps {
  data: ICard[];
  title: string;
  hidden?: boolean;
}

const ContentCard = (props: IContentCardProps): JSX.Element | null => {
  const { data, title, hidden } = props;

  if (data[0].content === undefined) {
    return null;
  }

  return (
    data && (
      <section hidden={hidden}>
        <div className="ant-collapse-header-summary">
          {title}
          <hr className="linea" />
        </div>
        <ul padding-left={10}>
          <Row gutter={40}>
            {data?.map((item: ICard, index) => {
              if (
                item.content === "-" ||
                item.content === "" ||
                item.content === undefined
              ) {
                return null;
              }
              return (
                <Col xs={24} md={12} xl={6} key={index}>
                  <li className="bold-label">{item.title}</li>
                  <span className="input">{item.content}</span>
                </Col>
              );
            })}
          </Row>
        </ul>
      </section>
    )
  );
};

export default ContentCard;
