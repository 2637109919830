import { Header } from "antd/lib/layout/layout";
import { RouteConfig } from "react-router-config";
import { IRouteConfig } from "../../../routes/routes";

import { MainMenu } from "../MainMenu";

import "./index.less";

interface IHeaderComponentProps {
  routes: RouteConfig[] | undefined;
}

export const HeaderComponent = (props: IHeaderComponentProps): JSX.Element => {
  const { routes } = props;
  return (
    <Header>
      <MainMenu routes={routes as IRouteConfig[]} />
    </Header>
  );
};
