import { useEffect } from "react";
import { Col, Row } from "antd";
import { useLocation } from "react-router";
import { RouteConfigComponentProps } from "react-router-config";

import SearchForm from "./SearchForm/SearchForm";
import ResultsScreen from "./ResultsScreen/ResultsScreen";
import useBusquedaPoliza from "../../shared/hooks/useBusquedaPoliza";
import { EnumsValues } from "../../shared";
import { useCheckAuthority } from "../../shared/CustomHooks";

import "./style.less";

export interface IPolizaInput {
  poliza: string;
  seccion: string;
  endoso: string;
  patente: string;
  name: string;
}

export interface IHistoryState {
  typeOfSearch: "name" | "patente" | "poliza";
  name?: string;
  poliza?: string;
  seccion?: string;
  endoso?: string;
  patente?: string;
}

const BusquedaPolizas = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  const location = useLocation();
  const state = location.state as IHistoryState;
  const { PolizaSearchType } = EnumsValues;
  const { NAME, PATENTE, POLIZA } = PolizaSearchType;
  const {
    getPoliza,
    cleanSearch,
    handleClickAsegurado,
    loading,
    setSearchType,
    searchType,
    searchTypeTitle,
    clearButton,
    polizas,
    setPolizas,
    asegurados,
    termSearched,
    setFilter,
    filter,
    setAseguradosOriginal,
    formValuesOnLoad,
    setFormValuesOnLoad,
  } = useBusquedaPoliza();

  useEffect(() => {
    if (state?.typeOfSearch === POLIZA) {
      const { poliza, seccion, endoso } = state;
      setFormValuesOnLoad({ seccion, poliza, endoso });
    }
    if (state?.typeOfSearch === NAME) {
      const { name } = state;
      setFormValuesOnLoad(name);
    }
    if (state?.typeOfSearch === PATENTE) {
      const { patente } = state;
      setFormValuesOnLoad(patente);
    }
  }, [
    state,
    setAseguradosOriginal,
    setFormValuesOnLoad,
    POLIZA,
    NAME,
    PATENTE,
  ]);

  return (
    useCheckAuthority(routeProps) || (
      <div className="BusquedaPolizasLayout">
        <Row>
          <Col xs={24} sm={24} lg={24} xl={8} xxl={7} className="search-side">
            <SearchForm
              getPoliza={getPoliza}
              setSearchType={setSearchType}
              searchType={searchType}
              clearButton={clearButton}
              cleanSearch={cleanSearch}
              formValuesOnLoad={formValuesOnLoad}
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            lg={24}
            xl={15}
            xxl={16}
            className="results-side"
            style={{ padding: 20 }}
          >
            <ResultsScreen
              polizas={polizas}
              setPolizas={setPolizas}
              asegurados={asegurados}
              handleClickAsegurado={handleClickAsegurado}
              loading={loading}
              termSearched={termSearched}
              cleanSearch={cleanSearch}
              setFilter={setFilter}
              filter={filter}
              searchTypeTitle={searchTypeTitle}
            />
          </Col>
        </Row>
      </div>
    )
  );
};

export default BusquedaPolizas;
