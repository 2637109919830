export const POLIZA_NUMBER = `
  query polizas($policy: String!, $section: String!, $endorsement: String) {
    polizas(filter:{policy: $policy, section: $section, endorsement: $endorsement}) {
      __typename
      ... on Poliza {
        canOpen
        endoso {
          importe_vencimiento
          fecha_vencimiento_aseg
          id
          vigente_desde
          vigente_hasta
          vigencia
          fecha_emision
          prima
          premio
          codigo_productor
          descripicion_endosos
          poliza_vinculada
          paymentMethod
        }
        vehiculo {
          marca
          anio
          patente
          id_vehiculo
          codigo_motor
          codigo_chasis
          estado
          cobertura
          prima_total
          tipo
          uso
          suma_asegurada
          ajuste_automotor
          descrip_accesorios
          suma_accesorios
          adicional_granizo
        }
        asegurado {
          nombre_aseg
          id_asegurado
          calle
          numero
          localidad
          provincia
          cod_tipo_documento
          nro_documento
          codigo_postal
          fiscal_condition
        }
        productor{
          nombre
        }
        cobranza {
          fecha
          importe
        }
        saldo {
          total
          consolidada
        }
        deuda_exigible {
          total
          consolidada
        }
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const POLIZA_PATENTE = `
query polizasByPatente($patente: String!){
    polizasByPatente(patent: $patente) {
      __typename
      ... on Poliza {
        canOpen
        endoso {
          importe_vencimiento
          fecha_vencimiento_aseg
          id
          vigente_desde
          vigente_hasta
          vigencia
          fecha_emision
          prima
          premio
          codigo_productor
          descripicion_endosos
          poliza_vinculada
          paymentMethod
        }
        vehiculo {
          marca
          anio
          patente
          id_vehiculo
          codigo_motor
          codigo_chasis
          estado
          cobertura
          prima_total
          tipo
          uso
          suma_asegurada
          ajuste_automotor
          descrip_accesorios
          suma_accesorios
          adicional_granizo
        }
        asegurado {
          nombre_aseg
          id_asegurado
          calle
          numero
          localidad
          provincia
          cod_tipo_documento
          nro_documento
          codigo_postal
          fiscal_condition
        }
        productor{
          nombre
        }
        cobranza {
          fecha
          importe
        }
        saldo {
          total
          consolidada
        }
        deuda_exigible {
          total
          consolidada
        }
      }
      ... on ResultError {
        status_code
        message
      }
    }
  }
`;

export const POLIZA_ASEGURADO = `query polizasByIdAseg($idAseg: String!){
  polizasByIdAseg(idAseg: $idAseg) {
    __typename
    ... on Poliza {
      canOpen
      endoso {
        importe_vencimiento
        fecha_vencimiento_aseg
        id
        vigente_desde
        vigente_hasta
        vigencia
        fecha_emision
        prima
        premio
        codigo_productor
        descripicion_endosos
        poliza_vinculada
        paymentMethod
      }
      vehiculo {
        marca
        anio
        patente
        id_vehiculo
        codigo_motor
        codigo_chasis
        estado
        cobertura
        prima_total
        tipo
        uso
        suma_asegurada
        ajuste_automotor
        descrip_accesorios
        suma_accesorios
        adicional_granizo
      }
      asegurado {
        nombre_aseg
        id_asegurado
        calle
        numero
        localidad
        provincia
        cod_tipo_documento
        nro_documento
        codigo_postal
        fiscal_condition
      }
      productor{
        nombre
      }
      cobranza {
        fecha
        importe
      }
      saldo {
        total
        consolidada
      }
      deuda_exigible {
        total
        consolidada
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
