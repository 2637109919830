import { FILE, FILES_IDS_BY_LICENSE_PLATE } from "./File";
import {
  USERS,
  AUTH,
  LOGOUT,
  RESET_PASSWORD,
  VALIDATE_TOKEN,
  COUNT_USERS,
  USERS_TINY,
  IS_EMAIL_ALREADY_TAKEN,
  IS_USERNAME_ALREADY_TAKEN,
} from "./User";
import { COMPANY, COMPANIES } from "./Company";
import { SETTINGS, SETTING_KEY } from "./AppSetting";
import { LANGUAGES } from "./Language";
import { POLIZA_ASEGURADO, POLIZA_NUMBER, POLIZA_PATENTE } from "./Poliza";
import { ASEGURADO } from "./Asegurado";
import { PRODUCERS } from "./Producers";
import { CUIT_ONLINE } from "./CuitOnline";
import { FISCAL_CONDITIONS } from "./FiscalCondition";
import { PROVINCES } from "./Provinces";
import { LOCATION_BY_PROVINCE_ID, POSTAL_CODES } from "./PostalCode";
import { VEHICLE_TYPES } from "./VehicleType";
import { DESTINO } from "./Destino";
import { AJUSTMENT_RATES } from "./AjustmentRates";
import { COVERAGE_PERIODS } from "./CoveragePeriod";
import { BRANDS } from "./Brands";
import {
  BRAND_MODEL,
  BRAND_MODEL_BY_BRAND_LIST,
  BRAND_MODEL_BY_BRAND_MODEL_YEAR,
  YEARS_BY_BRAND_AND_MODEL,
} from "./BrandModel";
import {
  COUNT_QUOTATIONS,
  GET_ALL_QUOTATION_REQUEST,
  GET_QUOTATION_FILE,
  QUOTATION,
  QUOTATIONS,
  QUOTATION_BY_REQUEST_AND_COVERAGE,
  RENOVATION_QUOTATED_PDF,
} from "./Quotation";
import { FUEL_TYPES } from "./FuelTypes";
import { DOCUMENT_TYPES } from "./DocumentTypes";
import { GENDERS } from "./genders";
import { BODYWORK_TYPES } from "./BodyWorkTypes";
import { TRACKING_TYPES } from "./TrackingTypes copy";
import { CREDIT_CARD_TYPES } from "./CreditCardTypes";
import { ROLE, ROLES } from "./Role";
import { PERMISSIONS } from "./Permission";
import { ROLE_PERMISSION, ROLE_PERMISSIONS } from "./RolePermission";
import { NATIONALITIES } from "./nationality";
import { COVERAGES, COVERAGE_TYPES } from "./CoverageType";
import { GET_USER_COVERAGE } from "./UserCoverage";
import { GET_VEHICLE_TYPE } from "./UserVehicleType";
import {
  GET_PRODUCERS,
  GET_PRODUCER_CUIT,
  GET_PRODUCER_GROUP,
} from "./UserProducer";
import {
  IMPRESSIONS_QUEUE_REQUESTS,
  IMPRESSIONS_QUEUE_REQUESTS_COUNT,
  IMPRESSIONS_QUEUE_REQUEST_FILE,
} from "./ImpressionsQueueRequest";
import {
  DEBT_SUMMARIES,
  DEBT_SUMMARY_COUNT,
  DEBT_SUMMARY_FROM_UNIVERSE,
  DEBT_SUMMARY_QUEUE_REQUESTS_COUNT,
  DEBT_SUMMARY_SELECTED_TOTAL_AMOUNT,
  IMPRESSIONS_FROM_UNIVERSE,
  LIST_DEBT_SUMMARY_QUEUE_REQUESTS,
} from "./Payment";
import {
  DEBTSUMMARY_FILE,
  PUBLICATIONS,
  PUBLICATION_FILE,
} from "./Publication";
import { PUBLICATION_TYPES } from "./publication_type";
import {
  RENOVATION,
  RENOVATIONS_LIST_FILE,
  COUNT_RENOVATIONS,
  RENOVATIONS,
} from "./Renovations";
import { GET_USER_COVERAGE_PERIOD } from "./UserCoveragePeriod";
import {
  COUNT_EMISSION,
  EMISSION,
  EMISSIONS,
  GET_EMISSION_FILE,
  GET_INSURED_FILE,
} from "./Emission";
import {
  BOOKS_FROM_UNIVERSE,
  BOOKS_QUEUE_REQUESTS,
  BOOKS_QUEUE_REQUESTS_COUNT,
  GET_BOOK_FILE,
} from "./booksQueueRequests";
import {
  COUNT_EMISSION_HISTORICAL,
  EMISSIONS_HISTORICAL,
  EMISSION_HISTORICAL,
  GET_EMISSION_HISTORICAL_FILE,
  GET_INSURED_HISTORICAL_FILE,
} from "./EmissionHistorical";
import { POLICY_SECTIONS } from "./PolicySection";

const Query = {
  users: { name: "users", gql: USERS },
  authenticate: { name: "authenticate", gql: AUTH },
  logout: { name: "logout", gql: LOGOUT },
  resetPassword: { name: "resetPassword", gql: RESET_PASSWORD },
  company: { name: "company", gql: COMPANY },
  companies: { name: "companies", gql: COMPANIES },
  getAppSettingByKey: { name: "getAppSettingByKey", gql: SETTING_KEY },
  getAppSettings: { name: "getAppSettings", gql: SETTINGS },
  languages: { name: "languages", gql: LANGUAGES },
  validateToken: { name: "verifyRecoveryPasswordToken", gql: VALIDATE_TOKEN },
  polizaByNumber: { name: "polizas", gql: POLIZA_NUMBER },
  polizaByPatente: { name: "polizasByPatente", gql: POLIZA_PATENTE },
  polizasByIdAsegurado: { name: "polizasByIdAseg", gql: POLIZA_ASEGURADO },
  asegurado: { name: "asegurado", gql: ASEGURADO },
  producers: { name: "producers", gql: PRODUCERS },
  cuitOnline: { name: "verifyCuitOnline", gql: CUIT_ONLINE },
  fiscal_conditions: { name: "fiscal_conditions", gql: FISCAL_CONDITIONS },
  provinces: { name: "provinces", gql: PROVINCES },
  postal_codes: { name: "postal_codes", gql: POSTAL_CODES },
  locationByProvinceId: {
    name: "locationByProvinceId",
    gql: LOCATION_BY_PROVINCE_ID,
  },
  vehicle_types: { name: "vehicle_types", gql: VEHICLE_TYPES },
  destinys: { name: "destinys", gql: DESTINO },
  ajustment_rates: { name: "adjustment_rates", gql: AJUSTMENT_RATES },
  quotation: { name: "quotation", gql: QUOTATION },
  quotations: {
    name: "quotations",
    gql: QUOTATIONS,
  },
  getAllQuotationRequest: {
    name: "getAllQuotationRequest",
    gql: GET_ALL_QUOTATION_REQUEST,
  },
  brands: {
    name: "getAllBrands",
    gql: BRANDS,
  },
  brand_model: { name: "brandModelByBrand", gql: BRAND_MODEL },
  brandModelByBrandList: {
    name: "brandModelByBrandList",
    gql: BRAND_MODEL_BY_BRAND_LIST,
  },
  yearsByBrandAndModel: {
    name: "yearsByBrandAndModel",
    gql: YEARS_BY_BRAND_AND_MODEL,
  },
  brandModelByBrandModelYear: {
    name: "brandModelByBrandModelYear",
    gql: BRAND_MODEL_BY_BRAND_MODEL_YEAR,
  },
  fuel_types: { name: "fuel_types", gql: FUEL_TYPES },
  document_types: { name: "document_types", gql: DOCUMENT_TYPES },
  quotationByRequestAndCoverage: {
    name: "quotationByRequestAndCoverage",
    gql: QUOTATION_BY_REQUEST_AND_COVERAGE,
  },
  tracking_types: { name: "tracking_types", gql: TRACKING_TYPES },
  bodywork_types: { name: "bodywork_types", gql: BODYWORK_TYPES },
  genders: { name: "genders", gql: GENDERS },
  credit_cards: { name: "credit_cards", gql: CREDIT_CARD_TYPES },
  permissions: { name: "permissions", gql: PERMISSIONS },
  role: { name: "role", gql: ROLE },
  roles: { name: "roles", gql: ROLES },
  rolePermission: { name: "rolePermission", gql: ROLE_PERMISSION },
  rolePermissions: { name: "rolePermissions", gql: ROLE_PERMISSIONS },
  nationalities: { name: "nationalities", gql: NATIONALITIES },
  coverages: { name: "coverages", gql: COVERAGES },
  coverage_periods: { name: "coverage_periods", gql: COVERAGE_PERIODS },
  getUserCoverage: { name: "getUserCoverage", gql: GET_USER_COVERAGE },
  getVehicleType: { name: "getVehicleType", gql: GET_VEHICLE_TYPE },
  getProducers: { name: "getProducers", gql: GET_PRODUCERS },
  getProducerCuit: { name: "getProducerCuit", gql: GET_PRODUCER_CUIT },
  getProducerGroup: { name: "getProducerGroup", gql: GET_PRODUCER_GROUP },
  filesIdsByLicensePlate: {
    name: "filesIdsByLicensePlate",
    gql: FILES_IDS_BY_LICENSE_PLATE,
  },
  file: {
    name: "file",
    gql: FILE,
  },
  impressionsQueueRequests: {
    name: "impressionsQueueRequests",
    gql: IMPRESSIONS_QUEUE_REQUESTS,
  },
  impressionsQueueRequestsCount: {
    name: "impressionsQueueRequestsCount",
    gql: IMPRESSIONS_QUEUE_REQUESTS_COUNT,
  },
  impressionsFromUniverse: {
    name: "impressionsFromUniverse",
    gql: IMPRESSIONS_FROM_UNIVERSE,
  },
  impressionsQueueRequestFile: {
    name: "impressionsQueueRequestFile",
    gql: IMPRESSIONS_QUEUE_REQUEST_FILE,
  },
  listDebtSummaryQueueRequests: {
    name: "listDebtSummaryQueueRequests",
    gql: LIST_DEBT_SUMMARY_QUEUE_REQUESTS,
  },
  debtSummaryQueueRequestsCount: {
    name: "debtSummaryQueueRequestsCount",
    gql: DEBT_SUMMARY_QUEUE_REQUESTS_COUNT,
  },
  debtSummaryFromUniverse: {
    name: "debtSummaryFromUniverse",
    gql: DEBT_SUMMARY_FROM_UNIVERSE,
  },
  debt_summaries: {
    name: "debt_summaries",
    gql: DEBT_SUMMARIES,
  },
  debtSummaryCount: {
    name: "debtSummaryCount",
    gql: DEBT_SUMMARY_COUNT,
  },
  debtSummarySelectedTotalAmount: {
    name: "debtSummarySelectedTotalAmount",
    gql: DEBT_SUMMARY_SELECTED_TOTAL_AMOUNT,
  },
  publications: {
    name: "publications",
    gql: PUBLICATIONS,
  },
  publicationFile: {
    name: "publicationFile",
    gql: PUBLICATION_FILE,
  },
  publication_types: {
    name: "publication_types",
    gql: PUBLICATION_TYPES,
  },
  renovation: {
    name: "renovation",
    gql: RENOVATION,
  },
  coverageTypes: {
    name: "coverageTypes",
    gql: COVERAGE_TYPES,
  },
  getUserCoveragePeriod: {
    name: "getUserCoveragePeriod",
    gql: GET_USER_COVERAGE_PERIOD,
  },
  debtSummaryFile: {
    name: "debtSummaryFile",
    gql: DEBTSUMMARY_FILE,
  },
  renovations: {
    name: "renovations",
    gql: RENOVATIONS,
  },
  countRenovations: {
    name: "countRenovations",
    gql: COUNT_RENOVATIONS,
  },
  emissions: {
    name: "emissions",
    gql: EMISSIONS,
  },
  emission: {
    name: "emission",
    gql: EMISSION,
  },
  countEmission: {
    name: "countEmission",
    gql: COUNT_EMISSION,
  },
  getEmissionFile: {
    name: "getEmissionFile",
    gql: GET_EMISSION_FILE,
  },
  getInsuredFile: {
    name: "getInsuredFile",
    gql: GET_INSURED_FILE,
  },
  renovationsPdf: {
    name: "renovationListFile",
    gql: RENOVATIONS_LIST_FILE,
  },
  getQuotationFile: {
    name: "getQuotationFile",
    gql: GET_QUOTATION_FILE,
  },
  renovationQuotatedPDF: {
    name: "renovationQuotatedPDF",
    gql: RENOVATION_QUOTATED_PDF,
  },
  countQuotations: {
    name: "countQuotations",
    gql: COUNT_QUOTATIONS,
  },
  booksQueueRequests: {
    name: "booksQueueRequests",
    gql: BOOKS_QUEUE_REQUESTS,
  },
  booksQueueRequestsCount: {
    name: "booksQueueRequestsCount",
    gql: BOOKS_QUEUE_REQUESTS_COUNT,
  },
  booksFromUniverse: {
    name: "booksFromUniverse",
    gql: BOOKS_FROM_UNIVERSE,
  },
  getBookFile: {
    name: "getBookFile",
    gql: GET_BOOK_FILE,
  },
  emissionsHistorical: {
    name: "emissionsHistorical",
    gql: EMISSIONS_HISTORICAL,
  },
  emissionHistorical: {
    name: "emissionHistorical",
    gql: EMISSION_HISTORICAL,
  },
  countEmissionHistorical: {
    name: "countEmissionHistorical",
    gql: COUNT_EMISSION_HISTORICAL,
  },
  getEmissionHistoricalFile: {
    name: "getEmissionHistoricalFile",
    gql: GET_EMISSION_HISTORICAL_FILE,
  },
  getInsuredHistoricalFile: {
    name: "getInsuredHistoricalFile",
    gql: GET_INSURED_HISTORICAL_FILE,
  },
  countUsers: {
    name: "countUsers",
    gql: COUNT_USERS,
  },
  usersTiny: {
    name: "usersTiny",
    gql: USERS_TINY,
  },
  isEmailAlreadyTaken: {
    name: "isEmailAlreadyTaken",
    gql: IS_EMAIL_ALREADY_TAKEN,
  },
  isUsernameAlreadyTaken: {
    name: "isUsernameAlreadyTaken",
    gql: IS_USERNAME_ALREADY_TAKEN,
  },
  policy_sections: {
    name: "policy_sections",
    gql: POLICY_SECTIONS,
  },
};

export { Query };
