import { Modal } from "antd";

import "./LoadingModal.less";

interface ILoadingModal {
  message: string;
  title: string;
  showModal: boolean;
  width?: number;
}

export const LoadingModal = (props: ILoadingModal): JSX.Element => {
  const { message, title, showModal, width = 300 } = props;
  return (
    <Modal
      title={title}
      className="loading-modal"
      visible={showModal}
      centered
      closable={false}
      footer={null}
      width={width}
    >
      {<p>{message}</p>}
    </Modal>
  );
};

export default LoadingModal;
