import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { CustomMessage, EnumsValues } from "..";
import { IAppSetting } from "../../services/AppSetting";
import { IBodyWork } from "../../services/BodyWork";
import { IFuel } from "../../services/Fuel";
import { IGender } from "../../services/Gender";
import GraphqlService from "../../services/graphql/GraphqlService";
import { CoverageSelected } from "../../services/ICoverage";
import { ICreditCardTypes } from "../../services/ICreditCardTypes";
import { INationality } from "../../services/INationality";
import { IQuotationForEmision } from "../../services/IQuotationForEmision";

interface IUseEmissionParams {
  quotationId: string | undefined;
}

const useEmission = (props: IUseEmissionParams) => {
  const { quotationId } = props;

  const history: any = useHistory();
  const HistoryState: CoverageSelected = history.location.state;
  const { customRequest, Query } = GraphqlService();
  const { messageError } = CustomMessage();

  const [quotation, setQuotation] = useState<IQuotationForEmision>();
  const [fuelTypes, setFuelTypes] = useState<IFuel[]>([]);
  const [bodyworkTypes, setBodyworkTypes] = useState<IBodyWork[]>([]);
  const [gendersTypes, setGendersTypes] = useState<IGender[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<ICreditCardTypes[]>([]);
  const [nationalities, setNationalities] = useState<INationality[]>([]);
  const [minBornTime, setMinBornTime] = useState<IAppSetting>();
  const [idsLiderarForMotorcycles, setIdsLiderarForMotorcycles] = useState<
    string[]
  >([]);
  const [idsLiderarForTrucks, setIdsLiderarForTrucks] = useState<string[]>([]);

  const getQuotationById = async (quotation_id: number) => {
    try {
      const data: IQuotationForEmision = await customRequest({
        query: Query.quotation,
        variables: {
          id: quotation_id,
        },
      });
      if (data) {
        setQuotation(data);
      }
    } catch (error) {
      messageError({
        context: "useEmission - quotation",
        message: "Error al obtener cotizacion",
      });
    }
  };

  useEffect(() => {
    if (quotationId) {
      getQuotationById(Number(quotationId));
    }
  }, [quotationId]);

  const getMinBornTime = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.MinBornEmissionTime },
        },
      })
        .then((data: IAppSetting) => {
          setMinBornTime(() => data);
          return resolve();
        })
        .catch((error: any) => {
          messageError({
            context: "Emission.getMinBornTime.1",
            message: error?.message,
          });
          return resolve();
        });
    });
  };

  const getIdsLiderarForMotorcycles = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: {
            key: EnumsValues.SettingKeys.idsLiderarVehicleTypeMotorcycle,
          },
        },
      });
      if (data) {
        setIdsLiderarForMotorcycles(() =>
          data.setting_value.replace(/ /g, "").split(",")
        );
      }
    } catch (error: any) {
      messageError({
        context: "Emission.idsLiderarVehicleTypeMotorcycle.1",
        message: error?.message,
      });
    }
  };

  const getIdsLiderarForTrucks = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingKeys.idsLiderarVehicleTypeTruck },
        },
      });

      if (data) {
        setIdsLiderarForTrucks(() =>
          data.setting_value.replace(/ /g, "").split(",")
        );
      }
    } catch (error: any) {
      messageError({
        context: "Emission.idsLiderarVehicleTypeTruck.1",
        message: error?.message,
      });
    }
  };

  const getQuotation = async (request_id: number, coverage_id: number) => {
    try {
      const data: IQuotationForEmision = await customRequest({
        query: Query.quotationByRequestAndCoverage,
        variables: {
          request_id,
          coverage_id,
        },
      });
      if (data) {
        setQuotation(data);
      }
    } catch (error) {
      messageError({
        context: "getQuotation_by_request_and_coverage",
        message: "Error al obtener cotizaciones",
      });
    }
  };

  const getFuelTypes = async (quotationParam: IQuotationForEmision) => {
    let fuel_type_available: string[] | null = null;

    const defaultFuelTypeIdLiderar: string | null | undefined =
      quotationParam.quotation_request.brand_model.fuel_type_default_id_liderar;

    switch (defaultFuelTypeIdLiderar) {
      case EnumsValues.FuelTypeIdLiderar.NAFTA:
        fuel_type_available = [
          EnumsValues.FuelTypeIdLiderar.GNC,
          EnumsValues.FuelTypeIdLiderar.NAFTA,
        ];
        break;
      case "":
      case null:
      case undefined:
        fuel_type_available = [EnumsValues.FuelTypeIdLiderar.NAFTA];
        break;
      default:
        fuel_type_available = [defaultFuelTypeIdLiderar];
        break;
    }

    try {
      const data: IFuel[] = await customRequest({
        query: Query.fuel_types,
        variables: {
          filter: {
            activo_web: true,
            id_liderar: fuel_type_available,
          },
        },
      });
      if (data) {
        setFuelTypes(data);
      }
    } catch (error) {
      messageError({
        context: "getFuelTypes",
        message: "Error al obtener tipo de combustibles",
      });
    }
  };

  const getBodyworkTypes = async (
    quotationParam: IQuotationForEmision,
    idsLiderarForTrucksParam: string[]
  ) => {
    let bodywork_type_available: string[] | null = [];

    const vehicleTypeIdLiderar =
      quotationParam.quotation_request.vehicle_type.id_liderar;

    const defaultBodyWorkIdLiderar =
      quotationParam.quotation_request.brand_model
        .bodywork_type_default_id_liderar;

    const isTruck = idsLiderarForTrucksParam.includes(vehicleTypeIdLiderar);

    if (isTruck || vehicleTypeIdLiderar === "") {
      bodywork_type_available = null;
    } else {
      bodywork_type_available = [defaultBodyWorkIdLiderar];
    }

    try {
      const data: IBodyWork[] = await customRequest({
        query: Query.bodywork_types,
        variables: {
          filter: {
            activo_web: true,
            id_liderar: bodywork_type_available,
          },
        },
      });
      if (data) {
        setBodyworkTypes(data);
      }
    } catch (error) {
      messageError({
        context: "bodywork_types",
        message: "Error al obtener tips de carroceria",
      });
    }
  };

  const getGendersTypes = async () => {
    try {
      const data: IGender[] = await customRequest({
        query: Query.genders,
        variables: {
          filter: {
            activo_web: true,
          },
        },
      });
      if (data) {
        setGendersTypes(data);
      }
    } catch (error) {
      messageError({
        context: "getGenders",
        message: "Error al obtener géneros",
      });
    }
  };

  const getPaymentsTypes = async () => {
    try {
      const data: ICreditCardTypes[] = await customRequest({
        query: Query.credit_cards,
        variables: {
          filter: {
            activo_web: true,
          },
        },
      });
      if (data) {
        setPaymentTypes(data);
      }
    } catch (error) {
      messageError({
        context: "credit_cards",
        message: "Error al obtener tipos de pago",
      });
    }
  };

  const getNationalities = async () => {
    try {
      const data: INationality[] = await customRequest({
        query: Query.nationalities,
        variables: {
          filter: {
            activo_web: true,
          },
        },
      });
      if (data) {
        setNationalities(data);
      }
    } catch (error) {
      messageError({
        context: "getNationalities",
        message: "Error al obtener listado de nacionalidades",
      });
    }
  };

  useEffect(() => {
    if (HistoryState) {
      getQuotation(HistoryState?.request_id, HistoryState?.coverage_id);
    }
  }, [HistoryState]);

  useEffect(() => {
    getMinBornTime();
    getGendersTypes();
    getPaymentsTypes();
    getNationalities();
    getIdsLiderarForMotorcycles();
    getIdsLiderarForTrucks();
  }, []);

  useEffect(() => {
    if (quotation && idsLiderarForTrucks) {
      getBodyworkTypes(quotation, idsLiderarForTrucks);
    }
  }, [idsLiderarForTrucks, quotation]);

  useEffect(() => {
    if (quotation) {
      getFuelTypes(quotation);
    }
  }, [quotation]);

  return {
    quotation,
    fuelTypes,
    bodyworkTypes,
    gendersTypes,
    paymentTypes,
    nationalities,
    minBornTime,
    idsLiderarForMotorcycles,
  };
};

export default useEmission;
