export const BODYWORK_TYPES = `query bodywork_types(
  $filter: FilterBodyworkTypeInput
  $orderBy: BodyworkTypeOrderInput
  $skip: Int
  $take: Int
  $searchText:String
) {
  bodywork_types(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take,searchText:$searchText) {
    __typename
    ... on  BodyworkType{
      id
      description
      id_liderar
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;
