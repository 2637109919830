export const CREDIT_CARD_TYPES = `
query credit_cards(
  $filter: FilterCreditCardInput
  $orderBy: CreditCardOrderInput
  $skip: Int
  $take: Int
  $searchText:String
) {
  credit_cards(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take,searchText:$searchText) {
    __typename
    ... on CreditCard {
      id
      description
      id_liderar
      activo_web
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
