import { useEffect, useState } from "react";
import moment from "moment";
import { FormInstance } from "antd";

import { Query } from "../../../services/graphql/query";
import { CustomMessage } from "../../../shared";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { IRenovation } from "../../../services/Renovation";
import { zeroPad } from "../../../shared/utils";
import { DEFAULT_FORMAT_DATE } from "../../../shared/MomentJS";
import { formNames } from "../utils/formNames";
import { IQuotation } from "../../../services/IQuotation";

interface IUserRenovationProloadFormProps {
  form: FormInstance;
  renovationId?: number;
  quotationId?: number;
}

export const useRenovationPreloadForm = (
  props: IUserRenovationProloadFormProps
): {
  renovationData: IRenovation | undefined;
  quotation: IQuotation | undefined;
} => {
  const { form, renovationId, quotationId } = props;

  const { customRequest } = GraphqlService();
  const { messageError, messageModalError } = CustomMessage();
  const [renovation, setRenovation] = useState<IRenovation>();
  const [quotation, setQuotation] = useState<IQuotation>();

  const getRenovation = async (id: number) => {
    try {
      const response: IRenovation = await customRequest({
        query: Query.renovation,
        variables: {
          id,
        },
      });

      if (response) {
        setRenovation(response);
      }
      if (!response.policy) {
        messageModalError({
          context: "useRenovationPreloadForm - getRenovation",
          message: "Poliza no encontrada",
        });
        setRenovation(undefined);
      }
    } catch (error: any) {
      messageError({
        context: "get - renovation",
        message: error.message || "Error al obtener datos de renovación",
      });
    }
  };

  useEffect(() => {
    if (renovationId) {
      getRenovation(renovationId);
    }
  }, [renovationId]);

  const getQuotation = async (id: number) => {
    try {
      const response: IQuotation = await customRequest({
        query: Query.quotation,
        variables: {
          id,
        },
      });

      if (response) {
        setQuotation(response);
      }
    } catch (error) {
      messageError({
        context: "get - quotation",
        message: "Error al obtener datos de cotización",
      });
    }
  };

  useEffect(() => {
    if (quotationId) {
      getQuotation(quotationId);
    }
  }, [quotationId]);

  const {
    clientInformation,
    generalData,
    information,
    naturalPerson,
    userInformation,
    vehicleData,
    quote,
  } = formNames;

  useEffect(() => {
    if (renovation && renovation.policy) {
      const lastEndoso =
        renovation.policy?.endoso[renovation.policy?.endoso.length - 1];
      const paymentMethod = lastEndoso?.paymentMethod[0];
      const paymentNumber = lastEndoso?.paymentMethod[1];

      form.setFieldsValue({
        //Information
        [information.REQUEST_ID.name]: renovation.id,
        [information.ASSIGNED_POLICY_NUMBER.name]: renovation.policy_number,
        [information.RENEW_POLICY_NUMBER.name]: renovation.policy_number,
        //Client Information
        [clientInformation.COMPANY_NAME_OR_SURNAME.name]:
          renovation.policy?.asegurado[0].nombre_aseg,
        [clientInformation.FISCAL_CONDITION.name]: renovation.policy
          ? zeroPad(Number(renovation.policy.asegurado[0].tipo_iva), 2)
          : undefined,
        [clientInformation.CUIT_CUIL.name]:
          renovation.policy?.asegurado[0].cuit,
        //User Information
        [userInformation.USER_HOME.name]: renovation.policy?.asegurado[0].calle,
        [userInformation.USER_HOME_NUMBER.name]:
          renovation.policy?.asegurado[0].numero,
        [userInformation.USER_HOME_FLOOR.name]:
          renovation.policy?.asegurado[0].piso,
        [userInformation.USER_HOME_DEPARTMENT.name]:
          renovation.policy?.asegurado[0].departamento,
        [userInformation.USER_PAYMENT_METHOD.name]: paymentMethod || null,
        [userInformation.USER_PAYMENT_NUMBER.name]: paymentNumber || null,
        //Natural person
        [naturalPerson.NATURAL_PERSON_GENDER.name]:
          renovation.policy?.asegurado[0].sexo,
        [naturalPerson.NATURAL_PERSON_BIRTH_DATE.name]: moment(
          renovation.policy?.asegurado[0].fecha_nacimiento,
          DEFAULT_FORMAT_DATE
        ),
        //Vehicle information
        [vehicleData.VEHICLE_CHASIS_NUMBER.name]: renovation.vehicle_chassis,
        [vehicleData.VEHICLE_ENGINE_NUMBER.name]:
          renovation.vehicle_engine_number,
        [vehicleData.VEHICLE_PATENT.name]: renovation.vehicle_license_plate,
      });
    }
  }, [renovation]);

  useEffect(() => {
    if (quotation) {
      form.setFieldsValue({
        [generalData.ADJUSTMENT_RATE.name]:
          quotation.quotation_request?.adjustment_rate?.description,
        [generalData.COVERAGE_TIME.name]:
          quotation.quotation_request?.coverage_period?.id_liderar,
        [quote.QUOTATION_PRIMA.name]:
          quotation.total_no_ice || quotation.total_ice || "",
      });
    }
  }, [quotation]);

  return { renovationData: renovation, quotation };
};

export default useRenovationPreloadForm;
