export const CREATE_PLEDGED_CREDITOR = `
mutation createPledgedCreditor($input: PledgedCreditorInput!) {
  createPledgedCreditor(input: $input) {
    __typename
    ... on PledgedCreditor {
      id
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
