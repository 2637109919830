import { useCallback, useContext, useEffect } from "react";
import { Row, Col, Divider, Button, Card } from "antd";
import { RouteConfigComponentProps } from "react-router-config";
import {
  CarFilled,
  DashboardFilled,
  DownloadOutlined,
  FileTextFilled,
  FundFilled,
} from "@ant-design/icons";
import CoverageItem from "../CoverageItem/CoverageItem";
import SummaryItem from "./SummaryItem";
import { ContextPoliza } from "../../../shared/ContextPoliza";
import { IQuotation } from "../../../services/IQuotation";
import { coverageInitial } from "../PolizaLayout";
import ButtonLiderar from "../../ButtonLiderar/ButtonLiderar";
import { useHistory } from "react-router";
import { formatStringToMoney } from "../../../shared/utils";
import { IBaseFile } from "../../../services/defaultSchema";
import { CustomMessage, Tools } from "../../../shared";
import GraphqlService from "../../../services/graphql/GraphqlService";
import { useCheckAuthority } from "../../../shared/CustomHooks";
import { coverageId } from "../../../shared/EnumsValues";

import "./style.less";

const CoverageDetails = (
  routeProps: RouteConfigComponentProps
): JSX.Element => {
  const {
    loading,
    vehicleInfo,
    quotationError,
    coverageSelected,
    setCoverageSelected,
    accessories,
    FiscalCondition,
    quotationRequest,
  } = useContext(ContextPoliza);

  const { details, name, period, total_ice, total_no_ice } = coverageSelected;
  const history = useHistory();
  const { Query, customRequest } = GraphqlService();
  const { messageError } = CustomMessage();

  useEffect(() => {
    setCoverageSelected(coverageInitial);
  }, []);

  const handleDownloadQuotationPdf = useCallback(async () => {
    try {
      const result: IBaseFile = await customRequest({
        query: Query.getQuotationFile,
        variables: {
          quotation_id: coverageSelected.quotation_id,
        },
      });
      if (!result.file) {
        throw new Error("Ocurrió un error al descargar el archivo");
      }

      Tools.downloadFilePDF(result);
    } catch (error: any) {
      console.error(error);
      messageError({
        context: "CoverageDetails.handleDownloadQuotationPdf",
        message: error.message || "Ocurrió un error al descargar el archivo",
      });
    }
  }, [coverageSelected]);

  return (
    useCheckAuthority(routeProps) || (
      <div className="CoverageDetails">
        <Row>
          <Col className="coverages" xs={24} sm={24} md={24} lg={14} xl={15}>
            <h2 className="title">Coberturas</h2>
            {quotationRequest?.quotation.length ? (
              <h6 className="CoverageDetails__legalInfo">
                NO IMPLICA ACEPTACIÓN DE LAS COBERTURAS COTIZADAS, QUEDANDO
                SUPEDITADAS A LAS PAUTAS DE SUSCRIPCIÓN DE LA COMPAÑÍA
              </h6>
            ) : null}
            {loading ? (
              <div className="cotizando">
                <img
                  src="/assets/cotizando.gif"
                  width={200}
                  alt="Aguarde un momento, estamos cotizando"
                />
                <h1>Aguarde un momento, estamos cotizando...</h1>
              </div>
            ) : quotationError ? (
              <div className="message-container">
                <img
                  src="/assets/error.gif"
                  width={200}
                  alt="Ocurrió un error al obtener cotizaciones"
                />
                <h1 className="quotation-error">
                  ¡Ups, ocurrió un error al obtener cotizaciones!
                </h1>
              </div>
            ) : quotationRequest?.quotation.length === 0 ? (
              <>
                <div className="message-container">
                  <h1 className="quotation-empty">
                    No se han encontrado coberturas para los datos ingresados
                  </h1>
                </div>
              </>
            ) : (
              <>
                <p className="subtitle">
                  Selecciona una cobertura para ver el detalle
                </p>
                <div className="coverages-list">
                  {quotationRequest?.quotation.map(
                    (coverage: IQuotation, index) => {
                      return (
                        <div key={index}>
                          <CoverageItem coverage={coverage} />
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}
          </Col>
          <Col
            className="coverage-summary-container"
            xs={24}
            sm={24}
            md={24}
            lg={9}
            xl={8}
          >
            <div className="coverage-summary">
              <h2 className="title">Resumen</h2>
              <h6 className="coverage-summary__numerator_id">
                ID Solicitud: {quotationRequest?.numerator_id}
              </h6>
              <span></span>
              <Divider />
              <Card bordered={false} className="card vehicle-summary">
                <CarFilled className="icon" />
                <p className="subtitle">Vehículo</p>
                <SummaryItem label="Marca" value={vehicleInfo.brand} />
                <SummaryItem label="Modelo" value={vehicleInfo.model} />
                <SummaryItem label="Año" value={vehicleInfo.year} />
                <SummaryItem
                  label="Suma asegurada"
                  value={formatStringToMoney(
                    coverageSelected.coverage_id === coverageId.A
                      ? "0"
                      : vehicleInfo.amount_insured
                  )}
                />
                <SummaryItem
                  label="Cláusula de ajuste"
                  value={vehicleInfo.adjustment_rate}
                />
              </Card>
              <Divider />
              <Card bordered={false} className="card accessories-summary">
                <DashboardFilled className="icon" />
                <p className="subtitle">Accesorios</p>
                <SummaryItem
                  label="Cúpula"
                  value={formatStringToMoney(
                    coverageSelected.coverage_id === coverageId.A
                      ? "0"
                      : accessories.dome
                  )}
                />
                <SummaryItem
                  label="Equipo de frío"
                  value={formatStringToMoney(
                    coverageSelected.coverage_id === coverageId.A
                      ? "0"
                      : accessories.cold_equipment
                  )}
                />
                <SummaryItem
                  label="GNC"
                  value={formatStringToMoney(
                    coverageSelected.coverage_id === coverageId.A
                      ? "0"
                      : accessories.cng
                  )}
                />
                <SummaryItem
                  label="Llantas especiales"
                  value={formatStringToMoney(
                    coverageSelected.coverage_id === coverageId.A
                      ? "0"
                      : accessories.special_tires
                  )}
                />
                <SummaryItem
                  label="Total Accesorios"
                  value={formatStringToMoney(
                    coverageSelected.coverage_id === coverageId.A
                      ? "0"
                      : accessories.total_accessories
                  )}
                />
              </Card>
              <Divider />
              <Card bordered={false} className="card coverage-selected-summary">
                <FileTextFilled className="icon" />
                <p className="subtitle">Cobertura</p>
                <SummaryItem label="Nombre" value={name} />
                <SummaryItem label="Período" value={period} />
                {Number(total_no_ice) !== 0 && (
                  <SummaryItem
                    label="Premio"
                    value={`${
                      total_no_ice === "-"
                        ? "-"
                        : formatStringToMoney(total_no_ice)
                    }`}
                  />
                )}
                {Number(total_ice) !== 0 && (
                  <SummaryItem
                    label={
                      coverageSelected.coverage_id === coverageId.A
                        ? "Premio"
                        : "Premio Granizo"
                    }
                    value={`${
                      total_ice === "-" ? "-" : formatStringToMoney(total_ice)
                    }`}
                  />
                )}
                <SummaryItem label="Detalles" value={details} />
              </Card>
              <Divider />
              <Card bordered={false} className="card coverage-selected-summary">
                <FundFilled className="icon" />
                <p className="subtitle">Condición fiscal</p>
                <SummaryItem
                  label="Condición fiscal"
                  value={FiscalCondition || "-"}
                />
              </Card>
              <Divider />
              <Button
                type="primary"
                shape="round"
                icon={
                  <DownloadOutlined
                    style={{
                      color:
                        coverageSelected === coverageInitial
                          ? "#e0e0e0"
                          : "#763790",
                    }}
                  />
                }
                size={"large"}
                className={
                  coverageSelected === coverageInitial
                    ? "disabled-download-button"
                    : "download-button"
                }
                disabled={coverageSelected === coverageInitial}
                onClick={() => handleDownloadQuotationPdf()}
              >
                Descargar cotización
              </Button>
              <div className="buttons">
                <ButtonLiderar
                  color="white"
                  buttonName="Volver"
                  onClick={() => history.goBack()}
                />
                <ButtonLiderar
                  color="violet"
                  buttonName="Emitir"
                  disabled={coverageSelected === coverageInitial}
                  onClick={() =>
                    history.push({
                      pathname: "/app/policy/emission",
                      state: coverageSelected,
                    })
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  );
};

export default CoverageDetails;
