export const QUOTATION = `
query quotation($id: Int!) {
  quotation(id: $id) {
    __typename
    ... on Quotation {
      id
      total_ice
      total_no_ice
      coverage {
        id_liderar
        descrip_web
        description
      }
      quotation_request {
        dome
        special_tires
        cold_equipment
        cng
        total_accessories
        vehicle_type {
          id_liderar
        }
        adjustment_rate {
          description
        }
        brand_model {
          brand
          model
          fuel_type_default_id_liderar
          bodywork_type_default_id_liderar
        }
        coverage_period {
          id_liderar
          description
        }
        producer {
          id_liderar
          zona_riesgo
          cod_prod_auto
          cod_prod_moto
        }
        fiscal_condition {
          id_liderar
          description
        }
        insured_amount
        destiny {
          id_liderar
        }
        province {
          id
          id_liderar
          description
        }
        postal_code {
          id
          location
          codpostal
        }
        name
        dni_cuit
        year
        additional_hail
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;

export const QUOTATION_BY_REQUEST_AND_COVERAGE = `query quotationByRequestAndCoverage($coverage_id: Int!, $request_id: Int!) {
  quotationByRequestAndCoverage(
    coverage_id: $coverage_id
    request_id: $request_id
  ) {
    __typename
    ... on Quotation {
      id
      total_ice
      total_no_ice
      coverage {
        id_liderar
        descrip_web
        description
      }
      quotation_request {
        dome
        special_tires
        cold_equipment
        cng
        total_accessories
        vehicle_type {
          id_liderar
        }
        adjustment_rate {
          description
        }
        brand_model {
          brand
          model
          fuel_type_default_id_liderar
          bodywork_type_default_id_liderar
        }
        coverage_period {
          id_liderar
          description
        }
        producer {
          id_liderar
          zona_riesgo
          cod_prod_auto
          cod_prod_moto
        }
        fiscal_condition {
          id_liderar
          description
        }
        insured_amount
        destiny {
          id_liderar
        }
        province {
          id
          id_liderar
          description
        }
        postal_code {
          id
          location
          codpostal
        }
        name
        dni_cuit
        year
        additional_hail
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const QUOTATIONS = `query quotations(
  $filter: FilterQuotationInput
  $orderBy: QuotationOrderInput
  $skip: Int
  $take: Int
) {
  quotations(filter: $filter, orderBy: $orderBy, skip: $skip, take: $take) {
    __typename
    ... on Quotation {
      id
      request_id
      coverage_id
      total_ice
      total_no_ice
      coverage {
        descrip_web
      }
      quotation_request {
        id
        dni_cuit
        name
        year
        insured_amount
        numerator_id
        renovation_data {
          id
          due_date
          policy_number
        }
        brand_model {
          brand
          model
          fuel_type_default_id_liderar
          bodywork_type_default_id_liderar
        }
        coverage_period {
          description
        }
        destiny {
          description
        }
        fiscal_condition {
          description
          id_liderar
        }
        postal_code {
          location
          codpostal
        }
        producer {
          nombre
          id_liderar
        }
        province {
          description
        }
        vehicle_type {
          codigo_tarifacion
          description
        }
      }
      createdBy{
        username
      }
      its
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_ALL_QUOTATION_REQUEST = `
query getAllQuotationRequest {
  getAllQuotationRequest {
    __typename
    ... on QuotationRequest {
      id
      name
      dni_cuit
      year
      insured_amount
      adjustment_rate {
        tasa_aumento_list
      }
      brand_model {
        brand
        model
      }
      coverage_period {
        description
      }
      destiny {
        description
      }
      fiscal_condition {
        description
        id_liderar
      }
      postal_code {
        location
        codpostal
      }
      producer {
        nombre
        id_liderar
      }
      province {
        description
      }
      vehicle_type {
        codigo_tarifacion
        description
      }
      quotation {
        total_ice
        total_no_ice
        coverage{
          descrip_web
        }
      }
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const GET_QUOTATION_FILE = `query getQuotationFile($quotation_id: Int!) {
  getQuotationFile(quotation_id: $quotation_id) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}`;

export const COUNT_QUOTATIONS = `
query countQuotations(
  $filter: FilterQuotationInput
  $orderBy: QuotationOrderInput
) {
  countQuotations(filter: $filter, orderBy: $orderBy) {
    __typename
    ... on Count {
      count
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;

export const RENOVATION_QUOTATED_PDF = `
query renovationQuotatedPDF($id: Int!) {
  renovationQuotatedPDF(id: $id) {
    __typename
    ... on BaseFile {
      filename
      mimetype
      encoding
      file
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
