export const PRODUCERS = `
query producers(
  $filter: FilterProducerInput,
  $orderBy:  ProducerOrderInput,
  $searchText: String,
  $skip:  Int,
  $take: Int,
  ) {
  producers(
    filter: $filter,
    orderBy: $orderBy,
    searchText: $searchText,
    skip: $skip,
    take: $take,
) {
    __typename
    ... on Producer{
      id
      id_liderar
      nombre
      cuit
      group
      zona_riesgo
      cod_prod_auto
      cod_prod_moto
    }
    ... on ResultError {
      status_code
      message
    }
  }
}
`;
